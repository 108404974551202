import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import TextField from '@cimpress/react-components/lib/TextField';

class MissedSlaRow extends React.Component {
  updateComment = e => {
    this.props.updateComment(this.props.name, e.target.value);
  };

  toggleSelected = () => {
    this.props.toggleSelected(this.props.name);
  };

  render() {
    const { name, label, selected, data, intl } = this.props;
    const commentsArea = selected ? (
      <TextField
        value={data.comment}
        placeholder={intl.formatMessage({ id: 'WatchDog.Comments' })}
        onChange={this.updateComment}
      />
    ) : null;
    return (
      <div>
        <Checkbox checked={selected} onChange={this.toggleSelected} label={label || name} />
        {commentsArea}
      </div>
    );
  }
}

MissedSlaRow.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  selected: PropTypes.bool,
  data: PropTypes.shape({ comment: PropTypes.string }),
  updateComment: PropTypes.func,
  toggleSelected: PropTypes.func,
  intl: PropTypes.object,
};

MissedSlaRow.defaultProps = {
  data: { comment: '' },
};

export default injectIntl(MissedSlaRow);
