import React, { useState, Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '@cimpress/react-components/lib/Button';
import Modal from '@cimpress/react-components/lib/Modal';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import { PAGE_SIZE, BULK_REORDER_PAGES_LIMIT } from '../../constants/pagination';
import { bulkReorder, clearBulkReorder, fetchNextPages } from '../../actions/bulkReorderActions';
import BulkReorderReview from './BulkReorderReview';
import BulkReorderResults from './BulkReorderResults';
import { searchResultsOrderShape } from '../propTypes';
import TextField from '@cimpress/react-components/lib/TextField';
import Alert from '@cimpress/react-components/lib/Alert';

const BulkReorderModal = ({
  orders,
  fetchNextPages,
  nextPagesOrders,
  fetchNextPagesError,
  permissions,
  bulkReorder,
  clearBulkReorder,
  loading,
  submitting,
  submitted,
  areAllReorderableOrdersSelected,
  params,
  totalOrders,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [shouldKeepOriginalPromiseDate, setShouldKeepOriginalPromiseDate] = useState(false);
  const [reorderFromNextPages, setReorderFromNextPages] = useState(false);
  const [nextPagesLimit, setNextPagesLimit] = useState(calculateNextPagesLimit());

  const close = () => {
    clearBulkReorder();
    setShowModal(false);
    setShouldKeepOriginalPromiseDate(false);
    setReorderFromNextPages(false);
  };

  const open = async () => {
    setShowModal(true);
    // check if we need to reorder subsequent pages
    if (reorderFromNextPages) {
      // trigger fetching of next pages
      fetchNextPages(params, permissions, nextPagesLimit);
    }
  };

  const submit = () => {
    bulkReorder(orders.concat(nextPagesOrders), shouldKeepOriginalPromiseDate);
  };

  const onKeepOriginalPromiseDateChange = useCallback(
    () => setShouldKeepOriginalPromiseDate(shouldKeepOriginalPromiseDate => !shouldKeepOriginalPromiseDate),
    [setShouldKeepOriginalPromiseDate]
  );

  const toggleReorderFromNextPages = () => {
    setReorderFromNextPages(!reorderFromNextPages);
    setNextPagesLimit(calculateNextPagesLimit());
  };

  const onChangeNextPagesLimit = e => {
    setNextPagesLimit(parseInt(e.target.value, 10));
  };

  // will be hoisted for initial nextPagesLimit state
  function calculateNextPagesLimit() {
    // subtracting one to exclude the current page
    const subsequentPages = Math.ceil((totalOrders - params.offset) / PAGE_SIZE) - 1;
    // setting max limit at 5 pages
    return subsequentPages > BULK_REORDER_PAGES_LIMIT ? BULK_REORDER_PAGES_LIMIT : subsequentPages;
  }

  return (
    <Fragment>
      <Button variant="default" size="sm" onClick={open} disabled={loading}>
        <FormattedMessage id="BulkReorder.BulkReorder" />
      </Button>
      {/*
        allow checking of checkbox and setting of next pages limit for reorder,
        only if all reorderable orders selected
        AND also check if we are currently not on the last page
      */}
      {areAllReorderableOrdersSelected && totalOrders - params.offset > PAGE_SIZE ? (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', width: '50%' }}>
          <Checkbox checked={reorderFromNextPages} onChange={toggleReorderFromNextPages} />
          <div style={{ color: '#DC143C', paddingRight: '5px' }}>
            <FormattedMessage id="BulkReorder.AlsoReorderFromNextPages" />
          </div>
          {reorderFromNextPages && (
            <div style={{ paddingRight: '5px' }} className="textfield-min-height">
              <TextField
                name="nextPagesLimit"
                id="nextPagesLimit"
                type="number"
                value={nextPagesLimit}
                onChange={onChangeNextPagesLimit}
                min={1}
                max={calculateNextPagesLimit()}
                autoFocus
              />
            </div>
          )}
          <div style={{ color: '#DC143C' }}>
            <FormattedMessage id="BulkReorder.Pages" values={{ nextPagesLimit: nextPagesLimit }} />
          </div>
        </div>
      ) : null}
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeButton
        title={<FormattedMessage id="BulkReorder.BulkReorder" />}
        footer={
          <Fragment>
            {!submitted && !loading ? (
              <Button variant="primary" onClick={submit} disabled={submitting}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {submitting ? (
                    <div style={{ marginRight: '5px' }}>
                      <Spinner className="no-padding" size="small" />
                    </div>
                  ) : null}
                  <FormattedMessage id="BulkReorder.Submit" />
                </div>
              </Button>
            ) : null}
            <Button variant="default" onClick={close}>
              <FormattedMessage id="Global.Close" />
            </Button>
          </Fragment>
        }>
        {submitted ? (
          <BulkReorderResults />
        ) : loading ? (
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div style={{ fontSize: '22px', fontWeight: '400', paddingBottom: '10px' }}>
              <FormattedMessage
                id="BulkReorder.FetchingOrdersFromNextPages"
                values={{ nextPagesLimit: nextPagesLimit }}
              />
            </div>
            <Spinner className="no-padding" size="large" />
          </div>
        ) : (
          <Fragment>
            {fetchNextPagesError && (
              <Alert
                message={
                  <Fragment>
                    <FormattedMessage
                      id="BulkReorder.FetchNextPagesFailure"
                      values={{ nextPagesLimit: nextPagesLimit }}
                    />
                    {` : ${fetchNextPagesError}`}
                  </Fragment>
                }
                dismissible={false}
              />
            )}
            <BulkReorderReview
              orders={orders.concat(nextPagesOrders)}
              onKeepOriginalPromiseDateChange={onKeepOriginalPromiseDateChange}
              shouldKeepOriginalPromiseDate={shouldKeepOriginalPromiseDate}
            />
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
};

BulkReorderModal.propTypes = {
  orders: PropTypes.arrayOf(searchResultsOrderShape),
  nextPagesOrders: PropTypes.arrayOf(searchResultsOrderShape),
  fetchNextPagesError: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  bulkReorder: PropTypes.func.isRequired,
  clearBulkReorder: PropTypes.func.isRequired,
  areAllReorderableOrdersSelected: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  submitted: PropTypes.bool,
  params: PropTypes.object,
  totalOrders: PropTypes.number,
  intl: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    bulkReorder: { nextPagesOrders, submitting, submitted, loading, fetchNextPagesError },
  } = state;

  return {
    nextPagesOrders,
    submitting,
    submitted,
    loading,
    fetchNextPagesError,
  };
};

export default connect(mapStateToProps, { bulkReorder, clearBulkReorder, fetchNextPages })(
  injectIntl(BulkReorderModal)
);
