import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@cimpress/react-components/lib/Button';
import { connect } from 'react-redux';
import IconRemoveCircle from '@cimpress-technology/react-streamline-icons/lib/IconRemoveCircle';
import styled from 'styled-components';

import { clearSearch } from '../../actions/ordersearchactions';
import { trackEvent, TrackingEventTitles } from '../../analytics';

const ClearSearchButton = styled(Button)`
  &.btn {
    border: none;
    background: transparent;
  }
`;

const ClearButton = ({ clearSearch: clearSearchProp }) => {
  const onClick = () => {
    trackEvent(TrackingEventTitles.DOWNLOAD, {});
    clearSearchProp();
  };

  return (
    <ClearSearchButton type="link" style={{ padding: 0 }} onClick={onClick}>
      <IconRemoveCircle weight="fill" /> <FormattedMessage id="SearchBar.ClearSearchForm" />
    </ClearSearchButton>
  );
};

export default connect(null, { clearSearch })(ClearButton);
