import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '@cimpress/react-components/lib/Modal';
import AttemptFailureDetails from './AttemptFailureDetails';

export default class EventAttemptsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  close = e => {
    e.stopPropagation();
    this.setState({ showModal: false });
  };

  open = e => {
    e.stopPropagation();
    this.setState({ showModal: true });
  };

  render() {
    const { attempts } = this.props;
    // map attempts to pretty formatting
    const attemptDetails = attempts.map((a, idx) => {
      return <AttemptFailureDetails key={idx} attempt={a} />;
    });

    return (
      <div>
        <a className="clickable" onClick={this.open}>
          <FormattedMessage id="EventAttempts.ViewAttempts" />
        </a>
        <Modal
          size="lg"
          show={this.state.showModal}
          onRequestHide={this.close}
          closeOnOutsideClick
          closeButton
          title={<FormattedMessage id="EventAttempts.ErrorDetails" />}
          footer={
            <button className="btn btn-default" onClick={this.close}>
              <FormattedMessage id="Global.Close" />
            </button>
          }>
          <p>
            <b>
              <FormattedMessage id="EventAttempts.TotalAttempts" />:
            </b>{' '}
            {attempts.length}
          </p>
          {attempts.length ? attemptDetails : null}
        </Modal>
      </div>
    );
  }
}

EventAttemptsModal.propTypes = {
  attempts: PropTypes.array,
};
