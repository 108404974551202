import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { countries } from 'country-data';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import Alert from '@cimpress/react-components/lib/Alert';
import Modal from '@cimpress/react-components/lib/Modal';
import Select from '@cimpress/react-components/lib/Select';
import TextField from '@cimpress/react-components/lib/TextField';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import FulfillmentGroupSelector from './FulfillmentGroupSelector';
import { changeDelivery } from '../../../actions/operationsactions';
import ActionLink from '../../shared/ActionLink';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const UpdateCustomerAddressModal = ({ changeableFulfillmentGroups }) => {
  const [showModal, setShowModal] = useState(false);
  const [showFulfillmentGroupSelector, setShowFulfillmentGroupSelector] = useState(true);
  const [selectedFulfillmentGroupId, setSelectedFulfillmentGroupId] = useState('');
  const [address, setAddress] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [countryOption, setCountryOption] = useState();
  const dispatch = useDispatch();
  const intl = useIntl();

  const toggleFulfillmentGroup = fulfillmentGroupId => {
    if (fulfillmentGroupId === selectedFulfillmentGroupId) {
      setSelectedFulfillmentGroupId('');
    } else {
      setSelectedFulfillmentGroupId(fulfillmentGroupId);
    }
  };

  const open = () => {
    setShowModal(true);

    trackEvent(TrackingEventTitles.UPDATE_CUSTOMER_ADDRESS_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS
        .FULFILLMENT_GROUPS_AVAILABLE_FOR_ADDRESS_CHANGE]: Object.keys(changeableFulfillmentGroups),
    });
  };

  const close = () => {
    setShowModal(false);
  };

  const goToFulfullmentGroupSelection = () => {
    setShowFulfillmentGroupSelector(true);
  };

  const goToAddressEntry = () => {
    const itemsInGroup = changeableFulfillmentGroups[selectedFulfillmentGroupId];
    const newAddress = itemsInGroup && itemsInGroup.length ? itemsInGroup[0].destinationAddress : {};

    setShowFulfillmentGroupSelector(false);
    setAddress(newAddress);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setAddress({ ...address, [name]: value });
  };

  const handleCountrySelection = newCountryOption => {
    setAddress({ ...address, country: countryOption.value });
    setCountryOption(newCountryOption);
  };

  const onChangeDelivery = async () => {
    setSubmitting(true);

    const itemsInGroup = changeableFulfillmentGroups[selectedFulfillmentGroupId];
    const orderId = itemsInGroup && itemsInGroup.length ? itemsInGroup[0].orderId : '';

    await dispatch(changeDelivery(orderId, selectedFulfillmentGroupId, address));

    setSubmitting(false);

    trackEvent(TrackingEventTitles.UPDATE_CUSTOMER_ADDRESS_SUBMITTED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS
        .FULFILLMENT_GROUP_SELECTED_FOR_ADDRESS_CHANGE]: selectedFulfillmentGroupId,
    });

    close();
  };

  let modalBody;
  let modalFooter;

  if (showFulfillmentGroupSelector || !selectedFulfillmentGroupId) {
    modalBody = (
      <div>
        <h4>
          <FormattedMessage id="ItemActions.UpdateAddressDescription" />
        </h4>
        <FulfillmentGroupSelector
          fulfillmentGroups={Object.values(changeableFulfillmentGroups)}
          selectedFulfillmentGroupIds={[selectedFulfillmentGroupId]}
          toggleFulfillmentGroup={toggleFulfillmentGroup}
        />
      </div>
    );
    modalFooter = (
      <div>
        <button className="btn btn-default" onClick={close}>
          <FormattedMessage id="Global.Cancel" />
        </button>
        <button className="btn btn-primary" disabled={!selectedFulfillmentGroupId} onClick={goToAddressEntry}>
          <FormattedMessage id="Global.Next" />
        </button>
      </div>
    );
  } else if (submitting) {
    modalBody = <Spinner />;
  } else {
    const addressTextInputControl = (colWidth, placeholder, field) => (
      <div className={`col-md-${colWidth}`}>
        <div className="form-group">
          <TextField
            name={field}
            value={address[field]}
            label={placeholder}
            onChange={handleChange}
            id={`${field}-input`}
          />
        </div>
      </div>
    );
    const assignedCountries = countries.all.filter(country => country.status === 'assigned');
    const countryOptions = sortBy(assignedCountries, c => c.name).map(country => ({
      label: country.name,
      value: country.alpha2,
    }));
    const productNames = uniq(
      changeableFulfillmentGroups[selectedFulfillmentGroupId].map(item => item.merchantProductName)
    );
    const alert = (
      <Alert
        status="warning"
        title={<FormattedMessage id="ItemActions.ChangesApplyToItems" />}
        message={productNames.map((productName, idx) => {
          return <div key={idx}>{productName}</div>;
        })}
        dismissible={false}
      />
    );

    modalBody = (
      <div>
        {alert}
        <div>
          <div className="row">
            {addressTextInputControl(4, intl.formatMessage({ id: 'AddressFields.FirstName' }), 'firstName')}
            {addressTextInputControl(4, intl.formatMessage({ id: 'AddressFields.MiddleName' }), 'middleName')}
            {addressTextInputControl(4, intl.formatMessage({ id: 'AddressFields.LastName' }), 'lastName')}
          </div>
          <div className="row">
            {addressTextInputControl(12, intl.formatMessage({ id: 'AddressFields.Company' }), 'company')}
          </div>
          <div className="row">
            {addressTextInputControl(12, intl.formatMessage({ id: 'AddressFields.Address1' }), 'street1')}
          </div>
          <div className="row">
            {addressTextInputControl(6, intl.formatMessage({ id: 'AddressFields.Address2' }), 'street2')}
            {addressTextInputControl(6, intl.formatMessage({ id: 'AddressFields.DoorCode' }), 'doorCode')}
          </div>
          <div className="row">
            {addressTextInputControl(4, intl.formatMessage({ id: 'AddressFields.City' }), 'city')}
            {addressTextInputControl(4, intl.formatMessage({ id: 'AddressFields.StateOrProvince' }), 'stateOrProvince')}
            {addressTextInputControl(4, intl.formatMessage({ id: 'AddressFields.PostalCode' }), 'postalCode')}
          </div>
          <div className="row">
            <div className="col-md-6">
              <Select
                label={intl.formatMessage({ id: 'AddressFields.Country' })}
                value={countryOption}
                options={countryOptions}
                onChange={handleCountrySelection}
              />
            </div>
            {addressTextInputControl(6, intl.formatMessage({ id: 'AddressFields.Email' }), 'email')}
          </div>
          <div className="row">
            {addressTextInputControl(6, intl.formatMessage({ id: 'AddressFields.Phone' }), 'phone')}
            {addressTextInputControl(6, intl.formatMessage({ id: 'AddressFields.Extension' }), 'phoneExt')}
          </div>
        </div>
      </div>
    );

    modalFooter = (
      <div>
        <button className="btn btn-primary pull-left" onClick={goToFulfullmentGroupSelection}>
          <FormattedMessage id="Global.Back" />
        </button>
        <button className="btn btn-default" onClick={close}>
          <FormattedMessage id="Global.Cancel" />
        </button>
        <button className="btn btn-primary" onClick={onChangeDelivery}>
          <FormattedMessage id="Global.Update" />
        </button>
      </div>
    );
  }

  return (
    <div>
      <ActionLink
        text={<FormattedMessage id="ItemActions.UpdateCustomerAddress" />}
        action={open}
        disabled={isEmpty(changeableFulfillmentGroups)}
        disabledMessage={<FormattedMessage id="ItemActions.UpdateAddressNotSupported" />}
      />
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={<FormattedMessage id="ItemActions.UpdateCustomerAddress" />}
        footer={modalFooter}>
        {modalBody}
      </Modal>
    </div>
  );
};

UpdateCustomerAddressModal.propTypes = {
  changeableFulfillmentGroups: PropTypes.object,
};

export default UpdateCustomerAddressModal;
