import { Schema, arrayOf, normalize } from 'normalizr';
import auth from '../utils/auth.js';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint, schema, method = 'GET', body) {
  var headers = new Headers({
    Authorization: 'Bearer ' + auth.getAccessToken(),
  });

  let explicitDomain = endpoint.includes('http');

  if (!explicitDomain) {
    headers.append('Cimpress-Id-Token', auth.getToken());
  }

  let mode = explicitDomain ? { mode: 'cors' } : {};
  let credentials = explicitDomain ? {} : { credentials: 'same-origin' };

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  var request = {
    ...mode,
    ...credentials,
    headers: headers,
    method: method,
    body: body,
  };

  return fetch(endpoint, request).then(response => {
    if (response.ok) {
      return response.json().then(json => {
        if (schema) {
          return { statusCode: response.status, response: normalize(json, schema) };
        }
        return { statusCode: response.status, response: json };
      });
    } else if (response.status === 401) {
      // A user should never get here, since they are being checked on route change
      throw response;
    } else {
      throw response;
    }
  });
}

const orderSchema = new Schema('orders', { idAttribute: 'orderId' });

export const Schemas = {
  ORDER: orderSchema,
  SEARCH_RESULTS: { results: arrayOf(orderSchema) },
  SEARCH_RESULTS_DIRECT: { results: arrayOf({ idAttribute: 'order.orderid', order: orderSchema }) },
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => async action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  // pulls off destructed variables from callAPI action for use later
  let { endpoint, method, body } = callAPI;
  const { schema, types, actionContext, checkPermission } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  // if (!schema) {
  //  throw new Error("Specify one of the exported Schemas.");
  // }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  function actionWith(data) {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  }
  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType, actionContext }));

  if (checkPermission && !checkPermission()) {
    return next(actionWith({ type: failureType, payload: 'Permission denied', actionContext }));
  }

  try {
    const { response, statusCode } = await callApi(endpoint, schema, method, body);
    return next(
      actionWith({
        response,
        statusCode,
        type: successType,
        actionContext,
      })
    );
  } catch (errorResponse) {
    const responseBody = await ((errorResponse.text && errorResponse.text()) || Promise.resolve());
    return next(
      actionWith({
        type: failureType,
        payload: `${errorResponse.status} ${errorResponse.statusText}`,
        responseBody,
        error: { message: 'An API call failed.' },
        actionContext,
      })
    );
  }
};
