import P from 'bluebird';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import max from 'lodash/max';
import startCase from 'lodash/startCase';
import { getParamsFromQuery } from './utilityfunctions';
import { initStatuses } from '../constants/statuses';
import { CURRENT } from '../constants/itemservicestatusstates';
import { PAGE_SIZE } from '../constants/pagination';
import stringify from 'json-stringify-safe';
import { formatSearchParams } from './searchModels';
import { callFetch } from '../services/serviceHelpers';
import { attachStatus } from '../decorators/status';
import { hasMerchantReadPermission, hasFulfillerReadProfilePermission } from './permissions-v2';
import { stringify as csvStringify } from 'csv-string';
import download from 'downloadjs';
import { statusDisplayMessages } from '../components/detailsv2/events/eventDisplayMessages';

export const MAX_DOWNLOAD_RESULTS = 2000;

const CSV_HEADERS = [
  'Order Progress',
  'Item Progress',
  'Item Progress Detailed',
  'Error(s)',
  'Missed SLA(s)',
  'Pending Cancellation',
  'Platform Created Date',
  'Promised Arrival Date',
  'Item Shipped Date',
  'Expected Ship Dates',
  'Merchant ID',
  'Merchant Sales Channel',
  'Language ID',
  'Recipient Order Price Paid',
  'Recipient Order Price Paid Currency',
  'Recipient Item Price Paid',
  'Recipient Item Price Paid Currency',
  'Base Item Transfer Price',
  'Total Item Transfer Price',
  'Item Transfer Price Currency',
  'Platform Order ID',
  'Merchant Order ID',
  'Fulfiller Order ID',
  'Platform Item ID',
  'Merchant Item ID',
  'Short Item ID',
  'Fulfiller Item ID',
  'Fulfiller ID',
  'Fulfiller Name',
  'Fulfillment Group ID',
  'Short Fulfillment Group ID',
  'Document Reference URL',
  'Ordered SKU',
  'SKU',
  'Ordered Quantity',
  'Rejection Reason',
];

const CSV_HEADERS_FOR_BULK_REORDER_RESULTS = [
  'Reorder Succeeded | Original orderId',
  'Reorder Succeeded | New orderId',
  'Reorder Failed',
  'Cancellation Failed',
  'Clear Alerts Failed',
  'Skipped',
];

export const downloadToCsvBulkReorderResults = async (
  reorderSucceeded,
  reorderFailed,
  cancellationFailed,
  clearAlertsFailed,
  skipped
) => {
  const csvRows = [];
  let reorderSucceededIndex = 0;
  let reorderFailedIndex = 0;
  let cancellationFailedIndex = 0;
  let clearAlertsFailedIndex = 0;
  let skippedIndex = 0;
  let maxRowNumber = Math.max(
    reorderSucceeded.length,
    reorderFailed.length,
    cancellationFailed.length,
    clearAlertsFailed.length,
    skipped.length
  );

  csvRows.push(CSV_HEADERS_FOR_BULK_REORDER_RESULTS);
  while (maxRowNumber > 0) {
    csvRows.push([
      reorderSucceeded[reorderSucceededIndex] ? reorderSucceeded[reorderSucceededIndex].originalOrder.orderId : '',
      reorderSucceeded[reorderSucceededIndex] ? reorderSucceeded[reorderSucceededIndex].reorder.orderId : '',
      reorderFailed[reorderFailedIndex] ? reorderFailed[reorderFailedIndex].order.orderId : '',
      cancellationFailed[cancellationFailedIndex] ? cancellationFailed[cancellationFailedIndex].orderId : '',
      clearAlertsFailed[clearAlertsFailedIndex] ? clearAlertsFailed[clearAlertsFailedIndex].orderId : '',
      skipped[skippedIndex] ? skipped[skippedIndex].orderId : '',
    ]);

    reorderSucceededIndex += 1;
    reorderFailedIndex += 1;
    cancellationFailedIndex += 1;
    clearAlertsFailedIndex += 1;
    skippedIndex += 1;
    maxRowNumber -= 1;
  }

  const csv = csvStringify(csvRows);
  download(csv, 'bulk-reorder-results.csv', 'text/csv');
};

export const downloadSearchResults = async (lastQuery, totalRows, permissions, intl) => {
  let params = getParamsFromQuery(lastQuery);

  // get the full set of results, a page at a time
  params.pageSize = PAGE_SIZE;
  const promises = [];
  for (let offset = 0; offset < totalRows; offset += PAGE_SIZE) {
    promises.push(() =>
      callFetch(
        process.env.REACT_APP_ORDER_MANAGER_URL,
        'POST',
        'v2/ordersearch',
        stringify(formatSearchParams({ ...params, offset }))
      )
    );
  }

  const responses = await P.map(promises, async promise => promise(), { concurrency: 10 });
  const orders = responses
    .flatMap(response => response.results)
    .map(result => result.order)
    .map(attachStatus);
  downloadToCsv(orders, permissions, intl);
};

function getDisplayName(status, intl) {
  if (!status || !status.type) {
    return '';
  }
  const statusTypes = initStatuses(intl);
  return statusTypes[status.type].getDisplayName
    ? statusTypes[status.type].getDisplayName(status)
    : statusTypes[status.type].displayName;
}

function getDisplayPrice(price) {
  return price ? price.toFixed(2) : '';
}

function getFulfillmentTime(item) {
  const shipments = get(item, 'linkedData.shipments');
  if (shipments && shipments.length) {
    return max(shipments.map(shipment => shipment.shipmentCreationDateTime));
  }
  return '';
}

function getExpectedShipDates(item) {
  const deliveryConfigurations = get(item, 'deliveryConfigurations', []);
  const expectedShipDates = deliveryConfigurations.map(deliveryConfiguration => {
    return get(deliveryConfiguration, 'shipmentPlan.expectedShipDate', '');
  });

  return expectedShipDates.join(', ');
}

function downloadToCsv(orders, permissions, intl) {
  const csvRows = [];
  csvRows.push(CSV_HEADERS);
  forEach(orders, order => {
    const orderStatus = getDisplayName(order.computedStatus, intl);
    const hasMerchantPermission = hasMerchantReadPermission(permissions, get(order, 'items[0].merchantId'));
    forEach(order.items, item => {
      csvRows.push([
        orderStatus,
        getDisplayName(item.computedStatus, intl),
        startCase(get(item, 'computedStatus.subStatus')),
        item.computedErrors
          ? item.computedErrors.map(sla => intl.formatMessage({ id: statusDisplayMessages[sla.name] })).join(', ')
          : '',
        item.computedMissedSlas
          ? item.computedMissedSlas.map(sla => intl.formatMessage({ id: statusDisplayMessages[sla.name] })).join(', ')
          : '',
        get(item, 'statuses.pendingCancellation.state', '') === CURRENT,
        item.createdDate,
        item.localPromisedArrivalDate,
        getFulfillmentTime(item),
        getExpectedShipDates(item),
        item.merchantId,
        item.merchantSalesChannel,
        item.languageId,
        hasMerchantPermission ? getDisplayPrice(order.totalPrice) : '',
        hasMerchantPermission ? order.currencyCode : '',
        hasMerchantPermission
          ? getDisplayPrice(
              get(item, 'customsInformation.pricePaid.basePrice', 0) +
                get(item, 'customsInformation.pricePaid.shippingPrice', 0)
            )
          : '',
        hasMerchantPermission ? get(item, 'customsInformation.pricePaid.currencyCode', '') : '',
        getDisplayPrice(get(item, 'transferPrice.basePrice', 0)),
        getDisplayPrice(get(item, 'transferPrice.basePrice', 0) + get(item, 'transferPrice.shippingPrice', 0)),
        get(item, 'transferPrice.currencyCode', ''),
        item.orderId,
        item.merchantOrderId,
        item.fulfillerOrderId,
        item.itemId,
        item.merchantItemId,
        item.shortItemId,
        item.fulfillerItemId,
        item.globalFulfillerId,
        hasFulfillerReadProfilePermission(permissions, item.globalFulfillerId)
          ? get(item, 'linkedData.fulfiller.name', '')
          : '',
        item.fulfillmentGroupId,
        item.shortFulfillmentGroupId,
        item.documentReferenceUrl,
        item.orderedSkuCode,
        item.skuCode,
        item.quantity,
        get(item, 'statuses.rejected.detail') || '',
      ]);
    });
  });

  const csv = csvStringify(csvRows);
  download(csv, 'bulk-query.csv', 'text/csv');
}
