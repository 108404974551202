import React from 'react';
import PropTypes from 'prop-types';
import { formatUserDate } from '../../utils/utilityfunctions';
import { connect } from 'react-redux';

const DateDisplay = props => {
  return <span>{formatUserDate(props.date, props.displayTimezone, props.displayRawDate)}</span>;
};

DateDisplay.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  displayTimezone: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userPreferences: { displayTimezone },
  } = state;

  return {
    displayTimezone,
  };
}

export default connect(mapStateToProps, null)(DateDisplay);
