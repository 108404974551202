export function initSearchClaimTypes(intl) {
  return [
    {
      value: 'claims',
      label: intl.formatMessage({ id: 'ClaimType.withClaim' }),
    },
    {
      value: 'reorderClaim',
      label: intl.formatMessage({ id: 'ClaimType.withClaimReorder' }),
    },
  ];
}
