import { callFetch } from './serviceHelpers';

export const clearAlert = (itemId, statusName) =>
  callFetch(
    process.env.REACT_APP_ITEM_SERVICE_URL,
    'DELETE',
    `v1/items/${itemId}/statuses/${statusName}/expectedCloseDate`
  );

export const getItem = itemId => callFetch(process.env.REACT_APP_ITEM_SERVICE_URL, 'GET', `v1/items/${itemId}`);
