import { TrackingEventTitles } from '../analytics';
import { initSearchErrorStatuses } from './searchErrorStatuses';
import { initSearchDelayedStatuses } from './searchDelayedStatuses';

export const SAVED_SEARCH = 'Saved Search';
export const RECOMMENDED_SEARCH = 'Recommended Search';
export const TIME_PERIOD_SEARCH = 'Time Period Search';

export function initRecommendedSearches(intl) {
  const errorStatuses = initSearchErrorStatuses(intl);
  const delayedStatuses = initSearchDelayedStatuses(intl);

  return {
    timePeriodSearches: [
      {
        name: intl.formatMessage({ id: 'ResultsSection.LastHour' }),
        key: '1 hour',
        trackingEventTitle: TrackingEventTitles.RECENT_ORDERS_LAST_HOUR,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: [],
          duration: {
            value: 1,
            unit: 'hours',
          },
        },
      },
      {
        name: intl.formatMessage({ id: 'ResultsSection.LastXHours' }, { timerange: 4 }),
        key: '4 hours',
        trackingEventTitle: TrackingEventTitles.RECENT_ORDERS_LAST_FOUR_HOURS,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: [],
          duration: {
            value: 4,
            unit: 'hours',
          },
        },
      },
      {
        name: intl.formatMessage({ id: 'ResultsSection.LastXHours' }, { timerange: 12 }),
        key: '12 hours',
        trackingEventTitle: TrackingEventTitles.RECENT_ORDERS_LAST_TWELVE_HOURS,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: [],
          duration: {
            value: 12,
            unit: 'hours',
          },
        },
      },
      {
        name: intl.formatMessage({ id: 'ResultsSection.LastXHours' }, { timerange: 24 }),
        key: '24 hours',
        trackingEventTitle: TrackingEventTitles.RECENT_ORDERS_LAST_TWENTY_FOUR_HOURS,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: [],
          duration: {
            value: 24,
            unit: 'hours',
          },
        },
      },
    ],
    recommendedSearches: [
      {
        name: intl.formatMessage({ id: 'RecommendedSearches.Blocked' }),
        key: 'blocked',
        trackingEventTitle: TrackingEventTitles.RECOMMENDED_SEARCH_ALL_BLOCKED_ORDERS,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: null,
          selectedErrorStatuses: errorStatuses.filter(s =>
            [
              'rejected',
              'documentReviewRejected',
              'inventoryCheckRejected',
              'prepressFailed',
              'suspended',
              'onHold',
            ].includes(s.value)
          ),
          sortField: null,
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: [],
        },
      },
      {
        name: intl.formatMessage({ id: 'RecommendedSearches.MissedSLA' }),
        key: 'missedSLA',
        trackingEventTitle: TrackingEventTitles.RECOMMENDED_SEARCH_MISSED_SLA,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: null,
          selectedErrorStatuses: [],
          sortField: null,
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: delayedStatuses.filter(s =>
            [
              'pendingCancellation',
              'fulfillment',
              'awaitingFulfillerResponse',
              'preparing',
              'documentReview',
              'documentReviewFeedback',
            ].includes(s.value)
          ),
        },
      },
      {
        name: intl.formatMessage({ id: 'RecommendedSearches.PendingCancellation' }),
        key: 'pendingCancellation',
        trackingEventTitle: TrackingEventTitles.RECOMMENDED_SEARCH_PENDING_CANCELLATION,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: delayedStatuses.filter(s => ['pendingCancellation'].includes(s.value)),
        },
      },
      {
        name: intl.formatMessage({ id: 'RecommendedSearches.AwaitingCustomerFeedback' }),
        key: 'awaitingCustomerFeedback',
        trackingEventTitle: TrackingEventTitles.RECOMMENDED_SEARCH_AWAITING_CUSTOMER_FEEDBACK,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: delayedStatuses.filter(s => ['documentReviewFeedback'].includes(s.value)),
        },
      },
      {
        name: intl.formatMessage({ id: 'RecommendedSearches.AwaitingFulfillment' }),
        key: 'awaitingFulfillment',
        trackingEventTitle: TrackingEventTitles.RECOMMENDED_SEARCH_AWAITING_FULFILLMENT,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: delayedStatuses.filter(s => ['fulfillment'].includes(s.value)),
        },
      },
      {
        name: intl.formatMessage({ id: 'RecommendedSearches.AwaitingFulfillerResponse' }),
        key: 'awaitingFulfillerResponse',
        trackingEventTitle: TrackingEventTitles.RECOMMENDED_SEARCH_AWAITING_FULFILLER_RESPONSE,
        filters: {
          selectedOrderTypes: [],
          selectedClaimTypes: [],
          selectedComplaintTypes: [],
          selectedChangeRequestTypes: [],
          searchTerm: null,
          sortOrder: 'desc',
          selectedErrorStatuses: [],
          sortField: 'createdDate',
          selectedSalesChannels: [],
          selectedProductCategories: [],
          selectedMerchants: [],
          selectedStatuses: [],
          selectedFulfillers: [],
          selectedDelayedStatuses: delayedStatuses.filter(s => ['awaitingFulfillerResponse'].includes(s.value)),
        },
      },
    ],
  };
}
