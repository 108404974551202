import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField } from '@cimpress/react-components';
import get from 'lodash/get';

import { getCallbackUrl, updateCallbackUrl } from '../../../actions/devtoolsactions';
import ActionLink from '../../shared/ActionLink';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const CallbackUrlModal = ({ hasPermission, orderId, canUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [input, setInput] = useState('');
  const callbackInfo = useSelector(state => get(state, 'devtools.callbackInfo'));
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (hasPermission) {
      dispatch(getCallbackUrl(orderId));
    }
  }, [hasPermission, orderId, dispatch]);

  const close = () => {
    setShowModal(false);
    setIsEditing(false);
  };

  const open = () => {
    setInput(callbackInfo.url);
    setShowModal(true);

    trackEvent(TrackingEventTitles.CALLBACK_URL_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: orderId,
    });
  };

  const edit = () => {
    setIsEditing(true);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setInput(callbackInfo.url);
  };

  const handleChange = e => {
    setInput(e.target.value);
  };

  const onFormSubmit = async e => {
    e.preventDefault();

    const response = await dispatch(updateCallbackUrl(input, callbackInfo));

    if (response.statusCode < 400) {
      setIsEditing(false);
    }

    trackEvent(TrackingEventTitles.CALLBACK_URL_MODAL_SUBMITTED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: orderId,
    });
  };

  const title = canUpdate
    ? intl.formatMessage({ id: 'DeveloperTools.ViewEditURL' })
    : intl.formatMessage({ id: 'DeveloperTools.ViewURL' });

  const modalTitle = `${
    isEditing ? intl.formatMessage({ id: 'Global.Edit' }) : intl.formatMessage({ id: 'Global.View' })
  } ${intl.formatMessage({ id: 'DeveloperTools.CallbackURL' })}`;
  const modalFooter = (
    <div>
      {canUpdate ? (
        isEditing ? (
          <button className="btn btn-primary" onClick={cancelEdit}>
            <FormattedMessage id="Global.Cancel" />
          </button>
        ) : (
          <button className="btn btn-primary" onClick={edit}>
            <FormattedMessage id="Global.Edit" />
          </button>
        )
      ) : null}
      <button className="btn btn-default" onClick={close}>
        <FormattedMessage id="Global.Close" />
      </button>
    </div>
  );

  return (
    <div>
      <ActionLink
        text={title}
        action={open}
        disabled={!callbackInfo}
        disabledMessage={intl.formatMessage({ id: 'DeveloperTools.NoCallbackURL' })}
      />
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={modalTitle}
        footer={modalFooter}>
        <div>
          {isEditing ? (
            <form onSubmit={onFormSubmit}>
              <TextField
                onChange={handleChange}
                value={input}
                rightAddon={
                  <button className="btn btn-primary" onClick={onFormSubmit}>
                    <FormattedMessage id="Global.Update" />
                  </button>
                }
              />
            </form>
          ) : (
            <pre>{callbackInfo ? callbackInfo.url : ''}</pre>
          )}
        </div>
      </Modal>
    </div>
  );
};

CallbackUrlModal.propTypes = {
  orderId: PropTypes.string,
  canUpdate: PropTypes.bool,
  hasPermission: PropTypes.bool,
};

export default CallbackUrlModal;
