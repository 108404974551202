import {
  ACKNOWLEDGE_DISCREPANCY_REQUEST,
  ACKNOWLEDGE_DISCREPANCY_SUCCESS,
  ACKNOWLEDGE_DISCREPANCY_FAILURE,
} from '../constants/actiontypes';
import stringify from 'json-stringify-safe';
import { callFetch } from '../services/serviceHelpers';

export const acknowledgeDiscrepancies = (merchantId, discrepancies) => async dispatch => {
  dispatch({ type: ACKNOWLEDGE_DISCREPANCY_REQUEST });
  try {
    const response = await callFetch(
      process.env.REACT_APP_OOPS_URL,
      'POST',
      '/api/v1/discrepancies',
      stringify({
        merchantId: merchantId,
        discrepancies: discrepancies,
      })
    );
    dispatch({ type: ACKNOWLEDGE_DISCREPANCY_SUCCESS, response });
  } catch (error) {
    dispatch({
      type: ACKNOWLEDGE_DISCREPANCY_FAILURE,
      responseBody: error,
      payload: `${error.status} ${error.statusText}`,
      error: { message: 'An API call failed.' },
    });
  }
};
