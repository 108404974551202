import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import ReactSmoothCollapse from 'react-smooth-collapse';
import { eventDisplayMessages } from './eventDisplayMessages';
import eventDisplayDetails from './eventDisplayDetails';
import DetailsPanel from './DetailsPanel';
import EventAttemptsModal from './EventAttemptsModal';
import FulfillmentEventSummary from './FulfillmentEventSummary.jsx';
import DateDisplay from '../../shared/dateDisplay.jsx';
import maxBy from 'lodash/maxBy';
import startCase from 'lodash/startCase';

const DEFAULT_ATTEMPTS = [];

class EventRow extends React.Component {
  constructor(props) {
    super(props);
    const { event, intl } = this.props;
    const { eventType } = event;
    const eventTypeDisplay = eventDisplayMessages[eventType]
      ? intl.formatMessage({ id: eventDisplayMessages[eventType](event) })
      : eventType;
    this.state = {
      eventTypeDisplay,
    };
  }

  toggleExpanded = e => {
    // don't expand/collapse if user is trying to copy text
    if (getSelection().toString().length) {
      return;
    }

    const { toggleExpanded, event } = this.props;

    toggleExpanded(event.eventId, e);
  };

  toggleSelected = e => {
    this.props.toggleSelected(this.props.event.eventId, e);
  };

  render() {
    const { event, eventsNotSent, showJson, totalQuantity, itemId, isExpanded } = this.props;
    const {
      eventId,
      attempts = DEFAULT_ATTEMPTS,
      eventType,
      eventSourceId,
      additionalData,
      eventDate,
      detail,
      createdOnBehalfOf,
    } = event;
    const hasHumanReadableDetails = Boolean(eventDisplayDetails[eventType]);
    const showToggle =
      this.props.showJson ||
      (!this.props.showJson && (hasHumanReadableDetails || eventSourceId)) ||
      eventType === 'fulfillment';

    // in case of fulfillment, this is going to default to the shipping message
    let eventTypeDisplay;
    if (eventType === 'fulfillment') {
      eventTypeDisplay = <FulfillmentEventSummary additionalData={additionalData} />;
    } else if (eventType === 'unspecified' && additionalData && additionalData.fulfillerRelatedLinks) {
      eventTypeDisplay = <FormattedMessage id="EventTypes.FULFILLER_LINKS" />;
      let links = additionalData.fulfillerRelatedLinks;
      if (Object.keys(links).length === 1) {
        let linkKey = Object.keys(links)[0];
        eventTypeDisplay = (
          <span>
            {eventTypeDisplay} (
            <a target="_blank" href={links[linkKey]} rel="noopener noreferrer">
              {linkKey} <i className="fa fa-sm fa-external-link" />
            </a>
            )
          </span>
        );
      }
    } else if (eventType === 'unspecified') {
      let humanReadable = startCase(additionalData.subType || additionalData.description || '');
      humanReadable = humanReadable.length > 100 ? humanReadable.substr(0, 100) + '...' : humanReadable;
      eventTypeDisplay = humanReadable || this.state.eventTypeDisplay;
    } else {
      eventTypeDisplay = this.state.eventTypeDisplay;
    }

    const attemptSuccess = attempts.length ? attempts.reduce((memo, att) => (att.success ? true : memo), false) : false;
    const latestSuccessfulAttempt = maxBy(attempts, a => (a.success ? a.createdDate : null));
    const attemptSuccessTime = attemptSuccess ? latestSuccessfulAttempt.createdDate : '';

    const detailsToggle = (
      <i
        className={`fa fa-lg clickable fa-angle-right ${isExpanded ? 'toggle-quarter open' : 'toggle-quarter'}`}
        aria-hidden="true"
      />
    );

    return (
      <div
        className={`row faux-table-row-new ${showToggle ? 'clickable' : ''}`}
        onClick={showToggle ? this.toggleExpanded : null}>
        <div className="clearfix">
          <div className="col-md-1" style={{ textAlign: 'center' }}>
            {showToggle ? detailsToggle : null}
          </div>
          <div className="col-md-4">
            <h5>{this.props.showJson ? eventType : eventTypeDisplay}</h5>
          </div>
          <div className="col-md-2">
            <DateDisplay date={eventDate} />
          </div>
          <div className="col-md-2">
            {attemptSuccess ? (
              <DateDisplay date={attemptSuccessTime} />
            ) : eventsNotSent ? (
              <i>
                <FormattedMessage id="EventAttempts.NotApplicable" />
              </i>
            ) : (
              <i className="text-danger">
                <FormattedMessage id="EventAttempts.NotReceived" />
              </i>
            )}
          </div>
          <div className="col-md-2">
            {!attemptSuccess && !eventsNotSent ? <EventAttemptsModal attempts={attempts} /> : null}
          </div>
          <div className="col-md-1">
            {this.props.canUpdate && !eventsNotSent ? (
              <Checkbox checked={this.props.selectedEvents.includes(eventId)} onChange={this.toggleSelected} />
            ) : null}
          </div>
        </div>
        <ReactSmoothCollapse expanded={isExpanded}>
          <div className="col-md-1" />
          <div className="col-md-4">
            <DetailsPanel
              showJson={showJson}
              eventType={eventType}
              eventSourceId={eventSourceId}
              detail={detail}
              createdOnBehalfOf={createdOnBehalfOf}
              data={{ ...additionalData, itemQuantity: totalQuantity, itemId }}
            />
          </div>
          <div className="col-md-7" />
        </ReactSmoothCollapse>
      </div>
    );
  }
}

EventRow.propTypes = {
  event: PropTypes.shape({
    eventType: PropTypes.string,
    eventDate: PropTypes.string,
    expectedEventCutoffDate: PropTypes.string,
    eventTypeDisplay: PropTypes.string,
    additionalData: PropTypes.object,
    eventId: PropTypes.string,
    attempts: PropTypes.array,
  }),
  isExpanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
  eventsNotSent: PropTypes.bool,
  totalQuantity: PropTypes.number,
  itemId: PropTypes.string,
  showJson: PropTypes.bool,
  selectedEvents: PropTypes.array,
  toggleSelected: PropTypes.func,
  canUpdate: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(EventRow);
