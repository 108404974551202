import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Drawer, FlexBox, Select } from '@cimpress/react-components';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import _, { flatMap, map, reduce, isEmpty } from 'lodash';

const idFilters = [
  {
    label: <FormattedMessage id={'OrderDetails.AdvancedFilterDrawer.SearchByItemId'} />,
    propertyName: 'itemId',
  },
  {
    label: <FormattedMessage id={'OrderDetails.AdvancedFilterDrawer.SearchByMerchantItemId'} />,
    propertyName: 'merchantItemId',
  },
  {
    label: <FormattedMessage id={'OrderDetails.AdvancedFilterDrawer.SearchByShortItemId'} />,
    propertyName: 'shortItemId',
  },
  {
    label: <FormattedMessage id={'OrderDetails.AdvancedFilterDrawer.SearchByFulfillmentGroupId'} />,
    propertyName: 'fulfillmentGroupId',
  },
];

const AdvancedFilter = ({ items, value: { propertyFilters }, onChange, filteredItemCount }) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    const options = reduce(
      propertyFilters,
      (accumulator, filterValues) => {
        const { propertyName, value } = filterValues;
        const option = { value, label: value };
        accumulator[propertyName] ? accumulator[propertyName].push(option) : (accumulator[propertyName] = [option]);
        return accumulator;
      },
      {}
    );
    setSelectedOptions(options);
  }, [propertyFilters, open]);

  const onOpenDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const onApplyChanges = () => {
    const propertyFilters = flatMap(selectedOptions, (options, property) =>
      map(options, option => ({ propertyName: property, value: option.value }))
    );
    onChange({ propertyFilters });
    setOpen(false);
  };

  const onChangeOptions = property => options =>
    setSelectedOptions({
      ...selectedOptions,
      [property]: options,
    });

  const onClear = () => setSelectedOptions({});

  const createAvailableOptions = property =>
    _.chain(items)
      .map(item => item[property])
      .compact()
      .uniq()
      .map(property => ({ value: property, label: property }))
      .value();

  const optionsMap = reduce(
    idFilters,
    (acc, idFilter) => {
      acc[idFilter.propertyName] = createAvailableOptions(idFilter.propertyName);
      return acc;
    },
    {}
  );

  return (
    <>
      <Button type="link" onClick={onOpenDrawer}>
        <FormattedMessage id="OrderDetails.AdvancedFilter" />
        {!isEmpty(selectedOptions) && (
          <>
            <span>{' ('}</span>
            <span>
              <FormattedMessage
                id={filteredItemCount === 1 ? 'OrderDetails.Result' : 'OrderDetails.Results'}
                values={{ numResults: filteredItemCount }}
              />
            </span>
            <span>)</span>
          </>
        )}
      </Button>
      <Drawer
        show={open}
        onRequestHide={onCloseDrawer}
        closeOnClickOutside
        header={
          <h1>
            <FormattedMessage id="OrderDetails.AdvancedFilterDrawer.FilterItems" />
          </h1>
        }
        footer={
          <FlexBox spaceBetween>
            <Button type="link" onClick={onClear}>
              <FormattedMessage id="Global.ClearAll" />
            </Button>
            <div>
              <Button onClick={onCloseDrawer}>
                <FormattedMessage id="Global.Cancel" />
              </Button>
              <Button className="btn btn-primary" onClick={onApplyChanges}>
                <FormattedMessage id="Global.Apply" />
              </Button>
            </div>
          </FlexBox>
        }>
        <>
          <h4>
            <FormattedMessage id="OrderDetails.AdvancedFilterDrawer.SearchById" />
          </h4>
          <p>
            <FormattedMessage id="OrderDetails.AdvancedFilterDrawer.SearchByIdDescription" />
          </p>
          {Object.values(optionsMap).find(options => !options.length) && (
            <p>
              <IconAlertTriangle className="text-warning" weight="fill" />{' '}
              <FormattedMessage id="OrderDetails.AdvancedFilterDrawer.FieldsNotAvailable" />
            </p>
          )}
          {map(idFilters, ({ label, propertyName }) => (
            <Select
              key={propertyName}
              label={label}
              value={selectedOptions[propertyName] || []}
              options={optionsMap[propertyName]}
              isDisabled={!optionsMap[propertyName].length}
              onChange={onChangeOptions(propertyName)}
              isClearable
              isMulti
            />
          ))}
        </>
      </Drawer>
    </>
  );
};

AdvancedFilter.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string.isRequired,
      merchantItemId: PropTypes.string.isRequired,
      shortItemId: PropTypes.string.isRequired,
      fulfillmentGroupId: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.shape({
    propertyFilters: PropTypes.arrayOf(
      PropTypes.shape({
        propertyName: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ),
  }),
  onChange: PropTypes.func.isRequired,
};

AdvancedFilter.defaultProps = {
  value: {},
};

export default AdvancedFilter;
