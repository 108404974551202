import { PAGE_SIZE } from '../constants/pagination.js';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import every from 'lodash/every';
import moment from 'moment';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { initSearchStatuses } from '../constants/searchStatuses.js';
import { initSearchErrorStatuses } from '../constants/searchErrorStatuses';
import { initSearchDelayedStatuses } from '../constants/searchDelayedStatuses';
import { initSearchOrderTypes } from '../constants/searchordertypes';
import { initSearchClaimTypes } from '../constants/searchClaimTypes';
import { initSearchComplaintTypes } from '../constants/searchComplaintTypes';
import { initSearchChangeRequestTypes } from '../constants/searchChangeRequestTypes';
import { DEFAULT_DATE_TIME_FORMAT } from '../constants/dates.js';
import * as state from '../constants/itemservicestatusstates';

export function getQueryString(locationQuery) {
  // this function builds a querystring from react-router location query object
  // querystring will be a string with the following general shape:
  // searchTerm=value&searchNum=1234&searchObj=key:value
  var entries = [];
  forOwn(locationQuery, (value, key) => {
    if (value) {
      if (isArray(value)) {
        value.forEach(v => {
          if (typeof v === 'object') {
            // fulfillers...
            entries.push(`${key}=${v.value}:${v.label}`);
          } else {
            entries.push(key + '=' + encodeURIComponent(v));
          }
        });
      } else if (key === 'searchTerm') {
        entries.push(key + '=' + encodeURIComponent(value));
      } else {
        entries.push(key + '=' + value);
      }
    }
  });
  return join(entries, '&');
}

export function getPropsFromQuery(querystring, intl) {
  const searchClaimTypes = initSearchClaimTypes(intl);
  const searchComplaintTypes = initSearchComplaintTypes(intl);
  const searchChangeRequestTypes = initSearchChangeRequestTypes(intl);
  const searchDelayedStatuses = initSearchDelayedStatuses(intl);
  const searchErrorStatuses = initSearchErrorStatuses(intl);
  const searchOrderTypes = initSearchOrderTypes(intl);
  const searchStatuses = initSearchStatuses(intl);
  // this function converts a querystring
  // into a react/redux-compatible query object which maps to
  // the corresponding props with the proper formatting
  // searchType is not handled in this function as it sits at a different level in redux
  const locationQuery = queryString.parse(querystring);
  const searchTerm = locationQuery.searchTerm || '';
  // merchants are transformed from an array of merchant names
  // into an array of objects w/ value and label for multiselect
  // eg ["merch1", "merch2"] becomes [{value: "merch1", label: "merch1"}, {value: "merch2", label: "merch2"}]
  const merchants = locationQuery.merchants
    ? [].concat(locationQuery.merchants).map(m => ({ value: m, label: m }))
    : [];
  // fulfillers have both name and ID, which are stored in the querystring separated by colon (:)
  // they are normalized out to an array of objects containing the ID as the "value" and the name as the "label"
  // eg ["1:Vistaprint", "6:Araprint"] becomes [{value: "1", label: "Vistaprint"}, {value: "6", label: "Araprint"}]
  const fulfillers = locationQuery.fulfillers
    ? [].concat(locationQuery.fulfillers).map(f => ({ value: f.split(':')[0], label: f.split(':')[1] }))
    : [];

  const salesChannels = locationQuery.salesChannels
    ? [].concat(locationQuery.salesChannels).map(salesChannel => ({ value: salesChannel, label: salesChannel }))
    : [];

  // split product category into a search value (Cap) and a label (Apparel - Hats - Cap)
  const productCategories = locationQuery.productCategories
    ? [].concat(locationQuery.productCategories).map(c => ({ value: c.split(':')[0], label: c.split(':')[1] }))
    : [];

  // ECOM-3216 Item Sub-statuses, normalize 'production' to 'production.production', etc.
  function getItemStatusesFromQuery(statuses, searchStatuses) {
    if (!statuses) {
      return [];
    }
    let givenStatuses = statuses;
    if (!Array.isArray(givenStatuses)) {
      givenStatuses = [givenStatuses];
    }
    const searchStatusValues = searchStatuses.map(s => s.value);
    let normalizedStatuses = [];
    for (const status of givenStatuses) {
      let foundStatusIndex = searchStatusValues.findIndex(searchStatusValue => searchStatusValue === status);
      let foundPrefixedStatusIndex = searchStatusValues.findIndex(
        searchStatusValue => searchStatusValue === 'production.' + status
      );
      if (foundStatusIndex !== -1) {
        normalizedStatuses.push(searchStatuses[foundStatusIndex]);
      } else if (foundPrefixedStatusIndex !== -1) {
        normalizedStatuses.push(searchStatuses[foundPrefixedStatusIndex]);
      }
    }
    return normalizedStatuses;
  }

  const getQueryValues = (itemArray, itemMap) => {
    return itemArray
      ? [].concat(itemArray).map(item => {
          let config = itemMap.find(im => {
            let value = im.value;
            if (value.includes('.')) {
              value = value.split('.')[1];
            }
            return value === item;
          });
          if (itemMap.find(i => i.value === 'production.' + item.value)) {
            return itemMap.find(i => i.value === 'production.' + item.value);
          }
          let label = config ? config.label : item;
          return { value: item, label: label };
        })
      : [];
  };
  const statuses = getItemStatusesFromQuery(locationQuery.statuses, searchStatuses);
  const errorStatuses = getQueryValues(locationQuery.errorStatuses, searchErrorStatuses);
  const delayedStatuses = getQueryValues(locationQuery.delayedStatuses, searchDelayedStatuses);
  const orderTypes = getQueryValues(locationQuery.orderTypes, searchOrderTypes);
  const claimTypes = getQueryValues(locationQuery.claimTypes, searchClaimTypes);
  const complaintTypes = getQueryValues(locationQuery.complaintTypes, searchComplaintTypes);
  const changeRequestTypes = getQueryValues(locationQuery.changeRequestTypes, searchChangeRequestTypes);

  // don't let user request a page that's out of bounds; just set them back to the beginning
  let page = (locationQuery.page && parseInt(locationQuery.page)) || 1;
  if (page > 100) {
    page = 1;
  }
  // start and end date are stored in redux as moment objects for internationalization and datepicker compatibility
  // the second argument to moment() specifies the string format, in this case unix milliseconds
  const startDate = locationQuery.startDate ? moment(locationQuery.startDate, 'x').utc() : null;
  const endDate = locationQuery.endDate ? moment(locationQuery.endDate, 'x').utc() : null;

  const sortField = locationQuery.sortField || null;
  const sortOrder = locationQuery.sortOrder || null;

  return {
    searchTerm,
    merchants,
    fulfillers,
    statuses,
    errorStatuses,
    delayedStatuses,
    orderTypes,
    page,
    startDate,
    endDate,
    sortField,
    sortOrder,
    salesChannels,
    productCategories,
    claimTypes,
    complaintTypes,
    changeRequestTypes,
  };
}

export function getParamsFromQuery(querystring) {
  // this function converts a queryString
  // into an query params object which can be sent to the server
  const locationQuery = queryString.parse(querystring);
  return constructQuery(locationQuery);
}

export function constructQuery(locationQuery) {
  const searchTerm = locationQuery.searchTerm || '';
  const startDate = locationQuery.startDate || null;
  const endDate = locationQuery.endDate || null;
  const merchants = locationQuery.merchants ? [].concat(locationQuery.merchants) : [];
  // fulfillers have both name and ID, which are stored in the querystring separated by colon (:)
  // for server query, we only want an array of IDs, so ["1:Vistaprint", "6:Araprint"] becomes ["1", "6"]
  const fulfillers = locationQuery.fulfillers ? [].concat(locationQuery.fulfillers).map(f => f.split(':')[0]) : [];
  // don't let user request a page that's out of bounds; just set them back to the beginning
  const statuses = locationQuery.statuses ? [].concat(locationQuery.statuses) : [];
  const errorStatuses = locationQuery.errorStatuses ? [].concat(locationQuery.errorStatuses) : [];
  const delayedStatuses = locationQuery.delayedStatuses ? [].concat(locationQuery.delayedStatuses) : [];
  const orderTypes = locationQuery.orderTypes ? [].concat(locationQuery.orderTypes) : [];
  const claimTypes = locationQuery.claimTypes ? [].concat(locationQuery.claimTypes) : [];
  const complaintTypes = locationQuery.complaintTypes ? [].concat(locationQuery.complaintTypes) : [];
  const changeRequestTypes = locationQuery.changeRequestTypes ? [].concat(locationQuery.changeRequestTypes) : [];
  const salesChannels = locationQuery.salesChannels ? [].concat(locationQuery.salesChannels) : [];
  // search based on the product category "leaf node" (Cap) instead of the nested path (Apparel - Hats - Cap)
  const productCategories = locationQuery.productCategories
    ? [].concat(locationQuery.productCategories).map(c => c.split(':')[1])
    : [];

  let page = (locationQuery.page && parseInt(locationQuery.page)) || 1;
  if (page > 100) {
    page = 1;
  }

  const sortField = locationQuery.sortField || null;
  const sortOrder = locationQuery.sortOrder || null;
  const params = pickBy(
    {
      searchTerm,
      startDate,
      endDate,
      merchants,
      fulfillers,
      statuses,
      errorStatuses,
      delayedStatuses,
      orderTypes,
      sortField,
      sortOrder,
      salesChannels,
      productCategories,
      claimTypes,
      complaintTypes,
      changeRequestTypes,
    },
    value => !isEmpty(value)
  );

  // always add pageSize and offset
  params.pageSize = PAGE_SIZE;
  params.offset = (page - 1) * PAGE_SIZE;

  return params;
}

export function getLocationFromProps(props) {
  let searchTerm;
  if (typeof props.searchTerm === 'string') {
    searchTerm = props.searchTerm ? props.searchTerm.trim() : '';
  }
  const startDate = props.startDate ? props.startDate.valueOf().toString() : '';
  const endDate = props.endDate ? props.endDate.valueOf().toString() : '';
  const merchants = props.merchants.map(merchant => merchant.value);
  const fulfillers = props.fulfillers.map(f => `${f.value}:${f.label}`);
  const statuses = props.statuses
    .map(status => {
      // ECOM-3216 Item Sub-statuses
      if (String(status.value).includes('.')) {
        return status.value.split('.')[1];
      } else {
        return status.value;
      }
    })
    .filter(s => String(s.value) !== 'inProduction'); // do not add to url
  const delayedStatuses = props.delayedStatuses.map(status => status.value);
  const errorStatuses = props.errorStatuses.map(status => status.value);
  const orderTypes = props.orderTypes.map(orderType => orderType.value);
  const claimTypes = props.claimTypes.map(claimType => claimType.value);
  const complaintTypes = props.complaintTypes.map(complaintType => complaintType.value);
  const changeRequestTypes = props.changeRequestTypes.map(changeRequestType => changeRequestType.value);
  const salesChannels = props.salesChannels.map(salesChannel => salesChannel.value);
  const productCategories = props.productCategories.map(category => `${category.value}:${category.label}`);
  const page = `${props.page}`;
  const sortField = props.sortField;
  const sortOrder = props.sortOrder;

  return pickBy(
    {
      searchTerm,
      startDate,
      endDate,
      merchants,
      fulfillers,
      statuses,
      delayedStatuses,
      errorStatuses,
      orderTypes,
      salesChannels,
      productCategories,
      claimTypes,
      complaintTypes,
      changeRequestTypes,
      page,
      sortField,
      sortOrder,
    },
    value => !isEmpty(value)
  );
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const wait = ms => new Promise(r => setTimeout(r, ms));

export function formatUserDate(date, displayTimezone, displayRawDate) {
  if (displayRawDate) {
    return date;
  }

  let tzDate = moment(date).tz(displayTimezone);
  let formattedDate = tzDate.format(DEFAULT_DATE_TIME_FORMAT);
  let zoneAbbr = tzDate.zoneAbbr();
  return `${formattedDate} ${zoneAbbr}`;
}

export function intersectionOf(arrayOfObjects) {
  let result = {};
  if (!arrayOfObjects || !arrayOfObjects.length) {
    return result;
  }

  const firstKeys = Object.keys(arrayOfObjects[0]);
  firstKeys.forEach(key => {
    if (every(arrayOfObjects, indexObject => indexObject[key] === arrayOfObjects[0][key])) {
      result[key] = arrayOfObjects[0][key];
    }
  });

  return result;
}

export function isItemSuspendedInRouting(item) {
  return (
    get(item, 'statuses.routing.state') === state.CURRENT && get(item, 'statuses.suspended.state') === state.CURRENT
  );
}

export function normalizeItems(items, searchStatuses) {
  let map = new Map();
  if (!Array.isArray(items) || items.length <= 0) {
    return [];
  }
  for (const item of items) {
    if (String(item.value).startsWith('production.')) {
      map.set(item.label, item);
    } else {
      // does not start with production
      if (map.has(item.label)) {
        // already in map
        continue;
      } else if (searchStatuses.map(s => s.label).includes(item.label)) {
        let foundStatus = searchStatuses.find(s => s.label === item.label);
        map.set(item.label, foundStatus);
      } else {
        map.set(item.label, item);
      }
    }
  }
  return Array.from(map.values());
}

export function normalizeQueryItems(items, searchStatuses) {
  let map = new Map();
  if (!Array.isArray(items)) {
    return [];
  }
  if (!items.every(i => typeof i === 'string')) {
    return normalizeItems(items, searchStatuses);
  }
  for (let item of items) {
    let index = searchStatuses.map(s => s.value).findIndex(s => s.includes(item));
    if (index) {
      map.set(searchStatuses[index].label, searchStatuses[index]);
    }
  }
  return Array.from(map.values());
}

export function getVariablesFromItem(item) {
  const output = {};
  const { variableAttributes = [] } = item;
  variableAttributes.forEach(variable => (output[variable.name] = variable.value));
  return output;
}
