import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IdDisplay from '../../shared/idDisplay';

const Breadcrumbs = ({ order, style, intl }) => (
  <div style={{ ...style }}>
    <ul className="breadcrumb" style={{ padding: 0 }}>
      <li>
        <Link to="/">{intl.formatMessage({ id: 'Global.OrderSearch' }).toLowerCase()}</Link>
      </li>
      <li className="active">
        #
        <IdDisplay
          merchant={order.merchantOrderId}
          platform={order.orderId}
          fulfiller={order.fulfillerOrderIds}
          short={order.shortOrderIds}
          joinString=", #"
        />
      </li>
    </ul>
  </div>
);

Breadcrumbs.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string,
    merchantOrderId: PropTypes.string,
    fulfillerOrderIds: PropTypes.arrayOf(PropTypes.string),
    shortOrderIds: PropTypes.arrayOf(PropTypes.string),
  }),
  style: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(Breadcrumbs);
