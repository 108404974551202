import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';

const BASE_URL = process.env.REACT_APP_AGGREGATION_URL;

export function cancelItem(orderId, itemId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.CANCEL_ITEMS_REQUEST, types.CANCEL_ITEMS_SUCCESS, types.CANCEL_ITEMS_FAILURE],
        endpoint: `${BASE_URL}/v1/orders/${orderId}/items/${itemId}/cancelrequest`,
        method: 'POST',
      },
    });
  };
}

export function cancelOrder(orderId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.CANCEL_ORDER_REQUEST, types.CANCEL_ORDER_SUCCESS, types.CANCEL_ORDER_FAILURE],
        endpoint: `${BASE_URL}/v1/orders/${orderId}/cancelrequest`,
        method: 'POST',
      },
    });
  };
}
