import React from 'react';
import PropTypes from 'prop-types';
import * as hljs from '../../../lib/highlight';
import { findDOMNode } from 'react-dom';

export default class Highlight extends React.Component {
  componentDidMount() {
    const snippet = findDOMNode(this);
    hljs.highlightBlock(snippet);
  }

  render() {
    return (
      <pre>
        <code className={this.props.language}>{this.props.children}</code>
      </pre>
    );
  }
}

Highlight.propTypes = {
  language: PropTypes.string,
  children: PropTypes.node,
};
