export function initShipmentStatuses(intl) {
  return {
    shipmentStatuses: {
      shipmentCancelled: {
        displayName: intl.formatMessage({ id: 'Statuses.ShipmentCancelled' }),
        priority: 1,
      },
      shipmentExceptions: {
        displayName: intl.formatMessage({ id: 'Statuses.ShipmentExceptions' }),
        priority: 2,
      },
      readyToDispatch: {
        displayName: intl.formatMessage({ id: 'Statuses.ReadyToDispatch' }),
        priority: 3,
      },
      dispatched: {
        displayName: intl.formatMessage({ id: 'Statuses.Dispatched' }),
        priority: 4,
      },
      receivedByCarrier: {
        displayName: intl.formatMessage({ id: 'Statuses.ReceivedByCarrier' }),
        priority: 5,
      },
      inTransit: {
        displayName: intl.formatMessage({ id: 'Statuses.InTransit' }),
        priority: 6,
      },
      outForDelivery: {
        displayName: intl.formatMessage({ id: 'Statuses.OutForDelivery' }),
        priority: 7,
      },
      delivered: {
        displayName: intl.formatMessage({ id: 'Statuses.Delivered' }),
        priority: 8,
      },
    },

    shipManTrackingStatuses: {
      'READY-TO-DISPATCH': intl.formatMessage({ id: 'Statuses.ReadyToDispatch' }),
      DISPATCHED: intl.formatMessage({ id: 'Statuses.Dispatched' }),
      CANCELLED: intl.formatMessage({ id: 'Statuses.ShipmentCancelled' }),
      'RECEIVED-BY-CARRIER': intl.formatMessage({ id: 'Statuses.ReceivedByCarrier' }),
      'IN-TRANSIT': intl.formatMessage({ id: 'Statuses.InTransit' }),
      'OUT-FOR-DELIVERY': intl.formatMessage({ id: 'Statuses.OutForDelivery' }),
      DELIVERED: intl.formatMessage({ id: 'Statuses.Delivered' }),
      EXCEPTIONS: intl.formatMessage({ id: 'Statuses.ShipmentExceptions' }),
      'NOT-FOUND': intl.formatMessage({ id: 'Statuses.NotFound' }),
    },
  };
}
