import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';

const SkuDetails = ({ sku }) => {
  const intl = useIntl();

  const recordCatalogEvent = () => {
    trackEvent(TrackingEventTitles.VIEW_SKU_IN_PRODUCT_CATALOG, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.SKU]: sku,
    });
  };

  if (!sku) {
    return intl.formatMessage({ id: 'Global.NotProvided' });
  }

  return (
    <a
      href={`https://marketplace.products.cimpress.io/family?mcpsku=${sku}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={recordCatalogEvent}>
      {sku} <i className="fa fa-sm fa-external-link" />
    </a>
  );
};

SkuDetails.propTypes = {
  sku: PropTypes.string,
};

export default SkuDetails;
