import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import CssLoader from '@cimpress/react-components/lib/CssLoader';
import configureStore from './store/configureStore';
import AppRouter from './appRouter.jsx';

require('./styles/index.scss');

export const store = configureStore();

class Main extends React.Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <CssLoader>
            <AppRouter {...this.props} />
          </CssLoader>
        </Provider>
      </div>
    );
  }
}

Main.propTypes = {
  path: PropTypes.string,
};

export default process.env.NODE_ENV === 'production' ? Main : hot(module)(Main);
