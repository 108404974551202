/// Search specifically only deals with the lowercase values of these
/// do not try to match the actual values (doNotShip) as the search will fail
export function initSearchOrderTypes(intl) {
  return [
    {
      value: 'ship',
      label: intl.formatMessage({ id: 'OrderType.default' }),
    },
    {
      value: 'donotship',
      label: intl.formatMessage({ id: 'OrderType.doNotShip' }),
    },
    {
      value: 'donotproduce',
      label: intl.formatMessage({ id: 'OrderType.doNotProduce' }),
    },
    {
      value: 'donottransmit',
      label: intl.formatMessage({ id: 'OrderType.doNotTransmit' }),
    },
    {
      value: 'donotprepare',
      label: intl.formatMessage({ id: 'OrderType.doNotPrepare' }),
    },
  ];
}
