import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TreeSelector } from '@cimpress-technology/tree-selector';
import cloneDeep from 'lodash/cloneDeep';
import AccordionFilterContainer from './AccordionFilterContainer';

// based on https://stackoverflow.com/questions/45289854/how-to-effectively-filter-tree-view-retaining-its-existing-structure
const filter = (selectTree, searchQuery) => {
  const tree = cloneDeep(selectTree);
  const filteredChildren = tree.children.filter(function iter(treeNode) {
    let temp;
    if (treeNode.name.toUpperCase().includes(searchQuery.toUpperCase())) {
      return true;
    }
    if (!Array.isArray(treeNode.children)) {
      return false;
    }
    temp = treeNode.children.filter(iter);
    if (temp.length) {
      treeNode.children = temp;
      return true;
    }
    return false;
  });
  tree.children = filteredChildren;
  return tree;
};

const AccordionTreeFilter = ({
  selectTree,
  filterTitle,
  searchQuery,
  isLoading,
  hasError,
  selectedItems,
  selectionChange,
  removeFilter,
  selectedItemsKey,
}) => {
  const tree = selectTree ? filter(selectTree, searchQuery) : selectTree;

  const customOpen = Boolean(searchQuery);
  const defaultOpen = false;
  const initialOpen = customOpen || defaultOpen;
  const [open, setOpen] = useState(initialOpen);

  const onValueChange = (value, title, path, rest) => {
    if (rest.selected) {
      const item = { value: rest.triggerNode.props.value, label: rest.triggerNode.props.title };
      selectionChange(item, selectedItemsKey);
    } else {
      // rest.triggerNode.props is not available when a node is de-selected and not present in the tree, so find it in selectedItems
      const value = selectedItems.find(item => item.value === rest.triggerValue).label;
      removeFilter(selectedItemsKey, value);
    }
  };

  useEffect(() => {
    setOpen(customOpen || defaultOpen);
  }, [customOpen, defaultOpen]);

  const onDropdownVisibleChange = () => {
    setOpen(prevOpen => {
      return !prevOpen;
    });
  };

  let accordionBody;
  if (!tree || !tree.children.length) {
    accordionBody = null;
  } else {
    accordionBody = (
      <div id="tree-select-container">
        <TreeSelector
          multiple
          value={selectedItems}
          labelInValue
          onChange={onValueChange}
          allowClear={false}
          showSearch={false}
          selectTree={tree}
          open={open}
          onDropdownVisibleChange={onDropdownVisibleChange}
          dropdownPopupAlign={{ overflow: false }}
          getPopupContainer={() => {
            return {
              /**
               * @see https://github.com/react-component/trigger/blob/858968c26585c7440ae948513045346c7b1eb682/src/index.tsx#L548
               *
               * The rc-trigger package adds a div with 'position: absolute' set and no way to modify the style. Wrap the appendChild
               * function so we can modify the child div that will be added, and add it to the div that contains the tree selector
               */
              appendChild(child) {
                child.style.position = 'relative';
                document.getElementById('tree-select-container').appendChild(child);
              },
            };
          }}
        />
      </div>
    );
  }

  return (
    <AccordionFilterContainer
      filterTitle={filterTitle}
      isLoading={isLoading}
      hasError={hasError}
      availableItems={selectTree}
      searchQuery={searchQuery}
      body={accordionBody}
    />
  );
};

AccordionTreeFilter.propTypes = {
  selectTree: PropTypes.object,
  searchQuery: PropTypes.string,
  filterTitle: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedItemsKey: PropTypes.string,
  selectionChange: PropTypes.func,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
};

export default AccordionTreeFilter;
