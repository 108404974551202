import * as types from '../constants/actiontypes.js';
import { putSetting, getSetting } from '../services/customizr';

const CUSTOMIZR_SEARCH_COLUMN_SETTING = 'OOPs-searchColumns';

export const setSearchColumns = searchColumns => dispatch => {
  dispatch({
    type: types.SET_SEARCH_COLUMNS,
    searchColumns,
  });
  return putSetting(CUSTOMIZR_SEARCH_COLUMN_SETTING, { columns: searchColumns });
};

export const resetSearchColumns = () => dispatch => {
  dispatch({ type: types.RESET_SEARCH_COLUMNS });
  return putSetting(CUSTOMIZR_SEARCH_COLUMN_SETTING, { columns: [] });
};

export const getSearchColumnSettings = () => dispatch => {
  return getSetting(CUSTOMIZR_SEARCH_COLUMN_SETTING)
    .then(response => {
      return dispatch({
        type: types.SET_SEARCH_COLUMNS,
        searchColumns: response.columns || (response.length ? response : []), //porting from old [] based setting
      });
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        // do nothing.  let the defaults handle it
      } else {
        console.error(error);
      }
    });
};
