import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import uniq from 'lodash/uniq';
import OrderRow from './orderrow';
import SearchResultsHeader from './searchresultsheader';
import ContextMenu from '../shared/contextMenu';
import { white } from '@cimpress/react-components/lib/colors';
import Button from '@cimpress/react-components/lib/Button';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { PAGE_SIZE } from '../../constants/pagination';
import { canReorderAnyOrder, canReorder } from '../../utils/permissions-v2';
import { getParamsFromQuery } from '../../utils/utilityfunctions';
import BulkReorderModal from '../bulkReorder/BulkReorderModal';

class SearchResultsTable extends React.Component {
  state = {
    showContextMenu: false,
    selectedOrders: {},
  };

  openInNewTab = orderId => {
    this.props.setLastOrder(orderId);
    window.open(`orders/${orderId}`, '_blank');
  };

  // use this for left clicks (with optional modifier keys)
  onRowClicked = (orderId, e) => {
    const leftClick = e.button === 0;
    if (!leftClick) {
      return;
    }

    this.setState({ showContextMenu: false });
    if (getSelection().toString().length) {
      return;
    }

    const newTab = e.metaKey || e.ctrlKey || e.shiftKey;
    if (newTab) {
      this.openInNewTab(orderId);
    } else {
      this.props.goToOrderDetails(orderId);
    }
  };

  // use this for middle-clicks
  onAuxClick = (orderId, e) => {
    const middleClick = e.button === 1;
    if (!middleClick) {
      return;
    }

    this.setState({ showContextMenu: false });
    if (getSelection().toString().length) {
      return;
    }

    this.openInNewTab(orderId);
  };

  // use this for right-clicks
  onContextMenu = (orderId, e) => {
    if (getSelection().toString().length) {
      return;
    }

    e.preventDefault();
    this.setState({
      showContextMenu: true,
      x: e.nativeEvent.layerX,
      y: e.nativeEvent.layerY,
      selectedOrderId: orderId,
    });
  };

  hideContextMenu = () => {
    this.setState({ showContextMenu: false });
  };

  onMenuItemSelected = orderId => {
    this.setState({ showContextMenu: false });
    this.props.setLastOrder(orderId);
  };

  areAllReorderableOrdersSelected = () => {
    const { selectedOrders } = this.state;
    const { results, permissions } = this.props;
    return results.every(order => !canReorder(permissions, order.merchantId) || selectedOrders[order.orderId]);
  };

  // if all selectable orders are selected, unselect all; else select all
  toggleAll = () => {
    let selectedOrders = { ...this.state.selectedOrders };
    const { results, permissions } = this.props;
    if (this.areAllReorderableOrdersSelected()) {
      // all the selectable orders were already selected, so clear them out
      selectedOrders = {};
    } else {
      // select all reorderable orders that weren't already selected
      results.forEach(order => {
        if (canReorder(permissions, order.merchantId) && !selectedOrders[order.orderId]) {
          selectedOrders[order.orderId] = order;
        }
      });
    }
    this.setState({ selectedOrders });
  };

  toggleOrder = order => () => {
    const selectedOrders = { ...this.state.selectedOrders };
    if (selectedOrders[order.orderId]) {
      delete selectedOrders[order.orderId];
    } else {
      selectedOrders[order.orderId] = order;
    }
    this.setState({ selectedOrders });
  };

  convertQueryToParams = () => getParamsFromQuery(this.props.queryString);

  render() {
    const { intl, permissions, results, totalResults } = this.props;
    const { selectedOrders } = this.state;

    let contextMenu;
    if (this.state.showContextMenu) {
      const menuItems = [
        {
          label: intl.formatMessage({ id: 'ResultsSection.OpenInNewTab' }),
          path: `/orders/${this.state.selectedOrderId}`,
          onClick: () => this.onMenuItemSelected(this.state.selectedOrderId),
        },
      ];
      contextMenu = (
        <ContextMenu onClickedOutside={this.hideContextMenu} x={this.state.x} y={this.state.y} items={menuItems} />
      );
    }

    const showReorderControls = canReorderAnyOrder(permissions);
    const areAnyReorderable = some(uniq(results.map(result => result.merchantId)), merchantId =>
      canReorder(permissions, merchantId)
    );

    return (
      <div>
        {showReorderControls ? (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', height: '50px' }}>
            {!isEmpty(selectedOrders) ? (
              <BulkReorderModal
                orders={Object.values(selectedOrders)}
                totalOrders={totalResults > PAGE_SIZE * 100 ? PAGE_SIZE * 100 : totalResults}
                areAllReorderableOrdersSelected={this.areAllReorderableOrdersSelected()}
                params={this.convertQueryToParams()}
                permissions={permissions}
              />
            ) : (
              <Tooltip
                direction="right"
                contents={
                  <FormattedMessage
                    id={areAnyReorderable ? 'BulkReorder.MustSelectOrder' : 'BulkReorder.AllNotReorderable'}
                  />
                }>
                <Button variant="default" size="sm" disabled>
                  <FormattedMessage id="BulkReorder.BulkReorder" />
                </Button>
              </Tooltip>
            )}
          </div>
        ) : null}
        {contextMenu}
        <table
          className="table table-hover"
          style={{ borderRight: 'none', borderLeft: 'none', backgroundColor: white }}>
          <thead>
            <SearchResultsHeader
              showCheckbox={showReorderControls}
              isCheckboxEnabled={areAnyReorderable}
              disabledMessage={<FormattedMessage id="BulkReorder.AllNotReorderable" />}
              isSelected={this.areAllReorderableOrdersSelected()}
              toggleSelected={this.toggleAll}
            />
          </thead>
          <tbody>
            {results.map(result => {
              return (
                <OrderRow
                  className={this.props.lastOrder === result.orderId ? 'selected' : null}
                  result={result}
                  key={result.orderId}
                  onClick={this.onRowClicked}
                  onAuxClick={this.onAuxClick}
                  onContextMenu={this.onContextMenu}
                  showCheckbox={showReorderControls}
                  isCheckboxEnabled={canReorder(permissions, result.merchantId)}
                  disabledMessage={<FormattedMessage id="BulkReorder.NotReorderable" />}
                  isSelected={Boolean(selectedOrders[result.orderId])}
                  toggleSelected={this.toggleOrder(result)}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

SearchResultsTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      merchantOrderId: PropTypes.string,
      orderId: PropTypes.string,
      createdDate: PropTypes.string,
      orderStatus: PropTypes.object,
      fulfillmentGroups: PropTypes.arrayOf(
        PropTypes.shape({
          destinationAddress: PropTypes.object,
        })
      ),
    })
  ),
  lastOrder: PropTypes.string,
  goToOrderDetails: PropTypes.func,
  setLastOrder: PropTypes.func,
  intl: PropTypes.object,
  permissions: PropTypes.object,
  queryString: PropTypes.string,
  totalResults: PropTypes.number,
};

SearchResultsTable.defaultProps = {
  results: [],
};

const mapStateToProps = ({ permissions: { permissions } }) => ({ permissions });

export default connect(mapStateToProps)(injectIntl(SearchResultsTable));
