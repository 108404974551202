import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Checkbox, shapes } from '@cimpress/react-components';
import Alert from '@cimpress/react-components/lib/Alert';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import EventRow from './events/EventRow';
import { resendEvents } from '../../actions/eventsactions';
import * as util from '../../utils/permissions-v2';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';

const { Spinner } = shapes;

const Events = ({
  orderId,
  events,
  callbackUrl,
  merchantId,
  totalQuantity,
  itemId,
  toggleExpanded,
  eventExpandedMap,
  refreshOrder,
}) => {
  const [showJson, setShowJson] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const permissions = useSelector(state => get(state, 'permissions.permissions', []));
  const dispatch = useDispatch();

  const toggleCheckedState = () => {
    setShowJson(prevShowJson => !prevShowJson);

    trackEvent(TrackingEventTitles.EVENT_VIEW_FORMAT_CHANGED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.EVENTS.VIEW_FORMAT]: showJson ? 'JSON View' : 'Simple View',
    });
  };

  const resendAndClear = async () => {
    await dispatch(resendEvents(selectedEvents, orderId));

    setSelectedEvents([]);

    trackEvent(TrackingEventTitles.EVENTS_RESENT, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.EVENTS.ORDER_ID]: orderId,
    });

    refreshOrder(orderId);
  };

  const toggleSelected = (eventId, e) => {
    e.stopPropagation();

    const newSelectedEvents = selectedEvents.slice();
    const i = newSelectedEvents.indexOf(eventId);

    if (i > -1) {
      newSelectedEvents.splice(i, 1);
    } else {
      newSelectedEvents.push(eventId);
    }

    setSelectedEvents(newSelectedEvents);
  };

  const eventsNotSent = callbackUrl === 'http://cit.cw-api-mocker.commerce.cimpress.io/ok';
  const hasMerchantUpdatePermission = util.hasMerchantUpdatePermission(permissions, merchantId);
  const header = (
    <div className="row faux-table-header-new">
      <div className="col-md-1" />
      <div className="col-md-4">
        <b>
          <FormattedMessage id="Events.Event" />
        </b>
      </div>
      <div className="col-md-2">
        <b>
          <FormattedMessage id="Events.EventTime" />
        </b>
      </div>
      <div className="col-md-2">
        <b>
          <FormattedMessage id="Events.MerchantReceivedTime" />
        </b>
      </div>
      <div className="col-md-2">
        <b>
          <FormattedMessage id="DetailPanels.Actions" />
        </b>
      </div>
      <div className="col-md-1">
        {hasMerchantUpdatePermission && !eventsNotSent ? (
          <b>
            <FormattedMessage id="Events.Resend" />
          </b>
        ) : null}
      </div>
    </div>
  );

  const usedKeys = {};
  let eventDetails = events ? (
    events.map(e => {
      // generate a key that hasn't been used before (e.g. eventId, eventId_1, eventId_2)
      // (can't just use a guid because that causes rows not to refresh properly when new events are sent from OOps)
      let key = `${e.eventId}`;
      if (usedKeys[key]) {
        key += `_${usedKeys[key]++}`;
      } else {
        usedKeys[key] = 1;
      }

      return (
        <EventRow
          eventsNotSent={eventsNotSent}
          event={e}
          isExpanded={Boolean(eventExpandedMap[e.eventId])}
          totalQuantity={totalQuantity}
          itemId={itemId}
          showJson={showJson}
          key={key}
          toggleSelected={toggleSelected}
          toggleExpanded={toggleExpanded}
          selectedEvents={selectedEvents}
          canUpdate={hasMerchantUpdatePermission}
        />
      );
    })
  ) : (
    <Spinner />
  );

  return (
    <div style={{ marginRight: '-5px', marginLeft: '-5px' }} className="clearfix">
      {/* Event Manager only sends a max of 300, have this
        warning while event manager is not paginated */
      events && events.length >= 300 && (
        <Alert status="warning" message={<FormattedMessage id="Events.WarningSize" />} dismissible={false} />
      )}
      {header}
      {eventDetails}
      <div style={{ padding: '15px' }}>
        <Checkbox
          className="pull-left"
          checked={showJson}
          onChange={toggleCheckedState}
          label={<FormattedMessage id="Events.ShowJson" />}
        />
        {hasMerchantUpdatePermission ? (
          <div className="pull-right">
            <button
              className="btn btn-info"
              style={{ marginTop: '5px' /* temporary fix for alignment */ }}
              disabled={!selectedEvents.length}
              onClick={resendAndClear}>
              {<FormattedMessage id="Events.ResendEvents" />}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Events.propTypes = {
  toggleExpanded: PropTypes.func,
  eventExpandedMap: PropTypes.object,
  events: PropTypes.array,
  callbackUrl: PropTypes.string,
  orderId: PropTypes.string,
  merchantId: PropTypes.string,
  refreshOrder: PropTypes.func,
  totalQuantity: PropTypes.number,
  itemId: PropTypes.string,
};

export default Events;
