import * as types from '../constants/actiontypes.js';
import auth from '../utils/auth.js';

export const initialState = {};

export default function session(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOGOUT_SESSION_SUCCESS:
    case types.LOGOUT_SESSION_FAILURE: // even if we failed to destroy the session on the server, still log the user out
      auth.logout(); // tell auth0 to log the user out. this will redirect them away from the app.
      return null; // value is irrelevant.
    default:
      return state;
  }
}
