import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { statusDisplayMessages } from '../detailsv2/events/eventDisplayMessages.js';
import { ERROR, WARNING, INFO } from '../../constants/issueSeverity.js';

class SimpleStatusExplanation extends React.Component {
  render() {
    const { status, severity } = this.props;

    let style;
    if (severity === ERROR) {
      style = 'fa fa-exclamation-triangle text-danger';
    } else if (severity === WARNING) {
      style = 'fa fa-exclamation-triangle text-warning';
    } else if (severity === INFO) {
      style = 'fa fa-info-circle text-info';
    }

    return (
      <div>
        <i className={style} aria-hidden="true" /> <FormattedMessage id={statusDisplayMessages[status]} />
      </div>
    );
  }
}

SimpleStatusExplanation.propTypes = {
  status: PropTypes.string,
  severity: PropTypes.string,
  type: PropTypes.string,
};

export default SimpleStatusExplanation;
