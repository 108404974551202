import auth from '../utils/auth';
import ServiceHttpResponseError from './ServiceHttpResponseError';

export function checkResponse(serviceEndpoint) {
  return res => {
    if (res.ok) {
      if (res.status === 204) {
        return res;
      }
      return res.json();
    } else {
      throw new ServiceHttpResponseError(`Error calling ${serviceEndpoint} endpoint`, res);
    }
  };
}

export function buildOptions(method, body, contentType = 'application/json') {
  const accessToken = auth.getAccessToken();
  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

  method = method ? method.toUpperCase() : 'GET';
  if ((method === 'POST' || method === 'PUT' || method === 'PATCH' || method === 'GET') && contentType) {
    headers.append('Content-Type', contentType);
  }

  return {
    method,
    mode: 'cors',
    headers,
    body,
  };
}

export function callFetch(endpointUrl, method, route, body, contentType = 'application/json') {
  const options = buildOptions(method, body, contentType);
  const fullUrl = route ? `${endpointUrl}/${route}` : endpointUrl;
  return fetch(fullUrl, options)
    .then(checkResponse(`${method} ${fullUrl}`))
    .catch(error => {
      const name = error.name;
      const message = error.message || `${method} ${fullUrl}`;
      const responseDetails = error.response ? `(${error.response.status} ${error.response.statusText})` : '';
      if (process.env.NODE_ENV !== 'test') {
        console.error(`${name}: ${message} ${responseDetails}`);
      }
      throw error;
    });
}

export function getBlob(url) {
  const accessToken = auth.getAccessToken();
  const headers = new Headers({
    Authorization: `Bearer ${accessToken}`,
  });
  const options = {
    method: 'GET',
    mode: 'cors',
    headers,
  };
  return fetch(url, options).then(res => {
    if (res.ok) {
      return res.blob();
    } else {
      throw new ServiceHttpResponseError(`Error getting blob from url ${url}`, res);
    }
  });
}
