import P from 'bluebird';
import moment from 'moment';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import uniqBy from 'lodash/uniqBy';
import stringify from 'json-stringify-safe';
import uuidV4 from 'uuid/v4';
import { getOrderRequest } from '../services/orderRequest';
import { getFulfillmentExpectationView } from '../services/fulfillmentExpectations';
import { postOrder, requestItemCancellation } from '../services/merchantOrder';
import { cancelOrder } from './cancelactions';
import { clearAlert } from '../services/itemService';
import { callFetch } from '../services/serviceHelpers';
import {
  BULK_REORDER_STARTED,
  BULK_REORDER_COMPLETED,
  CLEAR_BULK_REORDER,
  BULK_REORDER_SEARCH_REQUEST,
  BULK_REORDER_SEARCH_SUCCESS,
  BULK_REORDER_SEARCH_FAILURE,
} from '../constants/actiontypes';
import { REJECTED } from '../constants/statustypes';
import { PAGE_SIZE } from '../constants/pagination';
import { intersectionOf } from '../utils/utilityfunctions';
import { shouldSkipItem } from '../utils/reorders';
import { formatSearchParams } from '../utils/searchModels';
import { canReorder } from '../utils/permissions-v2';

export const fetchNextPages = (params, permissions, nextPagesLimit) => async dispatch => {
  dispatch({ type: BULK_REORDER_SEARCH_REQUEST });

  try {
    const offsets = [];
    for (
      let currentOffset = params.offset + PAGE_SIZE, iterator = 0;
      iterator < nextPagesLimit;
      currentOffset += PAGE_SIZE, iterator++
    ) {
      offsets.push(currentOffset);
    }
    // concurrency is not very useful currently
    // but will be in the future, if end up increasing nextPagesLimit
    const cumulativeResponses = await P.map(
      offsets,
      offset =>
        callFetch(
          process.env.REACT_APP_ORDER_MANAGER_URL,
          'POST',
          'v2/ordersearch',
          stringify(formatSearchParams({ ...params, offset }))
        ),
      { concurrency: 2 }
    );
    // get the full order for each orderId in the search results
    // and filter orders based on the permissions
    const orders = cumulativeResponses
      .flatMap(response => response.results)
      .map(result => ({ ...result.order, ...intersectionOf(result.order.items) }))
      .filter(order => canReorder(permissions, order.merchantId));

    return dispatch({ type: BULK_REORDER_SEARCH_SUCCESS, orders });
  } catch (error) {
    dispatch({ type: BULK_REORDER_SEARCH_FAILURE, error });
  }
};

const filterOrderRequest = (orderRequest, searchResultOrder, shouldKeepOriginalPromiseDate) => {
  // items we will not resubmit (put in a map for easier lookup)
  const skippedItemsMap = searchResultOrder.items.reduce((acc, item) => {
    acc[item.itemId] = shouldSkipItem(item);
    return acc;
  }, {});

  // remove the cancelled or shipped items from the order request
  orderRequest.fulfillmentGroups.forEach(group => {
    group.items = group.items.filter(item => !skippedItemsMap[item.itemId]);
  });
  // remove empty fulfillment groups
  orderRequest.fulfillmentGroups = orderRequest.fulfillmentGroups.filter(group => group.items.length);
  // if everything was removed, return null so that we skip this order
  if (!orderRequest.fulfillmentGroups.length) {
    return null;
  }

  // adjust promise dates
  const now = moment();
  orderRequest.fulfillmentGroups.forEach(group => {
    delete group.promisedArrivalDate;
    if (group.localPromisedArrivalDate) {
      if (shouldKeepOriginalPromiseDate) {
        // original promised date is in past, adjust the date
        if (moment(group.localPromisedArrivalDate) <= now) {
          group.localPromisedArrivalDate = now.format('YYYY-MM-DD');
        } // else keep original promised date
      } else {
        // Adjust all fulfillmentGroups irrespective of original date
        group.localPromisedArrivalDate = now.format('YYYY-MM-DD');
      }
    }
  });

  return orderRequest;
};

export const splitOrder = (orderRequest, itemGroupNumber) => {
  const newGroups = [];
  orderRequest.fulfillmentGroups.forEach(fulfillmentGroup => {
    chunk(fulfillmentGroup.items, itemGroupNumber).forEach(itemChunk =>
      newGroups.push({
        ...fulfillmentGroup,
        items: itemChunk,
      })
    );
  });
  return newGroups.map(group => ({ ...orderRequest, fulfillmentGroups: [group] }));
};

const singleReorder = async (newOrder, originalOrder, reorderFailed, reorderSucceeded) => {
  try {
    const reorder = await postOrder(newOrder);
    reorderSucceeded.push({ originalOrder, reorder });
  } catch (error) {
    const response = await error.response.json();
    reorderFailed.push({
      order: originalOrder,
      errorMessage: error.message + JSON.stringify(response.validationErrors),
    });
  }
};

export const quickReorder = (
  searchOrderResult,
  shouldKeepOriginalPromiseDate,
  shouldUnlinkFromOriginalOrder,
  itemGroupNumber
) => async dispatch => {
  dispatch({ type: BULK_REORDER_STARTED });
  const reorderSucceeded = [];
  const reorderFailed = [];
  const skipped = [];
  let orderRequest;
  // get the order request and place the reorder
  try {
    orderRequest = await getOrderRequest(searchOrderResult.orderId);
    orderRequest = filterOrderRequest(orderRequest, searchOrderResult, shouldKeepOriginalPromiseDate);
    if (!orderRequest) {
      skipped.push(searchOrderResult);
    }
  } catch (error) {
    reorderFailed.push({ order: searchOrderResult, errorMessage: error.message });
  }
  const shouldContinueToReorder = skipped.length === 0 && reorderFailed.length === 0;
  // itemGroupNumber is a optional parameter to specify the size of the groups
  // the user wants an order to be split into upon reorder
  if (itemGroupNumber && shouldContinueToReorder) {
    const splitOrders = splitOrder(orderRequest, itemGroupNumber);
    await P.map(
      splitOrders,
      async splitOrder => await singleReorder(splitOrder, searchOrderResult, reorderFailed, reorderSucceeded),
      {
        concurrency: 10,
      }
    );
  } else if (shouldContinueToReorder) {
    if (shouldUnlinkFromOriginalOrder) {
      orderRequest = {
        ...orderRequest,
        merchantOrderId: uuidV4(),
        merchantCustomerId: uuidV4(),
        eventCallbackUrl: null,
        fulfillmentGroups: orderRequest.fulfillmentGroups.map(fulfillmentGroup => ({
          ...fulfillmentGroup,
          items: fulfillmentGroup.items.map(item => ({
            ...item,
            merchantItemId: uuidV4(),
          })),
        })),
      };
    }
    await singleReorder(orderRequest, searchOrderResult, reorderFailed, reorderSucceeded);
  }
  dispatch({
    type: BULK_REORDER_COMPLETED,
    results: {
      reorderSucceeded,
      reorderFailed,
      skipped,
    },
  });
};

export const bulkReorder = (searchResultOrders, shouldKeepOriginalPromiseDate) => async dispatch => {
  dispatch({ type: BULK_REORDER_STARTED });

  // list of { originalOrder, reorder }
  const reorderSucceeded = [];
  // list of original orders
  const reorderFailed = [];
  const cancellationFailed = [];
  const clearAlertsFailed = [];
  const skipped = [];
  const fetchOrderCancellationFormatFailed = [];
  try {
    await P.map(
      searchResultOrders,
      async order => {
        // get the order request and place the reorder
        try {
          let orderRequest = await getOrderRequest(order.orderId);
          orderRequest = filterOrderRequest(orderRequest, order, shouldKeepOriginalPromiseDate);
          if (!orderRequest) {
            skipped.push(order);
            return;
          }
          const reorder = await postOrder(orderRequest);
          reorderSucceeded.push({ originalOrder: order, reorder });
        } catch (error) {
          reorderFailed.push({ order, errorMessage: error.message });
          return;
        }

        // Fetch list for FCs associated with that order
        const uniqueFCs = uniqBy(order.items, '_links.fulfillmentConfiguration.href');
        const orderLevelCancellationSupport = [];
        let cancelAllItems = false;
        try {
          await P.map(uniqueFCs, async fulfillmentConfigurationUrl => {
            const supportedExpectations = await getFulfillmentExpectationView(fulfillmentConfigurationUrl);
            const supported = supportedExpectations._embedded.processes.some(
              process => process.expectationCode === 'supportOrderCancellations' && process.supported
            );
            orderLevelCancellationSupport.push({
              fulfillmentConfigurationUrl,
              supported,
            });
          });

          if (orderLevelCancellationSupport.every(o => o.supported)) {
            cancelAllItems = order.items.every(i => i.computedStatus.type !== REJECTED || !shouldSkipItem(i));
          }
        } catch (error) {
          fetchOrderCancellationFormatFailed.push(error);
        }

        if (cancelAllItems) {
          try {
            // request cancellations on all reordered items that were not already rejected
            await cancelOrder(order.orderId);
          } catch (error) {
            cancellationFailed.push(order);
          }
        }

        const cancelRequestPromises = [];
        const clearAlertPromises = [];
        order.items.forEach(item => {
          if (shouldSkipItem(item)) {
            return;
          }
          if (!cancelAllItems) {
            // request cancellations on reordered items that were not already rejected
            const itemStatus = get(item, 'computedStatus.type');
            if (itemStatus !== REJECTED) {
              cancelRequestPromises.push(() => requestItemCancellation(item));
            }
          }

          // clear all alerts on reordered items
          const alerts = get(item, 'computedErrors', []).concat(get(item, 'computedMissedSlas', []));
          alerts.forEach(alert => {
            clearAlertPromises.push(() => clearAlert(item.itemId, alert.name));
          });
        });

        try {
          await P.map(cancelRequestPromises, async cancelRequestPromise => cancelRequestPromise(), {
            concurrency: 10,
          });
        } catch (error) {
          cancellationFailed.push(order);
        }

        try {
          await P.map(clearAlertPromises, async clearAlertPromise => clearAlertPromise(), { concurrency: 10 });
        } catch (error) {
          clearAlertsFailed.push(order);
        }
      },
      { concurrency: 10 }
    );
  } catch (error) {
    console.error(error);
  }
  dispatch({
    type: BULK_REORDER_COMPLETED,
    results: {
      reorderSucceeded,
      reorderFailed,
      cancellationFailed,
      clearAlertsFailed,
      fetchOrderCancellationFormatFailed,
      skipped,
    },
  });
};

export const clearBulkReorder = () => ({ type: CLEAR_BULK_REORDER });
