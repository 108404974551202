import * as types from '../constants/actiontypes.js';

export const initialState = {
  loading: false,
  carrierServicesMap: {},
  error: null,
};

export default function carrierServices(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_CARRIER_SERVICES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case types.GET_CARRIER_SERVICES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        carrierServicesMap: action.response.reduce((acc, carrierService) => {
          acc[carrierService.key] = carrierService.name;
          return acc;
        }, {}),
        error: null,
      });
    case types.GET_CARRIER_SERVICES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
