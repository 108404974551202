import * as types from '../constants/actiontypes.js';

const GLOBAL_ERROR_KEY = 'Global.Error';

export const initialState = {
  messageType: '',
  showSnackbar: false,
  messageKey: 'Global.Success',
  detailsKey: '',
  detailsValues: {},
  additionalData: null,
  style: 'info',
  delay: 10000,
};

export default function messages(state = initialState, action = {}) {
  let defaults = {
    messageType: action.type,
    showSnackbar: true,
    additionalData: null,
    delay: 10000,
  };

  let errorJson; // This is an intermediate variable and is only used in some cases
  switch (action.type) {
    case types.CANCEL_ITEMS_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.CANCEL_REQUEST_SUCCESS', style: 'success' };
    case types.CANCEL_ITEMS_FAILURE:
      errorJson = JSON.parse(action.responseBody);
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.CANCEL_REQUEST_FAILURE',
        additionalData: errorJson.message,
        style: 'danger',
      };
    case types.CANCEL_ORDER_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.CANCEL_REQUEST_SUCCESS', style: 'success' };
    case types.CANCEL_ORDER_FAILURE:
      errorJson = JSON.parse(action.responseBody);
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.CANCEL_REQUEST_FAILURE',
        additionalData: errorJson.message,
        style: 'danger',
      };
    case types.POST_EVENT_ATTEMPT_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.POST_EVENT_ATTEMPT_SUCCESS', style: 'success' };
    case types.POST_EVENT_ATTEMPT_FAILURE:
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.POST_EVENT_ATTEMPT_FAILURE',
        style: 'danger',
      };
    case types.ACKNOWLEDGE_DISCREPANCY_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.ACKNOWLEDGE_DISCREPANCY_SUCCESS', style: 'success' };
    case types.ACKNOWLEDGE_DISCREPANCY_FAILURE:
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.ACKNOWLEDGE_DISCREPANCY_FAILURE',
        style: 'danger',
      };
    case types.REJECT_ITEM_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.REJECT_ITEM_SUCCESS', style: 'success' };
    case types.REJECT_ITEM_FAILURE:
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.REJECT_ITEM_FAILURE',
        style: 'danger',
      };
    case types.CHANGE_DELIVERY_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.CHANGE_DELIVERY_SUCCESS', style: 'success' };
    case types.CHANGE_DELIVERY_FAILURE:
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.CHANGE_DELIVERY_FAILURE',
        style: 'danger',
      };
    case types.POST_CALLBACK_URL_SUCCESS:
      return { ...state, ...defaults, detailsKey: 'MessageBar.POST_CALLBACK_URL_SUCCESS', style: 'success' };
    case types.POST_CALLBACK_URL_FAILURE:
      return {
        ...state,
        ...defaults,
        messageKey: GLOBAL_ERROR_KEY,
        detailsKey: 'MessageBar.POST_CALLBACK_URL_FAILURE',
        style: 'danger',
      };
    case types.UPDATE_GROUP_STATUS_SUCCESS:
      return {
        ...state,
        ...defaults,
        detailsKey: 'MessageBar.UPDATE_GROUP_STATUS_SUCCESS',
        detailsValues: {
          numUpdates: action.totalFulfillmentGroups,
          totalUpdates: action.totalFulfillmentGroups,
        },
        style: 'success',
      };
    case types.UPDATE_GROUP_STATUS_FAILURE: {
      const { failures, totalFulfillmentGroups } = action;
      const allFailed = failures.length === totalFulfillmentGroups;
      const messageKey = allFailed ? GLOBAL_ERROR_KEY : 'Global.Warning';

      let detailsKey = initialState.detailsKey;
      let detailsValues = {};

      if (allFailed) {
        detailsKey = 'MessageBar.UPDATE_GROUP_STATUS_FAILURE';
      } else {
        detailsKey = 'MessageBar.UPDATE_GROUP_STATUS_PARTIAL_SUCCESS';
        detailsValues = {
          numUpdates: totalFulfillmentGroups - failures.length,
          totalUpdates: totalFulfillmentGroups,
        };
      }

      return {
        ...state,
        ...defaults,
        messageKey,
        detailsKey,
        detailsValues,
        additionalData: failures.map(({ fulfillmentGroupId, status }) => ({ key: fulfillmentGroupId, value: status })),
        style: allFailed ? 'danger' : 'warning',
        delay: 0,
      };
    }
    case types.UPDATE_ITEM_STATUS_SUCCESS:
      return {
        ...state,
        ...defaults,
        detailsKey: 'MessageBar.UPDATE_ITEM_STATUS_SUCCESS',
        detailsValues: {
          numUpdates: action.totalItems,
          totalUpdates: action.totalItems,
        },
        style: 'success',
      };
    case types.UPDATE_ITEM_STATUS_FAILURE: {
      const { failures, totalItems } = action;
      const allFailed = failures.length === totalItems;
      const messageKey = allFailed ? GLOBAL_ERROR_KEY : 'Global.Warning';

      let detailsKey = initialState.detailsKey;
      let detailsValues = {};

      if (allFailed) {
        detailsKey = 'MessageBar.UPDATE_ITEM_STATUS_FAILURE';
      } else {
        detailsKey = 'MessageBar.UPDATE_ITEM_STATUS_PARTIAL_SUCCESS';
        detailsValues = {
          numUpdates: totalItems - failures.length,
          totalUpdates: totalItems,
        };
      }

      return {
        ...state,
        ...defaults,
        messageKey,
        detailsKey,
        detailsValues,
        additionalData: failures.map(({ itemId, status }) => ({ key: itemId, value: status })),
        style: allFailed ? 'danger' : 'warning',
        delay: 0,
      };
    }
    case types.CLOSE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
}
