import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Dropdown from '@cimpress/react-components/lib/Dropdown';
import CancelRequestModal from './actions/CancelRequestModal';
import RejectItemModal from './actions/RejectItemModal';
import ClearItemAlertsModal from './status/ClearItemAlertsModal.jsx';
import { connect } from 'react-redux';
import * as util from '../../utils/permissions-v2.js';
import ActionLink from '../shared/ActionLink';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';

class ItemActions extends Component {
  trackPomClick = itemId => {
    trackEvent(TrackingEventTitles.VIEWED_IN_POM, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.ITEM_ID]: itemId,
    });
  };

  trackFulfillerClick = itemId => {
    trackEvent(TrackingEventTitles.VIEWED_IN_FULFILLMENT_SYSTEM, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.ITEM_ID]: itemId,
    });
  };

  trackEcommerceClick = itemId => {
    trackEvent(TrackingEventTitles.VIEWED_IN_ECOMMERCE, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.ITEM_ID]: itemId,
    });
  };

  render() {
    const { orderId, item, permissions } = this.props;
    const { merchantId, computedMissedSlas = [], computedErrors = [] } = item;
    const hasMerchantUpdatePermission = util.hasMerchantUpdatePermission(permissions, merchantId);
    // this option is only shown when we can perform these actions based on user permissions.
    const canCancel = Boolean(
      item.allowedActions && item.allowedActions.find(action => action.actionType === 'orderItemCancellation')
    );
    const cancelItemsButton = hasMerchantUpdatePermission && (
      <CancelRequestModal
        itemId={item.itemId}
        orderId={orderId}
        canCancel={canCancel}
        merchantProductName={item.merchantProductName}
      />
    );

    const clearAlertsButton = hasMerchantUpdatePermission && (
      <ClearItemAlertsModal
        clearableStatuses={computedMissedSlas.concat(computedErrors)}
        itemId={item.itemId}
        merchantId={merchantId}
      />
    );

    const rejectItemButton = util.hasFulfillerUpdatePermission(permissions, item.fulfillerId) && (
      <RejectItemModal item={item} orderId={orderId} />
    );

    const {
      _links: { cimpressProductionOrderManager = {}, fulfillerProductionSystem = {}, ecommerceSystem = {} },
    } = item;

    const pomLink = (
      <ActionLink
        text={
          <>
            <FormattedMessage id="ItemLinks.ViewInPOM" /> <i className="fa fa-sm fa-external-link" />
          </>
        }
        href={cimpressProductionOrderManager.href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => this.trackPomClick(item.itemId)}
        disabled={!cimpressProductionOrderManager.href}
        disabledMessage={<FormattedMessage id="ItemLinks.ViewInPOMUnavailable" />}
      />
    );

    const fulfillerLink = (
      <ActionLink
        text={
          <>
            <FormattedMessage id="ItemLinks.ViewInFulfillmentSystem" />
            {fulfillerProductionSystem.title ? ` - ${fulfillerProductionSystem.title}` : ''}{' '}
            <i className="fa fa-sm fa-external-link" />
          </>
        }
        href={fulfillerProductionSystem.href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => this.trackFulfillerClick(item.itemId)}
        disabled={!fulfillerProductionSystem.href}
        disabledMessage={<FormattedMessage id="ItemLinks.ViewInFulfillmentSystemUnavailable" />}
      />
    );

    const ecommerceLink = (
      <ActionLink
        text={
          <>
            <FormattedMessage id="ItemLinks.ViewInEcommerceSystem" />
            {ecommerceSystem.title ? ` - ${ecommerceSystem.title}` : ''} <i className="fa fa-sm fa-external-link" />
          </>
        }
        href={ecommerceSystem.href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => this.trackEcommerceClick(item.itemId)}
        disabled={!ecommerceSystem.href}
        disabledMessage={<FormattedMessage id="ItemLinks.ViewInEcommerceSystemUnavailable" />}
      />
    );

    return (
      <Dropdown
        title={<FormattedMessage id="ItemLevelFields.ItemActions" />}
        className="pull-right"
        style={{ whiteSpace: 'nowrap' }}>
        {cancelItemsButton}
        {rejectItemButton}
        {clearAlertsButton}
        {pomLink}
        {fulfillerLink}
        {ecommerceLink}
      </Dropdown>
    );
  }
}

ItemActions.propTypes = {
  orderId: PropTypes.string.isRequired,
  merchantOrderId: PropTypes.string,
  toggleEventDetails: PropTypes.func,
  showEventsDetails: PropTypes.bool,
  item: PropTypes.shape({
    itemId: PropTypes.string,
    merchantId: PropTypes.string,
    fulfillerId: PropTypes.string,
    merchantProductName: PropTypes.string,
    events: PropTypes.array,
    itemStatus: PropTypes.object,
    statuses: PropTypes.object,
  }),
  fulfillmentGroup: PropTypes.object,
  permissions: PropTypes.object,
};

ItemActions.defaultProps = {
  permissions: {},
};

const mapStateToProps = state => ({ permissions: state.permissions.permissions });

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md#important-note
export default withRouter(connect(mapStateToProps)(ItemActions));
