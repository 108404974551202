import { useEffect, useState } from 'react';
import P from 'bluebird';

import { callFetch } from '../../services/serviceHelpers';

const useLinkedItems = links => {
  const [linkedItems, setLinkedItems] = useState();

  useEffect(() => {
    const fetchLinkedItem = async () => {
      const items = await P.map(
        links,
        async link => {
          try {
            const item = await callFetch(link, 'GET');
            return item;
          } catch (error) {
            // do nothing, the user will get a 404 if they don't have access to the item
          }
        },
        { concurrency: 5 }
      );

      setLinkedItems(items);
    };

    if (links && links.length > 0) {
      fetchLinkedItem();
    }
  }, []);

  return linkedItems;
};

export default useLinkedItems;
