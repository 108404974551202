import _ from 'lodash';

export default filterCriteria => {
  return item => {
    // a property match refers to properties on the item such as itemId, merchantItemId, shortItemId that
    // we want to be able to do a case insensitive partial match on. a propertyFilter object has a key
    // and a value where the key is the name of the property on the item and the value is the value
    // to filter on
    const isPropertyMatch = propertyFilter =>
      new RegExp(propertyFilter.value, 'i').test(_.get(item, propertyFilter.propertyName, ''));
    const propertyMatches =
      _.isEmpty(filterCriteria.propertyFilters) || !!_.find(filterCriteria.propertyFilters, isPropertyMatch);

    // return if this item is a match or not
    return propertyMatches;
  };
};
