import {
  BULK_REORDER_STARTED,
  BULK_REORDER_COMPLETED,
  CLEAR_BULK_REORDER,
  BULK_REORDER_SEARCH_REQUEST,
  BULK_REORDER_SEARCH_SUCCESS,
  BULK_REORDER_SEARCH_FAILURE,
} from '../constants/actiontypes';

const initialState = {
  submitting: false,
  submitted: false,
  loading: false,
  fetchNextPagesError: null,
  nextPagesOrders: [],
  reorderSucceeded: [],
  reorderFailed: [],
  cancellationFailed: [],
  clearAlertsFailed: [],
  skipped: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case BULK_REORDER_SEARCH_REQUEST: {
      return { ...state, loading: true };
    }
    case BULK_REORDER_SEARCH_SUCCESS: {
      return { ...state, loading: false, nextPagesOrders: action.orders };
    }
    case BULK_REORDER_SEARCH_FAILURE: {
      return {
        ...state,
        loading: false,
        fetchNextPagesError: action.error,
      };
    }
    case BULK_REORDER_STARTED: {
      return { ...state, submitted: false, submitting: true };
    }
    case BULK_REORDER_COMPLETED: {
      const {
        reorderSucceeded = [],
        reorderFailed = [],
        cancellationFailed = [],
        clearAlertsFailed = [],
        skipped = [],
      } = action.results;
      return {
        ...state,
        submitting: false,
        submitted: true,
        reorderSucceeded,
        reorderFailed,
        cancellationFailed,
        clearAlertsFailed,
        skipped,
      };
    }
    case CLEAR_BULK_REORDER: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
