import { get } from 'lodash';

export const orderHasRoutingDecision = async (item, accessToken) => {
  const itemRoutingStatus = get(item, ['statuses', 'routing']);

  // Item hasn't reached routing state yet, so there won't be a decision
  if (!itemRoutingStatus) {
    return false;
  }

  // If there is a routing status, check to see if we have a routing decision
  // If it's a routed item, item's _links should include a routing decision
  const routingDecision = get(item, ['_links', 'routingDecision', 'href']);
  if (routingDecision) {
    return true;
  }

  // If it's an ordered item, the item's _links won't have a routing decision,
  // but should have a routing request
  const routingRequest = get(item, ['_links', 'routingRequest', 'href']);
  if (!routingRequest) {
    return false;
  }

  // If the item has a routing request, we should load the request and
  // check to see if it contains a routing decision
  try {
    const routingRequestResp = await fetch(routingRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    if (!routingRequestResp.ok) {
      return false;
    }

    // If we've made it this far and can't get the JSON for the routing request,
    // then we have no way to get a routing decision
    const routingRequestJson = await routingRequestResp.json();
    if (!routingRequestJson) {
      return false;
    }

    // The routing request, if routing has run, should have a routing result in the _embedded
    // object. That routing result may have multiple decisions on it depending on the number
    // of items routed together in an order. We can just check to see if there's at least one
    // decision and let the decision viewer handle which one to display
    const decisionOnRoutingRequest = get(routingRequestJson, ['_embedded', 'routingResult']);
    if (decisionOnRoutingRequest && decisionOnRoutingRequest.length) {
      return true;
    }
  } catch (error) {
    console.log(`There was an error trying to get the routing decision: ${error}`);
    return false;
  }

  return false;
};
