import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import auth from '../../utils/auth.js';
import HeaderComponent from '@cimpress-technology/react-platform-header';
import { LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';
import { connect } from 'react-redux';
import TimezoneToggle from './timezoneToggle.jsx';
import { logoutSession } from '../../actions/sessionActions.js';
import { Link } from 'react-router-dom';
import Settings from './Settings';
import { DEFAULT_LANGUAGE } from '../../constants/languages';
import { trackEvent, TrackingEventTitles, TrackingPageNames } from '../../analytics';

class Header extends React.Component {
  static propTypes = {
    logoutSession: PropTypes.func.isRequired,
    userLanguage: PropTypes.string,
  };

  static defaultProps = {
    userLanguage: DEFAULT_LANGUAGE,
  };

  onLogInClicked = () => auth.login('/');
  onLogOutClicked = () => this.props.logoutSession().then(() => auth.logout('/'));

  render() {
    const { userLanguage } = this.props;
    // TODO when Profile component supports it, pass in strings:
    // intl.formatMessage({ id: "Global.SignOut" })
    // intl.formatMessage({ id: "Preferences.Settings" })
    // intl.formatMessage({ id: "Global.SignIn" })
    const reportsLink = {
      id: 'reports',
      content: (
        <Link to="/reports">
          <FormattedMessage id="Reports.Reports" />
        </Link>
      ),
    };
    const claimsLink = {
      id: 'claims',
      content: (
        <a
          href={`${process.env.REACT_APP_COMPLAINTS_TOOL_URL}/fulfillerClaims`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            const pageProps = {
              'Page Path': '/claims',
              'Page Title': TrackingPageNames.CLAIMS,
            };
            trackEvent(TrackingEventTitles.PAGE_OPEN, pageProps);
          }}>
          <FormattedMessage id="Claims.Claims" />
        </a>
      ),
    };
    const divider = {
      id: 'divider',
      content: <div className="link-divider" />,
    };
    const timezoneToggleLink = {
      id: 'timezone',
      content: <TimezoneToggle />,
    };
    const settingsLink = {
      id: 'settings',
      content: <Settings />,
    };

    return (
      <div>
        <HeaderComponent
          responsive
          language={userLanguage}
          appTitle={
            <Link to="/">
              <h2>
                <FormattedMessage id="Global.Orders" />
              </h2>
            </Link>
          }
          appLinks={[claimsLink, reportsLink, divider, timezoneToggleLink, settingsLink]}
          profile={auth.getProfile()}
          onLogInClicked={this.onLogInClicked}
          onLogOutClicked={this.onLogOutClicked}
          isLoggedIn={auth.isLoggedIn()}
          accessToken={auth.getAccessToken()}
          isProduction={process.env.REACT_APP_ENV === 'prd'}
          notifications={
            <LoudspeakerIcon
              accessToken={auth.getAccessToken()}
              channelIds={[process.env.REACT_APP_LOUDSPEAKER_CHANNEL_ID]}
              title={<FormattedMessage id="Loudspeaker.Title" />}
              language={userLanguage}
            />
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userPreferences: { userLanguage } }) => ({ userLanguage });

export default connect(mapStateToProps, { logoutSession })(Header);
