import {
  LOAD_GLOBAL_SETTINGS,
  SAVE_APP_SETTINGS,
  LOAD_APP_SETTINGS,
  SET_DISPLAY_TIMEZONE,
} from '../constants/actiontypes';
import { get, isUndefined } from 'lodash';
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient';
import { setPreferredMcpSettings } from 'cimpress-customizr';
import { putSetting, getSetting } from '../services/customizr';
import auth from '../utils/auth';
import { LANGUAGES } from '../constants/languages';

const CUSTOMIZR_APP_SETTINGS_KEY = 'OOPs-appSettings';

const ID_FORMAT_LOCAL_STORAGE = 'idFormat';

const TIMEZONE_CLAIM = 'https://claims.cimpress.io/timezone';
const LANGUAGE_CLAIM = 'https://claims.cimpress.io/language';
const LOCALE_CLAIM = 'https://claims.cimpress.io/locale';

// if user doesn't have any global settings stored in customizr, copy their auth0 profile metadata over to customizr
const migrateGlobalSettingsIfNeeded = ({ language, regionalSettings, timezone }) => {
  if (language && regionalSettings && timezone) {
    return;
  }

  const profile = auth.getProfile();
  if (!profile) {
    return;
  }

  if (!language) {
    language = get(profile, 'user_metadata.language') || profile[LANGUAGE_CLAIM];
  }
  if (!regionalSettings) {
    regionalSettings = get(profile, 'user_metadata.locale') || profile[LOCALE_CLAIM];
  }
  if (!timezone) {
    timezone = get(profile, 'user_metadata.timezone') || profile[TIMEZONE_CLAIM];
  }
  setPreferredMcpSettings(auth.getAccessToken(), language, regionalSettings, timezone);
};

// if user doesn't have app-specific settings stored in customizr, copy it over from local storage
const migrateAppSettings = () => {
  const savedSettings = {
    idFormat: localStorage.getItem(ID_FORMAT_LOCAL_STORAGE),
  };
  if (savedSettings.idFormat) {
    putSetting(CUSTOMIZR_APP_SETTINGS_KEY, savedSettings);
  }
  return savedSettings;
};

export const setDisplayTimezone = displayTimezone => ({
  type: SET_DISPLAY_TIMEZONE,
  displayTimezone,
});

export const saveAppSettings = ({ idFormat, beta }) => (dispatch, getState) => {
  const state = getState();

  const settings = {
    idFormat: !isUndefined(idFormat) ? idFormat : state.userPreferences.idFormat,
    beta: !isUndefined(beta) ? beta : state.userPreferences.beta,
  };

  putSetting(CUSTOMIZR_APP_SETTINGS_KEY, settings);
  dispatch({
    type: SAVE_APP_SETTINGS,
    ...settings,
  });
};

export const loadAppSettings = () => dispatch => {
  return getSetting(CUSTOMIZR_APP_SETTINGS_KEY)
    .then(({ idFormat, beta }) => {
      return dispatch({
        type: LOAD_APP_SETTINGS,
        idFormat,
        beta,
      });
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        const savedSettings = migrateAppSettings();
        if (savedSettings.idFormat) {
          dispatch({
            type: LOAD_APP_SETTINGS,
            ...savedSettings,
          });
        }
      } else {
        console.error(error);
      }
    });
};

export const loadGlobalSettings = () => {
  return dispatch => {
    return getSettings(auth.getAccessToken(), LANGUAGES)
      .then(settings => {
        const globalSettings = settings.global || {};
        migrateGlobalSettingsIfNeeded(globalSettings);
        const { language, regionalSettings, timezone } = globalSettings;
        return dispatch({ type: LOAD_GLOBAL_SETTINGS, language, regionalSettings, timezone });
      })
      .catch(console.warn);
  };
};
