import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Robot from '@cimpress/react-components/lib/shapes/Robot';

const GeneralErrorPage = ({ title, message, orderId, status, alternate }) => (
  <div style={{ paddingTop: '30px' }}>
    <div style={{ display: 'flex' }} className="container">
      <div style={{ flexShrink: '0' }} className="card-block">
        <Robot status={status} alternate={alternate} size="lg" />
      </div>
      <div className="card-block" style={{ alignSelf: 'center' }}>
        <div className={`h2 text-${status}`}>{title}</div>
        {orderId ? (
          <h3>
            <FormattedMessage id="Global.Order" /> #{orderId}
          </h3>
        ) : (
          ''
        )}
        <h5>{message}</h5>
      </div>
    </div>
  </div>
);

GeneralErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  status: PropTypes.oneOf(['warning', 'success', 'info', 'danger']).isRequired,
  orderId: PropTypes.string,
  alternate: PropTypes.bool,
};

export default GeneralErrorPage;
