import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { Label } from '@cimpress/react-components';

import { callFetch } from '../../../../services/serviceHelpers';

const FormattedFulfillmentConfigurationName = ({
  fulfillmentConfigurationUrl,
  isNewFulfillmentConfiguration = false,
}) => {
  const intl = useIntl();

  const [{ name, versionNumber }, setFulfillmentConfigurationData] = useState({ name: null, version: null });

  useEffect(() => {
    const fetchFulfillmentConfigurationData = async () => {
      if (fulfillmentConfigurationUrl) {
        const fulfillmentConfigurationVersion = await callFetch(fulfillmentConfigurationUrl);
        const fulfillmentConfiguration = await callFetch(
          get(fulfillmentConfigurationVersion, '_links.fulfillmentConfiguration.href.v1')
        );

        setFulfillmentConfigurationData({
          name: fulfillmentConfiguration.name,
          versionNumber: fulfillmentConfigurationVersion.versionNumber,
        });
      }
    };
    fetchFulfillmentConfigurationData();
  }, [fulfillmentConfigurationUrl]);

  const wasFcDataLoaded = name && versionNumber;
  const formattedName = wasFcDataLoaded
    ? intl.formatMessage({ id: 'RequestRedirect.FulfillmentConfigurationName' }, { name, versionNumber })
    : fulfillmentConfigurationUrl;

  return isNewFulfillmentConfiguration ? (
    <Label
      text={wasFcDataLoaded ? formattedName : intl.formatMessage({ id: 'RequestRedirect.None' })}
      status={wasFcDataLoaded ? 'success' : 'warning'}
    />
  ) : (
    <span>{formattedName}</span>
  );
};

export default FormattedFulfillmentConfigurationName;
