import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import enhanceWithClickOutside from 'react-click-outside';
import Modal from '@cimpress/react-components/lib/Modal';
import { teal } from '@cimpress/react-components/lib/colors';
import { trackEvent } from '../../analytics';
import moment from 'moment';
import { initialFilterState } from '../../constants/searchFilters';
import {
  setMerchants,
  setFulfillers,
  setStatuses,
  setDelayedStatuses,
  setErrorStatuses,
  setOrderTypes,
  setSalesChannels,
  setProductCategories,
  setClaimTypes,
  setComplaintTypes,
  setChangeRequestTypes,
  setSearchTerm,
  setSort,
  setStartDate,
  setEndDate,
} from '../../actions/ordersearchactions';
import { SAVED_SEARCH, RECOMMENDED_SEARCH, TIME_PERIOD_SEARCH } from '../../constants/recommendedSearches';
import IconTimeClockCircle from '@cimpress-technology/react-streamline-icons/lib/IconTimeClockCircle';
import IconFloppyDiskAlt from '@cimpress-technology/react-streamline-icons/lib/IconFloppyDiskAlt';
import IconSearchStar from '@cimpress-technology/react-streamline-icons/lib/IconSearchStar';

class SearchItem extends PureComponent {
  static propTypes = {
    search: PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string,
      trackingEventTitle: PropTypes.string,
    }),
    searchType: PropTypes.oneOf([SAVED_SEARCH, RECOMMENDED_SEARCH, TIME_PERIOD_SEARCH]).isRequired,
    doSearch: PropTypes.func.isRequired,
  };

  doSearch = () => {
    trackEvent(this.props.search.trackingEventTitle, {});

    this.props.doSearch(this.props.search);
  };

  render() {
    const {
      search: { name },
    } = this.props;
    return (
      <li onClick={this.doSearch}>
        <a>{name}</a>
      </li>
    );
  }
}

class SearchDropdownPanel extends PureComponent {
  static propTypes = {
    searchType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    searchList: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    emptyMessage: PropTypes.string,
    onFiltersUpdated: PropTypes.func.isRequired,
    setMerchants: PropTypes.func.isRequired,
    setFulfillers: PropTypes.func.isRequired,
    setStatuses: PropTypes.func.isRequired,
    setErrorStatuses: PropTypes.func.isRequired,
    setDelayedStatuses: PropTypes.func.isRequired,
    setOrderTypes: PropTypes.func.isRequired,
    setSalesChannels: PropTypes.func.isRequired,
    setProductCategories: PropTypes.func.isRequired,
    setClaimTypes: PropTypes.func.isRequired,
    setComplaintTypes: PropTypes.func.isRequired,
    setChangeRequestTypes: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    searchList: [],
    emptyMessage: '',
  };

  state = {
    open: false,
    showEmptyMessageModal: false,
  };

  doSearch = search => {
    const filters = { ...initialFilterState, ...((search && search.filters) || {}) };
    const updates = [
      this.props.setMerchants(filters.selectedMerchants),
      this.props.setFulfillers(filters.selectedFulfillers),
      this.props.setStatuses(filters.selectedStatuses),
      this.props.setDelayedStatuses(filters.selectedDelayedStatuses),
      this.props.setErrorStatuses(filters.selectedErrorStatuses),
      this.props.setOrderTypes(filters.selectedOrderTypes),
      this.props.setSalesChannels(filters.selectedSalesChannels),
      this.props.setProductCategories(filters.selectedProductCategories),
      this.props.setClaimTypes(filters.selectedClaimTypes),
      this.props.setComplaintTypes(filters.selectedComplaintTypes),
      this.props.setChangeRequestTypes(filters.selectedChangeRequestTypes),
      this.props.setSearchTerm(filters.searchTerm),
      this.props.setSort(filters.sortField, filters.sortOrder),
    ];
    if (filters.duration) {
      const { value, unit } = filters.duration;
      if (value && unit) {
        updates.push(
          this.props.setStartDate(
            moment()
              .utc()
              .subtract(value, unit)
          )
        );
        updates.push(this.props.setEndDate(moment().utc()));
      }
    }

    return Promise.all(updates).then(() => {
      return this.props.onFiltersUpdated();
    });
  };

  toggle = () => {
    this.setState(prevState => {
      const { searchList } = this.props;
      const tryingToOpen = !prevState.open;
      const open = tryingToOpen && searchList.length;
      const showEmptyMessageModal = tryingToOpen && !searchList.length;
      return { open, showEmptyMessageModal };
    });
  };

  closeModal = () => {
    this.setState({ showEmptyMessageModal: false });
  };

  handleClickOutside() {
    this.state.open && this.setState({ open: false });
  }

  render() {
    const { open, showEmptyMessageModal } = this.state;
    const { title, searchType, searchList, emptyMessage } = this.props;
    return (
      <Fragment>
        <div
          className="btn btn-link"
          style={{
            flex: 1,
            padding: '30px 15px',
            maxWidth: '200px',
          }}
          onClick={this.toggle}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {searchType === TIME_PERIOD_SEARCH ? (
              <IconTimeClockCircle
                style={{ overflow: 'visible', marginBottom: '15px', marginRight: '16px' }}
                size="3x"
                color={teal.base}
              />
            ) : searchType === SAVED_SEARCH ? (
              <IconFloppyDiskAlt
                style={{ overflow: 'visible', marginBottom: '15px', marginRight: '16px' }}
                size="3x"
                color={teal.base}
              />
            ) : (
              <IconSearchStar
                style={{ overflow: 'visible', marginBottom: '15px', marginRight: '16px' }}
                size="3x"
                color={teal.base}
              />
            )}
            <div className={`btn-group${open ? ' open' : ''}`}>
              <a className="clickable" style={{ fontSize: '16px' }}>
                {title} <span className="caret" />
              </a>
              <ul className="dropdown-menu">
                {searchList.map((search, idx) => (
                  <SearchItem key={idx} search={search} searchType={searchType} doSearch={this.doSearch} />
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Modal
          show={showEmptyMessageModal}
          onRequestHide={this.closeModal}
          closeOnOutsideClick
          closeButton
          title={title}
          footer={
            <button className="btn btn-default" onClick={this.closeModal}>
              <FormattedMessage id="Global.Close" />
            </button>
          }>
          {emptyMessage}
        </Modal>
      </Fragment>
    );
  }
}

export default connect(null, {
  setMerchants,
  setFulfillers,
  setDelayedStatuses,
  setErrorStatuses,
  setStatuses,
  setOrderTypes,
  setSalesChannels,
  setProductCategories,
  setClaimTypes,
  setComplaintTypes,
  setChangeRequestTypes,
  setSearchTerm,
  setSort,
  setStartDate,
  setEndDate,
})(enhanceWithClickOutside(SearchDropdownPanel));
