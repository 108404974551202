import React from 'react';
import PropTypes from 'prop-types';

const FieldDisplay = ({
  name,
  value,
  compact,
  squished,
  newline,
  headingStyle = { maxWidth: '200px' },
  style = {},
}) => {
  const internalheadingStyle = compact ? { margin: 0 } : {};
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        flex: 1,
        wordBreak: 'break-word',
        flexDirection: newline ? 'column' : 'row',
        ...style,
      }}>
      <span className="h6" style={{ ...internalheadingStyle, ...headingStyle, flex: `1 ${squished ? '-1' : '0'} 0%` }}>
        {name}
      </span>
      <div style={{ flex: 1.5, marginLeft: newline ? '0' : '10px' }}>{value}</div>
    </div>
  );
};

FieldDisplay.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  compact: PropTypes.bool,
  squished: PropTypes.bool,
  newline: PropTypes.bool,
};

export default FieldDisplay;
