import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Alert from '@cimpress/react-components/lib/Alert';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import flatMap from 'lodash/flatMap';
import { connect } from 'react-redux';
import reactHtmlParser from 'react-html-parser';

import SearchBar from './search/searchbar.jsx';
import ResultsSection from './search/resultssection.jsx';
import * as searchActionCreators from '../actions/ordersearchactions.js';
import {
  getQueryString,
  getPropsFromQuery,
  getParamsFromQuery,
  getLocationFromProps,
} from '../utils/utilityfunctions.js';
import { trackOrderSearch } from '../analytics';
import { intersectionOf } from '../utils/utilityfunctions';
import auth from '../utils/auth.js';
import { canReadAnyOrder } from '../utils/permissions-v2';

class OrderSearch extends React.Component {
  componentDidMount() {
    const { intl } = this.props;
    document.title = intl.formatMessage({ id: 'OrderSearch.PageName' });

    // check whether the incoming querystring matches the query saved in props
    let lastQuery = getPropsFromQuery(this.props.location.search.substring(1), intl);
    // if the query is the same we can assume that we do not need to reload orders
    if (!isEqual(this.props.lastQuery, lastQuery)) {
      // if the query is not the same, (ie initial page load), call the server
      this.searchFromQueryParams(this.props.location.search.substring(1));
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.location.search.substring(1) && prevProps.location.search.substring(1)) {
      this.props.clearSearch();
    } else if (prevProps.location.search.substring(1) !== this.props.location.search.substring(1)) {
      this.searchFromQueryParams(this.props.location.search.substring(1));
    }
  }

  // updates store with given url query params, then does a search
  searchFromQueryParams = async query => {
    //don't search if there are no params!
    if (!query) {
      return;
    }
    // convert url params to props
    let newProps = getPropsFromQuery(query, this.props.intl);

    this.props.setSearchTerm(newProps.searchTerm);
    this.props.setStartDate(newProps.startDate);
    this.props.setEndDate(newProps.endDate);
    this.props.setMerchants(newProps.merchants);
    this.props.setFulfillers(newProps.fulfillers);
    this.props.setStatuses(newProps.statuses);
    this.props.setDelayedStatuses(newProps.delayedStatuses);
    this.props.setErrorStatuses(newProps.errorStatuses);
    this.props.setOrderTypes(newProps.orderTypes);
    this.props.setSalesChannels(newProps.salesChannels);
    this.props.setProductCategories(newProps.productCategories);
    this.props.setClaimTypes(newProps.claimTypes);
    this.props.setComplaintTypes(newProps.complaintTypes);
    this.props.setChangeRequestTypes(newProps.changeRequestTypes);
    this.props.setSort(newProps.sortField, newProps.sortOrder);
    this.setPage(newProps.page);

    trackOrderSearch(newProps);

    // perform a search based on the new props:
    let params = getParamsFromQuery(query);
    this.props.search(params, this.goToOrderDetails);
  };

  // this updates url based on props and any given overrides, and triggers a search
  // all numerical overrides should be STRINGS.
  setQueryParams = (override = {}) => {
    var params = { ...getLocationFromProps(this.props), ...override };
    var newQueryString = getQueryString(params);
    var oldQueryString = this.props.location.search.substring(1);
    // if query string has not changed (e.g. clicked on search button again without changing any input),
    // need to explicitly call search here
    if (newQueryString === oldQueryString) {
      this.searchFromQueryParams(newQueryString);
    }
    // for other cases, updating the url will trigger a search
    this.props.history.push(this.props.location.pathname + '?' + newQueryString);
  };

  // sets the page in redux for the currently selected search type
  setPage = page => {
    this.props.changePage(page);
  };

  // navigates to a new page by storing the page, then doing a search with new page
  changePage = page => {
    this.setPage(page);
    this.setQueryParams({ page: `${page}` }); // need to pass in page override because setQueryParams won't have new props yet
  };

  goToOrderDetails = orderId => {
    this.props.setLastOrder(orderId);
    const nextRoute = `/orders/${orderId}`;
    this.props.history.push(nextRoute);
  };

  setLastOrder = orderId => {
    this.props.setLastOrder(orderId);
  };

  onSearchTermChanged = evt => {
    this.props.setSearchTerm(evt.target.value);
  };

  onStartDateChanged = e => {
    this.props.setStartDate(e, true);
  };

  onEndDateChanged = e => {
    this.props.setEndDate(e, true);
  };

  onFiltersUpdated = () => {
    this.changePage(1);
  };

  onSubmit = () => {
    this.setQueryParams({ page: '1' });
  };

  render() {
    const { permissions, intl } = this.props;
    if (permissions && !canReadAnyOrder(permissions)) {
      const userId = auth.isLoggedIn() && encodeURI(auth.getProfile().sub);
      const warningMessage = reactHtmlParser(
        // TODO: Remove reactHtmlParser and add the html elements directly
        intl.formatMessage({ id: 'OrderSearch.WarningMessageSection' }, { userId })
      );
      return (
        <div style={{ margin: '10px', paddingTop: '20px' }}>
          <Alert type="danger" title={intl.formatMessage({ id: 'OrderSearch.NoAccess' })} message={warningMessage} />
        </div>
      );
    }
    return (
      <div className="container-fluid">
        <SearchBar
          searchTerm={this.props.searchTerm}
          onSearchTermChanged={this.onSearchTermChanged}
          startDate={moment(this.props.startDate).tz(this.props.displayTimezone)}
          endDate={moment(this.props.endDate).tz(this.props.displayTimezone)}
          displayTimezone={this.props.displayTimezone}
          onStartDateChanged={this.onStartDateChanged}
          onEndDateChanged={this.onEndDateChanged}
          canSubmit={!this.props.loading}
          onSubmit={this.onSubmit}
        />
        <ResultsSection
          includeDateHint={Boolean(this.props.searchTerm && (this.props.startDate || this.props.endDate))}
          loading={this.props.loading}
          error={this.props.error}
          results={this.props.results}
          goToOrderDetails={this.goToOrderDetails}
          page={this.props.page}
          handlePageChange={this.changePage}
          query={this.props.lastQuery}
          queryString={this.props.location.search}
          noSearchPerformed={isEmpty(this.props.lastQuery)}
          lastOrder={this.props.lastOrder}
          setLastOrder={this.setLastOrder}
          onFiltersUpdated={this.onFiltersUpdated}
          statuses={this.props.statuses}
          errorStatuses={this.props.errorStatuses}
          delayedStatuses={this.props.delayedStatuses}
          history={this.props.history}
        />
      </div>
    );
  }
}

OrderSearch.propTypes = {
  searchTerm: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  displayTimezone: PropTypes.string,
  page: PropTypes.number,
  merchants: PropTypes.arrayOf(PropTypes.object),
  salesChannels: PropTypes.arrayOf(PropTypes.object),
  availableSalesChannels: PropTypes.arrayOf(PropTypes.object),
  loadingSalesChannels: PropTypes.bool,
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  fulfillers: PropTypes.arrayOf(PropTypes.object),
  statuses: PropTypes.arrayOf(PropTypes.object),
  errorStatuses: PropTypes.arrayOf(PropTypes.object),
  delayedStatuses: PropTypes.arrayOf(PropTypes.object),
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  loading: PropTypes.bool,
  results: PropTypes.object,
  error: PropTypes.object,
  lastQuery: PropTypes.object,
  lastOrder: PropTypes.string,
  clearSearch: PropTypes.func,
  setSearchTerm: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  setMerchants: PropTypes.func,
  setFulfillers: PropTypes.func,
  setStatuses: PropTypes.func,
  setDelayedStatuses: PropTypes.func,
  setErrorStatuses: PropTypes.func,
  setOrderTypes: PropTypes.func,
  setSalesChannels: PropTypes.func,
  setProductCategories: PropTypes.func,
  setClaimTypes: PropTypes.func,
  setComplaintTypes: PropTypes.func,
  setChangeRequestTypes: PropTypes.func,
  setSort: PropTypes.func,
  setLastOrder: PropTypes.func,
  changePage: PropTypes.func,
  search: PropTypes.func,
  location: PropTypes.object,
  permissions: PropTypes.object,
  intl: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    // variables are destructured off of the state object
    search: {
      searchTerm,
      startDate,
      endDate,
      merchants,
      fulfillers,
      statuses,
      errorStatuses,
      delayedStatuses,
      orderTypes,
      salesChannels,
      productCategories,
      claimTypes,
      complaintTypes,
      changeRequestTypes,
      sortField,
      sortOrder,
      loading,
      result,
      error,
      page,
      lastQuery,
      lastOrder,
    },
    userPreferences: { displayTimezone },
    permissions: { permissions },
  } = state;

  var results;
  if (result) {
    results = {
      totalResults: result.totalResults || 0,
      sortField: result.query && result.query.sortField,
      sortOrder: result.query && result.query.sortOrder,
    };

    // get the full order for each orderId in the search results
    results.orders = flatMap(result.results, result => ({ ...result.order, ...intersectionOf(result.order.items) }));
  }

  return {
    searchTerm,
    startDate,
    endDate,
    merchants,
    fulfillers,
    statuses,
    errorStatuses,
    delayedStatuses,
    orderTypes,
    salesChannels,
    productCategories,
    claimTypes,
    complaintTypes,
    changeRequestTypes,
    sortField,
    sortOrder,
    loading,
    results,
    error,
    page,
    lastQuery,
    lastOrder,
    displayTimezone,
    permissions,
  };
}

export default connect(mapStateToProps, { ...searchActionCreators })(injectIntl(OrderSearch));
