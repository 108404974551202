export const productionAccepted = {
  costsIncurred: false,
};

export const productionStarted = {};

export const fulfillment = {
  carrierService: 'string',
  externalShipmentId: 'string',
  trackingId: 'string',
  trackingUrl: 'string',
  deliveryDetailsUrl: 'string',
};

export const fulfillerRejected = {
  rejectionDetails: 'string',
};

export const cancellationResponse = {
  costsIncurred: false,
};

export const nothingCancelled = {
  rejectionReason: 'string',
};
