import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { FormattedNumber } from 'react-intl';

class CurrencyDisplay extends React.Component {
  render() {
    const { intl } = this.props;
    if (!this.props.currency) {
      return <span>{this.props.value}</span>;
    } else {
      return (
        <Tooltip
          placement={this.props.position}
          contents={`${intl.formatMessage({ id: 'ItemLevelFields.Currency' })}: ${this.props.currency}`}>
          <span>
            <FormattedNumber style="currency" currency={this.props.currency} value={this.props.value} />
          </span>
        </Tooltip>
      );
    }
  }
}

CurrencyDisplay.propTypes = {
  position: PropTypes.string,
  currency: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  intl: PropTypes.object,
};

CurrencyDisplay.defaultProps = {
  position: 'bottom',
};

export default injectIntl(CurrencyDisplay);
