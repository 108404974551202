import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, FlexBox } from '@cimpress/react-components';
import get from 'lodash/get';

import MiniItemDetails from '../../MiniItemDetails';
import useRedirectContext from './RedirectContext';
import FormattedFulfillmentConfigurationName from './FormattedFulfillmentConfigurationName';

const ItemSelector = ({
  items,
  selectAll,
  areAllSelected,
  selectItem,
  isSelected,
  checkable = true,
  displayExistingFulfillmentConfiguration = true,
  displayNewFulfillmentConfiguration = false,
}) => {
  const intl = useIntl();
  const { state } = useRedirectContext();

  const buildAdditionalFields = item => {
    const fulfillmentConfigurationNameStyle = { alignItems: 'center' };
    const { newFulfillmentConfigurationUrl } = Object.values(state.itemsAvailableToRedirect).find(
      itemToRedirect => item.itemId === itemToRedirect.itemId
    );
    const additionalFields = [
      {
        name: intl.formatMessage({ id: 'ItemLevelFields.Fulfiller' }),
        value: item.fulfiller.name,
      },
    ];
    if (displayExistingFulfillmentConfiguration) {
      additionalFields.push({
        name: intl.formatMessage({ id: 'RequestRedirect.CurrentFulfillmentConfiguration' }),
        value: (
          <FormattedFulfillmentConfigurationName
            fulfillmentConfigurationUrl={get(item, '_links.fulfillmentConfiguration.href')}
          />
        ),
        style: fulfillmentConfigurationNameStyle,
      });
    }

    if (displayNewFulfillmentConfiguration) {
      additionalFields.push({
        name: intl.formatMessage({ id: 'RequestRedirect.NewFulfillmentConfiguration' }),
        value: (
          <FormattedFulfillmentConfigurationName
            fulfillmentConfigurationUrl={newFulfillmentConfigurationUrl}
            isNewFulfillmentConfiguration
          />
        ),
        style: fulfillmentConfigurationNameStyle,
      });
    }
    return additionalFields;
  };

  return (
    <div>
      {selectAll && (
        <Checkbox
          label={<FormattedMessage id="RequestRedirect.SelectAll" />}
          checked={areAllSelected}
          onChange={selectAll}
        />
      )}

      {items.map(item => {
        return (
          <div key={item.itemId}>
            <FlexBox middle>
              {checkable && (
                <Checkbox
                  style={{ marginBottom: '18px' }}
                  inline
                  checked={isSelected(item.itemId)}
                  onChange={() => selectItem(item.itemId)}
                />
              )}
              <table className="table">
                <tbody>
                  <MiniItemDetails
                    key={item.itemId}
                    item={item}
                    selectable={false}
                    additionalFields={buildAdditionalFields(item)}
                    isSelected={checkable && isSelected(item.itemId)}
                  />
                </tbody>
              </table>
            </FlexBox>
          </div>
        );
      })}
    </div>
  );
};

export default ItemSelector;
