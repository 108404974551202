import React from 'react';
import Accordion from '@cimpress/react-components/lib/Accordion';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import IconLoadingHalf from '@cimpress-technology/react-streamline-icons/lib/IconLoadingHalf';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { injectIntl, FormattedMessage } from 'react-intl';

const AccordionFilterContainer = ({ filterTitle, isLoading, hasError, availableItems, searchQuery, body }) => {
  let accordionBody, headerIcon;
  if (isLoading || !availableItems) {
    accordionBody = <Spinner />;
    headerIcon = <IconLoadingHalf />;
  } else if (hasError) {
    accordionBody = (
      <span class="italic">
        <FormattedMessage id="Filters.FailedToLoad" />
      </span>
    );
    headerIcon = <IconAlertTriangle className="text-danger" weight="fill" />;
  } else {
    accordionBody = body;
  }

  return accordionBody ? (
    <Accordion
      style={{ marginBottom: 0 }}
      bodyStyle={{ padding: '0 0 6px' }}
      title={
        <span>
          {filterTitle} {headerIcon}
        </span>
      }
      customOpen={Boolean(searchQuery)}>
      {accordionBody}
    </Accordion>
  ) : null;
};

export default injectIntl(AccordionFilterContainer);
