import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '@cimpress/react-components/lib/Modal';
import Button from '@cimpress/react-components/lib/Button';
import { setSearches } from '../../actions/savedSearchActions';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import IconDragVertical from '@cimpress-technology/react-streamline-icons/lib/IconDragVertical';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';

const DragHandle = sortableHandle(() => (
  <span style={{ cursor: 'grab' }}>
    {' '}
    <IconDragVertical size="lg" />{' '}
  </span>
));

const SortableItem = sortableElement(({ value, onDelete }) => (
  <div className="card" style={{ zIndex: '10000', fontSize: '20px', margin: '5px' }}>
    <div className="card-block">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DragHandle />
        <span style={{ marginLeft: '5px' }}>{value.name}</span>
        <div style={{ flexGrow: '1', textAlign: 'right', cursor: 'pointer' }}>
          <IconBin className="text-danger" size="lg" onClick={() => onDelete(value.name)} />
        </div>
      </div>
    </div>
  </div>
));

const SortableList = sortableContainer(({ items, onDelete }) => {
  return (
    <div style={{ listStyle: 'none' }}>
      {items.map((value, index) => (
        <SortableItem key={`${value.name}-${index}`} index={index} value={value} onDelete={onDelete} />
      ))}
    </div>
  );
});

class EditSavedSearch extends Component {
  static propTypes = {
    setSearches: PropTypes.func.isRequired,
    savedSearches: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    showEdit: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { setSearches, savedSearches } = this.props;
    setSearches(arrayMove(savedSearches, oldIndex, newIndex));
  };

  onDelete = name => {
    const { setSearches, savedSearches } = this.props;
    let index = savedSearches.findIndex(search => search && search.name === name);
    if (index >= 0) {
      savedSearches.splice(index, 1);
      setSearches(savedSearches);
    }
  };

  render() {
    const { showEdit, onClose, savedSearches } = this.props;
    return (
      <Modal
        show={showEdit}
        onRequestHide={onClose}
        closeButton={true}
        title={<FormattedMessage id="SaveSearch.editFiltersLink" />}
        footer={
          <Fragment>
            <Button className="btn btn-default" onClick={onClose}>
              <FormattedMessage id="Global.Close" />
            </Button>
          </Fragment>
        }>
        <h5>
          <FormattedMessage id="SaveSearch.EditHeader" />
        </h5>
        <SortableList
          items={savedSearches.filter(search => search !== null)}
          onSortEnd={this.onSortEnd}
          useDragHandle={true}
          onDelete={this.onDelete}
        />
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    savedSearches: { savedSearches, initialized },
  } = state;
  return { savedSearches, savedSearchInitialized: initialized };
};

export default connect(mapStateToProps, { setSearches })(EditSavedSearch);
