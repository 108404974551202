import React from 'react';
import reactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

class ContactPage extends React.Component {
  static propTypes = {
    intl: PropTypes.object,
  };

  render() {
    const { intl } = this.props;
    return (
      <div className="container">
        <h1>
          <i className="fa fa-support" /> <FormattedMessage id="Contact.ReportProblem" />
        </h1>
        <div style={{ margin: 0 }} className="card">
          {/* TODO: Remove reactHtmlParser and add the html elements directly */
          reactHtmlParser(intl.formatMessage({ id: 'Contact.ReportProblemDetails' }))}
        </div>
      </div>
    );
  }
}

export default injectIntl(ContactPage);
