import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import uniqWith from 'lodash/uniqWith';

export const flattenEventQueryResults = results => {
  let flattenedEvents = [];
  results
    .map(result => result.event)
    .forEach(event => {
      event.details.forEach(detail => {
        flattenedEvents.push({
          eventId: event.eventId,
          eventSourceId: event.eventSourceId,
          eventType: event.eventType,
          eventDate: event.eventDate,
          itemId: detail.itemId,
          fake: event.fake,
          additionalData: detail.additionalData,
          detail: detail.detail,
          createdOnBehalfOf: event.createdOnBehalfOf,
          attempts: event.attempts,
        });
      });
    });
  return flattenedEvents;
};

const cleanupAddress = address => {
  const trimmed = mapValues(address, value => (value && value.trim ? value.trim() : value));
  return pickBy(trimmed, value => !isNil(value) && value !== '');
};

const areAddressesEqual = (itemServiceAddress, shipmentManagerAddress) => {
  const {
    name = '',
    company: company1,
    doorCode: doorCode1,
    street1: street11,
    street2: street21,
    locality,
    region,
    postalCode: postalCode1,
    countryCode,
    phone: phone1,
    phoneExt: phoneExt1,
  } = cleanupAddress(shipmentManagerAddress);
  const {
    firstName = '',
    lastName = '',
    company: company2,
    doorCode: doorCode2,
    street1: street12,
    street2: street22,
    city,
    stateOrProvince,
    postalCode: postalCode2,
    country,
    phone: phone2,
    phoneExt: phoneExt2,
  } = cleanupAddress(itemServiceAddress);
  return (
    name.startsWith(firstName) &&
    name.endsWith(lastName) &&
    company1 === company2 &&
    doorCode1 === doorCode2 &&
    street11 === street12 &&
    street21 === street22 &&
    locality === city &&
    region === stateOrProvince &&
    postalCode1 === postalCode2 &&
    countryCode === country &&
    phone1 === phone2 &&
    phoneExt1 === phoneExt2
  );
};

// map from shipment-manager format to item-service format
const mapShipManAddress = ({
  name = '',
  company,
  doorCode,
  street1,
  street2,
  locality,
  region,
  postalCode,
  countryCode,
  phone,
  phoneExt,
  email,
}) => {
  const nameParts = name.split(' ');
  const firstName = nameParts.slice(0, nameParts.length - 1).join(' ');
  const lastName = nameParts[nameParts.length - 1];
  return {
    firstName,
    lastName,
    company,
    doorCode,
    street1,
    street2,
    city: locality,
    stateOrProvince: region,
    postalCode,
    country: countryCode,
    phone,
    phoneExt,
    email,
  };
};

export const attachChangeInfoToItem = item => {
  item.hasDeliveryChange = Boolean(
    item.events.find(
      e =>
        e.eventType === 'changeResponse' &&
        e.additionalData &&
        e.additionalData.changeType === 'delivery' &&
        e.additionalData.status === 'success'
    )
  );

  const fulfillmentEvents = item.events.filter(e => e.eventType === 'fulfillment');
  const uniqueFulfillmentAddresses = uniqWith(
    fulfillmentEvents.map(e => e.additionalData && e.additionalData.destinationAddress).filter(x => x),
    isEqual
  );
  item.shipmentDestinationAddresses = uniqueFulfillmentAddresses.map(mapShipManAddress);
  item.hasFulfillerCorrectedAddress = some(
    uniqueFulfillmentAddresses,
    a => !areAddressesEqual(item.destinationAddress, a)
  );
};

export const attachEventsToItems = (flatEvents, incomingItems) => {
  let items = { ...incomingItems };

  Object.values(items).forEach(item => {
    item.events = [];
  });

  flatEvents.forEach(event => {
    if (!items[event.itemId]) {
      // ignore events for other items
      return;
    }
    items[event.itemId].events.push(event);
  });
  Object.values(items).forEach(attachChangeInfoToItem);
  return items;
};
