import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Highlight from '../../shared/highlight';
import initEventDisplayDetails from './eventDisplayDetails';
import FulfillmentEventDetails from './FulfillmentEventDetails';
import { isJsonString, formatUserDate } from '../../../utils/utilityfunctions';
import { isFromPrepress } from '../../../utils/events';
import { getPrincipalById } from '../../../services/coam';
import { getClientById } from '../../../services/auth0Facade';
import eventTypes from '../../../constants/eventtypes';
import FulfillerDelayedDetails from './FulfillerDelayedDetails';
import pick from 'lodash/pick';
import get from 'lodash/get';

const CLIENT_SUFFIX = '@clients';

const detailsEntryStyle = {
  marginBottom: '5px',
};

class DetailsPanel extends React.Component {
  state = { error: false, readableCreatedOnBehalOf: '' };

  componentDidCatch(e) {
    console.warn(e);
    this.setState({ error: true });
  }

  componentDidMount() {
    const { createdOnBehalfOf } = this.props;
    if (createdOnBehalfOf) {
      if (createdOnBehalfOf.endsWith(CLIENT_SUFFIX)) {
        getClientById(createdOnBehalfOf.substring(0, createdOnBehalfOf.length - CLIENT_SUFFIX.length), [
          'name',
        ]).then(({ name }) => this.setState({ readableCreatedOnBehalOf: name }));
      } else {
        getPrincipalById(createdOnBehalfOf).then(({ profile }) => {
          if (profile && profile.name) {
            this.setState({ readableCreatedOnBehalOf: profile.name });
          }
        });
      }
    }
  }

  render() {
    if (this.state.error) {
      return null;
    }
    const {
      data,
      showJson,
      eventType,
      displayTimezone,
      eventSourceId,
      detail,
      createdOnBehalfOf,
      fulfillerName,
      intl,
    } = this.props;
    const shippingDetails = eventType === 'fulfillment';
    const fulfillerRelatedLinks = eventType === 'unspecified' && data && data.fulfillerRelatedLinks;
    const dateFormatter = (date, displayRawDate) => formatUserDate(date, displayTimezone, displayRawDate);
    const eventDisplayDetails = initEventDisplayDetails(intl);

    const humanReadableDetails = eventDisplayDetails[eventType]
      ? eventDisplayDetails[eventType]({ ...data, fulfillerName }, { dateFormatter })
      : '';

    if (data.additionalInfo && isJsonString(data.additionalInfo)) {
      data.additionalInfo = JSON.parse(data.additionalInfo);
    }

    data.eventSourceId = eventSourceId;
    data.detail = detail;
    data.createdOnBehalfOf = createdOnBehalfOf;
    const showCommonFields = !(eventType === eventTypes.FULFILLER_REJECTED && isFromPrepress(data.additionalInfo));

    const detailsJson = <Highlight language="json">{JSON.stringify(data, null, 2)}</Highlight>;
    if (showJson) {
      return detailsJson;
    } else if (shippingDetails) {
      return <FulfillmentEventDetails additionalData={data} />;
    } else if (fulfillerRelatedLinks) {
      return (
        <div>
          {Object.keys(data.fulfillerRelatedLinks).map(key => (
            <div key={key}>
              <a href={data.fulfillerRelatedLinks[key]} target="_blank" rel="noopener noreferrer">
                {key} <i className="fa fa-sm fa-external-link" />
              </a>
            </div>
          ))}
        </div>
      );
    } else {
      const { readableCreatedOnBehalOf } = this.state;
      return (
        <div>
          {typeof humanReadableDetails === 'string'
            ? humanReadableDetails.split('\n').map(i => {
                return <div key={i}>{i}</div>;
              })
            : humanReadableDetails}
          {detail ? (
            <div style={{ wordBreak: 'break-all' }}>
              <FormattedMessage id="EventDetails.DETAIL" values={{ detail }} />
            </div>
          ) : null}
          {eventType === eventTypes.FULFILLER_DELAYED ? (
            <FulfillerDelayedDetails
              details={{ ...pick(data, ['reason', 'quantity', 'expectedShipDate']), fulfillerName }}
              dateFormatter={dateFormatter}
            />
          ) : null}
          {showCommonFields ? (
            <Fragment>
              {createdOnBehalfOf ? (
                <div style={detailsEntryStyle}>
                  <FormattedMessage
                    id="EventDetails.CREATED_ON_BEHALF_OF"
                    values={{ createdOnBehalfOf: readableCreatedOnBehalOf || createdOnBehalfOf }}
                  />
                </div>
              ) : null}
            </Fragment>
          ) : null}
        </div>
      );
    }
  }
}

DetailsPanel.propTypes = {
  showJson: PropTypes.bool,
  eventType: PropTypes.string,
  eventSourceId: PropTypes.string,
  detail: PropTypes.string,
  createdOnBehalfOf: PropTypes.string,
  data: PropTypes.object,
  displayTimezone: PropTypes.string,
  fulfillerName: PropTypes.string,
  intl: PropTypes.object,
};

DetailsPanel.defaultProps = {
  data: {},
};

const mapStateToProps = (state, ownProps) => {
  const {
    userPreferences: { displayTimezone },
    fulfillers: { fulfillersMap },
  } = state;
  const {
    data: { globalFulfillerId },
  } = ownProps;

  const fulfillerName = get(fulfillersMap, `${globalFulfillerId}.name`, globalFulfillerId);

  return {
    displayTimezone,
    fulfillerName,
  };
};

export default connect(mapStateToProps, null)(injectIntl(DetailsPanel));
