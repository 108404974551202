import React from 'react';
import uniq from 'lodash/uniq';
import { searchResultsOrderShape } from '../propTypes';
import IdDisplay from '../shared/idDisplay';

const OrderLink = ({ order }) => (
  <a href={`${process.env.REACT_APP_OOPS_UI_URL}/orders/${order.orderId}`} target="_blank" rel="noopener noreferrer">
    <IdDisplay
      merchant={order.merchantOrderId}
      platform={order.orderId}
      fulfiller={uniq(order.items.map(item => item.fulfillerOrderId).filter(x => x))}
      short={uniq(order.items.map(item => item.shortFulfillmentGroupId).filter(x => x))}
    />
  </a>
);

OrderLink.propTypes = {
  order: searchResultsOrderShape,
};

export default OrderLink;
