import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from '@cimpress/react-components';
import IconQuestionCircle from '@cimpress-technology/react-streamline-icons/lib/IconQuestionCircle';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import styled from 'styled-components';

const ActionLinkButton = styled(Button)`
  &.btn {
    border: none;
    background: transparent;
    padding: 5px 23px;
    text-align: left;
    font-weight: normal;
  }

  &.btn:hover,
  &.btn:active,
  &.btn:active:hover {
    background: transparent;
    border: none;
  }

  &.btn:focus {
    outline: none;
    outline-offset: unset;
  }
`;

const ActionLink = ({ href, text, action, disabled, disabledMessage, helpText, ...rest }) => (
  <ActionLinkButton blockLevel disabled={disabled} href={href} onClick={action} {...rest}>
    {text}
    {disabled && disabledMessage ? (
      <Tooltip direction="left" contents={disabledMessage}>
        <IconQuestionCircle style={{ marginLeft: '5px' }} />
      </Tooltip>
    ) : null}
    {!disabled && helpText ? (
      <Tooltip direction="left" contents={helpText}>
        <IconInformationCircle style={{ marginLeft: '5px' }} />
      </Tooltip>
    ) : null}
  </ActionLinkButton>
);

ActionLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.node,
  action: PropTypes.func,
  enabled: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.node,
  helpText: PropTypes.node,
};

export default ActionLink;
