import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';

export function resendEvents(eventIds, orderId) {
  if (!eventIds.length || !orderId) {
    return {};
  }
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.POST_EVENT_ATTEMPT_REQUEST, types.POST_EVENT_ATTEMPT_SUCCESS, types.POST_EVENT_ATTEMPT_FAILURE],
        endpoint: `${process.env.REACT_APP_OOPS_URL}/api/v1/events/attempts`,
        method: 'POST',
        body: JSON.stringify({ ids: eventIds, orderId: orderId }),
      },
    });
  };
}
