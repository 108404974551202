import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import startCase from 'lodash/startCase';
import {
  DO_NOT_PREPARE,
  DO_NOT_PRODUCE,
  DO_NOT_SHIP,
  DO_NOT_TRANSMIT,
  SHIP,
} from '../../constants/testingConfigurations';

const color = '#3F51B5';

export default class TestingConfigurationLabel extends PureComponent {
  getTestingConfigurationDisplayName(testingConfiguration) {
    switch (testingConfiguration) {
      case DO_NOT_PREPARE:
        return <FormattedMessage id="OrderType.doNotPrepare" />;
      case DO_NOT_TRANSMIT:
        return <FormattedMessage id="OrderType.doNotTransmit" />;
      case DO_NOT_PRODUCE:
        return <FormattedMessage id="OrderType.doNotProduce" />;
      case DO_NOT_SHIP:
        return <FormattedMessage id="OrderType.doNotShip" />;
      default:
        return startCase(testingConfiguration);
    }
  }

  getTestingConfigurationTooltip(testingConfiguration) {
    switch (testingConfiguration) {
      case DO_NOT_PREPARE:
        return <FormattedMessage id="Global.DoNotPrepareTooltip" />;
      case DO_NOT_TRANSMIT:
        return <FormattedMessage id="Global.DoNotTransmitTooltip" />;
      case DO_NOT_PRODUCE:
        return <FormattedMessage id="Global.DoNotProduceTooltip" />;
      case DO_NOT_SHIP:
        return <FormattedMessage id="Global.DoNotShipTooltip" />;
      default:
        return <FormattedMessage id="Global.TestingConfigurationTooltip" />;
    }
  }

  render() {
    const { testingConfiguration } = this.props;
    return testingConfiguration && testingConfiguration !== SHIP ? (
      <Tooltip direction="top" variant="tooltip" contents={this.getTestingConfigurationTooltip(testingConfiguration)}>
        <span className="ghost label label-info small" style={{ color, borderColor: color }}>
          {this.getTestingConfigurationDisplayName(testingConfiguration)}
        </span>
      </Tooltip>
    ) : null;
  }
}

TestingConfigurationLabel.propTypes = {
  testingConfiguration: PropTypes.string,
};
