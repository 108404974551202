import jstz from 'jstz';
import moment from 'moment-timezone';
import { locales } from '@cimpress-technology/react-platform-settings';
import { DEFAULT_LOCALE } from '../constants/languages';

export const getDefaultLocale = () => {
  const language =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.userLanguage || navigator.language || navigator.browserLanguage;
  return locales.includes(language) ? language : DEFAULT_LOCALE;
};

export const getDefaultTimezone = () => {
  let timezone = 'Etc/UTC'; // default to UTC

  // try to guess timezone
  let guessedTimezone = moment.tz.guess();

  // if that fails, try an alternative legacy method:
  if (!guessedTimezone) {
    let altGuess = jstz.determine();
    if (altGuess) {
      guessedTimezone = altGuess.name(); // jzst returns an object with the name on it
    }
  }

  // if we found a valid guess
  if (guessedTimezone) {
    timezone = guessedTimezone;
    console.debug('Guessed local timezone as ', timezone);
  }
  return timezone;
};
