import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const detailsEntryStyle = {
  marginBottom: '5px',
};

export default class FulfillerDelayedDetails extends PureComponent {
  static propTypes = {
    details: PropTypes.shape({
      reason: PropTypes.string,
      quantity: PropTypes.string,
      expectedShipDate: PropTypes.string,
      fulfillerName: PropTypes.string,
    }).isRequired,
    dateFormatter: PropTypes.func,
  };

  render() {
    const {
      details: { reason, quantity, expectedShipDate, fulfillerName },
      dateFormatter,
    } = this.props;

    return (
      <Fragment>
        {reason ? (
          <div style={detailsEntryStyle}>
            <FormattedMessage
              id="EventDetails.FULFILLER_DELAYED_REASON"
              values={{ fulfiller: fulfillerName, reason }}
            />
          </div>
        ) : null}
        {quantity ? (
          <div style={detailsEntryStyle}>
            <FormattedMessage id="EventDetails.FULFILLER_DELAYED_QUANTITY" values={{ qty: quantity }} />
          </div>
        ) : null}
        {expectedShipDate ? (
          <div style={detailsEntryStyle}>
            <FormattedMessage
              id="EventDetails.FULFILLER_DELAYED_SHIPDATE"
              values={{ shipDate: dateFormatter(expectedShipDate, false) }}
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}
