import * as types from '../constants/actiontypes.js';
import * as idFormats from '../constants/idFormats.js';
import { defaultSearchColumns } from '../constants/searchColumns';

// idformat will eventually be moved out of localstorage, at which point this should
// set the default on load of that data.  copied from userPreferences for now
let ID_FORMAT_LOCAL_STORAGE = 'idFormat';
const DEFAULT_ID_FORMAT = idFormats.PLATFORM.format;

const getDefaultColumns = () => {
  const idFormat = localStorage.getItem(ID_FORMAT_LOCAL_STORAGE) || DEFAULT_ID_FORMAT;
  const defaultColumns = [...defaultSearchColumns];
  let orderIdColumn;
  switch (idFormat) {
    case idFormats.MERCHANT.format:
      orderIdColumn = 'merchantOrderId';
      break;
    case idFormats.FULFILLER.format:
      orderIdColumn = 'fulfillerOrderId';
      break;
    case idFormats.SHORT.format:
      orderIdColumn = 'shortFulfillmentGroupId';
      break;
    default:
      orderIdColumn = 'platformOrderId';
  }
  defaultColumns.splice(0, 0, orderIdColumn);
  return defaultColumns;
};

export const initialState = { searchColumns: getDefaultColumns() };

export default function searchColumns(state = initialState, action = {}) {
  switch (action.type) {
    case types.RESET_SEARCH_COLUMNS:
      return { ...state, searchColumns: getDefaultColumns() };
    case types.SET_SEARCH_COLUMNS:
      return { ...state, searchColumns: action.searchColumns.length ? action.searchColumns : getDefaultColumns() };
    default:
      return state;
  }
}
