import { callFetch } from './serviceHelpers';

const cacheSurfaces = {};

export const getSkuDetails = sku =>
  callFetch(process.env.REACT_APP_PRODUCT_INTRODUCTION_URL, 'GET', `v3/mcpSkus/${sku}`);

export const getSurfaces = (sku, variables = {}) => {
  const attributesParam =
    Object.keys(variables || {}).length > 0 ? `?variables=${encodeURIComponent(JSON.stringify(variables))}` : '';
  const route = `v3/calculatedSurfaceSets/${sku}${attributesParam}`;
  // Send request only once, this data doesn't change that often
  if (cacheSurfaces[route]) {
    return new Promise.resolve(cacheSurfaces[route]);
  }

  return callFetch(process.env.REACT_APP_PRODUCT_SUBSTRATES_URL, 'GET', route)
    .then(response => {
      if (!cacheSurfaces[route]) {
        cacheSurfaces[route] = response;
      }
      return response;
    })
    .catch(reason => ({ surfaceGroups: [] }));
};
