import React, { useEffect, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import Modal from '@cimpress/react-components/lib/Modal';

import { acknowledgeDiscrepancies } from '../../../actions/watchdogactions';
import { reloadOrder } from '../../../actions/orderv2actions';
import ActionLink from '../../shared/ActionLink';
import MissedSlaRow from './MissedSlaRow';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

export const initialState = { showModal: false, submitting: false };

export const reducer = (state, action) => {
  switch (action.type) {
    case 'toggleSelected':
      return {
        ...state,
        [action.key]: state[action.key] ? null : { comment: '' },
      };
    case 'showModal':
      return { ...state, showModal: true };
    case 'closeModal':
      return { showModal: false, submitting: false };
    case 'updateComment':
      return {
        ...state,
        [action.key]: { comment: action.text },
      };
    case 'submitting':
      return { ...state, submitting: true };
    default:
      return state;
  }
};

const ClearOrderAlertsModal = ({ merchantId, clearableStatuses = [], linkTitle }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const groupedClearableStatuses = groupBy(clearableStatuses, 'name');

  useEffect(() => {
    if (clearableStatuses.length === 1) {
      toggleSelected(clearableStatuses[0].name);
    }
  }, [clearableStatuses]);

  const open = () => {
    dispatch({ type: 'showModal' });

    trackEvent(TrackingEventTitles.CLEAR_ALERTS_MODAL_OPENED, {});
  };

  const close = () => {
    dispatch({ type: 'closeModal' });
  };

  const closeAndReload = () => {
    dispatch({ type: 'closeModal' });
    reduxDispatch(reloadOrder());
  };

  const updateComment = (key, text) => {
    dispatch({ type: 'updateComment', key, text });
  };

  const toggleSelected = key => {
    dispatch({ type: 'toggleSelected', key });
  };

  const onSubmit = async () => {
    dispatch({ type: 'submitting' });

    const clearedAlerts = [];
    Object.entries(groupedClearableStatuses).forEach(([statusType, statuses]) => {
      if (state[statusType]) {
        statuses.forEach(status => {
          clearedAlerts.push({
            itemId: status.itemId,
            status: status.name,
            comment: state[statusType].comment || '',
          });
        });
      }
    });

    try {
      await reduxDispatch(acknowledgeDiscrepancies(merchantId, clearedAlerts));
      closeAndReload();

      trackEvent(TrackingEventTitles.CLEAR_ALERTS_SUBMITTED, {
        [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.MERCHANT_ID]: merchantId,
        [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.CLEARED_STATUSES]: clearedAlerts.map(
          alert => alert.status
        ),
        [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.HAS_CLEARED_STATUS_COMMENT]: clearedAlerts.some(
          alert => alert.comment
        ),
      });
    } catch (error) {
      close();
    }
  };

  const { submitting, showModal } = state;
  const numMissedSlas = clearableStatuses.length;

  // TODO: use react-intl pluralization instead of double translation keys
  const modalTitle =
    numMissedSlas > 1 ? (
      <FormattedMessage id="WatchDog.ClearAlerts.OrderLevel" />
    ) : (
      <FormattedMessage id="WatchDog.ClearAlert.OrderLevel" />
    );

  return (
    <div>
      <ActionLink
        text={linkTitle || modalTitle}
        action={open}
        disabled={!clearableStatuses.length}
        disabledMessage={<FormattedMessage id="ItemActions.NoAlertsToClear" />}
      />
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        title={modalTitle}
        closeButton
        footer={
          <div>
            <button className="btn btn-default" onClick={close} disabled={submitting}>
              {' '}
              <FormattedMessage id="Global.Cancel" />{' '}
            </button>
            <button
              className="btn btn-primary"
              onClick={onSubmit}
              disabled={!some(clearableStatuses, s => state[s.name]) || submitting}>
              {modalTitle}
            </button>
          </div>
        }>
        <p>
          <FormattedMessage id="WatchDog.ClearAlertsInformation" />
        </p>
        {Object.keys(groupedClearableStatuses).map(name => (
          <MissedSlaRow
            key={name}
            name={name}
            label={[
              <strong key="displayName">{name}</strong>,
              <i key="itemDetails">{` (${groupedClearableStatuses[name].length} item${
                groupedClearableStatuses[name].length === 1 ? '' : 's'
              })`}</i>,
            ]}
            selected={Boolean(state[name])}
            data={state[name]}
            updateComment={updateComment}
            toggleSelected={toggleSelected}
          />
        ))}
      </Modal>
    </div>
  );
};

ClearOrderAlertsModal.propTypes = {
  merchantId: PropTypes.string.isRequired,
  clearableStatuses: PropTypes.array,
  linkTitle: PropTypes.node,
};

export default ClearOrderAlertsModal;
