import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loudspeaker } from '@cimpress-technology/react-loudspeaker';
import { FormattedMessage } from 'react-intl';

const CHANNEL_IDS = [process.env.REACT_APP_LOUDSPEAKER_CHANNEL_ID];

export default class LoudspeakerWrapper extends Component {
  render() {
    const { accessToken, language } = this.props;

    return (
      <Loudspeaker
        accessToken={accessToken}
        channelIds={CHANNEL_IDS}
        title={<FormattedMessage id="Loudspeaker.Title" />}
        language={language}
      />
    );
  }
}

LoudspeakerWrapper.propTypes = {
  accessToken: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};
