import React from 'react';
import PropTypes from 'prop-types';
import ItemCheckbox from './ItemCheckbox';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import AccordionFilterContainer from './AccordionFilterContainer';

export default class AccordionCheckboxFilter extends React.Component {
  state = {
    resultLimit: 20,
    isProductionIndeterminate: false, // ECOM-3216 Item Sub-statuses
  };

  showMoreResults = () => {
    this.setState({ resultLimit: this.state.resultLimit + 20 });
  };

  // ECOM-3216 Item Sub-statuses
  setProductionIndeterminate = value => {
    this.setState(previousValue => ({ ...previousValue, isProductionIndeterminate: value }));
  };

  render() {
    const { resultLimit } = this.state;
    const {
      filterTitle,
      isLoading,
      hasError,
      availableItems,
      selectedItems,
      searchQuery,
      selectedItemsKey,
      selectionChange,
    } = this.props;
    const exactSearchQueryFilterItems = [];
    const startingWithSearchQueryFilterItems = [];
    const notStartingWithSearchQueryFilterItems = [];

    let filteredItems =
      availableItems && availableItems.length
        ? searchQuery
          ? filter(availableItems, item => {
              if (item.label.toUpperCase().includes(searchQuery.toUpperCase())) {
                if (item.label.toUpperCase() === searchQuery.toUpperCase()) {
                  exactSearchQueryFilterItems.push(item);
                } else if (item.label.toUpperCase().indexOf(searchQuery.toUpperCase()) === 0) {
                  startingWithSearchQueryFilterItems.push(item);
                } else {
                  notStartingWithSearchQueryFilterItems.push(item);
                }
                return true;
              }
              return false;
            })
          : availableItems
        : [];
    if (
      !isEmpty(exactSearchQueryFilterItems) ||
      !isEmpty(startingWithSearchQueryFilterItems) ||
      !isEmpty(notStartingWithSearchQueryFilterItems)
    ) {
      filteredItems = exactSearchQueryFilterItems.concat(
        startingWithSearchQueryFilterItems,
        notStartingWithSearchQueryFilterItems
      );
    }
    let moreResults = false;
    if (filteredItems.length > resultLimit) {
      moreResults = true;
      filteredItems = filteredItems.slice(0, resultLimit - 1);
    }

    let accordionBody;
    if (!filteredItems || !filteredItems.length) {
      accordionBody = null;
    } else {
      accordionBody = (
        <div>
          {filteredItems
            .map(item => {
              if (item.value.startsWith('production.')) {
                return (
                  <div key={item.value} style={{ paddingLeft: '36px' }}>
                    <ItemCheckbox
                      key={item.value}
                      item={item}
                      selectedItems={selectedItems}
                      selectedItemsKey={selectedItemsKey}
                      selectionChange={selectionChange}
                      isProductionIndeterminate={this.state.isProductionIndeterminate}
                      setProductionIndeterminate={this.setProductionIndeterminate}
                    />
                  </div>
                );
              } else if (item.value === 'inProduction') {
                return (
                  <ItemCheckbox
                    key={item.value}
                    item={item}
                    selectedItems={selectedItems}
                    selectedItemsKey={selectedItemsKey}
                    selectionChange={selectionChange}
                    isProductionIndeterminate={this.state.isProductionIndeterminate}
                    setProductionIndeterminate={this.setProductionIndeterminate}
                  />
                );
              }
              return (
                <ItemCheckbox
                  key={item.value}
                  item={item}
                  selectedItems={selectedItems}
                  selectedItemsKey={selectedItemsKey}
                  selectionChange={selectionChange}
                />
              );
            })
            .reduce((prev, curr, idx) => [prev, <hr key={`hr-${idx}`} style={{ margin: 0 }} />, curr])}
          {moreResults ? (
            <span style={{ padding: '6px 16px' }}>
              Top {resultLimit} shown...{' '}
              <a className="clickable" onClick={this.showMoreResults}>
                click for more
              </a>{' '}
              or{' '}
              <a className="clickable" onClick={this.props.scrollToTop}>
                do a search
              </a>
            </span>
          ) : null}
        </div>
      );
    }

    return (
      <AccordionFilterContainer
        availableItems={availableItems}
        filterTitle={filterTitle}
        isLoading={isLoading}
        hasError={hasError}
        searchQuery={searchQuery}
        body={accordionBody}
      />
    );
  }
}

AccordionCheckboxFilter.propTypes = {
  searchQuery: PropTypes.string,
  filterTitle: PropTypes.string.isRequired,
  availableItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedItemsKey: PropTypes.string,
  selectionChange: PropTypes.func,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  scrollToTop: PropTypes.func,
};

AccordionCheckboxFilter.defaultProps = {
  searchQuery: '',
  selectedItems: [],
};
