import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';

import { PAGE_SIZE } from '../../../constants/pagination';
import { setLastOrder } from '../../../actions/ordersearchactions';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const OrderLink = ({ orderId, next, handleNavigation }) => {
  const navigate = () => {
    handleNavigation(orderId, next);
  };

  return (
    <Link to={`/orders/${orderId}`} onClick={navigate}>
      <button
        className="btn btn-primary btn-sm"
        style={{ borderRadius: next ? '0 2px 2px 0' : '2px 0 0 2px', marginRight: next ? 0 : '1px' }}
        disabled={!orderId}>
        {next ? <FormattedMessage id="Navigation.NextLabel" /> : <FormattedMessage id="Navigation.PreviousLabel" />}
      </button>
    </Link>
  );
};

OrderLink.propTypes = {
  orderId: PropTypes.string,
  next: PropTypes.bool,
  handleNavigation: PropTypes.func.isRequired,
};

const SearchResultsNavigator = ({ orderId, style }) => {
  const dispatch = useDispatch();
  const results = useSelector(state => get(state, 'search.result.results'));
  const totalResults = useSelector(state => get(state, 'search.result.totalResults', 0));
  const page = useSelector(state => get(state, 'search.page'));

  const handleNavigation = (orderId, next) => {
    dispatch(setLastOrder(orderId));

    trackEvent(TrackingEventTitles.PAGE_THROUGH_ORDERS, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.PAGE_THROUGH_ORDERS.DIRECTION]: next ? 'Next' : 'Previous',
    });
  };

  if (!results) {
    return null;
  }

  const currentIndex = findIndex(results, result => result.order.orderId === orderId);
  if (currentIndex < 0) {
    return null;
  }

  const previousOrderId = currentIndex > 0 ? results[currentIndex - 1].order.orderId : null;
  const nextOrderId = currentIndex < results.length - 1 ? results[currentIndex + 1].order.orderId : null;
  const resultNumber = (page - 1) * PAGE_SIZE + currentIndex + 1;
  const description = `${resultNumber} of ${totalResults}`;

  return (
    <div style={{ ...style }}>
      <Tooltip
        show={!previousOrderId ? false : undefined}
        direction="bottom"
        contents={<FormattedMessage id="Navigation.PreviousSearchOrder" />}>
        <OrderLink orderId={previousOrderId} next={false} handleNavigation={handleNavigation} />
      </Tooltip>
      <Tooltip
        show={!nextOrderId ? false : undefined}
        direction="bottom"
        contents={<FormattedMessage id="Navigation.NextSearchOrder" />}>
        <OrderLink orderId={nextOrderId} next={true} handleNavigation={handleNavigation} />
      </Tooltip>
      <span className="breadcrumb" style={{ marginLeft: '10px' }}>
        {description}
      </span>
    </div>
  );
};

SearchResultsNavigator.propTypes = {
  orderId: PropTypes.string,
  style: PropTypes.object,
};

export default SearchResultsNavigator;
