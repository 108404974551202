import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CopyText } from '@cimpress/react-components';

class IdDisplay extends React.Component {
  render() {
    const { intl } = this.props;
    let idString = '';
    let value = this.props[this.props.idFormat];

    // handle being passed arrays of ids, or single ids gracefully
    if (value instanceof Array) {
      let safeStrings = value.map(element => {
        return element || intl.formatMessage({ id: 'Global.NotProvided' });
      });

      idString = safeStrings.join(this.props.joinString);
    } else {
      idString = value;
    }

    if (!idString) {
      idString = (
        <em>
          <FormattedMessage id="Global.NotProvided" />
        </em>
      );
    }

    return (
      <Fragment>
        <span>
          {idString}
          <CopyText
            value={value}
            style={{ marginLeft: '8px', backgroundColor: 'none' }}
            onClick={e => e.stopPropagation()}>
            <div />
          </CopyText>
        </span>
      </Fragment>
    );
  }
}

IdDisplay.propTypes = {
  idFormat: PropTypes.string.isRequired,
  merchant: PropTypes.string,
  platform: PropTypes.string.isRequired,
  fulfiller: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  short: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  joinString: PropTypes.string,
  intl: PropTypes.object,
};

IdDisplay.defaultProps = {
  joinString: ', ',
};

function mapStateToProps(state) {
  return { idFormat: state.userPreferences.idFormat };
}

export default connect(mapStateToProps)(injectIntl(IdDisplay));
