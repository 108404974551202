import * as types from '../constants/actiontypes.js';

export const initialState = {
  loading: false,
  permissions: null,
  groups: null,
  error: null,
};

export default function permissions(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_ALL_PERMISSIONS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case types.GET_ALL_GROUPS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case types.GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.response,
        error: null,
      };
    case types.GET_ALL_GROUPS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    case types.GET_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        permissions: {
          ...action.response,
        },
        error: null,
      };
    case types.GET_ALL_PERMISSIONS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
