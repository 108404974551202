/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { silver } from '@cimpress/react-components/lib/colors';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';

import CurrencyDisplay from '../shared/currencyDisplay';
import DateDisplay from '../shared/dateDisplay';
import VerticalRule from '../shared/VerticalRule';
import * as util from '../../utils/permissions-v2';
import IdDisplay from '../shared/idDisplay';
import FieldDisplay from './FieldDisplay';
import MerchantPopover from './MerchantPopover';
import RelatedLinks from './RelatedLinks';
import OrderStatus from './OrderStatus';
import OrderSnapshot from './OrderSnapshot';
import OrderHealth from './OrderHealth';
import { SHIP } from '../../constants/testingConfigurations';
import TestingConfigurationLabel from '../shared/TestingConfigurationLabel';

// matches accordion style from itemDetail
const style = {
  background: 'white',
  border: `1px solid ${silver}`,
  borderTop: 'none',
  padding: '15px',
  display: 'flex',
};

const columnStyle = { minWidth: '350px', maxWidth: '350px', flex: '1', padding: '0 5px' };

class OrderSummary extends React.Component {
  render() {
    const { order, merchantName, permissions, intl, onFilter } = this.props;
    if (!order || !order.items || !Object.keys(order.items).length) {
      return null;
    }

    const {
      orderId,
      merchantId,
      merchantOrderId,
      merchantSalesChannel,
      createdDate,
      fulfillerOrderIds,
      shortOrderIds,
      customerPrices,
      merchantPrices,
      computedStatus,
    } = order;
    const canReadCustomerTotal = util.hasMerchantReadPermission(permissions, merchantId);
    const itemsWithIssues = Object.values(order.items).filter(
      item =>
        (item.computedMissedSlas && item.computedMissedSlas.length) ||
        (item.computedErrors && item.computedErrors.length) ||
        item.pendingCancellation
    );

    return (
      <div style={style}>
        <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
          <div style={columnStyle}>
            <FieldDisplay
              name={intl.formatMessage({ id: 'OrderLevelFields.OrderIdNoFormat' })}
              value={
                <IdDisplay
                  merchant={merchantOrderId}
                  platform={orderId}
                  fulfiller={fulfillerOrderIds}
                  short={shortOrderIds}
                />
              }
            />
            <FieldDisplay
              name={intl.formatMessage({ id: 'OrderLevelFields.Status' })}
              value={<OrderStatus computedStatus={computedStatus} />}
            />
            <FieldDisplay
              name={intl.formatMessage({ id: 'OrderLevelFields.CreatedDate' })}
              value={<DateDisplay date={createdDate} />}
            />
            {order.testingConfiguration && order.testingConfiguration !== SHIP ? (
              <FieldDisplay
                name={intl.formatMessage({ id: 'OrderLevelFields.TestingConfiguration' })}
                value={<TestingConfigurationLabel testingConfiguration={order.testingConfiguration} />}
              />
            ) : null}
          </div>
          <div style={{ ...columnStyle }}>
            {canReadCustomerTotal ? (
              <FieldDisplay
                name={intl.formatMessage({ id: 'OrderLevelFields.Total' })}
                value={
                  Object.keys(customerPrices).length ? (
                    Object.keys(customerPrices).map(currency => (
                      <div key={currency}>
                        <CurrencyDisplay currency={currency} value={customerPrices[currency]} />
                      </div>
                    ))
                  ) : (
                    <CurrencyDisplay value={0} />
                  )
                }
              />
            ) : null}
            <FieldDisplay
              name={intl.formatMessage({ id: 'OrderLevelFields.MerchantTotal' })}
              value={
                Object.keys(merchantPrices).length ? (
                  Object.keys(merchantPrices).map((currency, currencyIndex) => (
                    <div key={currency}>
                      <CurrencyDisplay currency={currency} value={merchantPrices[currency]} />
                      {Object.keys(merchantPrices).length > 1 &&
                      currencyIndex === Object.keys(merchantPrices).length - 1 ? (
                        <Tooltip
                          contents={intl.formatMessage({ id: 'OrderLevelFieldTooltips.MultipleMerchantTotalPrices' })}>
                          <IconInformationCircle style={{ marginLeft: '5px' }} className="text-info" weight="fill" />
                        </Tooltip>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <CurrencyDisplay value={0} />
                )
              }
            />
            <FieldDisplay
              name={intl.formatMessage({ id: 'OrderLevelFields.Merchant' })}
              value={<MerchantPopover merchantId={merchantId} merchantName={merchantName || merchantId} />}
            />
            {merchantSalesChannel ? (
              <FieldDisplay
                name={intl.formatMessage({ id: 'OrderLevelFields.SalesChannel' })}
                value={merchantSalesChannel}
              />
            ) : null}
          </div>
          <RelatedLinks style={columnStyle} order={order} />
        </div>
        {!isEmpty(itemsWithIssues) && (
          <>
            <VerticalRule />
            <OrderHealth
              style={{ ...columnStyle, minWidth: '300px', maxWidth: '300px' }}
              itemsWithIssues={itemsWithIssues}
              onFilter={onFilter}
              order={order}
            />
          </>
        )}
        <VerticalRule />
        <OrderSnapshot style={{ ...columnStyle, minWidth: '300px', maxWidth: '500px' }} order={order} />
      </div>
    );
  }
}

OrderSummary.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string,
    items: PropTypes.object,
  }),
  merchantName: PropTypes.string,
  permissions: PropTypes.object,
  intl: PropTypes.object,
  onFilter: PropTypes.func,
};

export default injectIntl(OrderSummary);
