import { combineReducers } from 'redux';
import bulkReorder from './bulkReorder';
import devtools from './devtools.js';
import fulfillers from './fulfillers.js';
import merchants from './merchants.js';
import messages from './messages.js';
import search from './search.js';
import userPreferences from './userPreferences.js';
import session from './session.js';
import operations from './operations.js';
import permissions from './permissions.js';
import searchColumns from './searchColumns.js';
import order from './order';
import availableFilters from './availableFilters';
import savedSearches from './savedSearches';
import subscriptions from './subscriptions';
import carrierServices from './carrierServices';
import foma from './foma';

const rootReducer = combineReducers({
  search,
  bulkReorder,
  devtools,
  messages,
  merchants,
  fulfillers,
  userPreferences,
  session,
  operations,
  permissions,
  searchColumns,
  order,
  availableFilters,
  savedSearches,
  subscriptions,
  carrierServices,
  foma,
});

export default rootReducer;
