import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import Dropdown from '@cimpress/react-components/lib/Dropdown';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import * as util from '../../../utils/permissions-v2';
import { generateSumoSearchUrlForOrder } from '../../../utils/sumoSearch';
import CallbackUrlModal from './CallbackUrlModal';
import CancelRequestModal from './CancelRequestModal';
import JSONModal from './JSONModal';
import ClearOrderAlertsModal from '../status/ClearOrderAlertsModal';
import UpdateCustomerAddressModal from './UpdateCustomerAddressModal';
import UpdateStatusModal from './UpdateStatusModal';
import CreateClaimLink from './CreateClaimLink';
import ActionLink from '../../shared/ActionLink';
import PlaceReorderModal from './PlaceReorderModal';
import RequestRedirectModal from './redirect/RequestRedirectModal';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const OrderActions = ({ order, shortOrderIdsInFoma = [] }) => {
  const permissions = useSelector(state => get(state, 'permissions.permissions', {}));
  const intl = useIntl();

  if (!order || !permissions) {
    return null;
  }

  const { orderId, merchantId, createdDate, allowedOrderActions, rawItems = {} } = order;

  const onViewLogsClicked = () => {
    trackEvent(TrackingEventTitles.VIEW_ORDER_LOGS, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: orderId,
    });
  };

  const items = Object.values(order.items);

  const hasMerchantReadPermission = util.hasMerchantReadPermission(permissions, merchantId);
  const hasMerchantUpdatePermission = util.hasMerchantUpdatePermission(permissions, merchantId);
  const hasMerchantCreatePermission = util.hasMerchantCreatePermission(permissions, merchantId);
  const hasMerchantUpdateClaimPermission = util.hasMerchantUpdateClaimPermission(permissions, order.merchantId);
  const itemsInFoma = items.filter(item => shortOrderIdsInFoma.includes(item.shortFulfillmentGroupId));
  const itemIdsInFoma = itemsInFoma.map(item => item.itemId);

  const showUpdateStatusModal = () => {
    return itemsInFoma.length && items.some(itm => util.hasFulfillerUpdatePermission(permissions, itm.fulfillerId));
  };

  const isPresentInFoma = item => {
    return itemIdsInFoma.includes(item.itemId);
  };

  const updateStatusModal = showUpdateStatusModal() && (
    <UpdateStatusModal
      order={order}
      accessibleItems={items.filter(
        itm => isPresentInFoma(itm) && util.hasFulfillerUpdatePermission(permissions, itm.fulfillerId)
      )}
    />
  );

  const createClaimLink = hasMerchantUpdateClaimPermission && <CreateClaimLink order={order} />;

  const computedErrors = items.flatMap(item =>
    get(item, 'computedErrors', []).map(e => ({ ...e, itemId: item.itemId }))
  );
  const computedMissedSlas = items.flatMap(item =>
    get(item, 'computedMissedSlas', []).map(e => ({ ...e, itemId: item.itemId }))
  );
  const clearableStatuses = computedErrors.concat(computedMissedSlas);
  const clearAlertsModal = hasMerchantUpdatePermission && (
    <ClearOrderAlertsModal clearableStatuses={clearableStatuses} merchantId={order.merchantId} />
  );

  const changeableFulfillmentGroupIds =
    allowedOrderActions && allowedOrderActions.fulfillmentGroups
      ? allowedOrderActions.fulfillmentGroups
          .filter(fg => fg.actions.some(action => action.actionType === 'deliveryChange'))
          .map(fg => fg.fulfillmentGroupId)
      : [];
  const changeableItems = items.filter(item => changeableFulfillmentGroupIds.includes(item.fulfillmentGroupId));
  const updateAddressModal = hasMerchantUpdatePermission && (
    <UpdateCustomerAddressModal changeableFulfillmentGroups={groupBy(changeableItems, 'fulfillmentGroupId')} />
  );

  const hasOrderLevelCancellation = Boolean(
    allowedOrderActions && allowedOrderActions.actions.find(action => action.actionType === 'orderCancellation')
  );
  const cancelRequestModal = hasMerchantUpdatePermission && (
    <CancelRequestModal orderId={orderId} canCancel={hasOrderLevelCancellation} />
  );

  const placeReorderModal = hasMerchantCreatePermission && <PlaceReorderModal order={order} />;

  const requestRedirectModal = hasMerchantCreatePermission && <RequestRedirectModal order={order} />;

  const callbackUrlModal = hasMerchantReadPermission && (
    <CallbackUrlModal
      orderId={orderId}
      canUpdate={hasMerchantUpdatePermission}
      hasPermission={hasMerchantReadPermission} // used
    />
  );

  const eventJsonModal = (
    <JSONModal
      dataType="event"
      json={order.rawEvents}
      defaultCollapsed={order.rawEvents && order.rawEvents.length > 3}
    />
  );

  const viewLogsLink = (
    <div>
      <ActionLink
        href={generateSumoSearchUrlForOrder(orderId, createdDate)}
        onClick={onViewLogsClicked}
        target="_blank"
        rel="noopener noreferrer"
        text={
          <>
            <FormattedMessage id="DeveloperTools.ViewLogs" /> <i className="fa fa-sm fa-external-link" />
          </>
        }
      />
    </div>
  );

  const orderJsonModal = (
    <JSONModal dataType="order" json={rawItems} defaultCollapsed={Object.keys(rawItems).length > 3} />
  );

  return (
    <>
      {/** The following styles ensure that the links and buttons
      contained in the dropdown items are the large enough and
      allow for the action to be performed regardless of where
      you click within the dropdown item. Fixes: ECOM-1063
      */}
      <style>{`
        .dropdown-menu > li.dropdown-item {
            padding: 0px;
        }
        li>div>button.btn, li>div>button.btn:hover {
          padding: 15px 23px !important;
        }
        li>div>a.btn, li>div>a.btn:hover {
          padding: 15px 23px !important;
        }
      `}</style>
      <Dropdown
        title={intl.formatMessage({ id: 'OrderDetails.OrderActions' })}
        className="pull-right"
        style={{ whiteSpace: 'nowrap' }}>
        {cancelRequestModal}
        {placeReorderModal}
        {requestRedirectModal}
        {updateAddressModal}
        {createClaimLink}
        {clearAlertsModal}
        {updateStatusModal}
        {orderJsonModal}
        {eventJsonModal}
        {callbackUrlModal}
        {viewLogsLink}
      </Dropdown>
    </>
  );
};

const mapStateToProps = state => {
  const { shortOrderIdsInFoma } = state.foma;

  return {
    shortOrderIdsInFoma,
  };
};

export default connect(mapStateToProps, null)(injectIntl(OrderActions));
