import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

class ContextMenu extends React.Component {
  render() {
    const style = {
      display: 'block',
      position: 'absolute',
      left: this.props.x || 0,
      top: this.props.y || 0,
    };

    const menuItems = this.props.items.map(item => {
      let linkProps = {};
      if (item.path) {
        linkProps.to = item.path;
        linkProps.target = '_blank';
      }
      if (item.onClick) {
        linkProps.onClick = item.onClick;
      }
      return (
        <li key={item.label}>
          <Link {...linkProps}>{item.label}</Link>
        </li>
      );
    });

    return (
      <ul style={style} className="dropdown-menu">
        {menuItems}
      </ul>
    );
  }

  handleClickOutside() {
    this.props.onClickedOutside();
  }
}

ContextMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  onClickedOutside: PropTypes.func,
};

export default onClickOutside(ContextMenu);
