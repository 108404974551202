import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import stringify from 'json-stringify-safe';
import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import { Modal, Snackbar, RadioGroup, Radio, Button } from '@cimpress/react-components';

import ActionLink from '../../shared/ActionLink';
import RawJsonView from './RawJsonView';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const JSONModal = ({ json, dataType, variant, defaultCollapsed, intl, style = {} }) => {
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [displayFormat, setDisplayFormat] = useState('collapsed');

  useEffect(() => {
    const defaultDisplayFormat = defaultCollapsed ? 'collapsed' : 'raw';
    setDisplayFormat(defaultDisplayFormat);
  }, [defaultCollapsed]);

  const close = () => {
    setShowModal(false);
    setCopied(false);
  };

  const onCopy = () => {
    setCopied(true);

    trackEvent(TrackingEventTitles.JSON_MODAL_COPIED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.JSON_MODAL_DATA_TYPE]: dataType,
    });
  };

  const open = () => {
    setShowModal(true);

    trackEvent(TrackingEventTitles.JSON_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.JSON_MODAL_DATA_TYPE]: dataType,
    });
  };

  const onDisplayFormatChange = (_, newFormat) => setDisplayFormat(newFormat);

  const prettyJSON = json ? stringify(json, null, 2) : '';
  const modalTitle = `${upperFirst(dataType)} JSON`;

  return (
    <div style={style}>
      {variant ? (
        <Button onClick={open} disabled={isEmpty(json)} variant={variant}>
          {isEmpty(json)
            ? intl.formatMessage({ id: 'JsonModal.Empty' })
            : `${intl.formatMessage({ id: 'Global.View' })} ${modalTitle}`}
        </Button>
      ) : (
        <ActionLink
          text={`${intl.formatMessage({ id: 'Global.View' })} ${modalTitle}`}
          action={open}
          disabled={isEmpty(json)}
          disabledMessage={intl.formatMessage({ id: 'JsonModal.Empty' })}
        />
      )}
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={`${modalTitle}`}
        footer={
          <button className="btn btn-default" onClick={close}>
            <FormattedMessage id="Global.Close" />
          </button>
        }>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
            <RadioGroup onChange={onDisplayFormatChange} defaultSelected={displayFormat} name="displayFormat" inline>
              <Radio label={intl.formatMessage({ id: 'JsonModal.Interactive' })} value="collapsed" />
              <Radio label={intl.formatMessage({ id: 'JsonModal.Raw' })} value="raw" />
            </RadioGroup>
            <CopyToClipboard text={prettyJSON} onCopy={onCopy}>
              <div className="clearfix">
                <a className="clickable pull-right" style={{ paddingBottom: '8px' }}>
                  <FormattedMessage id="Global.CopyToClipboard" /> <i className="fa fa-clipboard" aria-hidden="true" />
                </a>
              </div>
            </CopyToClipboard>
          </div>
          {displayFormat === 'collapsed' ? (
            <ReactJson src={json} enableClipboard={false} collapsed={1} name={false} iconStyle="square" />
          ) : (
            <RawJsonView json={json} deprecatedFields={['itemPreviewUrl']} messagePrefix="JsonModal.DeprecatedFields" />
          )}
        </div>
      </Modal>
      <Snackbar show={copied} delay={3000} onHideSnackbar={() => setCopied(false)}>
        <FormattedMessage id="Global.CopiedToClipboard" />
      </Snackbar>
    </div>
  );
};

JSONModal.propTypes = {
  dataType: PropTypes.string,
  json: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultCollapsed: PropTypes.bool,
  intl: PropTypes.object,
};

export default injectIntl(JSONModal);
