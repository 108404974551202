import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Tooltip } from '@cimpress/react-components';

import { trackEvent, TrackingEventTitles } from '../../analytics';

const InfoRow = ({ title, info }) => (
  <div>
    {title}: {info}
  </div>
);

InfoRow.propTypes = {
  title: PropTypes.string,
  info: PropTypes.node,
};

const BooleanRow = ({ title, data }) => (
  <div>
    {title}: {data ? <FormattedMessage id="Global.Yes" /> : <FormattedMessage id="Global.No" />}
  </div>
);

BooleanRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
};

const FulfillerPopover = ({ fulfiller, fulfillerDetails }) => {
  const [show, setShow] = useState(false);
  const intl = useIntl();

  const toggleShow = () => {
    setShow(prevShow => !prevShow);

    // Yay for closures! "show" is false when the popover has been toggled to opened.
    if (!show) {
      trackEvent(TrackingEventTitles.FULFILLER_POPOVER_OPENED, {});
    }
  };

  const onFulfillerConfigurationLinkClick = () => {
    trackEvent(TrackingEventTitles.VIEW_FULFILLER_IN_FULFILLER_CONFIGURATION, {});
  };

  const {
    requiresShipmentPlanning,
    requiresContactInformation,
    supportOrderCancellations,
    supportDeliveryAddressChanges,
    supportSplitShippingSamples,
    supportPlatformShipmentPlans,
    receivingTestFulfillmentRequests,
    supportDoNotShip,
  } = fulfillerDetails;

  return (
    <Tooltip
      tooltipStyle={{ maxWidth: '550px' }}
      direction="bottom"
      variant="popover"
      show={show}
      onClickOutside={show ? toggleShow : null}
      contents={
        <div style={{ marginTop: '9px' }}>
          <h5>{fulfiller.name}</h5>
          <div style={{ marginTop: '15px' }}>
            <h6>
              <FormattedMessage id="Fulfiller.General" />
            </h6>
            <InfoRow title={intl.formatMessage({ id: 'Fulfiller.ContactNumber' })} info={fulfiller.phone} />
            <InfoRow title={intl.formatMessage({ id: 'Fulfiller.ContactEmail' })} info={fulfiller.email} />
            <InfoRow title={intl.formatMessage({ id: 'Fulfiller.ID' })} info={fulfiller.fulfillerId} />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div style={{ paddingRight: '5px', width: '280px' }}>
              <h6>
                <FormattedMessage id="Fulfiller.Order" />
              </h6>
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.RequiresShipmentPlanning' })}
                data={requiresShipmentPlanning}
              />
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.RequiresContactInfo' })}
                data={requiresContactInformation}
              />
            </div>
            <div style={{ paddingLeft: '5px', width: '170px' }}>
              <h6>
                <FormattedMessage id="Fulfiller.SupportedFeatures" />
              </h6>
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.Cancellation' })}
                data={supportOrderCancellations}
              />
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.DeliveryChange' })}
                data={supportDeliveryAddressChanges}
              />
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.SplitShipping' })}
                data={supportSplitShippingSamples}
              />
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.ShipmentPlan' })}
                data={supportPlatformShipmentPlans}
              />
              <BooleanRow
                title={intl.formatMessage({ id: 'Fulfiller.DoNotProduce' })}
                data={receivingTestFulfillmentRequests}
              />
              <BooleanRow title={intl.formatMessage({ id: 'Fulfiller.DoNotShip' })} data={supportDoNotShip} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
            <a
              href={`${process.env.REACT_APP_FULFILLER_CONFIG_URL}/fulfillers/${fulfiller.fulfillerId}`}
              onClick={onFulfillerConfigurationLinkClick}
              target="_blank"
              rel="noopener noreferrer">
              <FormattedMessage id="Global.ViewMore" /> <i className="fa fa-sm fa-external-link" />
            </a>
          </div>
        </div>
      }>
      <a className="clickable" onClick={toggleShow}>
        {fulfiller.name}
      </a>
    </Tooltip>
  );
};

FulfillerPopover.propTypes = {
  fulfiller: PropTypes.shape({
    fulfillerId: PropTypes.string,
    internalFulfillerId: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  fulfillerDetails: PropTypes.shape({
    requiresShipmentPlanning: PropTypes.bool,
    requiresContactInformation: PropTypes.bool,
    supportOrderCancellations: PropTypes.bool,
    supportDeliveryAddressChanges: PropTypes.bool,
    supportSplitShippingSamples: PropTypes.bool,
    supportPlatformShipmentPlans: PropTypes.bool,
    receivingTestFulfillmentRequests: PropTypes.bool,
    supportDoNotShip: PropTypes.bool,
  }),
};

FulfillerPopover.defaultProps = {
  fulfillerDetails: {
    requiresShipmentPlanning: false,
    requiresContactInformation: false,
    supportOrderCancellations: false,
    supportDeliveryAddressChanges: false,
    supportSplitShippingSamples: false,
    supportPlatformShipmentPlans: false,
    receivingTestFulfillmentRequests: false,
    supportDoNotShip: false,
  },
};

export default FulfillerPopover;
