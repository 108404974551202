import { aggregateItemLevelDetails, attachActionsToItems } from '../decorators/item';
import { attachStatus } from '../decorators/status';
import { attachEventsToItems } from '../decorators/events';
import * as types from '../constants/actiontypes.js';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

export const initialState = {};

const sortItemsBySeverity = items => {
  const sorted = {};
  Object.keys(items)
    .sort((a, b) => {
      const itemA = items[a];
      const itemB = items[b];
      const aSeverityScore = get(itemA, 'computedErrors.length', 0) * 10 + get(itemA, 'computedMissedSlas.length', 0);
      const bSeverityScore = get(itemB, 'computedErrors.length', 0) * 10 + get(itemB, 'computedMissedSlas.length', 0);
      return bSeverityScore - aSeverityScore;
    })
    .forEach(itemId => {
      sorted[itemId] = items[itemId];
    });
  return sorted;
};

export default function order(state = initialState, action = {}) {
  switch (action.type) {
    case types.RESET_ORDER:
      return initialState;
    case types.LOAD_ORDER_ITEMS_REQUEST:
      return { ...state, loadingOrder: true };
    case types.LOAD_ORDER_ITEMS_SUCCESS: {
      const rawItems = cloneDeep(get(action, 'response.items', {}));
      const orderWithStatus = attachStatus(aggregateItemLevelDetails(action.response));
      orderWithStatus.items = sortItemsBySeverity(orderWithStatus.items);
      return { ...state, ...orderWithStatus, rawItems, loadingOrder: false };
    }
    case types.LOAD_ORDER_ITEMS_FAILURE:
      return { ...state, loadingOrder: false };
    case types.LOAD_MERCHANT_ORDER_REQUEST:
      return { ...state, loadingMerchantOrder: true };
    case types.LOAD_MERCHANT_ORDER_SUCCESS:
      return { ...state, merchantOrder: action.response, loadingMerchantOrder: false };
    case types.LOAD_MERCHANT_ORDER_FAILURE:
      return { ...state, loadingMerchantOrder: false };
    case types.LOAD_ACTIONS_SUCCESS: {
      const items = attachActionsToItems(action.response, cloneDeep(state.items));
      return { ...state, allowedOrderActions: action.response, items };
    }
    case types.LOAD_EVENTS_SUCCESS: {
      const { decoratedEvents, rawEvents } = action.response;
      let items = attachEventsToItems(decoratedEvents.slice(), cloneDeep(state.items));
      return { ...state, rawEvents, items };
    }
    case types.LOAD_CLAIMS_SUCCESS: {
      const { claimsSummary, claimReordersSummary } = action;
      return { ...state, claimsSummary, claimReordersSummary };
    }
    case types.LOAD_COMPLAINTS_SUCCESS: {
      const { complaintsSummary, complaintReordersSummary } = action;
      return { ...state, complaintsSummary, complaintReordersSummary };
    }
    case types.LOAD_ORIGINAL_ORDERS_SUCCESS: {
      const { claimOriginalOrdersSummary, complaintOriginalOrdersSummary } = action;
      return { ...state, claimOriginalOrdersSummary, complaintOriginalOrdersSummary };
    }
    case types.LOAD_ORDER_SHIPPING_PRICING_SUCCESS: {
      const items = cloneDeep(state.items);
      const item = items[action.actionContext];
      item.itemPricing = {
        ...item.itemPricing,
        shippingPrice: action.response.amount,
        currencyCode: action.response.currency,
      };
      return { ...state, items };
    }
    case types.LOAD_ORDER_PRODUCT_PRICING_SUCCESS: {
      const items = cloneDeep(state.items);
      const item = items[action.actionContext];
      item.itemPricing = {
        ...item.itemPricing,
        basePrice: action.response.amount,
        currencyCode: action.response.currency,
      };
      return { ...state, items };
    }
    case types.LOAD_FULFILLER_SUCCESS: {
      const fulfiller = action.response;
      let items = { ...state.items };
      Object.values(items).forEach(item => {
        if (Number(item.fulfillerId) === fulfiller.internalFulfillerId) {
          item.fulfiller = fulfiller;
        }
      });
      return { ...state, items };
    }
    case types.LOAD_FULFILLER_DETAILS_SUCCESS: {
      const { expectations, fulfillmentConfiguration } = action.response;
      let items = { ...state.items };
      Object.values(items).forEach(item => {
        if (item._links.fulfillmentConfiguration?.href === fulfillmentConfiguration) {
          item.fulfillerDetails = expectations;
        }
      });
      return { ...state, items };
    }
    case types.LOAD_FULFILLER_NO_PERMISSION: {
      const itemId = action.item;
      if (!state.items[itemId]) {
        return state;
      }
      const item = { ...state.items[itemId] };
      item.fulfillerPermission = action.fulfillerPermission;
      return { ...state, items: { ...state.items, [itemId]: item } };
    }
    case types.LOAD_ITEM_PRODUCT_MANUFACTURING_SUCCESS: {
      const itemId = action.actionContext;
      if (!state.items[itemId]) {
        return state;
      }
      const item = { ...state.items[itemId] };
      const productManufacturingDataSourced = action.productManufacturingData;
      item.productManufacturingData = productManufacturingDataSourced;
      return { ...state, items: { ...state.items, [itemId]: item } };
    }
    case types.LOAD_PRINT_JOB_SUCCESS: {
      const itemId = action.actionContext;
      if (!state.items[itemId]) {
        return state;
      }
      const item = { ...state.items[itemId] };
      const printJob = action.response;
      item.printJob = printJob;
      return { ...state, items: { ...state.items, [itemId]: item } };
    }
    case types.LOAD_PRINT_JOB_FAILURE: {
      const itemId = action.actionContext;
      if (!state.items[itemId]) {
        return state;
      }
      const item = { ...state.items[itemId] };
      item.printJob = { status: 'error', message: get(action, 'error.message') };
      return { ...state, items: { ...state.items, [itemId]: item } };
    }
    case types.LOAD_ITEM_ALERT_COMMENTS_SUCCESS: {
      const itemId = action.item;
      if (!state.items[itemId]) {
        return state;
      }
      const item = { ...state.items[itemId] };
      const alertComments = action.comments;
      item.alertComments = alertComments;
      return { ...state, items: { ...state.items, [itemId]: item } };
    }
    case types.LOAD_ITEM_ALERT_COMMENTS_FAILURE: {
      const itemId = action.item;
      if (!state.items[itemId]) {
        return state;
      }
      const item = { ...state.items[itemId] };
      const alertError = action.error;
      item.hasAlertError = true;
      item.alertError = alertError;
      return { ...state, items: { ...state.items, [itemId]: item } };
    }
    default:
      return state;
  }
}
