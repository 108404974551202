import uniq from 'lodash/uniq';
import flatMap from 'lodash/flatMap';
import * as types from '../constants/actiontypes.js';

export const initialState = {
  loading: false,
  filters: {},
  error: null,
  complete: false,
};

const UUID_REGEX = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
const sortAscendingLabel = (a, b) => {
  const aGuid = UUID_REGEX.test(a.label);
  const bGuid = UUID_REGEX.test(b.label);

  // make non-GUIDs come before GUIDs
  if (aGuid ^ bGuid) {
    return aGuid ? 1 : -1;
  }

  return a.label.localeCompare(b.label);
};

export default function merchants(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_AVAILABLE_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        complete: false,
      };
    case types.GET_AVAILABLE_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        filters: {
          merchants: action.response.merchants
            .map(merchant => ({
              value: merchant.merchantId,
              label: merchant.displayName || merchant.merchantId,
            }))
            .sort(sortAscendingLabel),
          fulfillers: action.response.fulfillers
            .map(fulfiller => ({
              value: fulfiller.internalFulfillerId,
              label: fulfiller.name || fulfiller.fulfillerId || fulfiller.internalFulfillerId,
            }))
            .sort(sortAscendingLabel),
          salesChannels: uniq(action.response.salesChannels.map(s => s.salesChannel))
            .map(value => ({
              value,
              label: value,
            }))
            .sort(sortAscendingLabel),
          productCategoriesTree: action.response.productCategories,
          productCategories: flattenCategoriesTree(action.response.productCategories),
        },
        error: null,
        complete: true,
      };
    case types.GET_AVAILABLE_FILTERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
}

const flattenCategoriesTree = root => {
  return flatMap(root.children, category => getCategories(category));
};

function getCategories(category) {
  const strings = flatMap(category.children, childCategory => getCategories(childCategory));
  strings.push({ label: category.name, value: category.categoryId });
  return strings;
}
