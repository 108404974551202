import { centralizedAuth as CentralizedAuth, Delegation } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'Z5nseere042D3xGkMje5YRU7QODqFG6T';

const auth = new CentralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

Object.assign(
  auth,
  new Delegation(auth) // when services stop requiring v1 auth this can be removed
);

export default auth;
