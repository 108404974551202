import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';

export function logoutSession() {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.LOGOUT_SESSION_REQUEST, types.LOGOUT_SESSION_SUCCESS, types.LOGOUT_SESSION_FAILURE],
        endpoint: `${process.env.REACT_APP_OOPS_URL}/api/v1/session`,
        method: 'DELETE',
      },
    });
  };
}
