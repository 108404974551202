export default {
  PLATFORM_ACCEPTED: 'platformAccepted',
  PLATFORM_REJECTED: 'platformRejected',
  PRODUCTION_ACCEPTED: 'productionAccepted',
  PRODUCTION_STARTED: 'productionStarted',
  FULFILLMENT: 'fulfillment',
  CANCELLATION_REQUEST_RECEIVED: 'cancellationRequestReceived',
  CANCELLATION_RESPONSE: 'cancellationResponse',
  NOTHING_CANCELLED: 'nothingCancelled',
  FULFILLER_REJECTED: 'fulfillerRejected',
  FULFILLER_ADAPTER_ERROR: 'fulfillerAdapterError',
  FULFILLER_TRANSMISSION_ERROR: 'fulfillerTransmissionError',
  FULFILLER_ID_MAPPINGS_CREATED: 'fulfillerIdMappingsCreated',
  UNSPECIFIED: 'unspecified',
  DRAWING_REQUESTED: 'drawingRequested',
  DRAWING_COMPLETED: 'drawingCompleted',
  DRAWING_FAILED: 'drawingFailed',
  DOCUMENT_REVIEW_STARTED: 'documentReviewStarted',
  DOCUMENT_REVIEW_APPROVED: 'documentReviewApproved',
  DOCUMENT_REVIEW_FEEDBACK_RECEIVED: 'documentReviewFeedbackReceived',
  DOCUMENT_REVIEW_FEEDBACK_REQUESTED: 'documentReviewFeedbackRequested',
  DOCUMENT_REVIEW_REJECTED: 'documentReviewRejected',
  DOCUMENT_REVIEW_EXCEPTION: 'documentReviewException',
  INVENTORY_CHECK_REQUESTED: 'inventoryCheckRequested',
  AWAITING_SUFFICIENT_INVENTORY: 'awaitingSufficientInventory',
  SUFFICIENT_INVENTORY: 'sufficientInventory',
  INSUFFICIENT_INVENTORY: 'insufficientInventory',
  CHANGE_REQUEST_RECEIVED: 'changeRequestReceived',
  CHANGE_REQUEST_TRANSFERRED_TO_FULFILLER: 'changeRequestTransferredToFulfiller',
  CHANGE_RESPONSE: 'changeResponse',
  PROMISED_ARRIVAL_DATE_CHANGED: 'promisedArrivalDateChanged',
  READY_TO_ROUTE: 'readyToRoute',
  ORDER_ROUTED: 'orderRouted',
  REPLANNED: 'replanned',
  SHIPPING_PLANNED: 'shippingPlanned',
  ITEM_UPDATED: 'itemUpdated',
  READY_FOR_FULFILLER: 'readyForFulfiller',
  TRANSMITTED_TO_FULFILLER: 'transmittedToFulfiller',
  CLAIM_CREATED: 'claimCreated',
  REORDER_CREATED: 'reorderCreated',
  FULFILLER_DELAYED: 'delayed',
  SHIPMENT_UPDATED: 'shipmentUpdated',
};
