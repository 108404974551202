import React, { useState, useEffect, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { FlexBox } from '@cimpress/react-components';
import { filter, isEqual } from 'lodash';

import Item from './Item';
import Loading from '../shared/Loading';
import usePrevious from '../../hooks/usePrevious';
import AdvancedFilter from './AdvancedFilter';
import createItemFilter from './itemFilter';
import { FILTER_VISIBILITY_ITEM_COUNT } from '../../constants/filter';

const InfiniteItemList = ({ order, permissions, location, history, intl, filterCriteria, setFilterCriteria }) => {
  // Have a reference back to order from the previous render
  const prevOrder = usePrevious(order);

  // State variables
  const [loadedItems, setLoadedItems] = useState([]);
  const [itemExpandedMap, setItemExpandedMap] = useState({});

  const queryParams = new URLSearchParams(location.search);

  // Infinite scrolling makes deep linking tricky.  If the total number of items is
  // greater than our page size, we should preset the filter so that we can bubble up
  // the deep linked item.
  useEffect(() => {
    const totalItemCount = order.itemIds.length;
    const initialItemId = queryParams.get('itemId');

    if (initialItemId && order.items[initialItemId] && totalItemCount > FILTER_VISIBILITY_ITEM_COUNT) {
      setLoadedItems([order.items[initialItemId]]);
    }
  }, []);

  // If the items in the order have changed (ex. decorated with events) we need to
  // update our state variable to reflect the changes.
  useEffect(() => {
    const newItems = loadedItems.map(({ itemId }) => order.items[itemId]);

    if (prevOrder && !isEqual(loadedItems, newItems)) {
      setLoadedItems(newItems);
    }
  }, [order]);

  // Callback for the infinite scroll
  const loadMoreItems = () => {
    const fullItemList = Object.values(order.items);
    const endIndex = Math.min(loadedItems.length + FILTER_VISIBILITY_ITEM_COUNT, fullItemList.length);

    setLoadedItems([...fullItemList.slice(0, endIndex)]);
  };

  // Callback for the ItemHeader Accordion
  const onItemContainerClick = (itemId, isNowOpen) => {
    const currentQueryItemId = queryParams.get('itemId');

    setItemExpandedMap({ ...itemExpandedMap, [itemId]: isNowOpen });

    // If they are opening the item header set it as a query param
    if (isNowOpen) {
      queryParams.set('itemId', itemId);
      history.replace({
        search: queryParams.toString(),
      });
    } else if (currentQueryItemId === itemId) {
      // If they are closing the currently deep-linked item, then remove it from the url
      queryParams.delete('itemId');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const isItemVisible = createItemFilter(filterCriteria);
  const loadedFilteredItems = filter(loadedItems, isItemVisible);

  const itemList = loadedFilteredItems.map(item => {
    const isCurrentItem = queryParams.get('itemId') === item.itemId;
    const isOpen = itemExpandedMap[item.itemId] || isCurrentItem || order.totalRows === 1;

    return (
      <Item
        item={item}
        itemList={order.items}
        key={item.itemId}
        isOpen={isOpen}
        scrollIntoView={isCurrentItem}
        onContainerClick={onItemContainerClick}
        permissions={permissions}
      />
    );
  });

  return (
    <Fragment>
      <h5 style={{ marginTop: '30px' }}>
        <FlexBox spaceBetween middle>
          <div>
            {intl.formatMessage({ id: 'OrderDetails.ItemsInOrder' }).toUpperCase()} ({order.itemIds.length})
          </div>
          {order.itemIds.length > FILTER_VISIBILITY_ITEM_COUNT ? (
            <AdvancedFilter
              items={order.items}
              value={filterCriteria}
              onChange={setFilterCriteria}
              filteredItemCount={loadedFilteredItems.length}
            />
          ) : null}
        </FlexBox>
      </h5>
      <InfiniteScroll
        loadMore={loadMoreItems}
        hasMore={order.itemIds.length > loadedItems.length}
        threshold={500}
        loader={<Loading key="loading" />}>
        {itemList}
      </InfiniteScroll>
    </Fragment>
  );
};

InfiniteItemList.propTypes = {
  order: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default injectIntl(InfiniteItemList);
