import {
  LOAD_GLOBAL_SETTINGS,
  SAVE_APP_SETTINGS,
  LOAD_APP_SETTINGS,
  SET_DISPLAY_TIMEZONE,
} from '../constants/actiontypes';
import { DEFAULT_LANGUAGE } from '../constants/languages';
import { DEFAULT_ID_FORMAT } from '../constants/idFormats';
import { getDefaultLocale, getDefaultTimezone } from '../utils/settings';

// save a copy of these in local storage
const ID_FORMAT_LOCAL_STORAGE = 'idFormat';
const DISPLAY_TIMEZONE_LOCAL_STORAGE = 'displayTimezone';

const DEFAULT_LOCALE = getDefaultLocale();
const DEFAULT_TIMEZONE = getDefaultTimezone();

const initialState = {
  userLanguage: DEFAULT_LANGUAGE,
  userLocale: DEFAULT_LOCALE,
  userTimezone: DEFAULT_TIMEZONE,
  displayTimezone: localStorage.getItem(DISPLAY_TIMEZONE_LOCAL_STORAGE) || DEFAULT_TIMEZONE,
  idFormat: localStorage.getItem(ID_FORMAT_LOCAL_STORAGE) || DEFAULT_ID_FORMAT,
  beta: false,
  areSettingsLoaded: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_DISPLAY_TIMEZONE:
      localStorage.setItem(DISPLAY_TIMEZONE_LOCAL_STORAGE, action.displayTimezone);
      return { ...state, displayTimezone: action.displayTimezone };
    case LOAD_GLOBAL_SETTINGS: {
      const newState = { ...state, areSettingsLoaded: true, userTimezone: action.timezone || DEFAULT_TIMEZONE };
      // if display timezone was previously set to user timezone, make sure it gets updated to the new user timezone
      if (state.userTimezone === state.displayTimezone) {
        newState.displayTimezone = newState.userTimezone;
      }

      const language = action.language || DEFAULT_LANGUAGE;
      if (language && language !== state.userLanguage) {
        newState.userLanguage = language;
      }

      const locale = action.regionalSettings || DEFAULT_LOCALE;
      if (locale && locale !== state.userLocale) {
        newState.userLocale = locale;
      }

      return newState;
    }
    case LOAD_APP_SETTINGS:
      return {
        ...state,
        idFormat: action.idFormat || DEFAULT_ID_FORMAT,
        beta: Boolean(action.beta),
      };
    case SAVE_APP_SETTINGS:
      localStorage.setItem(ID_FORMAT_LOCAL_STORAGE, action.idFormat);
      return { ...state, idFormat: action.idFormat };
    default:
      return state;
  }
};
