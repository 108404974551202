import itemServiceStatuses from '../constants/itemservicestatuses';
import itemServiceStatusStates from '../constants/itemservicestatusstates';
import { statusToItemServiceStatuses } from './statusModels';
import moment from 'moment';
import { uniqBy } from 'lodash';

export const formatSearchParams = params => {
  var query = {
    pageSize: params.pageSize || 100,
    offset: params.offset || 0,
  };
  if (params.searchTerm) {
    // free text search (sorted by relevance by default)
    query.queryString = formatSearchTerm(params.searchTerm);
  }

  if (!params.sortField && !params.sortOrder && !params.searchTerm) {
    // if no explicit sort is specified, and this is not free text search, then sort by latest created date
    query.sortField = 'createdDate';
    query.sortOrder = 'desc';
  } else if (params.sortField === 'createdDate') {
    query.sortField = 'createdDate';
    query.sortOrder = params.sortOrder || 'desc';
  } else if (params.sortField === 'promiseDate') {
    query.sortOrder = params.sortOrder || 'asc';
    query.sortField = 'localPromisedArrivalDate';
    query.sortMode = query.sortOrder === 'asc' ? 'min' : 'max';
  }
  // the search api has a bug where it doesn't accept _score (i.e. relevance) as a sortField. so if we want to sort by
  // relevance, just leave the fields blank (which will cause it to default to _score / desc)
  // ignoring any other sortFields for now
  let statuses = uniqBy(
    getQueryStatuses(params.statuses, true, { state: itemServiceStatusStates.CURRENT })
      .concat(
        getQueryStatuses(params.errorStatuses, false, { state: itemServiceStatusStates.CURRENT, missedCloseDate: true })
      )
      .concat(
        getQueryStatuses(params.delayedStatuses, false, {
          state: itemServiceStatusStates.CURRENT,
          missedCloseDate: true,
        })
      ),
    'name'
  );

  if (params.ids) {
    // search for specific order ids (used by watchdog searches only)
    query.ids = params.ids;
  }
  if (params.startDate) {
    query.startDateUtc = moment.utc(parseInt(params.startDate)).format();
  }
  if (params.endDate) {
    query.endDateUtc = moment.utc(parseInt(params.endDate)).format();
  }
  if (statuses && statuses.length) {
    query.statusesInclusive = statuses;
  }
  if (params.merchants) {
    query.merchantIds = params.merchants;
  }
  if (params.fulfillers) {
    query.fulfillerIds = params.fulfillers;
  }
  if (params.orderTypes) {
    query.testingConfigurations = params.orderTypes;
  }
  if (params.salesChannels) {
    query.merchantSalesChannels = params.salesChannels;
  }
  if (params.productCategories) {
    query.productCategories = params.productCategories;
  }
  if (params.claimTypes) {
    query.linkedRelations = params.claimTypes;
  }
  if (params.complaintTypes) {
    query.linkedRelations = params.complaintTypes;
  }

  if (params.changeRequestTypes) {
    query.changeRequestTypes = params.changeRequestTypes;
  }

  return query;
};

function getQueryStatuses(statusesParam, mapStatus, options) {
  let statusesInclusive = [];
  if (!statusesParam || !statusesParam.length || !statusesParam.length > 0) {
    return statusesInclusive;
  }
  statusesParam.forEach(statusParam => {
    const [status, flag] = statusParam.split('+');
    const statusOptions = { ...options };

    if (String(flag).toLowerCase() === itemServiceStatuses.SUSPENDED) {
      statusOptions.suspended = true;
    }

    let statusesCriteria = mapStatus ? statusToItemServiceStatuses[status] : [status];

    // ensure that searching for "production" only adds "production" to the search query instead of ["production", "awaitingFulfillerResponse", "productionAccepted"]
    if (status === itemServiceStatuses.PRODUCTION) {
      statusesCriteria = [itemServiceStatuses.PRODUCTION];
    }

    if (statusesCriteria && statusesCriteria.length) {
      statusesCriteria.forEach(c => {
        statusesInclusive.push({
          name: c,
          ...statusOptions,
        });
      });
    }
  });

  return statusesInclusive;
}

const formatSearchTerm = searchTerm => {
  // split string by non-word characters, preserving "+", "-", "*", ":", ".", and quoted substrings
  // eslint-disable-next-line
  let words = searchTerm.match(/[-\+]?"[^"]+"|[\w-\+\*:\.]+/g).map(word => {
    // put quotes around each word so that words with dashes (like guids) won't be split,
    // unless the word/phrase already has quotes, if there's a * in the string, or if the string starts with + or -
    if (word.length > 0) {
      if (
        (word.charAt(0) === '"' && word.charAt(word.length - 1) === '"') ||
        word.indexOf('*') > -1 ||
        word.charAt(0) === '-' ||
        word.charAt(0) === '+'
      ) {
        return word;
      }
    }
    return '"' + word + '"';
  });

  // if there is just one word in the search term, add a + to the beginning to require that term. this is a workaround
  // for an issue where searches return too many results when other clauses get added to the queryString (e.g. for
  // merchant and fulfiller filtering)
  if (words.length === 1 && words[0].charAt(0) !== '+') {
    words[0] = '+' + words[0];
  }

  return words.join(' ');
};
