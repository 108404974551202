import React, { useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Tooltip, Label } from '@cimpress/react-components';

import AddressSection from './AddressSection';
import FieldDisplay from './FieldDisplay';
import { trackEvent, TrackingEventTitles } from '../../analytics';

const AddressPopover = ({ addresses, hasChanged, hasFulfillerCorrected, shipmentDestinationAddresses }) => {
  const [show, setShow] = useState(false);
  const intl = useIntl();

  const toggleShow = () => {
    setShow(prevShow => !prevShow);

    // Yay for closures! "show" is false when the popover has been toggled to opened.
    if (!show) {
      trackEvent(TrackingEventTitles.ADDRESS_POPOVER_OPENED, {});
    }
  };

  const text =
    addresses.length > 1
      ? `${addresses.length} ${intl.formatMessage({ id: 'ItemLevelFields.Addresses' })}`
      : addresses[0].firstName && addresses[0].lastName
      ? `${addresses[0].firstName || ''} ${addresses[0].lastName || ''}`
      : addresses[0].company
      ? `${addresses[0].company}`
      : addresses[0].firstName || addresses[0].lastName
      ? `${addresses[0].firstName || ''}${addresses[0].lastName || ''}`
      : intl.formatMessage({ id: 'AddressFields.RecipientNotSpecified' });

  return (
    <Tooltip
      tooltipStyle={{ width: '300px' }}
      direction="bottom"
      variant="popover"
      show={show}
      onClickOutside={show ? toggleShow : null}
      contents={
        <div className="clearfix">
          {hasFulfillerCorrected ? (
            <h6>
              <FormattedMessage id="AddressFields.RequestedShippingAddress" />
            </h6>
          ) : null}
          {addresses.map((address, i) => (
            <>
              <FieldDisplay
                newline
                name={intl.formatMessage({ id: 'Address.Address' }) + ' ' + (i + 1)}
                value={<AddressSection key={`address-${i}`} address={address} includeName />}
              />
              {i < addresses.length - 1 && <hr />}
            </>
          ))}
          {hasFulfillerCorrected ? (
            <Fragment>
              <h6 style={{ marginTop: '15px' }}>
                <FormattedMessage id="AddressFields.FinalShippingAddress" />
              </h6>
              {shipmentDestinationAddresses.map((address, i) => (
                <>
                  <FieldDisplay
                    newline
                    name={intl.formatMessage({ id: 'Address.Address' }) + ' ' + (i + 1)}
                    value={<AddressSection key={`shipping-address-${i}`} address={address} includeName />}
                  />
                  {i < shipmentDestinationAddresses.length - 1 && <hr />}
                </>
              ))}
              <Label
                type="success"
                className="pull-right"
                text={intl.formatMessage({ id: 'AddressFields.FulfillerCorrected' })}
              />
            </Fragment>
          ) : null}
          {hasChanged && (
            <Label type="primary" className="pull-right" text={intl.formatMessage({ id: 'AddressFields.Updated' })} />
          )}
        </div>
      }>
      <a className="clickable" onClick={toggleShow}>
        {text}
      </a>
    </Tooltip>
  );
};

AddressPopover.propTypes = {
  addresses: PropTypes.array,
  hasChanged: PropTypes.bool,
  hasFulfillerCorrected: PropTypes.bool,
  shipmentDestinationAddresses: PropTypes.array,
};

export default AddressPopover;
