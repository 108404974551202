import React, { PureComponent } from 'react';
import IdDisplay from './idDisplay';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default class OrderLink extends PureComponent {
  render() {
    const { orderIds } = this.props;
    return (
      <Link to={`/orders/${orderIds.platform}`}>
        <p>
          <IdDisplay {...orderIds} />
        </p>
      </Link>
    );
  }
}

OrderLink.propTypes = {
  orderIds: PropTypes.object.isRequired,
};
