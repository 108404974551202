import React, { Fragment, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Snackbar from '@cimpress/react-components/lib/Snackbar';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

import withNotificationsHubAuthFlow from './withNotificationsHubAuthFlow';
import WatchButton from './WatchButton';
import { isSubscriptionEqual } from './utils';
import OrderSubscriptionSelector from '../detailsv2/OrderSubscriptionSelector';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';

const SUCCESS = 'success';
const DANGER = 'danger';

const WatchButtonModal = ({
  orderId,
  ensureAuthorized,
  returningFromAuth,
  relevantSubscriptions,
  subscriptionResourceType,
  finishAuthFlow,
  getSubscriptions,
  addSubscription,
  removeSubscription,
}) => {
  const [show, setShow] = useState(false);
  const [snackbarStyle, setSnackbarStyle] = useState();
  const showSnackbar = [DANGER, SUCCESS].includes(snackbarStyle);
  const isWatching = Boolean(relevantSubscriptions.length);
  const open = () => setShow(true);
  const close = () => setShow(false);
  const showSuccess = () => setSnackbarStyle(SUCCESS);
  const showError = () => setSnackbarStyle(DANGER);
  const hideSnackbar = () => setSnackbarStyle();

  useEffect(() => {
    setShow(returningFromAuth);
    finishAuthFlow();

    if (!relevantSubscriptions.length) {
      getSubscriptions({ resourceType: subscriptionResourceType, resourceId: orderId });
    }
  }, [
    finishAuthFlow,
    getSubscriptions,
    orderId,
    relevantSubscriptions.length,
    returningFromAuth,
    subscriptionResourceType,
  ]);

  const onClick = async () => {
    trackEvent(TrackingEventTitles.VIEW_ORDER_SUBSCRIPTION_OPTIONS, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_SUBSCRIPTION.ORDER_ID]: orderId,
    });

    try {
      await ensureAuthorized();
      open();
    } catch ({ reason = 'unknown' }) {}
  };

  const onUpdate = async nextSubscriptions => {
    const subsToAdd = nextSubscriptions.filter(n => !relevantSubscriptions.some(c => isSubscriptionEqual(n, c)));
    const idsToDelete = relevantSubscriptions
      .filter(c => !nextSubscriptions.some(n => isSubscriptionEqual(c, n)))
      .map(s => s.id);

    try {
      await Promise.all(subsToAdd.map(addSubscription).concat(idsToDelete.map(removeSubscription)));
      showSuccess();

      trackEvent(TrackingEventTitles.SAVED_ORDER_SUBSCRIPTION, {
        [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_SUBSCRIPTION.ORDER_ID]: orderId,
      });
    } catch (error) {
      console.error(error);
      showError();
    } finally {
      close();
    }
  };

  return (
    <Fragment>
      {showSnackbar ? (
        <Snackbar
          onHideSnackbar={hideSnackbar}
          show
          status={snackbarStyle}
          delay={snackbarStyle === SUCCESS ? 5000 : 0}>
          {snackbarStyle === DANGER ? (
            <FormattedMessage id="WatchOrder.ErrorSnackbar" />
          ) : (
            <FormattedMessage id="WatchOrder.SuccessSnackbar" />
          )}
        </Snackbar>
      ) : null}
      <Tooltip
        contents={
          isWatching ? (
            <FormattedMessage id="WatchOrder.ManageOrderSubscriptions" />
          ) : (
            <FormattedMessage id="WatchOrder.WatchThisOrder" />
          )
        }>
        <WatchButton isWatching={isWatching} onClick={onClick} />
      </Tooltip>
      <OrderSubscriptionSelector
        onUpdate={onUpdate}
        relevantSubscriptions={relevantSubscriptions}
        show={show}
        onClose={close}
        orderId={orderId}
      />
    </Fragment>
  );
};

WatchButtonModal.propTypes = {
  orderId: PropTypes.string.isRequired,
  ensureAuthorized: PropTypes.func.isRequired,
  returningFromAuth: PropTypes.bool,
  relevantSubscriptions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  subscriptionResourceType: PropTypes.string.isRequired,
  finishAuthFlow: PropTypes.func,
  getSubscriptions: PropTypes.func.isRequired,
  addSubscription: PropTypes.func.isRequired,
  removeSubscription: PropTypes.func.isRequired,
};

export default withNotificationsHubAuthFlow(WatchButtonModal);
