import * as types from '../constants/actiontypes.js';

export const initialState = {
  searchTerm: '',
  startDate: '',
  endDate: '',
  merchants: [],
  fulfillers: [],
  statuses: [],
  delayedStatuses: [],
  errorStatuses: [],
  orderTypes: [],
  salesChannels: [],
  productCategories: [],
  claimTypes: [],
  complaintTypes: [],
  changeRequestTypes: [],
  sortField: null,
  sortOrder: null,
  page: 1,
  loading: false,
  result: {},
  error: null,
  lastQuery: {},
  lastOrder: '',
};

export default function search(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };

    case types.SET_START_DATE: {
      // variable is being hoisted from SEARCH_SUCCESS case
      const startDate = action.startDate ? action.startDate : null;

      if (startDate) {
        // if this is the first time we've picked a start date, AND it is a user click action
        // default to the start of the day
        if (!state.startDate && action.isClick) {
          startDate.tz(action.displayTimezone, true);
          startDate.startOf('day');
        }
        startDate.utc();
      }

      return { ...state, startDate };
    }

    case types.SET_END_DATE: {
      // variable is being hoisted from SEARCH_SUCCESS case
      const endDate = action.endDate ? action.endDate : null;

      if (endDate) {
        // if this is the first time we've picked an end date, AND it is a user click action
        // default to the end of the day
        if (!state.endDate && action.isClick) {
          endDate.tz(action.displayTimezone, true);
          endDate.endOf('day');
        } else {
          // otherwise, capture through the end of the selected minute
          endDate.endOf('minute');
        }
        endDate.utc();
      }

      return { ...state, endDate };
    }

    case types.SET_MERCHANTS:
      return { ...state, merchants: action.merchants };

    case types.SET_FULFILLERS:
      return { ...state, fulfillers: action.fulfillers };

    case types.SET_STATUSES:
      return { ...state, statuses: action.statuses };

    case types.SET_DELAYED_STATUSES:
      return { ...state, delayedStatuses: action.delayedStatuses };

    case types.SET_ERROR_STATUSES:
      return { ...state, errorStatuses: action.errorStatuses };

    case types.SET_ORDER_TYPES:
      return { ...state, orderTypes: action.orderTypes };
    case types.SET_CLAIM_TYPES:
      return { ...state, claimTypes: action.claimTypes };

    case types.SET_COMPLAINT_TYPES:
      return { ...state, complaintTypes: action.complaintTypes };

    case types.SET_CHANGE_REQUEST_TYPES:
      return { ...state, changeRequestTypes: action.changeRequestTypes };

    case types.SET_SALES_CHANNELS:
      return { ...state, salesChannels: action.salesChannels };

    case types.SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.productCategories };

    case types.SET_SEARCH_SORT:
      return {
        ...state,
        sortField: action.sortField,
        sortOrder: action.sortOrder,
      };

    case types.CLEAR_SEARCH:
      return { ...state, ...initialState };

    case types.SEARCH_REQUEST:
      return {
        ...state,
        ...{
          // clear last query and all query result fields while the request is processing
          lastQuery: {},
          loading: true,
          result: {},
          error: null,
          lastOrder: '',
        },
      };

    case types.SEARCH_SUCCESS:
      // copy the current state of each of the query fields
      var {
        searchTerm,
        startDate,
        endDate,
        merchants,
        fulfillers,
        statuses,
        delayedStatuses,
        errorStatuses,
        orderTypes,
        salesChannels,
        productCategories,
        claimTypes,
        complaintTypes,
        changeRequestTypes,
        page,
        sortField,
        sortOrder,
      } = state;
      return {
        ...state,
        ...{
          // assign the current query to lastQuery for use navigating between pages
          // and to reference for CSV download
          lastQuery: {
            searchTerm,
            startDate,
            endDate,
            merchants,
            fulfillers,
            statuses,
            delayedStatuses,
            errorStatuses,
            orderTypes,
            salesChannels,
            productCategories,
            claimTypes,
            complaintTypes,
            changeRequestTypes,
            page,
            sortField,
            sortOrder,
          },
          loading: false,
          result: action.response,
        },
      };

    case types.SEARCH_FAILURE:
      return {
        ...state,
        ...{
          loading: false,
          entities: [],
          result: {},
          error: { payload: action.payload, source: action.errorMessage || '' },
        },
      };

    case types.CHANGE_PAGE:
      return { ...state, page: action.page };

    case types.SELECT_ORDER:
      return { ...state, lastOrder: action.orderId };

    default:
      return state;
  }
}
