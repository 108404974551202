import { useEffect, useReducer } from 'react';
import P from 'bluebird';

import { callFetch } from '../../../services/serviceHelpers';

const initialUnmetExpectationsState = { isLoading: false, errorMessage: null };

export const useUnmetExpectations = unmetExpectations => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'REQUEST':
        return { isLoading: true, remediationSteps: [], expectations: [], errorMessage: null };
      case 'SUCCESS':
        return {
          isLoading: false,
          remediationSteps: action.remediationSteps,
          expectations: action.expectations,
          errorMessage: null,
        };
      case 'FAILURE':
        return { isLoading: false, remediationSteps: [], expectations: [], errorMessage: action.errorMessage };
      default:
        return state;
    }
  }, initialUnmetExpectationsState);

  useEffect(() => {
    const fetchExpectationData = async () => {
      dispatch({ type: 'REQUEST' });
      try {
        const remediationSteps = [],
          expectations = [];
        await P.map(unmetExpectations, async ({ href }) => {
          const expectation = await callFetch(href, 'GET');
          const { expectationCode } = expectation;
          expectations.push(expectation);

          try {
            const remediationStep = await callFetch(
              process.env.REACT_APP_FULFILLMENT_EXPECTATIONS_URL,
              'GET',
              `v1/remediationStep/${expectationCode}`
            );
            remediationSteps.push(remediationStep);
          } catch (error) {
            if (error.response && error.response.status !== 404) {
              throw error;
            }
          }
        });
        dispatch({ type: 'SUCCESS', remediationSteps, expectations });
      } catch (error) {
        if (error.response && error.response.status !== 404) {
          dispatch({ type: 'FAILURE', errorMessage: error.message });
        } else {
          dispatch({ type: 'FAILURE' });
        }
      }
    };
    fetchExpectationData();
  }, [unmetExpectations]);

  return state;
};
