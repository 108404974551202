import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initStatuses } from '../../constants/statuses';
import statusTypes from '../../constants/statustypes';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import get from 'lodash/get';

const ItemStatus = ({ item, showIcons, intl }) => {
  const statuses = initStatuses(intl);
  const { computedStatus = {} } = item;
  const displayName =
    computedStatus.type === statusTypes.SHIPPED && computedStatus.partial
      ? get(statuses, `${computedStatus.type}.displayNamePartial`)
      : get(statuses, `${computedStatus.type}.displayName`);
  if (!showIcons) {
    return displayName;
  }

  const { computedErrors = [], computedMissedSlas = [], pendingCancellation } = item;
  const errorIcon = computedErrors.length ? <IconAlertTriangle className="text-danger" weight="fill" /> : null;
  const warningIcon = computedMissedSlas.length ? <IconAlertTriangle className="text-warning" weight="fill" /> : null;
  const infoIcon = pendingCancellation ? <IconInformationCircle className="text-info" weight="fill" /> : null;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {errorIcon} {warningIcon} {infoIcon} <span style={{ marginLeft: '5px' }}>{displayName}</span>
    </div>
  );
};

ItemStatus.propTypes = {
  item: PropTypes.shape({
    computedStatus: PropTypes.object,
    computedMissedSlas: PropTypes.array,
    computedErrors: PropTypes.array,
  }).isRequired,
  showIcons: PropTypes.bool,
  intl: PropTypes.object,
};

ItemStatus.defaultProps = {
  showIcons: true,
};

export default injectIntl(ItemStatus);
