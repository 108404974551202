import eventTypes from './eventtypes.js';
import { statusToItemServiceStatuses } from '../utils/statusModels.js';
import { isEmpty } from 'lodash';

const additionalStatusesMap = intl => ({
  workflowItemGeneration: {
    displayName: intl.formatMessage({ id: 'Statuses.ItemGeneration' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  workflowAddressValidation: {
    displayName: intl.formatMessage({ id: 'Statuses.AddressValidation' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  workflowDocumentDataMining: {
    displayName: intl.formatMessage({ id: 'Statuses.DocumentDataMining' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  orderValidation: {
    displayName: intl.formatMessage({ id: 'Statuses.OrderValidation' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  workflowCallbackRegistration: {
    displayName: intl.formatMessage({ id: 'Statuses.CallbackRegistration' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  workflowRoutingIngest: {
    displayName: intl.formatMessage({ id: 'Statuses.RoutingIngest' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: false,
  },
  workflowDeliveryRequestOrchestrator: {
    displayName: intl.formatMessage({ id: 'Statuses.DeliveryRequestOrchestrator' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  workflowItemPricing: {
    displayName: intl.formatMessage({ id: 'Statuses.ItemPricing' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  workflowProductDocumentOverride: {
    displayName: intl.formatMessage({ id: 'Statuses.ProductDocumentOverride' }),
    groupStepName: 'standardizingOrder',
    showInStatusOverview: true,
  },
  ConsumeCapacity: {
    displayName: intl.formatMessage({ id: 'Statuses.ConsumeCapacity' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  prepress: {
    displayName: intl.formatMessage({ id: 'Statuses.Prepress' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  DeliveryConstraints: {
    displayName: intl.formatMessage({ id: 'Statuses.DeliveryConstraints' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  ShipmentPlanner: {
    displayName: intl.formatMessage({ id: 'Statuses.ShipmentPlanner' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  WorkflowPrice: {
    displayName: intl.formatMessage({ id: 'Statuses.WorkflowPricing' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  ProductManufacturingDataPopulation: {
    displayName: intl.formatMessage({ id: 'Statuses.ProductManufacturingDataPopulation' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  FulfillmentExpectations: {
    displayName: intl.formatMessage({ id: 'Statuses.FulfillmentExpectations' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  FulfillmentOrderManager: {
    displayName: intl.formatMessage({ id: 'Statuses.FulfillmentOrderManager' }),
    groupStepName: 'preparing',
    showInStatusOverview: true,
  },
  ProductionAccepted: {
    displayName: intl.formatMessage({ id: 'Statuses.ProductionAccepted' }),
    showInStatusOverview: true,
  },
});

// This function uses the same map object in `initStatus` but changes the key to the original
// item service status (e.g. 'processing' -> 'preparing')
export const itemServiceStatusToDisplayMap = intl => {
  const itemServiceStatuses = initStatuses(intl);
  let displayStatusMap = {};

  // if the status model has multiple items just take the first item as the display name
  for (const statusName in itemServiceStatuses) {
    const displayedStatusName = !isEmpty(statusToItemServiceStatuses[statusName])
      ? statusToItemServiceStatuses[statusName][0]
      : statusName;
    displayStatusMap[displayedStatusName] = itemServiceStatuses[statusName];
  }
  displayStatusMap = {
    ...displayStatusMap,
    ...additionalStatusesMap(intl),
  };
  return displayStatusMap;
};

export function initStatuses(intl) {
  return {
    standardizingOrder: {
      displayName: intl.formatMessage({ id: 'Statuses.StandardizingOrder' }),
      step: 1,
      showInStatusOverview: true,
    },
    docReview: {
      displayName: intl.formatMessage({ id: 'Statuses.DocumentReview' }),
      step: 2,
      showInStatusOverview: true,
      messages: {
        [eventTypes.DOCUMENT_REVIEW_FEEDBACK_REQUESTED]: intl.formatMessage({
          id: 'Statuses.WaitingDocReviewFeedbacksMessage',
        }),
        [eventTypes.DOCUMENT_REVIEW_REJECTED]: intl.formatMessage({ id: 'Statuses.DocReviewFailedMessage' }),
        [eventTypes.DOCUMENT_REVIEW_EXCEPTION]: intl.formatMessage({ id: 'Statuses.DocReviewFailedMessage' }),
        // used by the doc review details page to display status messages
        [eventTypes.DOCUMENT_REVIEW_STARTED]: intl.formatMessage({ id: 'Statuses.DocumentReviewStarted' }),
        [eventTypes.DOCUMENT_REVIEW_APPROVED]: intl.formatMessage({ id: 'Statuses.DocumentReviewApproved' }),
        [eventTypes.DOCUMENT_REVIEW_FEEDBACK_REQUESTED]: intl.formatMessage({
          id: 'Statuses.DocumentReviewFeedbackReceived',
        }),
      },
    },
    routing: {
      displayName: intl.formatMessage({ id: 'Statuses.Routing' }),
      step: 3,
      showInStatusOverview: true,
    },
    shippingAggregation: {
      displayName: intl.formatMessage({ id: 'Statuses.ShippingAggregation' }),
      step: 4,
      showInStatusOverview: true,
    },
    processing: {
      displayName: intl.formatMessage({ id: 'Statuses.Processing' }),
      step: 5,
      showInStatusOverview: true,
      messages: {
        [eventTypes.DRAWING_FAILED]: intl.formatMessage({ id: 'Statuses.DrawingFailedMessage' }),
      },
    },
    awaitingFulfillerResponse: {
      displayName: intl.formatMessage({ id: 'Statuses.AwaitingFulfillerResponse' }),
      step: 6,
      showInStatusOverview: true,
    },
    production: {
      displayName: intl.formatMessage({ id: 'Statuses.Production' }),
      step: 7,
      showInStatusOverview: true,
    },
    shipped: {
      displayName: intl.formatMessage({ id: 'Statuses.Shipped' }),
      displayNamePartial: intl.formatMessage({ id: 'Statuses.PartialShipped' }),
      getDisplayName: function(status) {
        return status.partial
          ? intl.formatMessage({ id: 'Statuses.PartialShipped' })
          : intl.formatMessage({ id: 'Statuses.Shipped' });
      },
      step: 8,
      showInStatusOverview: true,
    },
    cancelled: {
      displayName: intl.formatMessage({ id: 'Statuses.Cancelled' }),
      messages: {
        [eventTypes.CANCELLATION_REQUEST_RECEIVED]: intl.formatMessage({ id: 'Statuses.CancellationRequestedMessage' }),
        [eventTypes.NOTHING_CANCELLED]: intl.formatMessage({ id: 'Statuses.CannotCancelItemMessage' }),
        [eventTypes.CANCELLATION_RESPONSE]: intl.formatMessage({ id: 'Statuses.ItemCancelledMessage' }),
      },
      showInStatusOverview: true,
    },
    rejected: {
      displayName: intl.formatMessage({ id: 'Statuses.Rejected' }),
      showInStatusOverview: true,
    },
    suspended: {
      displayName: intl.formatMessage({ id: 'Statuses.Suspended' }),
      showInStatusOverview: true,
    },
    unknown: {
      displayName: intl.formatMessage({ id: 'Statuses.Unknown' }),
    },
  };
}
