import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { setDisplayTimezone } from '../../actions/userPreferencesActions';

const UTC_TIMEZONE = 'Etc/UTC';

class TimezoneSelector extends Component {
  static propTypes = {
    setDisplayTimezone: PropTypes.func.isRequired,
    userTimezone: PropTypes.string.isRequired,
    displayTimezone: PropTypes.string.isRequired,
  };

  toggleUtcTimezone = () => {
    this.props.setDisplayTimezone(UTC_TIMEZONE);
  };

  toggleDisplayTimezone = () => {
    this.props.setDisplayTimezone(this.props.userTimezone);
  };

  render() {
    let userTz = moment.tz(this.props.userTimezone);
    let timezoneAbbr = `${userTz.zoneAbbr()} (UTC${userTz.format('Z')})`;
    let utcSelected = this.props.displayTimezone === UTC_TIMEZONE;

    let utcSelector = utcSelected ? (
      'UTC'
    ) : (
      <a className="clickable" onClick={this.toggleUtcTimezone}>
        UTC
      </a>
    );

    let userPrefSelector = !utcSelected ? (
      timezoneAbbr
    ) : (
      <a className="clickable" onClick={this.toggleDisplayTimezone}>
        {timezoneAbbr}
      </a>
    );

    return (
      <span style={{ position: 'relative', top: '13px', paddingRight: '15px' }}>
        {utcSelector} / {userPrefSelector}
      </span>
    );
  }
}

const mapStateToProps = ({ userPreferences: { displayTimezone, userTimezone } }) => ({
  displayTimezone,
  userTimezone,
});

export default connect(mapStateToProps, { setDisplayTimezone })(TimezoneSelector);
