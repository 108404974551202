export const isSubscriptionEqual = (sub1, sub2) => {
  if (
    !sub1 ||
    !sub2 ||
    !sub1.resourceFilters ||
    !sub2.resourceFilters ||
    sub1.resourceFilters.length !== sub2.resourceFilters.length ||
    sub1.resourceId !== sub2.resourceId
  ) {
    return false;
  }

  return areResourceFiltersEqual(sub1.resourceFilters, sub2.resourceFilters);
};

export const areResourceFiltersEqual = (filters1, filters2) => {
  if (filters1.length === 0 && filters2.length === 0) {
    return true;
  }
  if (filters1.length !== filters2.length) {
    return false;
  }
  return filters1.every(a => filters2.some(b => a.path === b.path && a.value === b.value && a.operator === b.operator));
};
