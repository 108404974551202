import {
  GET_ORDER_ACTIONS_REQUEST,
  GET_ORDER_ACTIONS_SUCCESS,
  GET_ORDER_ACTIONS_FAILURE,
} from '../constants/actiontypes.js';

export const initialState = {
  loading: false,
  operations: null,
  isError: false,
};

export default function operations(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ORDER_ACTIONS_REQUEST:
      return { ...initialState, loading: true };
    case GET_ORDER_ACTIONS_SUCCESS:
      return { ...state, loading: false, operations: action.response };
    case GET_ORDER_ACTIONS_FAILURE:
      return { ...state, loading: false, isError: true };
    default:
      return state;
  }
}
