import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Button, FlexBox, Modal, Snackbar, Step, Stepper } from '@cimpress/react-components';

import ActionLink from '../../../shared/ActionLink';
import Loading from '../../../shared/Loading';
import { requestRedirect } from '../../../../services/merchantOrder';
import { CURRENT, CLOSED } from '../../../../constants/itemservicestatusstates';
import useRedirectContext, { RedirectProvider } from './RedirectContext';
import ConfirmSelections from './ConfirmSelections';
import SelectItems from './SelectItems';
import SelectFulfillmentConfigurations from './SelectFulfillmentConfigurations';
import InfiniteScroll from 'react-infinite-scroller';

const ITEMS_TO_LOAD = 2;

const RequestRedirectModal = ({ order }) => {
  const items = Object.values(order.items);

  return (
    <RedirectProvider items={Object.values(order.items)}>
      <RedirectModal items={items} />
    </RedirectProvider>
  );
};

const RedirectModal = ({ items }) => {
  const { state } = useRedirectContext();
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);
  const [loadedItems, setLoadedItems] = useState([]);
  const [redirectRequestResponse, setRedirectRequestResponse] = useState();

  const close = () => setShowModal(false);
  const open = () => setShowModal(true);
  const nextClicked = async () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      const itemsToRedirect = Object.values(state.itemsAvailableToRedirect).filter(
        item => item.isSelectedForRedirection
      );
      const redirectRequest = {
        waitForFulfiller: state.waitForFulfiller,
        redirects: itemsToRedirect.map(item => ({
          _links: {
            fulfillmentConfiguration: {
              href: item.newFulfillmentConfigurationUrl,
            },
            fulfillableItem: {
              href: get(
                items.find(({ itemId }) => (itemId = item.itemId)),
                '_links.self.href'
              ),
            },
          },
        })),
      };
      try {
        const response = await requestRedirect(items[0].orderId, redirectRequest);
        setRedirectRequestResponse(response);
      } catch (err) {
        setRedirectRequestResponse(err);
      }
    }
  };

  const loadMoreItems = () => {
    const fullItemList = Object.values(items);
    const endIndex = Math.min(loadedItems.length + ITEMS_TO_LOAD, fullItemList.length);

    setLoadedItems([...fullItemList.slice(0, endIndex)]);
  };

  const canRedirect = items.every(item => {
    return (
      get(item, 'statuses.Baton.state') === CLOSED &&
      get(item, 'statuses.cancelled.state') !== CURRENT &&
      get(item, 'statuses.rejected.state') !== CURRENT &&
      !get(item, '_links.routedItem.href') &&
      !get(item, '_links.redirectedItem.href')
    );
  });

  const isNextButtonDisabled =
    step === 0
      ? !Object.values(state.itemsAvailableToRedirect).some(item => item.isSelectedForRedirection)
      : step === 1
      ? !Object.values(state.itemsAvailableToRedirect).every(
          item => item.newFulfillmentConfigurationUrl || !item.isSelectedForRedirection
        )
      : false;
  const stepDescriptions = [
    'RequestRedirect.Stepper.SelectItemsDescription',
    'RequestRedirect.Stepper.SelectNewFulfillmentConfigurationDescription',
    'RequestRedirect.Stepper.ConfirmDescription',
  ];

  return (
    <>
      <ActionLink
        text={<FormattedMessage id="OrderActions.RequestRedirect" />}
        action={open}
        disabled={!canRedirect}
        disabledMessage={<FormattedMessage id="OrderActions.RequestRedirect" />}
      />
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={<FormattedMessage id="OrderActions.RequestRedirect" />}
        style={{ width: '90%' }}
        footer={
          <FlexBox>
            <Snackbar
              show={Boolean(redirectRequestResponse)}
              status={redirectRequestResponse instanceof Error ? 'danger' : 'success'}>
              {redirectRequestResponse instanceof Error ? (
                <FormattedMessage id="RequestRedirect.RequestFailed" />
              ) : (
                <FormattedMessage id="RequestRedirect.RequestSucceeded" />
              )}
            </Snackbar>

            {step > 0 && (
              <FlexBox left>
                <Button variant="default" onClick={() => setStep(step - 1)}>
                  <FormattedMessage id="Global.Back" />
                </Button>
              </FlexBox>
            )}
            <FlexBox right>
              <Button variant="primary" onClick={nextClicked} disabled={isNextButtonDisabled}>
                <FormattedMessage id={step !== 2 ? 'Global.Next' : 'Global.Submit'} />
              </Button>
              <Button variant="default" onClick={close}>
                <FormattedMessage id="Global.Close" />
              </Button>
            </FlexBox>
          </FlexBox>
        }>
        <InfiniteScroll
          loadMore={loadMoreItems}
          hasMore={items.length > loadedItems.length}
          threshold={500}
          useWindow={false}
          loader={<Loading key="loading" />}>
          <Stepper activeStep={step}>
            <Step>
              <FormattedMessage id="RequestRedirect.Stepper.SelectItems" />
            </Step>
            <Step>
              <FormattedMessage id="RequestRedirect.Stepper.SelectNewFulfillmentConfigurations" />
            </Step>
            <Step>
              <FormattedMessage id="RequestRedirect.Confirm" />
            </Step>
          </Stepper>

          <p>
            <FormattedMessage id={stepDescriptions[step]} />
          </p>

          <FlexBox style={{ marginTop: '10px' }}>
            <FlexBox isVertical grow>
              {step === 0 && <SelectItems items={loadedItems} />}
              {step === 1 && <SelectFulfillmentConfigurations items={loadedItems} />}
              {step === 2 && <ConfirmSelections items={loadedItems} />}
            </FlexBox>
          </FlexBox>
        </InfiniteScroll>
      </Modal>
    </>
  );
};

export default RequestRedirectModal;
