import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedTime } from 'react-intl';
import PropTypes from 'prop-types';

import { getCarrierServiceById } from '../../../services/registryCarrierServices';
import { HISTORICAL_SHIPMENT_UPDATE } from '../../../constants/carriers';

const FulfillmentEventSummary = ({ additionalData: { carrierId, carrierServiceId, carrierServiceKey } = {} }) => {
  const [carrierServiceName, setCarrierServiceName] = useState('');

  useEffect(() => {
    if (carrierServiceId && carrierServiceId.substring(0, 5) === 'recs:') {
      getCarrierServiceById(carrierServiceId).then(setCarrierServiceName);
    } else if (carrierServiceKey && carrierServiceKey.substring(0, 5) === 'recs:') {
      getCarrierServiceById(carrierServiceKey).then(setCarrierServiceName);
    }
  }, [carrierServiceId, carrierServiceKey]);

  if (carrierId === HISTORICAL_SHIPMENT_UPDATE) {
    return (
      <span>
        <FormattedTime id="ShippingMessage.ManualShipmentUpdate" />
      </span>
    );
  }

  const carrier =
    carrierServiceName ||
    `${carrierId ? `${carrierId} ` : ''}${carrierServiceId || carrierServiceKey || ''}` ||
    'Unknown';

  return (
    <span>
      <FormattedMessage id="ShippingMessage.ShippedVia" values={{ carrier }} />
    </span>
  );
};

FulfillmentEventSummary.propTypes = {
  additionalData: PropTypes.shape({
    carrierId: PropTypes.string,
    carrierServiceId: PropTypes.string,
    carrierServiceKey: PropTypes.string,
  }),
};

FulfillmentEventSummary.defaultProps = {
  additionalData: {},
};

export default FulfillmentEventSummary;
