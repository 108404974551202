import * as fulfillmentOrderManager from '../services/fulfillmentOrderManager';
import * as types from '../constants/actiontypes';
import eventTypes from '../constants/eventtypes';

export function updateStatus(statusType, fulfillmentGroups, items) {
  switch (statusType) {
    case eventTypes.PRODUCTION_ACCEPTED:
      return acceptProduction(items, fulfillmentGroups);
    case eventTypes.PRODUCTION_STARTED:
      return startProduction(fulfillmentGroups);
    case eventTypes.FULFILLMENT:
      return confirmShipment(fulfillmentGroups);
    case eventTypes.FULFILLER_REJECTED:
      return rejectProduction(fulfillmentGroups);
    case eventTypes.CANCELLATION_RESPONSE:
      return confirmCancellation(items, fulfillmentGroups);
    case eventTypes.NOTHING_CANCELLED:
      return rejectCancellation(items, fulfillmentGroups);
    default:
      return;
  }
}

function queryNotificationIds(items, notificationTypes) {
  return Promise.all(
    items.map(item => {
      return fulfillmentOrderManager
        .queryNotifications(item.fulfillerId, item.shortFulfillmentGroupId, item.shortItemId)
        .then(res => {
          const notificationIds = res.notifications.filter(
            notification => notificationTypes.findIndex(n => n === notification.type) > -1
          );
          let itemResponse = { shortItemId: item.shortItemId, itemId: item.itemId, requestBody: item.requestBody };
          if (notificationIds.length > 0) {
            itemResponse.notificationId = notificationIds[0].notificationId;
          }
          return itemResponse;
        });
    })
  );
}

function sendNotification(fulfillmentGroups, notificationType) {
  const failures = [];
  return Promise.all(
    fulfillmentGroups.map(fulfillmentGroup => {
      const items = [];
      fulfillmentGroup.items.forEach(itm => {
        items.push({ itemId: itm.shortItemId, quantity: itm.quantity });
      });
      const json = { items, notificationType };
      Object.assign(json, fulfillmentGroup.requestBody);
      return fulfillmentOrderManager.postNotification(json).then(result => {
        if (!result.ok) {
          failures.push({ fulfillmentGroupId: fulfillmentGroup.fulfillmentGroupId, status: result.status });
        }
        return result;
      });
    })
  ).then(() => {
    if (failures.length) {
      throw failures;
    }
  });
}

function respondToNotification(items, notificationTypes, response) {
  const failures = [];
  return queryNotificationIds(items, notificationTypes)
    .then(itemResponses => {
      itemResponses
        .filter(itemResponse => !itemResponse.notificationId)
        .forEach(itemResponse => failures.push({ itemId: itemResponse.itemId, status: 500 }));
      return Promise.all(
        itemResponses
          .filter(itemResponse => !!itemResponse.notificationId)
          .map(itemResponse => {
            return fulfillmentOrderManager
              .respondNotification(
                itemResponse.requestBody,
                itemResponse.notificationId,
                itemResponse.shortItemId,
                response
              )
              .then(result => {
                if (!result.ok) {
                  failures.push({ itemId: itemResponse.itemId, status: result.status });
                }
                return result;
              });
          })
      );
    })
    .then(() => {
      if (failures.length) {
        throw failures;
      }
    });
}

function acceptProduction(items, fulfillmentGroups) {
  items.forEach(item => {
    const groupIndex = fulfillmentGroups.findIndex(
      fulfillmentGroup => fulfillmentGroup.fulfillmentGroupId === item.fulfillmentGroupId
    );
    item.requestBody = { costsIncurred: fulfillmentGroups[groupIndex].additionalData.costsIncurred };
  });
  return dispatch => {
    return respondToNotification(items, ['OrderRequest', 'RetryOrderRequest'], 'accept')
      .then(() => dispatch({ type: types.UPDATE_ITEM_STATUS_SUCCESS, totalItems: items.length }))
      .catch(failures => dispatch({ type: types.UPDATE_ITEM_STATUS_FAILURE, failures, totalItems: items.length }));
  };
}

function startProduction(fulfillmentGroups) {
  fulfillmentGroups.forEach(fulfillmentGroup => {
    fulfillmentGroup.requestBody = {};
  });
  return dispatch => {
    return sendNotification(fulfillmentGroups, 'ProductionStarted')
      .then(() =>
        dispatch({ type: types.UPDATE_GROUP_STATUS_SUCCESS, totalFulfillmentGroups: fulfillmentGroups.length })
      )
      .catch(failures =>
        dispatch({
          type: types.UPDATE_GROUP_STATUS_FAILURE,
          failures,
          totalFulfillmentGroups: fulfillmentGroups.length,
        })
      );
  };
}

function rejectProduction(fulfillmentGroups) {
  fulfillmentGroups.forEach(fulfillmentGroup => {
    fulfillmentGroup.requestBody = { rejectionDetails: { reason: fulfillmentGroup.additionalData.rejectionDetails } };
  });
  return dispatch => {
    return sendNotification(fulfillmentGroups, 'ItemsRejected')
      .then(() =>
        dispatch({ type: types.UPDATE_GROUP_STATUS_SUCCESS, totalFulfillmentGroups: fulfillmentGroups.length })
      )
      .catch(failures =>
        dispatch({
          type: types.UPDATE_GROUP_STATUS_FAILURE,
          failures,
          totalFulfillmentGroups: fulfillmentGroups.length,
        })
      );
  };
}

function confirmCancellation(items, fulfillmentGroups) {
  items.forEach(item => {
    const groupIndex = fulfillmentGroups.findIndex(
      fulfillmentGroup => fulfillmentGroup.fulfillmentGroupId === item.fulfillmentGroupId
    );
    item.requestBody = { costsIncurred: fulfillmentGroups[groupIndex].additionalData.costsIncurred };
  });
  return dispatch => {
    return respondToNotification(items, ['CancellationRequest'], 'accept')
      .then(() => dispatch({ type: types.UPDATE_ITEM_STATUS_SUCCESS, totalItems: items.length }))
      .catch(failures => dispatch({ type: types.UPDATE_ITEM_STATUS_FAILURE, failures, totalItems: items.length }));
  };
}

function rejectCancellation(items, fulfillmentGroups) {
  items.forEach(item => {
    const groupIndex = fulfillmentGroups.findIndex(
      fulfillmentGroup => fulfillmentGroup.fulfillmentGroupId === item.fulfillmentGroupId
    );
    item.requestBody = { reason: fulfillmentGroups[groupIndex].additionalData.rejectionReason };
  });
  return dispatch => {
    return respondToNotification(items, ['CancellationRequest'], 'reject')
      .then(() => dispatch({ type: types.UPDATE_ITEM_STATUS_SUCCESS, totalItems: items.length }))
      .catch(failures => dispatch({ type: types.UPDATE_ITEM_STATUS_FAILURE, failures, totalItems: items.length }));
  };
}

function confirmShipment(fulfillmentGroups) {
  return dispatch => {
    const failures = [];
    return Promise.all(
      fulfillmentGroups.map(fulfillmentGroup => {
        const json = {};
        const items = [];
        fulfillmentGroup.items.forEach(itm => {
          items.push({ itemId: itm.shortItemId, quantity: itm.quantity });
        });
        Object.keys(fulfillmentGroup.additionalData).forEach(key => {
          json[key] = fulfillmentGroup.additionalData[key];
        });
        json.items = items;
        return fulfillmentOrderManager.postShipment(json).then(result => {
          if (!result.ok) {
            failures.push({ fulfillmentGroupId: fulfillmentGroup.fulfillmentGroupId, status: result.status });
          }
          return result;
        });
      })
    )
      .then(() => {
        if (failures.length) {
          throw failures;
        }
      })
      .then(() =>
        dispatch({ type: types.UPDATE_GROUP_STATUS_SUCCESS, totalFulfillmentGroups: fulfillmentGroups.length })
      )
      .catch(failures =>
        dispatch({
          type: types.UPDATE_GROUP_STATUS_FAILURE,
          failures,
          totalFulfillmentGroups: fulfillmentGroups.length,
        })
      );
  };
}
