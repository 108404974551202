import { callFetch } from './serviceHelpers';

/*
  fields: string[]
  returns queryParams generated from fields
  example: Given fields: ['a', 'b'], return "fields=a&fields=b"

*/
const getFieldsAsQueryParams = fields => {
  let fieldsInQueryParamFormat = fields.map(field => `fields=${field}`);
  return fieldsInQueryParamFormat.join('&');
};

/*
  clientId: string
  fields: string[]
*/
export const getClientById = (clientId, fields) => {
  let route = `v1/clients/${clientId}?get_client_secret=false`;
  if (fields) {
    route = `${route}&${getFieldsAsQueryParams(fields)}`;
  }

  return callFetch(process.env.REACT_APP_AUTH0_FACADE_URL, 'GET', route).catch(err => {
    console.warn(err);
    return {};
  });
};
