import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { info } from '@cimpress/react-components/lib/colors';
import IconQuestionCircle from '@cimpress-technology/react-streamline-icons/lib/IconQuestionCircle';

import { HISTORICAL_SHIPMENT_UPDATE } from '../../constants/carriers';

const TrackingLink = ({ trackingId, trackingUrl }) => {
  if (trackingId === HISTORICAL_SHIPMENT_UPDATE) {
    return (
      <div style={{ display: 'flex' }}>
        <span style={{ marginRight: '5px' }}>
          <FormattedMessage id="Global.NotApplicable" />
        </span>
        <Tooltip direction="left" contents={<FormattedMessage id="ShippingMessage.ManualShipmentUpdate" />}>
          <IconQuestionCircle weight="fill" color={info.base} />
        </Tooltip>
      </div>
    );
  }

  if (trackingUrl) {
    // if it is an actual url, show it as a link
    if (trackingUrl.substring(0, 4) === 'http') {
      return (
        <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
          {trackingId} <i className="fa fa-sm fa-external-link" />
        </a>
      );
    }
    // otherwise just show it as text
    return <div>{`${trackingId} - ${trackingUrl}`}</div>;
  }

  return <div>{trackingId || <FormattedMessage id="Events.UnknownTrackingId" />}</div>;
};

TrackingLink.propTypes = {
  trackingId: PropTypes.string,
  trackingUrl: PropTypes.string,
};

export default TrackingLink;
