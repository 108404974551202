import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initShipmentStatuses } from '../../constants/shipmentStatuses';
import { SHIPPED } from '../../constants/statustypes';
import { CURRENT } from '../../constants/itemservicestatusstates';
import get from 'lodash/get';
import first from 'lodash/first';
import { itemStatusShape, computedStatusShape } from '../propTypes';

class ShipmentStatus extends PureComponent {
  static propTypes = {
    currentStatus: computedStatusShape.isRequired,
    statuses: PropTypes.arrayOf(itemStatusShape).isRequired,
    intl: PropTypes.object,
  };

  static defaultProps = {
    statuses: [],
    currentStatus: {},
  };

  render() {
    const {
      currentStatus: { type },
      statuses,
      intl,
    } = this.props;

    const { shipmentStatuses } = initShipmentStatuses(intl);

    /**
     * We get our active trackingStatuses by filtering all statuses by the state being current and checking that it's a valid
     * shipmentStatus. We then map each existing status with the shipmentStatus data and sort the result in ascending priority.
     * Our currentShipmentStatus will always be the first of these sorted tracking statuses.
     */

    const trackingStatuses = statuses
      .filter(({ name, state }) => state === CURRENT && Boolean(get(shipmentStatuses, name)))
      .map(status => ({ ...status, ...shipmentStatuses[status.name] }));

    const currentShipmentStatus = first(trackingStatuses.sort((a, b) => a.priority - b.priority));

    if (!currentShipmentStatus) {
      return null;
    }

    return (
      <div style={{ margin: '1rem 0' }}>
        <strong>{get(currentShipmentStatus, 'displayName')}</strong>
      </div>
    );
  }
}

export default injectIntl(ShipmentStatus);
