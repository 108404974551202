export function initSearchComplaintTypes(intl) {
  return [
    {
      value: 'complaints',
      label: intl.formatMessage({ id: 'ComplaintType.withComplaint' }),
    },
    {
      value: 'reorderComplaint',
      label: intl.formatMessage({ id: 'ComplaintType.withComplaintReorder' }),
    },
  ];
}
