module.exports = {
  FULFILLED: 'fulfilled',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  AWAITING_FULFILLER_RESPONSE: 'awaitingFulfillerResponse',
  PRODUCTION_ACCEPTED: 'productionAccepted',
  IN_PRODUCTION: 'inProduction',
  PRODUCTION: 'production', // & 'awaitingFulfillerResponse' & 'proudctionAccepted'
  PREPARING: 'preparing',
  INVENTORY_CHECK: 'inventoryCheck',
  DOCUMENT_REVIEW: 'documentReview',
  CANCELLATION_REJECTED: 'cancellationRejected',
  PENDING_CANCELLATION: 'pendingCancellation',
  FULFILLMENT: 'fulfillment',
  INVENTORY_CHECK_REJECTED: 'inventoryCheckRejected',
  DOCUMENT_REVIEW_REJECTED: 'documentReviewRejected',
  DOCUMENT_REVIEW_FEEDBACK: 'documentReviewFeedback',
  PREPRESS_FAILED: 'prepressFailed',
  PREPRESS: 'prepress',
  PLATFORM_ACCEPTED: 'platformAccepted',
  SUSPENDED: 'suspended',
  FULFILLMENT_DELAYED: 'fulfillmentDelayed',
  STANDARDIZING_ORDER: 'standardizingOrder',
  ROUTING: 'routing',
  SHIPPING_AGGREGATION: 'shippingAggregation',
  ON_TIME_TO_CUSTOMER: 'onTimeToCustomer',
  DELIVERED: 'delivered',
  DISPATCHED: 'dispatched',
  OUT_FOR_DELIVERY: 'outForDelivery',
};
