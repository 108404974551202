import React from 'react';
import PropTypes from 'prop-types';
import stringify from 'json-stringify-safe';
import Highlight from '../../shared/highlight';
import { isJsonString } from '../../../utils/utilityfunctions';

export default class AttemptFailureJson extends React.Component {
  render() {
    const { attempt } = this.props;

    let errorDescription;
    if (!attempt.errorDescription) {
      errorDescription = null;
    } else if (!isJsonString(attempt.errorDescription)) {
      errorDescription = attempt.errorDescription;
    } else {
      errorDescription = stringify(JSON.parse(attempt.errorDescription), null, 2);
    }

    return (
      <div>
        {attempt.errorDescription ? <Highlight language="json">{errorDescription}</Highlight> : null}
        {attempt.errorStackTrace ? <Highlight language="json">{attempt.errorStackTrace}</Highlight> : null}
      </div>
    );
  }
}

AttemptFailureJson.propTypes = {
  attempt: PropTypes.object,
};
