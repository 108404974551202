import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import find from 'lodash/find';

export class ColumnCheckbox extends React.Component {
  onChange = () => {
    this.props.onCheckChange(this.props.column);
  };

  render() {
    const { column, selectedColumns, state, availableColumns, intl } = this.props;
    const checked = selectedColumns && selectedColumns.includes(column);
    const availableColumn = find(availableColumns, { key: column });
    const header = availableColumn && availableColumn.headingFormatter(state);
    if (!header || !header.headerKey) {
      return null;
    }
    return (
      <div style={{ padding: '0 5px', flexGrow: '1' }}>
        <Checkbox
          label={<span>{header.title || intl.formatMessage({ id: header.headerKey })}</span>}
          checked={checked}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

ColumnCheckbox.propTypes = {
  column: PropTypes.string.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  state: PropTypes.object.isRequired,
  onCheckChange: PropTypes.func.isRequired,
  availableColumns: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, headingFormatter: PropTypes.func, valueFormatter: PropTypes.func })
  ),
  intl: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps, {})(injectIntl(ColumnCheckbox));
