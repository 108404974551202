import { CALL_API } from '../middleware/api';
import auth from '../utils/auth';
import * as types from '../constants/actiontypes.js';

export function loadGroups() {
  return dispatch => {
    if (!auth.isLoggedIn()) {
      throw new Error('Permissions cannot be loaded without authenticated user');
    }
    return dispatch({
      [CALL_API]: {
        types: [types.GET_ALL_GROUPS_REQUEST, types.GET_ALL_GROUPS_SUCCESS, types.GET_ALL_GROUPS_FAILURE],
        endpoint: `https://api.cimpress.io/auth/access-management/v1/principals/${encodeURIComponent(
          auth.getProfile().sub
        )}/groups`,
      },
    });
  };
}
