import isString from 'lodash/isString';

export const isFromPrepress = statusDetails =>
  Boolean(isString(statusDetails) && statusDetails.startsWith('Drawing Failed'));

const errSlices = {
  minSpotErr: 'Prepress failed: The minimum number of spot colors',
  maxSpotErr: 'Prepress failed: The maximum number of spot colors',
  invSpotErr: 'Prepress failed: Spot color found when only process colors are allowed.',
  noPriceErr: 'productprices due to Pricing price is missing',
  fulfillerErr: 'Missing Fulfiller Requirements and/or fulfiller does not support features',
  genPrepressErr: 'Prepress failed: One or more errors occurred.',
  doNotShipErr: 'donotship due to donotShip option is not supported by fulfiller',
  reportGenErr: 'Prepress failed: Error generating report:',
  fivexxErr: '5xx error while getting resource',
  docTimeoutErr: 'The remote server returned an error: (500) Internal Server Error.',
  prepressFileErr: 'Prepress failed: Could not download the file',
  surfaceSpecErr: 'Document is invalid: Unable to retrieve surface specifications for SKU',
  SKUSetupErr: '["No recommendations for the given fulfillment location"]',
  quoterFileErr: '["No recommendations could be found based on the supplied criteria."]',
  infeasibleAddErr: '["Infeasible address. None of the available carrier services can ship to given destination"]',
  shipmentCatchallErr: 'Missing and/or does not support features by fulfiller: shipmentplanning due to {',
  prepressTimeoutErr: 'Prepress failed: The remote server returned an error: (504) Gateway Timeout.',
  prepressFailErr: 'Prepress failed: There was an error for an asyncronous call.',
};

export const findHint = (statusDetails, intl) => {
  if (!isString(statusDetails)) {
    return statusDetails;
  }

  let statusHint = '';

  if (
    statusDetails.startsWith(errSlices.minSpotErr) ||
    statusDetails.startsWith(errSlices.maxSpotErr) ||
    statusDetails === errSlices.invSpotErr
  ) {
    intl.formatMessage({ id: 'ErrorHints.SpotErr' });
  } else if (statusDetails.endsWith(errSlices.noPriceErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.NoPriceErr' });
  } else if (statusDetails === errSlices.fulfillerErr) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.FulfillerErr' });
  } else if (statusDetails === errSlices.genPrepressErr) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.GenPrepressErr' });
  } else if (statusDetails.endsWith(errSlices.doNotShipErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.DoNotShipErr' });
  } else if (statusDetails.startsWith(errSlices.reportGenErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.ReportGenErr' });
  } else if (statusDetails.startsWith(errSlices.fivexxErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.FivexxErr' });
  } else if (statusDetails.endsWith(errSlices.docTimeoutErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.DocTimeoutErr' });
  } else if (statusDetails.startsWith(errSlices.prepressFileErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.PrepressFileErr' });
  } else if (statusDetails.startsWith(errSlices.surfaceSpecErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.SurfaceSpecErr' });
  } else if (statusDetails.endsWith(errSlices.SKUSetupErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.SKUSetupErr' });
  } else if (statusDetails.endsWith(errSlices.quoterFileErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.QuoterFileErr' });
  } else if (
    statusDetails.endsWith(errSlices.infeasibleAddErr) ||
    statusDetails.startsWith(errSlices.shipmentCatchallErr)
  ) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.ShipmentCatchallErr' });
  } else if (statusDetails === errSlices.prepressTimeoutErr) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.PrepressTimeoutErr' });
  } else if (statusDetails.startsWith(errSlices.prepressFailErr)) {
    statusHint = intl.formatMessage({ id: 'ErrorHints.PrepressFailErr' });
  }

  return statusHint;
};
