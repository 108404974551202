import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@cimpress/react-components/lib/Checkbox';

export default class SubscriptionCheckbox extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  toggle = () => this.props.onToggle(this.props.value);

  render() {
    const { label, checked } = this.props;
    return <Checkbox label={label} checked={checked} onChange={this.toggle} />;
  }
}
