import { callFetch } from './serviceHelpers';

const BASE_URL = process.env.REACT_APP_CUSTOMIZR_SERVICE;

const getEnvPrefix = () => {
  return process.env.REACT_APP_CUSTOMIZR_ENV_PREFIX ? `${process.env.REACT_APP_CUSTOMIZR_ENV_PREFIX}-` : '';
};

export const getSetting = settingKey =>
  callFetch(BASE_URL, 'GET', `v1/resources/${getEnvPrefix()}${settingKey}/settings`);

export const putSetting = (settingKey, body) =>
  callFetch(BASE_URL, 'PUT', `v1/resources/${getEnvPrefix()}${settingKey}/settings`, JSON.stringify(body));
