import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import englishStrings from 'react-timeago/lib/language-strings/en';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import germanStrings from 'react-timeago/lib/language-strings/de';
import dutchStrings from 'react-timeago/lib/language-strings/nl';
import italianStrings from 'react-timeago/lib/language-strings/it';

const FORMATTERS = {
  en: buildFormatter(englishStrings),
  fr: buildFormatter(frenchStrings),
  de: buildFormatter(germanStrings),
  nl: buildFormatter(dutchStrings),
  it: buildFormatter(italianStrings),
};

export const getFormatter = language => {
  return FORMATTERS[language] || FORMATTERS['en'];
};
