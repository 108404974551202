import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal } from '@cimpress/react-components';

import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../analytics';

const AttributesModal = ({ title, showAttributeClass, showAttributeSource, attributes, children }) => {
  const [showModal, setShowModal] = useState(false);

  const close = () => {
    setShowModal(false);
  };

  const open = () => {
    setShowModal(true);

    trackEvent(TrackingEventTitles.ATTRIBUTES_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ATTRIBUTE_MODAL_TYPE]: title,
    });
  };

  const buildRow = (attribute, idx) => {
    return (
      <tr key={`${attribute.name}-${idx}`}>
        <td>
          <strong>{attribute.name}</strong>
        </td>
        <td style={{ wordBreak: 'break-all' }}>{attribute.value}</td>
        {showAttributeSource ? <td>{attribute.source}</td> : null}
        {showAttributeClass ? <td>{attribute.attributeClass}</td> : null}
      </tr>
    );
  };

  const tableRows = attributes.map((attr, idx) => buildRow(attr, idx));

  return (
    <div>
      <a className="clickable" onClick={open}>
        {children}
      </a>
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={title}
        footer={
          <button className="btn btn-default" onClick={close}>
            <FormattedMessage id="Global.Close" />
          </button>
        }>
        <div>
          <table className="table" style={{ margin: 0 }}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Attributes.Name" />
                </th>
                <th>
                  <FormattedMessage id="Attributes.Value" />
                </th>
                {showAttributeSource ? (
                  <th>
                    <FormattedMessage id="Attributes.Source" />
                  </th>
                ) : null}
                {showAttributeClass ? (
                  <th>
                    <FormattedMessage id="Attributes.Class" />
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

AttributesModal.propTypes = {
  attributes: PropTypes.array.isRequired,
  showAttributeClass: PropTypes.bool,
  showAttributeSource: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AttributesModal;
