import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { colors } from '@cimpress/react-components';

const claimColor = '#e91e63';
const reorderColor = '#8bc34a';
const quoteColor = '#B567F1';
const redirectColor = colors.teal.base;
const changeRequestColor = colors.info.darkest;

const Label = ({ shouldDisplay, color, id }) =>
  shouldDisplay ? (
    <span className="ghost label label-info small" style={{ color, borderColor: color, margin: '2px' }}>
      <FormattedMessage id={id} />
    </span>
  ) : null;

const RelatedResourceLabels = ({
  hasClaim,
  hasComplaint,
  isClaimReorder,
  isComplaintReorder,
  isCustomQuote,
  wasRedirected,
  isRedirectedOrder,
  hasChangeRequestReorder,
  isChangeRequestReorder,
  isPreferredOrder,
}) => {
  return (
    <span>
      <Label id="Claims.Claim" shouldDisplay={hasClaim} color={claimColor} />
      <Label id="Claims.Complaint" shouldDisplay={hasComplaint} color={claimColor} />
      <Label id="Claims.ClaimReorder" shouldDisplay={isClaimReorder} color={reorderColor} />
      <Label id="Claims.ComplaintReorder" shouldDisplay={isComplaintReorder} color={reorderColor} />
      <Label id="Quotes.CustomQuote" shouldDisplay={isCustomQuote} color={quoteColor} />
      <Label id="RequestRedirect.Redirect" shouldDisplay={wasRedirected} color={redirectColor} />
      <Label id="RequestRedirect.RedirectedFromOrder" shouldDisplay={isRedirectedOrder} color={redirectColor} />
      <Label id="ChangeRequest.ChangeRequest" shouldDisplay={hasChangeRequestReorder} color={changeRequestColor} />
      <Label id="ChangeRequest.Reorder" shouldDisplay={isChangeRequestReorder} color={changeRequestColor} />
      <Label id="ChangeRequest.PreferredOrder" shouldDisplay={isPreferredOrder} color={changeRequestColor} />
    </span>
  );
};

RelatedResourceLabels.propTypes = {
  hasClaim: PropTypes.bool,
  hasComplaint: PropTypes.bool,
  isClaimReorder: PropTypes.bool,
  isComplaintReorder: PropTypes.bool,
  isCustomQuote: PropTypes.bool,
};

export default injectIntl(RelatedResourceLabels);
