import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '@cimpress/react-components/lib/Modal';
import Button from '@cimpress/react-components/lib/Button';
import SubscriptionCheckbox from '../subscriptions/SubscriptionCheckbox';
import { isSubscriptionEqual, areResourceFiltersEqual } from '../subscriptions/utils';
import { OPA_ORDER } from '../../constants/notifications';

const subscriptionTypes = {
  productionAccepted: 'WatchOrder.Types.ProductionAccepted',
  itemRejected: 'WatchOrder.Types.FulfillerRejected',
  itemShipped: 'WatchOrder.Types.Fulfillment',
  cancellationResponse: 'WatchOrder.Types.CancellationResponse',
  nothingCancelled: 'WatchOrder.Types.NothingCancelled',
};

const generateResourceFilters = orderId => ({
  productionAccepted: [
    { path: '/resource/orderId', value: orderId, operator: 'equals' },
    { path: '/resourceChange/eventType', value: 'productionAccepted', operator: 'equals' },
  ],
  itemRejected: [
    { path: '/resource/orderId', value: orderId, operator: 'equals' },
    { path: '/resourceChange/eventType', value: 'fulfillerRejected', operator: 'equals' },
  ],
  itemShipped: [
    { path: '/resource/orderId', value: orderId, operator: 'equals' },
    { path: '/resourceChange/eventType', value: 'fulfillment', operator: 'equals' },
  ],
  cancellationResponse: [
    { path: '/resource/orderId', value: orderId, operator: 'equals' },
    { path: '/resourceChange/eventType', value: 'cancellationResponse', operator: 'equals' },
  ],
  nothingCancelled: [
    { path: '/resource/orderId', value: orderId, operator: 'equals' },
    { path: '/resourceChange/eventType', value: 'nothingCancelled', operator: 'equals' },
  ],
});

class OrderSubscriptionSelector extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    relevantSubscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onUpdate: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object,
  };

  state = { selectedSubscriptions: [] };

  updateState = () => {
    const { relevantSubscriptions, orderId } = this.props;
    const resourceFilters = generateResourceFilters(orderId);

    this.setState({
      selectedSubscriptions: Object.keys(subscriptionTypes).filter(type =>
        relevantSubscriptions.some(subscription =>
          areResourceFiltersEqual(resourceFilters[type], subscription.resourceFilters)
        )
      ),
    });
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.relevantSubscriptions !== this.props.relevantSubscriptions || prevProps.show !== this.props.show) {
      this.updateState();
    }
  }

  onCheckboxChange = key => {
    let selectedSubscriptions = [...this.state.selectedSubscriptions];
    if (selectedSubscriptions.includes(key)) {
      selectedSubscriptions = selectedSubscriptions.filter(value => value !== key);
    } else {
      selectedSubscriptions.push(key);
    }
    this.setState({ selectedSubscriptions });
  };

  onSave = () => {
    const { onUpdate, relevantSubscriptions, orderId } = this.props;
    const { selectedSubscriptions } = this.state;

    const resourceFilters = generateResourceFilters(orderId);

    const newSubscriptions = selectedSubscriptions.map(subscriptionKey => ({
      resourceId: orderId,
      resourceFilters: resourceFilters[subscriptionKey],
      resourceType: OPA_ORDER,
      alertInfo: {
        channel: 'email',
      },
    }));

    let updatedSubscriptions = relevantSubscriptions.filter(subscription =>
      newSubscriptions.some(newSubscription => isSubscriptionEqual(subscription, newSubscription))
    );

    updatedSubscriptions = updatedSubscriptions.concat(
      newSubscriptions.filter(
        newSubscription =>
          !updatedSubscriptions.some(updatedSubscription => isSubscriptionEqual(newSubscription, updatedSubscription))
      )
    );

    onUpdate(updatedSubscriptions);
  };

  render() {
    const { onClose, show, intl } = this.props;
    const { selectedSubscriptions } = this.state;
    return (
      <Modal
        show={show}
        onRequestHide={onClose}
        closeOnOutsideClick
        closeButton
        title={intl.formatMessage({ id: 'WatchOrder.ModalTitle' })}
        footer={
          <Fragment>
            <Button onClick={onClose}>
              <FormattedMessage id="Global.Cancel" />
            </Button>
            <Button variant="primary" onClick={this.onSave}>
              <FormattedMessage id="Global.Save" />
            </Button>
          </Fragment>
        }>
        <FormattedMessage id="WatchOrder.ModalInfoMessage" />
        {Object.keys(subscriptionTypes).map(key => (
          <SubscriptionCheckbox
            key={key}
            value={key}
            label={intl.formatMessage({ id: subscriptionTypes[key] })}
            checked={selectedSubscriptions.includes(key)}
            onToggle={this.onCheckboxChange}
          />
        ))}
      </Modal>
    );
  }
}

export default injectIntl(OrderSubscriptionSelector);
