import React from 'react';
import PropTypes from 'prop-types';
import ColumnDropdown from '../shared/columns/columnDropdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { availableSearchColumns } from '../../constants/searchColumns';
import { setSearchColumns, resetSearchColumns } from '../../actions/searchColumnActions';

export class SearchColumnDropdown extends React.Component {
  onSaveDefault = selectedColumns => {
    this.props.setSearchColumns(selectedColumns);
  };

  onResetDefault = () => {
    this.props.resetSearchColumns();
  };

  render() {
    const { searchColumns } = this.props;
    return (
      <ColumnDropdown
        selectedColumns={searchColumns}
        saveColumns={this.onSaveDefault}
        resetColumns={this.onResetDefault}
        availableColumns={availableSearchColumns}
        gaCategory="Order Search"
      />
    );
  }
}

SearchColumnDropdown.propTypes = {
  searchColumns: PropTypes.arrayOf(PropTypes.string),
  setSearchColumns: PropTypes.func,
  resetSearchColumns: PropTypes.func,
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
};

function mapStateToProps(state) {
  const {
    searchColumns: { searchColumns },
  } = state;

  return {
    searchColumns,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSearchColumns, resetSearchColumns }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchColumnDropdown);
