import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectWrapper from '@cimpress/react-components/lib/SelectWrapper';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import Select from 'react-select-plus';
import { setSelectedSavedSearch, getSearches } from '../../actions/savedSearchActions';
import EditSavedSearches from './editSavedSearches';
import {
  setMerchants,
  setFulfillers,
  setStatuses,
  setDelayedStatuses,
  setErrorStatuses,
  setOrderTypes,
  setSalesChannels,
  setProductCategories,
  setClaimTypes,
  setComplaintTypes,
  setChangeRequestTypes,
  setSearchTerm,
} from '../../actions/ordersearchactions';
import { initRecommendedSearches, SAVED_SEARCH, RECOMMENDED_SEARCH } from '../../constants/recommendedSearches';

class SavedSearchSelect extends Component {
  static propTypes = {
    selectedSavedSearch: PropTypes.shape({ name: PropTypes.string.isRequired }),
    savedSearches: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    setSelectedSavedSearch: PropTypes.func.isRequired,
    setMerchants: PropTypes.func.isRequired,
    setFulfillers: PropTypes.func.isRequired,
    setStatuses: PropTypes.func.isRequired,
    setErrorStatuses: PropTypes.func.isRequired,
    setDelayedStatuses: PropTypes.func.isRequired,
    setOrderTypes: PropTypes.func.isRequired,
    setSalesChannels: PropTypes.func.isRequired,
    setProductCategories: PropTypes.func.isRequired,
    setClaimTypes: PropTypes.func.isRequired,
    setComplaintTypes: PropTypes.func.isRequired,
    setChangeRequestTypes: PropTypes.func.isRequired,
    setSearchTerm: PropTypes.func.isRequired,
    getSearches: PropTypes.func.isRequired,
    savedSearchInitialized: PropTypes.bool.isRequired,
    onSavedSearchChange: PropTypes.func.isRequired,
    intl: PropTypes.object,
  };

  static defaultProps = {
    savedSearches: [],
  };

  state = { showEdit: false };

  componentDidMount = () => {
    if (!this.props.savedSearchInitialized) {
      this.props.getSearches();
    }
  };

  onSavedSearchChange = item => {
    const { recommendedSearches } = initRecommendedSearches(this.props.intl);
    const group = item && item.group && item.group.value;
    const searchList = group === RECOMMENDED_SEARCH ? recommendedSearches : this.props.savedSearches;
    const value = item && item.value;
    const savedSearch = searchList.find(search => search.name === value);

    this.props.onSavedSearchChange(savedSearch);
  };

  onEditClick = () => {
    this.setState({ showEdit: true });
  };

  onCloseEdit = () => {
    this.setState({ showEdit: false });
  };

  render() {
    const { selectedSavedSearch, savedSearches, intl } = this.props;
    const { showEdit } = this.state;
    const { recommendedSearches } = initRecommendedSearches(intl);
    const selectProps = {};
    if (selectedSavedSearch) {
      selectProps.value = selectedSavedSearch.name;
    }

    return (
      <Fragment>
        <SelectWrapper
          selectedSelect={Select}
          label={intl.formatMessage({ id: 'SaveSearch.SelectLabel' })}
          options={[
            {
              label: intl.formatMessage({ id: 'SaveSearch.SavedSearches' }),
              value: SAVED_SEARCH,
              options: savedSearches
                .filter(search => search !== null)
                .map(search => ({ value: search.name, label: search.name })),
            },
            {
              label: intl.formatMessage({ id: 'RecommendedSearches.RecommendedSearches' }),
              value: RECOMMENDED_SEARCH,
              options: recommendedSearches.map(search => ({ value: search.name, label: search.name })),
            },
          ]}
          onChange={this.onSavedSearchChange}
          {...selectProps}
        />
        <div>
          {savedSearches.length ? (
            <a className="clickable" onClick={this.onEditClick}>
              <FormattedMessage id="SaveSearch.EditLink" />
            </a>
          ) : (
            <Tooltip contents={<FormattedMessage id="SaveSearch.NoSavedSearchesTooltip" />}>
              <a className="text-muted" style={{ pointerEvents: 'none' }}>
                <FormattedMessage id="SaveSearch.EditLink" />
              </a>
            </Tooltip>
          )}
          <EditSavedSearches showEdit={showEdit} onClose={this.onCloseEdit} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    savedSearches: { savedSearches, initialized },
  } = state;
  return { savedSearches, savedSearchInitialized: initialized };
};

export default connect(mapStateToProps, {
  setSelectedSavedSearch,
  setMerchants,
  setFulfillers,
  setStatuses,
  setDelayedStatuses,
  setErrorStatuses,
  setOrderTypes,
  setSalesChannels,
  setProductCategories,
  setClaimTypes,
  setComplaintTypes,
  setChangeRequestTypes,
  setSearchTerm,
  getSearches,
})(injectIntl(SavedSearchSelect));
