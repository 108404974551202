import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  setMerchants,
  setFulfillers,
  setStatuses,
  setDelayedStatuses,
  setErrorStatuses,
  setOrderTypes,
  setSalesChannels,
  setProductCategories,
  setClaimTypes,
  setComplaintTypes,
  setChangeRequestTypes,
  setSearchTerm,
  setStartDate,
  setEndDate,
  setSort,
} from '../../actions/ordersearchactions';
import { setSearches, setSelectedSavedSearch } from '../../actions/savedSearchActions';
import { connect } from 'react-redux';
import FilterDrawer from './filterdrawer';
import concat from 'lodash/concat';
import some from 'lodash/some';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import { initSearchStatuses } from '../../constants/searchStatuses';
import { initSearchErrorStatuses } from '../../constants/searchErrorStatuses';
import { initSearchDelayedStatuses } from '../../constants/searchDelayedStatuses';
import { initSearchOrderTypes } from '../../constants/searchordertypes';
import { initSearchClaimTypes } from '../../constants/searchClaimTypes';
import { initSearchComplaintTypes } from '../../constants/searchComplaintTypes';
import { initSearchChangeRequestTypes } from '../../constants/searchChangeRequestTypes';
import { initialFilterState } from '../../constants/searchFilters';
import { getPropsFromQuery } from '../../utils/utilityfunctions';
import { withRouter } from 'react-router-dom';

const initialState = {
  ...initialFilterState,
  startDate: null,
  endDate: null,
  selectedSavedSearch: null,
};

class FilterDrawerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.merchants, this.props.merchants) ||
      !isEqual(prevProps.fulfillers, this.props.fulfillers) ||
      !isEqual(prevProps.statuses, this.props.statuses) ||
      !isEqual(prevProps.delayedStatuses, this.props.delayedStatuses) ||
      !isEqual(prevProps.errorStatuses, this.props.errorStatuses) ||
      !isEqual(prevProps.orderTypes, this.props.orderTypes) ||
      !isEqual(prevProps.salesChannels, this.props.salesChannels) ||
      !isEqual(prevProps.productCategories, this.props.productCategories) ||
      !isEqual(prevProps.claimTypes, this.props.claimTypes) ||
      !isEqual(prevProps.complaintTypes, this.props.complaintTypes) ||
      !isEqual(prevProps.changeRequestTypes, this.props.changeRequestTypes) ||
      !isEqual(prevProps.searchTerm, this.props.searchTerm) ||
      !isEqual(prevProps.startDate, this.props.startDate) ||
      !isEqual(prevProps.endDate, this.props.endDate) ||
      !isEqual(prevProps.sortField, this.props.sortField) ||
      !isEqual(prevProps.sortOrder, this.props.sortOrder)
    ) {
      this.setState({
        selectedMerchants: this.props.merchants,
        selectedFulfillers: this.props.fulfillers,
        selectedStatuses: this.props.statuses,
        selectedDelayedStatuses: this.props.delayedStatuses,
        selectedErrorStatuses: this.props.errorStatuses,
        selectedOrderTypes: this.props.orderTypes,
        selectedSalesChannels: this.props.salesChannels,
        selectedProductCategories: this.props.productCategories,
        selectedClaimTypes: this.props.claimTypes,
        selectedComplaintTypes: this.props.complaintTypes,
        selectedChangeRequestTypes: this.props.changeRequestTypes,
        searchTerm: this.props.searchTerm,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        sortField: this.props.sortField,
        sortOrder: this.props.sortOrder,
      });
    }
  }

  componentDidMount() {
    // if (this.props.history.location) {
    const { statuses } = getPropsFromQuery(this.props.history.location.query, this.props.intl);

    this.setState({
      selectedMerchants: this.props.merchants,
      selectedFulfillers: this.props.fulfillers,
      selectedStatuses: Array.isArray(this.props.statuses)
        ? Array.isArray(statuses)
          ? [...this.props.statuses, ...statuses]
          : [...this.props.statuses, statuses]
        : [this.props.statuses, statuses],
      selectedDelayedStatuses: this.props.delayedStatuses,
      selectedErrorStatuses: this.props.errorStatuses,
      selectedOrderTypes: this.props.orderTypes,
      selectedSalesChannels: this.props.salesChannels,
      selectedProductCategories: this.props.productCategories,
      selectedClaimTypes: this.props.claimTypes,
      selectedComplaintTypes: this.props.complaintTypes,
      selectedChangeRequestTypes: this.props.changeRequestTypes,
      searchTerm: this.props.searchTerm,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      sortField: this.props.sortField,
      sortOrder: this.props.sortOrder,
    });
  }

  clearAll = () => {
    this.setState({ ...initialState });
  };

  change = (item, category) => {
    let tmpState = {};
    let selectedState = this.state[category];

    // ECOM-3216 Item Sub-statuses
    if (Array.isArray(item)) {
      // Avoid Confusing Name
      let items = item;

      // lookup values
      const searchStatuses = initSearchStatuses(this.props.intl);
      // normalize items
      items = normalizeItems(items, searchStatuses);

      const prefixedItems = items.map(i => {
        // if this item exists in searchStatuses with prefix "production."
        if (searchStatuses.find(s => s.value === 'production.' + i.value)) {
          return searchStatuses.find(s => s.value === 'production.' + i.value);
        } else {
          return i;
        }
      });
      // items at this point includes previously selected items
      this.setState({ [category]: prefixedItems, selectedSavedSearch: null });
      return;
    }

    // if Item in selected state, remove it, else add it
    tmpState[category] = some(selectedState, item)
      ? filter(selectedState, selectedItem => {
          return selectedItem.value !== item.value;
        })
      : concat(selectedState, item);

    this.setState({ ...tmpState, selectedSavedSearch: null });
  };

  removeFilter = (reference, value) => {
    let tmpState = {};
    tmpState[reference] = filter(this.state[reference], item => {
      return item.label !== value;
    });
    this.setState({ ...tmpState, selectedSavedSearch: null });
  };

  onSearchTermChanged = e => {
    this.setState({ searchTerm: e.target.value });
    this.setState({ selectedSavedSearch: null });
  };

  onStartDateChanged = date => {
    this.setState({ startDate: date ? date.utc() : null });
  };

  onEndDateChanged = date => {
    this.setState({ endDate: date ? date.utc() : null });
  };

  onSortChanged = (sortField, sortOrder) => {
    this.setState({ sortField, sortOrder });
  };

  onSaveFilters = filterName => {
    const savedSearches = [...this.props.savedSearches];
    const newSearch = {
      name: filterName,
      filters: {
        selectedMerchants: this.state.selectedMerchants,
        selectedFulfillers: this.state.selectedFulfillers,
        selectedStatuses: this.state.selectedStatuses,
        selectedDelayedStatuses: this.state.selectedDelayedStatuses,
        selectedErrorStatuses: this.state.selectedErrorStatuses,
        selectedOrderTypes: this.state.selectedOrderTypes,
        selectedSalesChannels: this.state.selectedSalesChannels,
        selectedProductCategories: this.state.selectedProductCategories,
        selectedClaimTypes: this.state.selectedClaimTypes,
        selectedComplaintTypes: this.state.selectedComplaintTypes,
        selectedChangeRequestTypes: this.state.selectedChangeRequestTypes,
        searchTerm: this.state.searchTerm || '',
        sortField: this.state.sortField,
        sortOrder: this.state.sortOrder,
      },
    };
    const currentSearchIndex = savedSearches.findIndex(search => search && search.name === filterName);
    if (currentSearchIndex >= 0) {
      savedSearches[currentSearchIndex] = newSearch;
    } else {
      savedSearches.push(newSearch);
    }
    return this.props.setSearches(savedSearches).then(() => {
      this.setState({ ...newSearch.filters });
    });
  };

  onSavedSearchChange = savedSearch => {
    this.setState({ ...initialFilterState, ...((savedSearch && savedSearch.filters) || {}) });
    this.setState({ selectedSavedSearch: savedSearch });
  };

  updateFilters = () => {
    let updates = [
      this.props.setMerchants(this.state.selectedMerchants),
      this.props.setFulfillers(this.state.selectedFulfillers),
      this.props.setStatuses(this.state.selectedStatuses.filter(s => s.value !== 'inProduction')), // ECOM-3216 - Item Sub-Statuses, avoid 'inProduction' in query string
      this.props.setDelayedStatuses(this.state.selectedDelayedStatuses),
      this.props.setErrorStatuses(this.state.selectedErrorStatuses),
      this.props.setOrderTypes(this.state.selectedOrderTypes),
      this.props.setSalesChannels(this.state.selectedSalesChannels),
      this.props.setProductCategories(this.state.selectedProductCategories),
      this.props.setClaimTypes(this.state.selectedClaimTypes),
      this.props.setComplaintTypes(this.state.selectedComplaintTypes),
      this.props.setChangeRequestTypes(this.state.selectedChangeRequestTypes),
      this.props.setSearchTerm(this.state.searchTerm),
      this.props.setStartDate(this.state.startDate, true),
      this.props.setEndDate(this.state.endDate, true),
      this.props.setSort(this.state.sortField, this.state.sortOrder),
    ];

    return Promise.all(updates).then(() => {
      return this.props.onFiltersUpdated();
    });
  };

  render() {
    const {
      availableMerchants,
      availableFulfillers,
      availableSalesChannels,
      availableProductCategories,
      availableProductCategoriesTree,
      loadingAvailableFilters,
      availableFiltersError,
      intl,
    } = this.props;
    const searchClaimTypes = initSearchClaimTypes(intl);
    const searchComplaintTypes = initSearchComplaintTypes(intl);
    const searchChangeRequestTypes = initSearchChangeRequestTypes(intl);
    const searchDelayedStatuses = initSearchDelayedStatuses(intl);
    const searchErrorStatuses = initSearchErrorStatuses(intl);
    const searchOrderTypes = initSearchOrderTypes(intl);
    const searchStatuses = initSearchStatuses(intl);
    const showMerchants = Boolean(availableMerchants && availableMerchants.length > 1);
    const showFulfillers = Boolean(availableFulfillers && availableFulfillers.length > 1);
    const showSalesChannels = Boolean(availableSalesChannels && availableSalesChannels.length > 1);
    const showProductCategories = Boolean(availableProductCategories && availableProductCategories.length > 1);

    const filters = [
      {
        name: intl.formatMessage({ id: 'Filters.Merchants' }),
        availableValues: availableMerchants,
        selectedValues: this.state.selectedMerchants,
        selectedValuesKey: 'selectedMerchants',
        isLoading: loadingAvailableFilters,
        hasError: availableFiltersError,
        hide: !showMerchants,
      },
      {
        name: intl.formatMessage({ id: 'Filters.Fulfillers' }),
        availableValues: availableFulfillers,
        selectedValues: this.state.selectedFulfillers,
        selectedValuesKey: 'selectedFulfillers',
        isLoading: loadingAvailableFilters,
        hasError: availableFiltersError,
        hide: !showFulfillers,
      },
      {
        name: intl.formatMessage({ id: 'Filters.SalesChannels' }),
        availableValues: availableSalesChannels,
        selectedValues: this.state.selectedSalesChannels,
        selectedValuesKey: 'selectedSalesChannels',
        isLoading: loadingAvailableFilters,
        hasError: availableFiltersError,
        hide: !showSalesChannels,
      },
      {
        name: intl.formatMessage({ id: 'Filters.ProductCategories' }),
        availableValues: availableProductCategories,
        selectTree: availableProductCategoriesTree,
        selectedValues: this.state.selectedProductCategories,
        selectedValuesKey: 'selectedProductCategories',
        isLoading: loadingAvailableFilters,
        hasError: availableFiltersError,
        hide: !showProductCategories,
      },
      {
        name: intl.formatMessage({ id: 'Filters.ItemStatus' }),
        availableValues: searchStatuses,
        selectedValues: this.state.selectedStatuses,
        selectedValuesKey: 'selectedStatuses',
      },
      {
        name: intl.formatMessage({ id: 'Filters.ErrorStatus' }),
        availableValues: searchErrorStatuses,
        selectedValues: this.state.selectedErrorStatuses,
        selectedValuesKey: 'selectedErrorStatuses',
      },
      {
        name: intl.formatMessage({ id: 'Filters.DelayedStatus' }),
        availableValues: searchDelayedStatuses,
        selectedValues: this.state.selectedDelayedStatuses,
        selectedValuesKey: 'selectedDelayedStatuses',
      },
      {
        name: intl.formatMessage({ id: 'Filters.OrderType' }),
        availableValues: searchOrderTypes,
        selectedValues: this.state.selectedOrderTypes,
        selectedValuesKey: 'selectedOrderTypes',
      },
      {
        name: intl.formatMessage({ id: 'Filters.ClaimType' }),
        availableValues: searchClaimTypes,
        selectedValues: this.state.selectedClaimTypes,
        selectedValuesKey: 'selectedClaimTypes',
      },
      {
        name: intl.formatMessage({ id: 'Filters.ComplaintType' }),
        availableValues: searchComplaintTypes,
        selectedValues: this.state.selectedComplaintTypes,
        selectedValuesKey: 'selectedComplaintTypes',
      },
      {
        name: intl.formatMessage({ id: 'Filters.ChangeRequestType' }),
        availableValues: searchChangeRequestTypes,
        selectedValues: this.state.selectedChangeRequestTypes,
        selectedValuesKey: 'selectedChangeRequestTypes',
      },
    ];
    return (
      <div>
        <FilterDrawer
          filters={filters}
          onFilterChange={this.change}
          onFilterRemove={this.removeFilter}
          onClearAll={this.clearAll}
          onUpdateFilters={this.updateFilters}
          onSaveClick={this.onSaveFilters}
          searchTerm={this.state.searchTerm}
          onSearchTermChanged={this.onSearchTermChanged}
          startDate={moment(this.state.startDate).tz(this.props.displayTimezone)}
          endDate={moment(this.state.endDate).tz(this.props.displayTimezone)}
          displayTimezone={this.props.displayTimezone}
          onStartDateChanged={this.onStartDateChanged}
          onEndDateChanged={this.onEndDateChanged}
          onSavedSearchChange={this.onSavedSearchChange}
          selectedSavedSearch={this.state.selectedSavedSearch}
          setSort={this.onSortChanged}
          sortField={this.state.sortField}
          sortOrder={this.state.sortOrder}
        />
      </div>
    );
  }
}

FilterDrawerContainer.propTypes = {
  availableMerchants: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  availableFulfillers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  availableSalesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  availableProductCategories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  availableFiltersError: PropTypes.object,
  loadingAvailableFilters: PropTypes.bool,
  salesChannels: PropTypes.arrayOf(PropTypes.object),
  productCategories: PropTypes.arrayOf(PropTypes.object),
  merchants: PropTypes.arrayOf(PropTypes.object),
  fulfillers: PropTypes.arrayOf(PropTypes.object),
  statuses: PropTypes.arrayOf(PropTypes.object),
  delayedStatuses: PropTypes.arrayOf(PropTypes.object),
  errorStatuses: PropTypes.arrayOf(PropTypes.object),
  orderTypes: PropTypes.arrayOf(PropTypes.object),
  claimTypes: PropTypes.arrayOf(PropTypes.object),
  complaintTypes: PropTypes.arrayOf(PropTypes.object),
  changeRequestTypes: PropTypes.arrayOf(PropTypes.object),
  setMerchants: PropTypes.func.isRequired,
  setFulfillers: PropTypes.func.isRequired,
  setStatuses: PropTypes.func.isRequired,
  setErrorStatuses: PropTypes.func.isRequired,
  setDelayedStatuses: PropTypes.func.isRequired,
  setOrderTypes: PropTypes.func.isRequired,
  setSalesChannels: PropTypes.func.isRequired,
  setProductCategories: PropTypes.func.isRequired,
  setClaimTypes: PropTypes.func.isRequired,
  setComplaintTypes: PropTypes.func.isRequired,
  setChangeRequestTypes: PropTypes.func.isRequired,
  onFiltersUpdated: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  displayTimezone: PropTypes.string,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  savedSearches: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
  setSearches: PropTypes.func.isRequired,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  setSort: PropTypes.func,
  intl: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    // variables are destructured off of the state object
    search: {
      merchants,
      fulfillers,
      statuses,
      errorStatuses,
      orderTypes,
      delayedStatuses,
      salesChannels,
      productCategories,
      claimTypes,
      complaintTypes,
      changeRequestTypes,
      searchTerm,
      startDate,
      endDate,
      sortField,
      sortOrder,
    },
    userPreferences: { displayTimezone },
    availableFilters: {
      filters: {
        merchants: availableMerchants,
        fulfillers: availableFulfillers,
        salesChannels: availableSalesChannels,
        productCategories: availableProductCategories,
        productCategoriesTree: availableProductCategoriesTree,
        statuses: availableStatuses,
      },
      loading: loadingAvailableFilters,
      error: availableFiltersError,
    },
    savedSearches: { savedSearches },
  } = state;

  return {
    merchants,
    fulfillers,
    statuses,
    errorStatuses,
    delayedStatuses,
    orderTypes,
    salesChannels,
    productCategories,
    claimTypes,
    complaintTypes,
    changeRequestTypes,
    availableMerchants,
    availableFulfillers,
    availableSalesChannels,
    availableProductCategories,
    availableProductCategoriesTree,
    availableStatuses,
    loadingAvailableFilters,
    availableFiltersError,
    searchTerm,
    startDate,
    endDate,
    displayTimezone,
    savedSearches,
    sortField,
    sortOrder,
  };
}

export default connect(mapStateToProps, {
  setMerchants,
  setFulfillers,
  setDelayedStatuses,
  setErrorStatuses,
  setStatuses,
  setOrderTypes,
  setSalesChannels,
  setProductCategories,
  setClaimTypes,
  setComplaintTypes,
  setChangeRequestTypes,
  setSearchTerm,
  setStartDate,
  setEndDate,
  setSearches,
  setSelectedSavedSearch,
  setSort,
})(injectIntl(withRouter(FilterDrawerContainer)));

function normalizeItems(items, searchStatuses) {
  let map = new Map();
  for (const item of items) {
    if (String(item.value).startsWith('production.')) {
      map.set(item.label, item);
    } else {
      // does not start with production
      if (map.has(item.label)) {
        // already in map
        continue;
      } else if (searchStatuses.map(s => s.label).includes(item.label)) {
        let foundStatus = searchStatuses.find(s => s.label === item.label);
        map.set(item.label, foundStatus);
      } else {
        map.set(item.label, item);
      }
    }
  }
  return Array.from(map.values());
}
