module.exports = {
  STANDARDIZING_ORDER: 'standardizingOrder',
  DOC_REVIEW: 'docReview',
  ROUTING: 'routing',
  SHIPPING_AGGREGATION: 'shippingAggregation',
  AWAITING_FULFILLER_RESPONSE: 'awaitingFulfillerResponse',
  PROCESSING: 'processing',
  IN_PRODUCTION: 'inProduction',
  PRODUCTION: 'production',
  PRODUCTION_ACCEPTED: 'productionAccepted',
  SHIPPED: 'shipped',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  SUSPENDED: 'suspended',
  UNKNOWN: 'unknown',
};
