import React, { useState, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Snackbar } from '@cimpress/react-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const MODAL_ID = {
  link: 'relatedIds1',
  dropdown: 'relatedIds2',
};

const OtherIds = ({ item, location: { search }, context = 'link', history, children }) => {
  const queryParams = new URLSearchParams(search);
  const [showModal, setShowModal] = useState(
    queryParams.get('modal') === MODAL_ID[context] && queryParams.get('itemId') === item.itemId
  );
  const [copied, setCopied] = useState(false);
  const intl = useIntl();

  const close = () => {
    queryParams.delete('modal');
    history.replace({
      search: queryParams.toString(),
    });

    setShowModal(false);
    setCopied(false);
  };

  const open = itemId => e => {
    e.stopPropagation();

    queryParams.set('itemId', itemId);
    queryParams.set('modal', MODAL_ID[context]);
    history.replace({
      search: queryParams.toString(),
    });

    trackEvent(TrackingEventTitles.ADDITIONAL_IDS_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ORDER_ID]: item.orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ITEM_ID]: item.itemId,
    });

    setShowModal(true);
  };

  const onCopy = () => {
    setCopied(true);

    trackEvent(TrackingEventTitles.ADDITIONAL_ID_COPIED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ORDER_ID]: item.orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ITEM_ID]: item.itemId,
    });
  };

  const buildIdRow = (title, value) => {
    if (value) {
      const friendlyTitle = title
        .replace('%merchantId', capitalize(item.merchantId))
        .replace('%fulfillerName', capitalize(item.fulfillerName));

      return (
        <tr key={title}>
          <td className="text-right">
            <strong>{friendlyTitle}</strong>
          </td>
          <td>
            {value}{' '}
            <CopyToClipboard text={value} onCopy={onCopy}>
              <a className="clickable pull-right">
                <i className="fa fa-clipboard" aria-hidden="true" />
              </a>
            </CopyToClipboard>
          </td>
        </tr>
      );
    }

    return null;
  };

  const buildIdTable = (heading, rowData) => {
    const tableRows = map(rowData, (value, key) => buildIdRow(key, value));

    return (
      // setting margin 0 to override style inherited from accordion
      <table className="table" style={{ margin: 0 }}>
        <thead>
          <tr>
            <th className="text-center" colSpan="2">
              <strong>{heading}</strong>
            </th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  const orderIds = buildIdTable(intl.formatMessage({ id: 'RelatedIdSections.OrderIds' }), {
    [intl.formatMessage({ id: 'OrderLevelFields.PlatformID' })]: item.orderId,
    [intl.formatMessage({ id: 'OrderLevelFields.MerchantOrderID' })]: item.merchantOrderId,
    [intl.formatMessage({ id: 'OrderLevelFields.FulfillerOrderID' })]: item.fulfillerOrderId,
  });

  const fulfillmentGroupIds = buildIdTable(intl.formatMessage({ id: 'RelatedIdSections.FulfillmentGroupIds' }), {
    [intl.formatMessage({ id: 'ItemLevelFields.FulfillmentGroupID' })]: item.fulfillmentGroupId,
    [intl.formatMessage({ id: 'ItemLevelFields.ShortFulfillmentGroupID' })]: item.shortFulfillmentGroupId,
  });

  const itemIds = buildIdTable(intl.formatMessage({ id: 'RelatedIdSections.ItemIds' }), {
    [intl.formatMessage({ id: 'ItemLevelFields.PlatformItemID' })]: item.itemId,
    [intl.formatMessage({ id: 'ItemLevelFields.ShortItemID' })]: item.shortItemId,
    [intl.formatMessage({ id: 'ItemLevelFields.MerchantItemID' })]: item.merchantItemId,
    [intl.formatMessage({ id: 'ItemLevelFields.FulfillerItemID' })]: item.fulfillerItemId,
  });

  let otherIdRows = {};
  let otherIds = null;
  if (item.documentReference) {
    otherIdRows[intl.formatMessage({ id: 'ItemLevelFields.DocumentID' })] = item.documentReference.documentId;
  }

  if (!isEmpty(otherIdRows)) {
    otherIds = buildIdTable(intl.formatMessage({ id: 'RelatedIdSections.OtherIds' }), otherIdRows);
  }

  const relatedIdsDialog = (
    <Modal
      size="lg"
      show={showModal}
      onRequestHide={close}
      closeOnOutsideClick
      closeButton
      footer={
        <button className="btn btn-default" onClick={close}>
          <FormattedMessage id="Global.Close" />
        </button>
      }>
      <div>
        {orderIds}
        {fulfillmentGroupIds}
        {itemIds}
        {otherIds}
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <div>
        <a className="clickable" onClick={open(item.itemId)}>
          {children}
        </a>
      </div>
      {relatedIdsDialog}
      <Snackbar show={copied} delay={3000}>
        <FormattedMessage id="Global.CopiedToClipboard" />
      </Snackbar>
    </Fragment>
  );
};

OtherIds.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  context: PropTypes.oneOf(['link', 'dropdown']),
  children: PropTypes.node.isRequired,
};

export default OtherIds;
