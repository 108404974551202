import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';
import { getMerchantById } from '../services/merchants';

export function loadMerchants() {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.GET_MERCHANTS_REQUEST, types.GET_MERCHANTS_SUCCESS, types.GET_MERCHANTS_FAILURE],
        endpoint: `${process.env.REACT_APP_MERCHANT_CONFIG_URL}/v0/merchants?verbose=false`,
      },
    });
  };
}

export const loadMerchantById = merchantId => dispatch =>
  getMerchantById(merchantId).then(merchant => dispatch({ type: types.GET_SINGLE_MERCHANT, merchant }));
