import * as types from '../constants/actiontypes.js';
import { TrackingEventTitles } from '../analytics';

export const initialState = { savedSearches: [], selectedSavedSearch: null, initialized: false };

export default function savedSearches(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_SAVED_SEARCHES:
      return {
        ...state,
        savedSearches: action.savedSearches
          .filter(search => search !== null)
          .map(search => ({
            ...search,
            trackingEventTitle: TrackingEventTitles.SAVED_SEARCH,
          })),
        initialized: true,
      };
    case types.SET_SELECTED_SAVED_SEARCH:
      return { ...state, selectedSavedSearch: action.selectedSavedSearch };
    default:
      return state;
  }
}
