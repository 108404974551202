import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initStatuses } from '../../constants/statuses';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import SimpleStatusExplanation from './simplestatusexplanation.jsx';
import { INFO, ERROR, WARNING } from '../../constants/issueSeverity.js';
import flatMap from 'lodash/flatMap';

const OrderStatus = props => {
  const { order, intl } = props;
  const statusTypes = initStatuses(intl);

  let { type, hasErrors, hasMissedSlas, hasPendingCancellation } = order.computedStatus;

  var statusMessage = statusTypes[type]
    ? statusTypes[type].getDisplayName
      ? statusTypes[type].getDisplayName(order.computedStatus)
      : statusTypes[type].displayName
    : intl.formatMessage({ id: 'Global.Unknown' });
  var icons = [];

  if (!hasErrors && !hasMissedSlas) {
    return <div style={{ whiteSpace: 'nowrap' }}>{statusMessage}</div>;
  }

  if (hasErrors) {
    icons.push(<i key="hasError" className="fa fa-exclamation-triangle text-danger" aria-hidden="true" />);
  }

  if (hasMissedSlas) {
    icons.push(<i key="hasWarning" className="fa fa-exclamation-triangle text-warning" aria-hidden="true" />);
  }

  if (hasPendingCancellation) {
    icons.push(<i key="hasPendingCancellation" className="fa fa-info-circle text-info" aria-hidden="true" />);
  }

  let iconInfo;
  if (icons.length) {
    const errorIssueDetails = flatMap(order.items, (item, idx) =>
      item.computedErrors
        ? item.computedErrors.map(computedError => (
            <SimpleStatusExplanation
              key={`${order.orderId}-${computedError.name}-${idx}`}
              status={computedError.name}
              type="error"
              severity={ERROR}
            />
          ))
        : null
    );

    const missedSlaDetails = flatMap(order.items, (item, idx) =>
      item.computedMissedSlas
        ? item.computedMissedSlas.map(computedMissedSla => (
            <SimpleStatusExplanation
              key={`${order.orderId}-${computedMissedSla.name}-${idx}`}
              status={computedMissedSla.name}
              type="missedSla"
              severity={WARNING}
            />
          ))
        : null
    );

    const pendingCancellationDetails = flatMap(order.items, (item, idx) =>
      item.pendingCancellation ? (
        <SimpleStatusExplanation
          key={`${order.orderId}-pendingCancellation-${item.itemId}-${idx}`}
          status="pendingCancellation"
          type="cancellation"
          severity={INFO}
        />
      ) : null
    );

    let issueDetails = (
      <div>
        {errorIssueDetails}
        {missedSlaDetails}
        {pendingCancellationDetails}
      </div>
    );
    iconInfo = (
      <Tooltip direction="right" contents={issueDetails} className="details-status-tooltip">
        <span>{icons}</span>
      </Tooltip>
    );

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        <span style={{ marginRight: '5px' }}>{statusMessage}</span>
        {iconInfo}
      </div>
    );
  }
};

OrderStatus.propTypes = {
  order: PropTypes.shape({
    type: PropTypes.string,
    hasErrors: PropTypes.bool,
    hasMissedSlas: PropTypes.bool,
  }),
  intl: PropTypes.object,
};

export default injectIntl(OrderStatus);
