import { Accordion, FlexBox, Tooltip } from '@cimpress/react-components';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import React, { useEffect, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { filter, get, isEmpty, map } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { Alert } from '../../shared/Alert';
import { itemServiceStatusToDisplayMap } from '../../../constants/statuses';
import { callFetch } from '../../../services/serviceHelpers';
import { StyledText } from './StyledText';
import populateColumnData, { getDuration } from '../../../utils/populateTimelineColumnData';
import ItemHistoryRow from './ItemHistoryRow';
import TimelineRow from './TimelineRow';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';
import { hasError } from '../../../utils/statusModels';

const initialItemHistoryState = { isLoading: true, errorMessage: null };

const ItemHistoryContainer = styled(FlexBox)`
  padding: 20px;
  margin: 5px;
  vertical-align: top;
`;

const StyledAccordion = styled(Accordion)`
  border: 0px;
  .accordion-header {
    border: 0px;
    padding: 0px;
  }
  .accordion-arrow {
    visibility: ${({ isAggregation }) => (isAggregation ? 'inherit' : 'hidden')};
  }
  .accordion-body {
    padding: 0px 48px;
  }
`;

export const useItemHistoryData = itemId => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'REQUEST':
        return { isLoading: true, itemHistory: [], errorMessage: null };
      case 'SUCCESS':
        return { isLoading: false, itemHistory: action.itemHistory, errorMessage: null };
      case 'FAILURE':
        return { isLoading: false, itemHistory: [], errorMessage: action.errorMessage };
      default:
        return state;
    }
  }, initialItemHistoryState);

  useEffect(() => {
    const fetchItemHistoryData = async () => {
      dispatch({ type: 'REQUEST' });
      try {
        const itemHistory = await callFetch(
          process.env.REACT_APP_ITEM_HISTORY_URL,
          'GET',
          `v1/itemHistory?itemId=${itemId}`
        );
        dispatch({ type: 'SUCCESS', itemHistory });
      } catch (error) {
        dispatch({ type: 'FAILURE', errorMessage: error.message });
      }
    };
    fetchItemHistoryData();
  }, [itemId]);

  return state;
};

const ItemHistory = ({ errorMessage, isLoading, itemHistory, showAdvancedDeveloperView, item }) => {
  const intl = useIntl();
  const statusDisplayMap = itemServiceStatusToDisplayMap(intl);
  const [timelineData, setTimelineData] = useState([]);
  const [expandedStatuses, setExpandedStatuses] = useState({});

  useEffect(() => {
    if (isLoading || errorMessage) {
      return;
    }

    const validStates = new Set(['current', 'closed']);
    const validStatuses = filter(itemHistory.statuses, status => validStates.has(status.state));

    const shouldShowStatus = status => get(statusDisplayMap, [status.name, 'showInStatusOverview'], false);
    const statusesToDisplay = showAdvancedDeveloperView ? validStatuses : filter(validStatuses, shouldShowStatus);

    const currentDate = moment().toISOString();

    // construct our timeline data
    const dontForceEndDateStates = new Set(['fulfilled', 'rejected', 'cancelled']);
    const timelineSteps = map(statusesToDisplay, status => {
      const { name, state, endDate: potentialEndDate, startDate } = status;

      // set the end date to right now if conditions are correct
      const shouldSetEndDate =
        !dontForceEndDateStates.has(status.name) && status.state === 'current' && !potentialEndDate;
      const endDate = shouldSetEndDate ? currentDate : potentialEndDate;

      // this is a bit tricky here. we don't want to consider statuses that are hidden by the collapsed
      // aggregation rows in the timeline column abbreviation logic, but we still want to include them
      // in the final collection so the parents and children are acknowledged correctly, so lets just set a
      // flag denoting if the row is visible and let the abbreviation logic act on it
      const groupStepName = get(statusDisplayMap, [name, 'groupStepName'], null);
      // if we don't have a group name then we are a visible parent, or if our group name is
      // expanded then we are a visible child
      const isVisible = !groupStepName || !!expandedStatuses[groupStepName];

      return {
        name,
        state,
        startDate: moment(startDate)
          .milliseconds(0)
          .toISOString(),
        endDate: moment(endDate)
          .milliseconds(0)
          .toISOString(),
        groupStepName,
        displayName: get(statusDisplayMap, [name, 'displayName'], name),
        elapsedTime: moment.duration(getDuration(startDate, endDate)).humanize(),
        isError: ['rejected', 'cancelled'].includes(name) && state === 'current',
        // pulling the status directly from the item since Item History isn't returning the expectedCloseDate
        isAlertOpen: hasError(item.statuses[name]),
        isVisible,
      };
    });

    setTimelineData(populateColumnData(timelineSteps));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, isLoading, itemHistory.statuses, showAdvancedDeveloperView, expandedStatuses]);

  if (isLoading) {
    return <Spinner />;
  } else if (errorMessage) {
    return <Alert errorMessage={errorMessage} formattedErrorMessageId={'StatusOverview.ItemHistoryFailure'} />;
  }

  // get all parent steps (steps that have no group)
  const parentSteps = filter(timelineData, s => !s.groupStepName);

  return (
    <FlexBox isVertical grow={2}>
      <FlexBox style={{ paddingRight: '10px', maxHeight: '20px' }}>
        <StyledText boldText>
          <FormattedMessage id="StatusOverview.ItemHistory" />
        </StyledText>
        <Tooltip contents={<FormattedMessage id="StatusOverview.ItemHistoryTooltip" />}>
          <IconInformationCircle style={{ marginLeft: '5px', marginTop: '2px' }} className="text-info" weight="fill" />
        </Tooltip>
      </FlexBox>

      <ItemHistoryContainer isVertical>
        {map(parentSteps, parentStep => {
          // toggle the expanded status when the accordion is opened or closed
          const onAccordionClick = () => {
            if (!expandedStatuses[parentStep.name]) {
              trackEvent(TrackingEventTitles.UNFOLD_ITEM_HISTORY_PARENT_STATUS, {
                [TrackingEventPropertiesNames.STATUS_OVERVIEW.UNFOLD_PARENT_STATUS]: parentStep.name,
              });
            }
            setExpandedStatuses({
              ...expandedStatuses,
              [parentStep.name]: !expandedStatuses[parentStep.name],
            });
          };

          // get statuses with this parent step as their group
          const children = filter(timelineData, s => s.groupStepName === parentStep.name);

          return (
            <StyledAccordion
              key={parentStep.name}
              onHeaderClick={onAccordionClick}
              defaultOpen={expandedStatuses[parentStep.name]}
              isAggregation={!isEmpty(children)}
              title={<ItemHistoryRow rowData={parentStep} />}>
              {map(children, childStep => (
                <div style={{ marginTop: '15px' }} key={childStep.name}>
                  <ItemHistoryRow rowData={childStep} isChild />
                </div>
              ))}
            </StyledAccordion>
          );
        })}
        <TimelineRow timelineData={timelineData} numColumns={500} />
      </ItemHistoryContainer>
    </FlexBox>
  );
};

export default ItemHistory;
