import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@cimpress/react-components/lib/Button';

export default class WatchButton extends PureComponent {
  static propTypes = {
    isWatching: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    isWatching: false,
  };

  render() {
    const { isWatching, onClick } = this.props;
    return (
      <Button variant="link" onClick={onClick}>
        {isWatching ? <FormattedMessage id="Subscription.Manage" /> : <FormattedMessage id="Subscription.Subscribe" />}
      </Button>
    );
  }
}
