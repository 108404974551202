import PropTypes from 'prop-types';

const BASE_ITEM_SHAPE = {
  orderId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  merchantProductName: PropTypes.string.isRequired,
};

export const itemShape = PropTypes.shape(BASE_ITEM_SHAPE);

export const itemStatusShape = PropTypes.shape({
  name: PropTypes.string,
  detail: PropTypes.string,
  expectedCloseDate: PropTypes.string,
  quantity: PropTypes.number,
  state: PropTypes.oneOf(['notStarted', 'current', 'closed']),
  updatedDate: PropTypes.string,
});

export const computedStatusShape = PropTypes.shape({
  type: PropTypes.string,
  previousType: PropTypes.string,
  partial: PropTypes.bool,
});

export const decoratedItemShape = PropTypes.shape({
  ...BASE_ITEM_SHAPE,
  computedStatus: computedStatusShape,
  computedMissedSlas: PropTypes.arrayOf(PropTypes.object),
  computedErrors: PropTypes.arrayOf(PropTypes.object),
  pendingCancellation: PropTypes.bool,
});

export const searchResultsOrderShape = PropTypes.shape({
  orderId: PropTypes.string,
  merchantOrderId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fulfillerOrderId: PropTypes.string,
      shortFulfillmentGroupId: PropTypes.string,
    })
  ),
});

export const merchantOrderShape = PropTypes.shape({
  orderId: PropTypes.string,
});

export const decoratedOrderShape = PropTypes.shape({
  items: PropTypes.objectOf(decoratedItemShape),
  claimLinks: PropTypes.arrayOf(PropTypes.string),
  reorderClaimLinks: PropTypes.arrayOf(PropTypes.string),
  complaintLinks: PropTypes.arrayOf(PropTypes.string),
  reorderComplaintLinks: PropTypes.arrayOf(PropTypes.string),
});

export const orderIdsShape = PropTypes.shape({
  platform: PropTypes.string.isRequired,
  merchant: PropTypes.string,
  fulfiller: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  short: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
});
