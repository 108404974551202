import React from 'react';
import { PRODUCTION } from '../../constants/statustypes';
import { useIntl } from 'react-intl';
import { CURRENT } from '../../constants/itemservicestatusstates';

const ProductionStatus = ({ currentStatus, itemStatuses }) => {
  const intl = useIntl();

  const productionStatuses = [
    {
      name: 'productionAccepted',
      displayName: intl.formatMessage({ id: 'Statuses.ProductionAccepted' }),
    },
  ];

  if (currentStatus.type !== PRODUCTION) {
    return null;
  }

  const currentProductionSubStatus = productionStatuses.find(
    status => itemStatuses.hasOwnProperty(status.name) && itemStatuses[status.name].state === CURRENT
  );
  if (!currentProductionSubStatus) {
    return null;
  }

  return (
    <div style={{ margin: '1rem 0' }}>
      <strong>{currentProductionSubStatus.displayName}</strong>
    </div>
  );
};

export default ProductionStatus;
