import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TimeAgo from 'react-timeago';
import { teal } from '@cimpress/react-components/lib/colors';
import get from 'lodash/get';
import IconSynchronizeArrowsAlt from '@cimpress-technology/react-streamline-icons/lib/IconSynchronizeArrowsAlt';

import { getFormatter } from '../../constants/timeFormatters';
import { trackEvent, TrackingEventTitles } from '../../analytics';

const LastUpdated = ({ onRefresh }) => {
  const [updatedDate, setUpdatedDate] = useState(Date.now() - 1);
  const userLanguage = useSelector(state => get(state, 'userPreferences.userLanguage'));

  const handleRefresh = () => {
    // subtract one because when updatedDate === now, TimeAgo displays as "from now" instead of "ago"
    setUpdatedDate(Date.now() - 1);

    trackEvent(TrackingEventTitles.REFRESH_ORDER, {});

    if (onRefresh) {
      onRefresh();
    }
  };

  const formatter = getFormatter(userLanguage);

  return (
    <a className="clickable small" onClick={handleRefresh}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormattedMessage id="Global.Updated" />
        &nbsp;
        <TimeAgo date={updatedDate} formatter={formatter} />
        <IconSynchronizeArrowsAlt color={teal.base} style={{ marginLeft: '5px' }} />
      </div>
    </a>
  );
};

LastUpdated.propTypes = {
  onRefresh: PropTypes.func,
  userLanguage: PropTypes.string,
};

export default LastUpdated;
