import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import intersectionWith from 'lodash/intersectionWith';
import FulfillmentConfigurationPicker from '@cimpress-technology/fulfillment-configuration-picker';
import { FlexBox } from '@cimpress/react-components';

import auth from '../../../../utils/auth';
import ItemSelector from './ItemSelector';
import useRedirectContext from './RedirectContext';

const SelectFulfillmentConfigurations = ({ items }) => {
  const { state, dispatch } = useRedirectContext();

  const itemsToRedirect = intersectionWith(
    items,
    Object.values(state.itemsAvailableToRedirect),
    (item, itemToRedirect) => {
      return itemToRedirect.isSelectedForRedirection && itemToRedirect.itemId === item.itemId;
    }
  );
  const [selectedItemId, setSelectedItemId] = useState(itemsToRedirect[0].itemId);
  const itemServiceItem = itemsToRedirect.find(item => item.itemId === selectedItemId);

  const fulfillmentConfigurationSelected = ({ fulfillmentConfigurationUrl }) => {
    if (fulfillmentConfigurationUrl !== '') {
      dispatch({
        type: 'SELECT_FULFILLMENT_CONFIGURATION',
        payload: { itemId: itemServiceItem.itemId, fulfillmentConfigurationUrl },
      });
    }
  };

  if (!itemServiceItem) {
    return null;
  }

  return (
    <FlexBox>
      <FlexBox isVertical shrink={1} basis={0}>
        <h4>
          <FormattedMessage id="RequestRedirect.ItemsChosenForRedirection" />
        </h4>
        <ItemSelector
          items={itemsToRedirect}
          selectItem={itemId => setSelectedItemId(itemId)}
          isSelected={itemId => selectedItemId === itemId}
          displayNewFulfillmentConfiguration
        />
      </FlexBox>

      <FlexBox isVertical shrink={1} basis={0}>
        <h4>
          <FormattedMessage id="RequestRedirect.Stepper.SelectNewFulfillmentConfigurations" />
        </h4>
        <FulfillmentConfigurationPicker
          accessToken={auth.getAccessToken()}
          onFulfillmentConfigurationSelected={fulfillmentConfigurationSelected}
          buyerAccountId={get(itemServiceItem, '_links.buyerAccount.name')}
          productConfigurationUrl={get(itemServiceItem, '_links.productConfiguration.href')}
          initialFulfillmentConfigurationUrl={get(itemServiceItem, '_links.fulfillmentConfiguration.href')}
          environment={process.env.REACT_APP_ENV === 'loc' ? 'int' : process.env.REACT_APP_ENV}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default SelectFulfillmentConfigurations;
