import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal } from '@cimpress/react-components';

import { cancelOrder, cancelItem } from '../../../actions/cancelactions';
import ActionLink from '../../shared/ActionLink';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const CancelRequestModal = ({ orderId, itemId, canCancel, merchantProductName }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const cancelItemMode = Boolean(itemId);

  const open = () => {
    setShowConfirmation(true);

    trackEvent(TrackingEventTitles.CANCEL_REQUEST_MODAL, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.CANCEL_REQUEST.TYPE]: cancelItemMode ? 'Item' : 'Order',
      [TrackingEventPropertiesNames.ORDER_DETAILS.CANCEL_REQUEST.ORDER_ID]: orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.CANCEL_REQUEST.ITEM_ID]: itemId || 'N/A',
    });
  };

  const close = () => {
    setShowConfirmation(false);
  };

  const onRequestOrderCancel = () => {
    dispatch(cancelOrder(orderId));

    trackEvent(TrackingEventTitles.REQUESTED_ORDER_CANCEL, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.CANCEL_REQUEST.ORDER_ID]: orderId,
    });

    close();
  };

  const onRequestItemCancel = () => {
    dispatch(cancelItem(orderId, itemId));

    trackEvent(TrackingEventTitles.REQUESTED_ITEM_CANCEL, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.CANCEL_REQUEST.ORDER_ID]: orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.CANCEL_REQUEST.ITEM_ID]: itemId,
    });

    close();
  };

  const title = cancelItemMode
    ? intl.formatMessage({ id: 'ItemActions.RequestCancellation' })
    : intl.formatMessage({ id: 'DeveloperTools.RequestCancellation' });

  return (
    <div>
      <ActionLink
        text={title}
        action={open}
        disabled={!canCancel}
        disabledMessage={
          cancelItemMode
            ? intl.formatMessage({ id: 'ItemActions.CancelItemUnsupported' })
            : intl.formatMessage({ id: 'DeveloperTools.CancelOrderUnsupported' })
        }
      />
      <Modal
        size="lg"
        show={showConfirmation}
        onRequestHide={close}
        closeOnOutsideClick
        closeButton
        title={intl.formatMessage({ id: 'CancelRequest.RequestCancellation' })}
        footer={
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default" onClick={close}>
              {cancelItemMode ? (
                <FormattedMessage id="CancelRequest.DoNotCancelItem" />
              ) : (
                <FormattedMessage id="CancelRequest.DoNotCancelOrder" />
              )}
            </button>
            <button className="btn btn-primary" onClick={cancelItemMode ? onRequestItemCancel : onRequestOrderCancel}>
              {cancelItemMode ? (
                <FormattedMessage id="CancelRequest.DoCancelItem" />
              ) : (
                <FormattedMessage id="CancelRequest.DoCancelOrder" />
              )}
            </button>
          </div>
        }>
        <div style={{ textAlign: 'center' }}>
          <p>
            <b>
              {cancelItemMode ? (
                <FormattedMessage
                  id="CancelRequest.ConfirmItemCancellation"
                  values={{ item: merchantProductName || itemId }}
                />
              ) : (
                <FormattedMessage id="CancelRequest.ConfirmOrderCancellation" />
              )}
            </b>
          </p>
          <p>
            <FormattedMessage id="CancelRequest.CancellationDisclaimer" />
          </p>
        </div>
      </Modal>
    </div>
  );
};

CancelRequestModal.propTypes = {
  orderId: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  merchantProductName: PropTypes.string,
  canCancel: PropTypes.bool,
};

export default CancelRequestModal;
