export function initPrintJobStatuses(intl) {
  return {
    accepted: {
      displayName: intl.formatMessage({ id: 'PrintJob.Accepted' }),
    },
    cancelled: {
      displayName: intl.formatMessage({ id: 'PrintJob.Cancelled' }),
    },
    closed: {
      displayName: intl.formatMessage({ id: 'PrintJob.Closed' }),
    },
    created: {
      displayName: intl.formatMessage({ id: 'PrintJob.Created' }),
    },
    error: {
      displayName: intl.formatMessage({ id: 'PrintJob.Error' }),
    },
    'pending-acceptance': {
      displayName: intl.formatMessage({ id: 'PrintJob.PendingAcceptance' }),
    },
    rejected: {
      displayName: intl.formatMessage({ id: 'PrintJob.Rejected' }),
    },
    default: {
      displayName: intl.formatMessage({ id: 'PrintJob.Default' }),
    },
  };
}

export const printJobRel = 'https://relations.cimpress.io/pool/print-job';
