import moment from 'moment';
import allStatusTypes from '../constants/statustypes.js';
import itemServiceStatuses from '../constants/itemservicestatuses';
import itemServiceStatusStates from '../constants/itemservicestatusstates';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import some from 'lodash/some';
import forEach from 'lodash/forEach';

// specifies the order in which statusToItemServiceStatuses should be checked for item level status
const itemStatusTypes = [
  allStatusTypes.SHIPPED,
  allStatusTypes.CANCELLED,
  allStatusTypes.REJECTED,
  allStatusTypes.PRODUCTION,
  allStatusTypes.AWAITING_FULFILLER_RESPONSE,
  allStatusTypes.PROCESSING,
  allStatusTypes.SHIPPING_AGGREGATION,
  allStatusTypes.ROUTING,
  allStatusTypes.DOC_REVIEW,
  allStatusTypes.STANDARDIZING_ORDER,
  allStatusTypes.SUSPENDED,
];

export const statusToItemServiceStatuses = {
  [allStatusTypes.SHIPPED]: [itemServiceStatuses.FULFILLED],
  [allStatusTypes.CANCELLED]: [itemServiceStatuses.CANCELLED],
  [allStatusTypes.REJECTED]: [itemServiceStatuses.REJECTED],
  [allStatusTypes.SUSPENDED]: [itemServiceStatuses.SUSPENDED],
  [allStatusTypes.AWAITING_FULFILLER_RESPONSE]: [itemServiceStatuses.AWAITING_FULFILLER_RESPONSE],
  [allStatusTypes.PRODUCTION]: [itemServiceStatuses.PRODUCTION, itemServiceStatuses.PRODUCTION_ACCEPTED],
  [allStatusTypes.IN_PRODUCTION]: [itemServiceStatuses.PRODUCTION, itemServiceStatuses.PRODUCTION_ACCEPTED],
  [allStatusTypes.PRODUCTION_ACCEPTED]: [itemServiceStatuses.PRODUCTION_ACCEPTED],
  [allStatusTypes.PROCESSING]: [itemServiceStatuses.PREPARING],
  [allStatusTypes.ROUTING]: [itemServiceStatuses.ROUTING],
  [allStatusTypes.SHIPPING_AGGREGATION]: [itemServiceStatuses.SHIPPING_AGGREGATION],
  [allStatusTypes.DOC_REVIEW]: [itemServiceStatuses.DOCUMENT_REVIEW, itemServiceStatuses.DOCUMENT_REVIEW_REJECTED],
  [allStatusTypes.STANDARDIZING_ORDER]: [itemServiceStatuses.STANDARDIZING_ORDER],
};

const itemServiceStatusToLowLevelStatuses = {
  [itemServiceStatuses.PREPARING]: [itemServiceStatuses.INVENTORY_CHECK],
};

const missedSlaStatuses = [
  itemServiceStatuses.PENDING_CANCELLATION,
  itemServiceStatuses.FULFILLMENT,
  itemServiceStatuses.AWAITING_FULFILLER_RESPONSE,
  itemServiceStatuses.PREPARING,
  itemServiceStatuses.DOCUMENT_REVIEW,
  itemServiceStatuses.DOCUMENT_REVIEW_FEEDBACK,
  itemServiceStatuses.FULFILLMENT_DELAYED,
  itemServiceStatuses.ON_TIME_TO_CUSTOMER,
];

const errorStatuses = [
  itemServiceStatuses.REJECTED,
  itemServiceStatuses.DOCUMENT_REVIEW_REJECTED,
  itemServiceStatuses.INVENTORY_CHECK_REJECTED,
  itemServiceStatuses.PREPRESS_FAILED,
  itemServiceStatuses.SUSPENDED,
];

export const interpretItemServiceStatus = itemServiceItem => {
  if (!itemServiceItem) {
    return {};
  }
  let { type, previousType, partial, subStatus } = interpretStatus(
    itemServiceItem.statuses,
    itemStatusTypes,
    itemServiceItem.quantity
  );
  return {
    type,
    previousType,
    partial,
    subStatus,
  };
};

export const interpretStatus = (itemServiceStatuses, statusTypes, itemQuantity) => {
  if (!itemServiceStatuses) {
    return {};
  }

  let statusType, previousType, partial, subStatus;
  let closed = true;

  forEach(statusTypes, type => {
    let criteria = statusToItemServiceStatuses[type];
    let match = checkStatus(criteria, itemServiceStatuses, false, itemQuantity);
    if (match) {
      statusType = type;
      partial = match.partial;
      subStatus = match.subStatus;
      // skip checking the status state if the statusType is suspended
      closed = statusType === allStatusTypes.SUSPENDED ? null : true;
      // break out of loop
      return false;
    }
  });

  // if we can't find a current open status display the most recent closed status
  // if there is a current status, find the previous status
  forEach(statusTypes, type => {
    let criteria = statusToItemServiceStatuses[type];
    let match = checkStatus(criteria, itemServiceStatuses, closed, itemQuantity);
    if (match) {
      if (type === allStatusTypes.CANCELLED || type === allStatusTypes.REJECTED || type === allStatusTypes.SUSPENDED) {
        // keep going, these aren't useful as previous statuses
        return true;
      } else if (!statusType) {
        statusType = type;
        partial = match.partial;
      } else {
        previousType = type;
      }
      // break out of loop
      return false;
    }
  });
  return {
    type: statusType || allStatusTypes.UNKNOWN,
    partial,
    previousType: previousType || allStatusTypes.UNKNOWN,
    subStatus,
  };
};

/**
 * Returns whether the status matches the config criteria.
 * @param {*} criteria the target status(es)
 * @param {*} itemServiceStatuses The item's statuses
 * @param {*} closed a nullable boolean to check if the target status is closed (true), current(false) or present (null)
 * @param {*} quantity the item quantity
 */
function checkStatus(criteria, itemServiceStatuses, closed, quantity) {
  if (!criteria.length) {
    // if there are no acceptance criteria, then allow anything to match
    return true;
  }

  let matches,
    subStatus,
    partial = false;
  forEach(criteria, c => {
    matches =
      itemServiceStatuses[c] &&
      (closed === null ||
        itemServiceStatuses[c].state === (closed ? itemServiceStatusStates.CLOSED : itemServiceStatusStates.CURRENT));
    if (matches) {
      partial = itemServiceStatuses[c].quantity < quantity;

      subStatus = Object.values(itemServiceStatuses)
        .filter(status => status.state === itemServiceStatusStates.CURRENT)
        .map(status => status.name)
        .find(statusName => (itemServiceStatusToLowLevelStatuses[c] || []).includes(statusName));

      // break out of loop
      return false;
    }
  });
  return matches ? { matches, partial, subStatus } : false;
}

export const hasMissedSla = status =>
  missedSlaStatuses.includes(status.name) &&
  status.state === itemServiceStatusStates.CURRENT &&
  status.expectedCloseDate &&
  moment(status.expectedCloseDate).diff(moment(), 'seconds') < 0;

export const getItemMissedSlaStatuses = statuses => {
  return statuses ? filter(Object.keys(statuses), key => hasMissedSla(statuses[key])).map(key => statuses[key]) : [];
};

export const getOrderMissedSlaStatuses = allStatuses => {
  return allStatuses ? filter(allStatuses.map(getItemMissedSlaStatuses), arr => arr.length > 0) : [];
};

export const hasError = status =>
  errorStatuses.includes(status.name) &&
  status.state === itemServiceStatusStates.CURRENT &&
  status.expectedCloseDate &&
  moment(status.expectedCloseDate).diff(moment(), 'seconds') < 0;

export const getItemErrorStatuses = statuses => {
  return statuses ? filter(Object.keys(statuses), key => hasError(statuses[key])).map(key => statuses[key]) : [];
};

export const getItemCancellationStatus = statuses =>
  statuses
    ? Boolean(Object.keys(statuses).find(key => key === 'pendingCancellation' && statuses[key].state === 'current'))
    : false;

export const getOrderErrorStatuses = allStatuses => {
  return allStatuses ? filter(allStatuses.map(getItemErrorStatuses), arr => arr.length > 0) : [];
};

export const getOrderStatus = itemStatuses => {
  // set the order status to the least-progressed item status
  var type;
  var groupedByStatus = groupBy(itemStatuses, 'type');
  if (groupedByStatus[allStatusTypes.STANDARDIZING_ORDER]) {
    type = allStatusTypes.STANDARDIZING_ORDER;
  } else if (groupedByStatus[allStatusTypes.DOC_REVIEW]) {
    type = allStatusTypes.DOC_REVIEW;
  } else if (groupedByStatus[allStatusTypes.ROUTING]) {
    type = allStatusTypes.ROUTING;
  } else if (groupedByStatus[allStatusTypes.SHIPPING_AGGREGATION]) {
    type = allStatusTypes.SHIPPING_AGGREGATION;
  } else if (groupedByStatus[allStatusTypes.PROCESSING]) {
    type = allStatusTypes.PROCESSING;
  } else if (groupedByStatus[allStatusTypes.AWAITING_FULFILLER_RESPONSE]) {
    type = allStatusTypes.AWAITING_FULFILLER_RESPONSE;
  } else if (groupedByStatus[allStatusTypes.PRODUCTION]) {
    type = allStatusTypes.PRODUCTION;
  } else if (groupedByStatus[allStatusTypes.SHIPPED]) {
    type = allStatusTypes.SHIPPED;
  } else if (groupedByStatus[allStatusTypes.SUSPENDED]) {
    // if items are all suspended, cancelled, rejected, or unknown, treat the order as suspended
    type = allStatusTypes.SUSPENDED;
  } else if (groupedByStatus[allStatusTypes.CANCELLED]) {
    // if items are all cancelled, rejected, or unknown, treat the order as cancelled
    type = allStatusTypes.CANCELLED;
  } else if (groupedByStatus[allStatusTypes.REJECTED]) {
    // if items are all rejected or unknown, treat the order as rejected
    type = allStatusTypes.REJECTED;
  } else {
    type = allStatusTypes.UNKNOWN;
  }

  var partial = some(groupedByStatus[type], 'partial');

  return {
    type,
    partial,
  };
};
