import find from 'lodash/find';
import get from 'lodash/get';

const MERCHANTS = 'merchants';
const FULFILLERS = 'fulfillers';

const READ = 'read:order';
const UPDATE = 'update:order';
const REMEDIATE = 'remediate:order';
const CREATE = 'create:order';
const READ_PROFILE = 'read:profile';
const DEFINE_ORDER = 'define:order';

const UPDATE_CLAIM = 'update:claim';

const REINDEX_GROUP_ID = '26622';

function hasPermission(permissions, resourceType, resourceId, permission) {
  let permissionsAtResourceId,
    starPermission = null;
  if (permissions && permissions[resourceType]) {
    permissionsAtResourceId = find(permissions[resourceType], resource => resource.identifier === resourceId);
    starPermission = find(permissions[resourceType], resource => resource.identifier === '*');
  }

  return Boolean(
    (permissionsAtResourceId && permissionsAtResourceId.permissions.includes(permission)) ||
      (starPermission && starPermission.permissions.includes(permission))
  );
}

export function hasMerchantReadPermission(permissions, merchantId) {
  return hasPermission(permissions, MERCHANTS, merchantId, READ);
}

export function hasMerchantUpdatePermission(permissions, merchantId) {
  return hasPermission(permissions, MERCHANTS, merchantId, UPDATE);
}

export function hasMerchantCreatePermission(permissions, merchantId) {
  return hasPermission(permissions, MERCHANTS, merchantId, CREATE);
}

export function hasMerchantRemediatePermission(permissions, merchantId) {
  return hasPermission(permissions, MERCHANTS, merchantId, REMEDIATE);
}

export function hasMerchantUpdateClaimPermission(permissions, merchantId) {
  return hasPermission(permissions, MERCHANTS, merchantId, UPDATE_CLAIM);
}

export function hasFulfillerUpdatePermission(permissions, fulfillerId) {
  return hasPermission(permissions, FULFILLERS, fulfillerId, UPDATE);
}

export function hasFulfillerDefineOrderPermission(permissions, fulfillerId) {
  return hasPermission(permissions, FULFILLERS, fulfillerId, DEFINE_ORDER);
}

export function hasReindexPermissions(groups) {
  return find(groups, group => group.id === REINDEX_GROUP_ID);
}

export function hasFulfillerRemediatePermission(permissions, fulfillerId) {
  return hasPermission(permissions, FULFILLERS, fulfillerId, REMEDIATE);
}

export function hasFulfillerReadPermission(permissions, fulfillerId) {
  return hasPermission(permissions, FULFILLERS, fulfillerId, READ);
}

export function hasFulfillerReadProfilePermission(permissions, fulfillerId) {
  return hasPermission(permissions, FULFILLERS, fulfillerId, READ_PROFILE);
}

export function canReadAnyOrder(permissions) {
  const fulfillerPermissions = get(permissions, FULFILLERS);
  const merchantPermissions = get(permissions, MERCHANTS);
  return Boolean(
    (fulfillerPermissions && fulfillerPermissions.find(resource => resource.permissions.includes(READ))) ||
      (merchantPermissions && merchantPermissions.find(resource => resource.permissions.includes(READ)))
  );
}

// whether user has reorder permission for ANY merchant
export const canReorderAnyOrder = permissions => {
  const merchantPermissions = get(permissions, MERCHANTS);
  return Boolean(
    merchantPermissions &&
      merchantPermissions.some(
        merchant =>
          merchant.permissions && merchant.permissions.includes(REMEDIATE) && merchant.permissions.includes(CREATE)
      )
  );
};

// whether user has reorder permission for a particular merchant
export const canReorder = (permissions, merchantId) =>
  hasMerchantRemediatePermission(permissions, merchantId) && hasMerchantCreatePermission(permissions, merchantId);
