import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import PreviewCarousel from '@cimpress-technology/mex-preview-carousel';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import LazyLoad from 'react-lazy-load';
import FieldDisplay from './FieldDisplay';
import IdDisplay from '../shared/idDisplay';
import ItemStatus from './ItemStatus';
import auth from '../../utils/auth';

class MiniItemDetails extends PureComponent {
  toggleSelection = () => (this.props.selectable ? this.props.toggle(this.props.item) : null);

  onClickStopPropagation = event => {
    event.stopPropagation();
  };

  render() {
    const { item, selectable, isSelected, rightAddon, additionalFields, intl } = this.props;
    if (!item) {
      return null;
    }

    let rowClass;
    if (selectable) {
      rowClass = isSelected ? 'clickable selected' : 'clickable';
    } else if (isSelected) {
      rowClass = 'active';
    }
    return (
      <tr onClick={this.toggleSelection} className={rowClass}>
        {selectable ? (
          <td style={{ verticalAlign: 'middle', width: '50px' }}>
            <div onClick={this.onClickStopPropagation}>
              <Checkbox checked={isSelected} onChange={this.toggleSelection} />
            </div>
          </td>
        ) : null}
        <td style={{ verticalAlign: 'middle', width: '75px' }}>
          <div className="preview" onClick={this.onClickStopPropagation} style={{ fontSize: '11px' }}>
            <LazyLoad offset={0} height={100} width={150}>
              <PreviewCarousel
                includeModal
                pxSize={75}
                minimal
                hideIndicators
                auth={auth}
                mcpSku={item.skuCode}
                docRefUrl={item.documentReferenceUrl}
                variableAttributes={item.variableAttributes || []}
                fallbackPreview={intl.formatMessage({ id: 'ItemActions.NoPreview' })}
                includeMerchandising={!item.documentReferenceUrl && !item.manufacturingReadyDataUrl}
              />
            </LazyLoad>
          </div>
        </td>
        <td style={{ fontSize: '10pt' }}>
          <h5>{item.merchantProductName}</h5>
          <FieldDisplay name={intl.formatMessage({ id: 'ItemLevelFields.SKU' })} value={item.skuCode} compact />
          <FieldDisplay name={intl.formatMessage({ id: 'ItemLevelFields.Quantity' })} value={item.quantity} compact />
          <FieldDisplay
            name={intl.formatMessage({ id: 'ItemLevelFields.ItemID' })}
            value={
              <IdDisplay
                merchant={item.merchantItemId}
                platform={item.itemId}
                fulfiller={item.fulfillerItemId}
                short={item.shortItemId}
              />
            }
            compact
          />
          <FieldDisplay
            name={intl.formatMessage({ id: 'DetailPanels.Status' })}
            value={<ItemStatus item={item} />}
            compact
          />
          {additionalFields && additionalFields.length
            ? additionalFields.map(({ name, value, style }) => (
                <FieldDisplay key={name} name={name} value={value} style={style} compact />
              ))
            : null}
        </td>
        <td style={{ verticalAlign: 'middle' }}>{rightAddon}</td>
      </tr>
    );
  }
}

MiniItemDetails.propTypes = {
  item: PropTypes.object,
  selectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  toggle: PropTypes.func,
  rightAddon: PropTypes.node,
  additionalFields: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.node })),
  intl: PropTypes.object,
};

export default injectIntl(MiniItemDetails);
