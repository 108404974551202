import { isWebUri } from 'valid-url';

const alphaNumeric = val => /^[a-z0-9_-]+$/i.test(val);

export const fulfillment = {
  shipmentId: alphaNumeric,
  trackingId: alphaNumeric,
  externalShipmentId: alphaNumeric,
  trackingUrl: isWebUri,
  deliveryDetails: isWebUri,
};
