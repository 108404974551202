import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import ActionLink from '../../shared/ActionLink';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const CreateClaimLink = ({ order }) => {
  const onClick = () => {
    trackEvent(TrackingEventTitles.CREATE_CLAIM, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: order.orderId,
    });
  };

  return (
    <div>
      <ActionLink
        href={`${process.env.REACT_APP_COMPLAINTS_TOOL_URL}/createFulfillerClaim?orderId=${order.orderId}&tenantType=merchant&tenantValue=${order.merchantId}`}
        action={onClick}
        target="_blank"
        rel="noopener noreferrer"
        text={<FormattedMessage id="Claims.CreateClaim" />}
        helpText={<FormattedMessage id="Claims.ClaimInfoMerchantTooltip" />}
      />
    </div>
  );
};

CreateClaimLink.propTypes = {
  order: PropTypes.object,
};

export default CreateClaimLink;
