import * as types from '../constants/actiontypes.js';

export const initialState = {
  subscriptions: {},
};

const addSubscription = (currentSubscriptions, subscription) => {
  if (currentSubscriptions[subscription.resourceType]) {
    currentSubscriptions[subscription.resourceType].push(subscription);
  } else {
    currentSubscriptions[subscription.resourceType] = [subscription];
  }
  return currentSubscriptions;
};

const removeSubscription = (currentSubscriptions, subscriptionId) => {
  const newSubscriptions = { ...currentSubscriptions };
  Object.keys(newSubscriptions).forEach(resourceType => {
    newSubscriptions[resourceType] = newSubscriptions[resourceType].filter(
      subscription => subscription.id !== subscriptionId
    );
  });
  return newSubscriptions;
};

export default function subscriptions(state = initialState, action = {}) {
  switch (action.type) {
    case types.ADD_SUBSCRIPTION_RESOURCE_SUBSCRIPTIONS: {
      const existing = state.subscriptions[action.resourceType] || [];
      const additional = action.subscriptions.filter(
        ({ id }) => !existing.some(({ id: existingId }) => id === existingId)
      );
      return {
        ...state,
        subscriptions: { ...state.subscriptions, [action.resourceType]: existing.concat(additional) },
      };
    }
    case types.ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: addSubscription({ ...state.subscriptions }, action.subscription),
      };
    case types.REMOVE_SUBSCRIPTION:
      return {
        ...state,
        subscriptions: removeSubscription({ ...state.subscriptions }, action.subscriptionId),
      };
    default:
      return state;
  }
}
