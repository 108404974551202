import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import Modal from '@cimpress/react-components/lib/Modal';
import Table from '@cimpress/react-components/lib/Table';

import FieldDisplay from '../FieldDisplay';
import TrackingLink from '../../shared/TrackingLink';
import { initShipmentStatuses } from '../../../constants/shipmentStatuses';

const TrackingDetailsModal = ({ itemId, itemStatuses, shipmentData = [], intl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { shipmentStatuses, shipManTrackingStatuses } = initShipmentStatuses(intl);

  const openModal = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const closeModal = e => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const packageTrackingData = uniqBy(
    shipmentData.flatMap(({ packages, items }) => {
      return packages.map(shipmentPackage => ({
        trackingId: shipmentPackage.trackingId,
        trackingUrl: shipmentPackage.trackingUrl,
        trackingStatus: shipManTrackingStatuses[shipmentPackage.trackingStatus] || shipmentPackage.trackingStatus,
        quantity: sumBy(
          (items || shipmentPackage.items).filter(i => i.mcpItemId === itemId),
          'quantity'
        ),
      }));
    }),
    'trackingId'
  );

  if (!packageTrackingData.length) {
    return null;
  }

  if (packageTrackingData.length === 1) {
    const pkg = packageTrackingData[0];

    const currentShipmentStatuses = Object.keys(shipmentStatuses)
      .filter(shipmentStatus => itemStatuses[shipmentStatus] && itemStatuses[shipmentStatus].status === 'current')
      .map(currentShipmentStatus => ({ ...shipmentStatuses[currentShipmentStatus], name: currentShipmentStatus }))
      .sort((a, b) => a.priority - b.priority);

    let quantity = get(itemStatuses, 'fulfilled.quantity', 0);
    if (currentShipmentStatuses.length) {
      quantity = itemStatuses[currentShipmentStatuses[0].name].quantity;
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <FieldDisplay
          squished
          compact
          name={intl.formatMessage({ id: 'FulfillmentEvent.Track' })}
          value={<TrackingLink trackingId={pkg.trackingId} trackingUrl={pkg.trackingUrl} />}
        />
        <div>
          <FormattedMessage id="ShippingMessage.Quantity" />: {quantity}
        </div>
      </div>
    );
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Events.TrackingId' }),
      /* eslint-disable react/prop-types */
      Cell: ({ original: { trackingId, trackingUrl } }) => (
        <TrackingLink trackingId={trackingId} trackingUrl={trackingUrl} />
      ),
      /* eslint-enable react/prop-types */
    },
    { Header: intl.formatMessage({ id: 'ShippingMessage.Quantity' }), accessor: 'quantity' },
    {
      Header: intl.formatMessage({ id: 'FulfillmentEvent.ShipmentStatus' }),
      accessor: 'trackingStatus',
    },
  ];

  return (
    <Fragment>
      <div style={{ margin: '0 auto' }}>
        <FieldDisplay
          squished
          compact
          name={intl.formatMessage({ id: 'FulfillmentEvent.Track' })}
          value={
            <a className="clickable" onClick={openModal}>
              <FormattedMessage id="FulfillmentEvent.NumberShipments" values={{ n: packageTrackingData.length }} />
            </a>
          }
        />
      </div>
      <Modal
        size="lg"
        show={isOpen}
        onRequestHide={closeModal}
        closeOnOutsideClick
        closeButton
        title={<FormattedMessage id="FulfillmentEvent.ShipmentTracker" />}>
        <Table
          columns={columns}
          data={packageTrackingData}
          showPagination={false}
          pageSize={1000}
          minRows={0}
          getTdProps={() => ({
            style: { wordBreak: 'break-word' },
          })}
        />
      </Modal>
    </Fragment>
  );
};

TrackingDetailsModal.propTypes = {
  itemId: PropTypes.string.isRequired,
  itemStatuses: PropTypes.object,
  shipmentData: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentId: PropTypes.string.isRequired,
      carrierServiceKey: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      shipmentCreationDateTime: PropTypes.string,
      packedDateTime: PropTypes.string,
      destinationAddress: PropTypes.any.isRequired,
      packages: PropTypes.arrayOf(PropTypes.any),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          mcpItemId: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  intl: PropTypes.object,
};

export default injectIntl(TrackingDetailsModal);
