import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FourOhFourComponent from '@cimpress/react-components/lib/FourOhFour';
import { Link } from 'react-router-dom';

export default class FourOhFour extends Component {
  render() {
    return (
      <div style={{ paddingTop: '100px' }}>
        <FourOhFourComponent
          message={
            <div>
              <h5>
                Do you want to <Link to="/">search for orders</Link>?
              </h5>
            </div>
          }
        />
      </div>
    );
  }
}

FourOhFour.propTypes = {
  location: PropTypes.object,
};
