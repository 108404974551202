import React from 'react';
import eventTypes from '../../../constants/eventtypes';
import { initShipmentStatuses } from '../../../constants/shipmentStatuses';
import { isJsonString } from '../../../utils/utilityfunctions';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import MessageWithSingleLink from '../../shared/messageWithSingleLink';
import FulfillmentEventSummary from './FulfillmentEventSummary';
import { isFromPrepress } from '../../../utils/events';
import reactHtmlParser from 'react-html-parser';

var initEventDisplayDetails = intl => {
  return {
    [eventTypes.PLATFORM_REJECTED]: e =>
      intl.formatMessage({ id: 'EventDetails.PLATFORM_REJECTED' }, { reason: e.reason }),
    [eventTypes.PRODUCTION_ACCEPTED]: e =>
      intl.formatMessage({ id: 'EventDetails.PRODUCTION_ACCEPTED' }, { fulfiller: e.fulfillerName }),
    [eventTypes.PRODUCTION_STARTED]: e =>
      intl.formatMessage(
        { id: 'EventDetails.PRODUCTION_STARTED' },
        { fulfiller: e.fulfillerName, quantity: e.quantity }
      ),
    [eventTypes.CANCELLATION_REQUEST_RECEIVED]: e =>
      intl.formatMessage(
        { id: 'EventDetails.CANCELLATION_REQUEST_RECEIVED' },
        { reason: e.reason, cancelledBy: e.cancelledBy }
      ),
    [eventTypes.CANCELLATION_RESPONSE]: e => {
      const responseMessage = e.handledByPlatform
        ? intl.formatMessage({ id: 'EventDetails.CANCELLATION_RESPONSE_PLATFORM' })
        : intl.formatMessage({ id: 'EventDetails.CANCELLATION_RESPONSE_FULFILLER' }, { fulfiller: e.fulfillerName });
      const qtyCancelled =
        e.quantity !== undefined
          ? intl.formatMessage({ id: 'EventDetails.ITEMS_CANCELLED' }, { quantity: e.quantity })
          : '';
      const costMessage = e.costsIncurred
        ? intl.formatMessage({ id: 'EventDetails.COST_INCURRED' })
        : intl.formatMessage({ id: 'EventDetails.NO_COST_INCURRED' });
      return responseMessage + (qtyCancelled ? `\n${qtyCancelled}` : '') + `\n${costMessage}`;
    },
    [eventTypes.NOTHING_CANCELLED]: e =>
      intl.formatMessage(
        { id: 'EventDetails.NOTHING_CANCELLED' },
        { details: e.additionalInfo || e.description, fulfillerName: e.fulfillerName }
      ),
    [eventTypes.FULFILLER_REJECTED]: e => {
      if (isFromPrepress(e.additionalInfo)) {
        return <div>{reactHtmlParser(intl.formatMessage({ id: 'EventDetails.FULFILLER_REJECTED_BY_PREPRESS' }))}</div>;
      }
      const details = e.additionalInfo || e.description || '';
      const unsupported =
        e.unsupportedProcesses &&
        e.unsupportedProcesses.map(u => ({
          docs: u._links.documentation && u._links.documentation.href,
          name: u.name,
        }));
      const requirements =
        e.missingFulfillerRequirements &&
        e.missingFulfillerRequirements.map(r => ({
          docs: r._links.documentation && r._links.documentation.href,
          name: r.name,
        }));
      return (
        <div>
          {intl.formatMessage(
            { id: 'EventDetails.FULFILLER_REJECTED' },
            { fulfiller: e.fulfillerName, additionalInfo: JSON.stringify(details) }
          )}
          {unsupported && unsupported.length ? (
            <div>
              {intl.formatMessage({ id: 'AdditionalDetails.UnsupportedProcesses' })}:{' '}
              {unsupported.map(u => (
                <a key={u.name} href={u.docs} target="_blank" rel="noopener noreferrer">
                  {u.name} <i className="fa fa-sm fa-external-link" />{' '}
                </a>
              ))}
            </div>
          ) : null}
          {requirements && requirements.length ? (
            <div>
              {intl.formatMessage({ id: 'AdditionalDetails.MissingFulfillerRequirements' })}:{' '}
              {requirements.map(u => (
                <a key={u.name} href={u.docs} target="_blank" rel="noopener noreferrer">
                  {u.name} <i className="fa fa-sm fa-external-link" />{' '}
                </a>
              ))}
            </div>
          ) : null}
        </div>
      );
    },
    [eventTypes.FULFILLER_ADAPTER_ERROR]: e => {
      let details = getFulfillerErrorDetails(intl, e);
      return intl.formatMessage(
        { id: 'EventDetails.FULFILLER_ADAPTER_ERROR' },
        { fulfiller: e.fulfillerName, details }
      );
    },
    [eventTypes.FULFILLER_TRANSMISSION_ERROR]: e => {
      let details = getFulfillerErrorDetails(intl, e);
      return intl.formatMessage(
        { id: 'EventDetails.FULFILLER_TRANSMISSION_ERROR' },
        { fulfiller: e.fulfillerName, details }
      );
    },
    [eventTypes.FULFILLER_ID_MAPPINGS_CREATED]: e =>
      intl.formatMessage(
        { id: 'EventDetails.FULFILLER_ID_MAPPINGS_CREATED' },
        { fulfiller: e.fulfillerName, fulfillerItemId: e.fulfillerItemId }
      ),
    [eventTypes.SHIPPING_PLANNED]: (e, options) =>
      intl.formatMessage(
        { id: 'EventDetails.SHIPPING_PLANNED' },
        {
          shipDateTime: options && options.dateFormatter ? options.dateFormatter(e.shipDateTime) : e.shipDateTime,
          carrierService: e.carrierService,
        }
      ),
    [eventTypes.DRAWING_COMPLETED]: e => {
      const message = intl.formatMessage({ id: 'EventDetails.DRAWING_COMPLETED_MESSAGE' });
      return <MessageWithSingleLink message={message} url={e.manufacturingReadyDataUrl} />;
    },
    [eventTypes.DRAWING_FAILED]: e => {
      const details = e.errorDescription
        ? intl.formatMessage({ id: 'EventDetails.DETAILS' }, { details: e.errorDescription })
        : intl.formatMessage({ id: 'EventDetails.NO_ADDITIONAL_INFO' });
      return intl.formatMessage({ id: 'EventDetails.DRAWING_FAILED' }, { details });
    },
    [eventTypes.DOCUMENT_REVIEW_STARTED]: e => (
      <span>
        <div>
          {intl.formatMessage({ id: 'EventDetails.DOCUMENT_REVIEW_STARTED' })}{' '}
          {e.reviewRequestedReferenceUrl && (
            <a href={e.reviewRequestedReferenceUrl} target="_blank" rel="noopener noreferrer">
              {intl.formatMessage({ id: 'Global.Here' })} <i className="fa fa-sm fa-external-link" />
            </a>
          )}
        </div>
        <div>
          {intl.formatMessage(
            { id: 'EventDetails.DOCUMENT_REVEIW_CONTACT_EMAIL' },
            { email: e.reviewerSupportContactEmail }
          )}
        </div>
      </span>
    ),
    [eventTypes.DOCUMENT_REVIEW_APPROVED]: e => {
      const message = intl.formatMessage({ id: 'EventDetails.DOCUMENT_REVIEW_APPROVED' });
      return <MessageWithSingleLink message={message} url={e.updatedDocumentReferenceUrl} />;
    },
    [eventTypes.DOCUMENT_REVIEW_EXCEPTION]: e => {
      const reason = e ? e.description : intl.formatMessage({ id: 'EventDetails.NO_ADDITIONAL_INFO' });
      return intl.formatMessage({ id: 'EventDetails.DOCUMENT_REVIEW_EXCEPTION' }, { reason });
    },
    [eventTypes.DOCUMENT_REVIEW_REJECTED]: e => {
      let reason = '';

      if (Array.isArray(e.agentFeedback) && e.agentFeedback.length) {
        reason += `${e.agentFeedback.join(', ')}.`;
      }

      if (e.agentNotes) {
        if (reason) {
          reason += ' ';
        }

        reason += e.agentNotes.charAt(0).toUpperCase() + e.agentNotes.slice(1);
      }

      if (reason.length && reason.slice(-1) !== '.') {
        reason += '.';
      }

      return intl.formatMessage({ id: 'EventDetails.DOCUMENT_REVIEW_REJECTED' }, { reason });
    },
    [eventTypes.AWAITING_SUFFICIENT_INVENTORY]: e =>
      `${intl.formatMessage({ id: 'EventDetails.AWAITING_SUFFICIENT_INVENTORY' })}\n${
        e.sufficientInventory
          ? intl.formatMessage({ id: 'EventDetails.SUFFICIENT_ITEM_INVENTORY' })
          : intl.formatMessage({ id: 'EventDetails.INSUFFICIENT_ITEM_INVENTORY' })
      }`,
    [eventTypes.SUFFICIENT_INVENTORY]: e =>
      intl.formatMessage(
        { id: 'EventDetails.SUFFICIENT_INVENTORY' },
        { quantity: e.quantity, availableOn: e.availableOn }
      ),
    [eventTypes.INSUFFICIENT_INVENTORY]: e =>
      `${intl.formatMessage({ id: 'EventDetails.INSUFFICIENT_INVENTORY' })}\n${
        e.sufficientInventory
          ? intl.formatMessage({ id: 'EventDetails.SUFFICIENT_ITEM_INVENTORY' })
          : intl.formatMessage({ id: 'EventDetails.INSUFFICIENT_ITEM_INVENTORY' })
      }`,
    [eventTypes.CHANGE_REQUEST_RECEIVED]: e =>
      intl.formatMessage({ id: 'EventDetails.CHANGE_REQUEST_RECEIVED' }, { changeType: e.changeType }),
    [eventTypes.CHANGE_REQUEST_TRANSFERRED_TO_FULFILLER]: e =>
      intl.formatMessage({ id: 'EventDetails.CHANGE_REQUEST_TRANSFERRED_TO_FULFILLER' }, { changeType: e.changeType }),
    [eventTypes.CHANGE_RESPONSE]: e =>
      intl.formatMessage({ id: 'EventDetails.CHANGE_RESPONSE' }, { changeType: e.changeType, status: e.status }),
    [eventTypes.PROMISED_ARRIVAL_DATE_CHANGED]: e =>
      intl.formatMessage(
        { id: 'EventDetails.PROMISED_ARRIVAL_DATE_CHANGED' },
        {
          originalPromisedArrivalDate: e.originalPromisedArrivalDate,
          updatedPromisedArrivalDate: e.updatedPromisedArrivalDate,
        }
      ),
    [eventTypes.ORDER_ROUTED]: e =>
      intl.formatMessage({ id: 'EventDetails.ORDER_ROUTED' }, { fulfiller: e.fulfillerName }),
    [eventTypes.TRANSMITTED_TO_FULFILLER]: e =>
      intl.formatMessage({ id: 'EventDetails.TRANSMITTED_TO_FULFILLER' }, { fulfillerName: e.fulfillerName }),
    [eventTypes.SHIPMENT_UPDATED]: e => {
      const packages = uniqBy(e.packages, 'trackingId');
      const trackingStatuses = packages.map(
        ({
          trackingId = intl.formatMessage({ id: 'Events.UnknownTrackingId' }),
          trackingUrl,
          trackingStatus,
          items,
        }) => {
          const trackingLink =
            trackingUrl && trackingUrl.substring(0, 4) === 'http' ? (
              <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                {trackingId} <i className="fa fa-sm fa-external-link" />
              </a>
            ) : (
              <span>
                {trackingId} {trackingUrl}
              </span>
            );

          // get the quantity of this item that was in this shipment.
          // items can either be declared at the top level or package level in the shipment manager results
          let shipmentItems = e.items || items;
          if (shipmentItems) {
            shipmentItems = shipmentItems.filter(item => item.mcpItemId === e.itemId);
          }
          const qty = sumBy(shipmentItems, 'quantity');
          const { shipManTrackingStatuses } = initShipmentStatuses(intl);
          return (
            <div key={trackingId}>
              {trackingLink} ({intl.formatMessage({ id: 'ItemLevelFields.Quantity' })} {qty}) -{' '}
              {shipManTrackingStatuses[trackingStatus] || trackingStatus}
            </div>
          );
        }
      );

      return (
        <div>
          {trackingStatuses}
          <FulfillmentEventSummary additionalData={e} />
        </div>
      );
    },
    [eventTypes.UNSPECIFIED]: e => {
      let description = e.description;
      if (e.subType === 'NoShipmentPlan') {
        description =
          e.caveats &&
          uniq(
            e.caveats.map(c =>
              c.errorMessage
                ? isJsonString(c.errorMessage)
                  ? JSON.stringify(JSON.parse(c.errorMessage))
                  : c.errorMessage
                : c.message
            )
          ).join('\n');
      }
      return description ? intl.formatMessage({ id: 'EventDetails.UNSPECIFIED_DESCRIPTION' }, { description }) : '';
    },
  };
};

function getFulfillerErrorDetails(intl, e) {
  let errorDetails;
  if (e.additionalInfo) {
    const info = isJsonString(e.additionalInfo) ? JSON.parse(e.additionalInfo) : e.additionalInfo;
    errorDetails = info.message ? info.message : '';
    errorDetails += info.exceptionType ? ` ${info.exceptionType}` : '';
    errorDetails += info.exceptionMessage ? ` ${info.exceptionMessage}` : '';
    return intl.formatMessage({ id: 'EventDetails.DETAILS' }, { details: errorDetails || info });
  }
  return intl.formatMessage({ id: 'EventDetails.NO_ADDITIONAL_INFO' });
}

export default initEventDisplayDetails;
