import * as searchColumnFormatters from '../utils/searchColumnFormatters';
import * as searchColumnHeaderFormatters from '../utils/searchColumnHeaderFormatters';

export const defaultSearchColumns = [
  'status',
  'orderType',
  'merchant',
  'fulfiller',
  'recipient',
  'createdDate',
  'promiseDate',
  'customerTotal',
];

export const availableSearchColumns = [
  {
    key: 'preview',
    headingFormatter: searchColumnHeaderFormatters.preview,
    valueFormatter: searchColumnFormatters.preview,
  },
  {
    key: 'platformOrderId',
    headingFormatter: searchColumnHeaderFormatters.platformOrderId,
    valueFormatter: searchColumnFormatters.platformOrderId,
  },
  {
    key: 'merchantOrderId',
    headingFormatter: searchColumnHeaderFormatters.merchantOrderId,
    valueFormatter: searchColumnFormatters.merchantOrderId,
  },
  {
    key: 'fulfillerOrderId',
    headingFormatter: searchColumnHeaderFormatters.fulfillerOrderId,
    valueFormatter: searchColumnFormatters.fulfillerOrderId,
  },
  {
    key: 'fulfillmentGroupId',
    headingFormatter: searchColumnHeaderFormatters.fulfillmentGroupId,
    valueFormatter: searchColumnFormatters.fulfillmentGroupId,
  },
  {
    key: 'shortFulfillmentGroupId',
    headingFormatter: searchColumnHeaderFormatters.shortFulfillmentGroupId,
    valueFormatter: searchColumnFormatters.shortFulfillmentGroupId,
  },
  {
    key: 'platformItemId',
    headingFormatter: searchColumnHeaderFormatters.platformItemId,
    valueFormatter: searchColumnFormatters.platformItemId,
  },
  {
    key: 'merchantItemId',
    headingFormatter: searchColumnHeaderFormatters.merchantItemId,
    valueFormatter: searchColumnFormatters.merchantItemId,
  },
  {
    key: 'fulfillerItemId',
    headingFormatter: searchColumnHeaderFormatters.fulfillerItemId,
    valueFormatter: searchColumnFormatters.fulfillerItemId,
  },
  {
    key: 'shortItemId',
    headingFormatter: searchColumnHeaderFormatters.shortItemId,
    valueFormatter: searchColumnFormatters.shortItemId,
  },
  {
    key: 'status',
    headingFormatter: searchColumnHeaderFormatters.status,
    valueFormatter: searchColumnFormatters.status,
  },
  {
    key: 'testingConfiguration',
    headingFormatter: searchColumnHeaderFormatters.testingConfiguration,
    valueFormatter: searchColumnFormatters.testingConfiguration,
  },
  {
    key: 'merchant',
    headingFormatter: searchColumnHeaderFormatters.merchant,
    valueFormatter: searchColumnFormatters.merchant,
  },
  {
    key: 'fulfiller',
    headingFormatter: searchColumnHeaderFormatters.fulfiller,
    valueFormatter: searchColumnFormatters.fulfiller,
  },
  {
    key: 'recipient',
    headingFormatter: searchColumnHeaderFormatters.recipient,
    valueFormatter: searchColumnFormatters.recipient,
  },
  {
    key: 'createdDate',
    headingFormatter: searchColumnHeaderFormatters.createdDate,
    valueFormatter: searchColumnFormatters.createdDate,
  },
  {
    key: 'promiseDate',
    headingFormatter: searchColumnHeaderFormatters.promiseDate,
    valueFormatter: searchColumnFormatters.promiseDate,
  },
  {
    key: 'customerTotal',
    headingFormatter: searchColumnHeaderFormatters.customerTotal,
    valueFormatter: searchColumnFormatters.customerTotal,
  },
  {
    key: 'salesChannel',
    headingFormatter: searchColumnHeaderFormatters.salesChannel,
    valueFormatter: searchColumnFormatters.salesChannel,
  },
  {
    key: 'orderedSku',
    headingFormatter: searchColumnHeaderFormatters.orderedSkus,
    valueFormatter: searchColumnFormatters.orderedSkus,
  },
  {
    key: 'fulfilledSku',
    headingFormatter: searchColumnHeaderFormatters.fulfilledSkus,
    valueFormatter: searchColumnFormatters.fulfilledSkus,
  },
  {
    key: 'orderType',
    headingFormatter: searchColumnHeaderFormatters.orderType,
    valueFormatter: searchColumnFormatters.orderType,
  },
  {
    key: 'missedSlaTime',
    headingFormatter: searchColumnHeaderFormatters.missedSlaTime,
    valueFormatter: searchColumnFormatters.missedSlaTime,
  },
  {
    key: 'printJob',
    headingFormatter: searchColumnHeaderFormatters.printJob,
    valueFormatter: searchColumnFormatters.printJob,
  },
  {
    key: 'expectedShipDate',
    headingFormatter: searchColumnHeaderFormatters.expectedShipDate,
    valueFormatter: searchColumnFormatters.expectedShipDate,
  },
  {
    key: 'itemShippedDate',
    headingFormatter: searchColumnHeaderFormatters.itemShippedDate,
    valueFormatter: searchColumnFormatters.itemShippedDate,
  },
  {
    key: 'carrierServices',
    headingFormatter: searchColumnHeaderFormatters.carrierServices,
    valueFormatter: searchColumnFormatters.carrierServices,
  },
];
