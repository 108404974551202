import { callFetch } from './serviceHelpers';

export const getOrder = orderId => callFetch(process.env.REACT_APP_ORDER_MANAGER_URL, 'GET', `v1/orders/${orderId}`);

export const getChangeResponseByChangeRequestId = changeRequestId =>
  callFetch(
    process.env.REACT_APP_ORDER_MANAGER_URL,
    'GET',
    `v1/changerequests/fulfillmentgroupchangeresponse?changeRequestId=${changeRequestId}`
  );
