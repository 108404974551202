import * as types from '../constants/actiontypes.js';
import { putSetting, getSetting } from '../services/customizr';

const CUSTOMIZR_SAVED_SEARCHES_SETTING = 'OOPs-savedSearches';

export const setSearches = savedSearches => dispatch => {
  dispatch({
    type: types.SET_SAVED_SEARCHES,
    savedSearches,
  });
  return putSetting(CUSTOMIZR_SAVED_SEARCHES_SETTING, { savedSearches });
};

export const getSearches = () => dispatch => {
  return getSetting(CUSTOMIZR_SAVED_SEARCHES_SETTING)
    .then(response => {
      return dispatch({
        type: types.SET_SAVED_SEARCHES,
        savedSearches: response.savedSearches || [],
      });
    })
    .catch(error => {
      if (error.response && error.response.status === 404) {
        // do nothing.  let the defaults handle it
      } else {
        console.error(error);
      }
    });
};

export const setSelectedSavedSearch = selectedSavedSearch => dispatch => {
  dispatch({
    type: types.SET_SELECTED_SAVED_SEARCH,
    selectedSavedSearch,
  });
};
