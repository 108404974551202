import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Stepper from '@cimpress/react-components/lib/Stepper';
import Step from '@cimpress/react-components/lib/Step';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { alloy } from '@cimpress/react-components/lib/colors';
import { ItemRoutingDecisionViewer } from '@cimpress-technology/routing-decision-viewer-components';
import { initStatuses } from '../../constants/statuses';
import { CANCELLED, SHIPPED, UNKNOWN } from '../../constants/statustypes';
import ShipmentStatus from './ShipmentStatus';
import ProductionStatus from './ProductionStatus';
import * as utility from '../../utils/utilityfunctions';
import auth from '../../utils/auth';
import itemservicestatuses from '../../constants/itemservicestatuses';

const noop = () => {};

const ItemStatusStepper = ({
  currentStatus,
  onMoreDetailsClick,
  errors = [],
  missedSlas = [],
  itemStatuses,
  intl,
  item,
}) => {
  const statuses = initStatuses(intl);
  const statusSteps = Object.keys(statuses)
    .filter(statusKey => statuses[statusKey].step)
    .sort((a, b) => statuses[a].step - statuses[b].step);
  const warningLevel =
    currentStatus.type === CANCELLED
      ? 'default'
      : errors.length || currentStatus.type === UNKNOWN
      ? 'danger'
      : missedSlas.length
      ? 'warning'
      : 'success';
  let displayName = statuses[currentStatus.type].displayName;
  let step = statuses[currentStatus.type].step;
  let partial = false;
  let suspendedDetail = '';
  const [showSuspendedOrdersItemModal, setShowSuspendedOrdersItemModal] = useState(false);
  const isItemSuspendedInRouting = utility.isItemSuspendedInRouting(item);

  const onOpenSuspendedOrdersItemModal = () => {
    setShowSuspendedOrdersItemModal(true);
  };

  const onCloseSuspendedOrdersItemModal = () => {
    setShowSuspendedOrdersItemModal(false);
  };

  // there is no stepper position for cancelled or rejected, show the previous active step instead of stepper position
  if (!step) {
    step = statuses[currentStatus.previousType].step;
  }

  if (currentStatus.type === SHIPPED && currentStatus.partial) {
    displayName = statuses[currentStatus.type].displayNamePartial;
    partial = true;
  }

  if (errors.length) {
    const suspendedStatus = errors.find(error => error.name === itemservicestatuses.SUSPENDED);

    if (suspendedStatus) {
      suspendedDetail = suspendedStatus.detail;
    }
  }

  const colorProps = currentStatus.type === CANCELLED ? { color: alloy } : { status: warningLevel };
  const labelElement = (
    <div
      style={{ cursor: 'pointer' }}
      className={`label label-${warningLevel} ghost`}
      onClick={isItemSuspendedInRouting ? onOpenSuspendedOrdersItemModal : noop}>
      <span>
        {displayName} {isItemSuspendedInRouting ? <i className="fa fa-sm fa-wrench" /> : null}
      </span>
    </div>
  );
  return (
    <div
      style={{ width: '200px', textAlign: 'center', justifySelf: 'flex-end', cursor: 'default' }}
      onClick={e => e.stopPropagation()}>
      {errors.length && suspendedDetail ? (
        <Tooltip direction="top" contents={suspendedDetail}>
          {labelElement}
        </Tooltip>
      ) : (
        labelElement
      )}
      <ShipmentStatus currentStatus={currentStatus} statuses={Object.values(itemStatuses)} />
      <ProductionStatus currentStatus={currentStatus} itemStatuses={itemStatuses} />
      <Stepper small activeStep={step - 1} {...colorProps} partial={partial}>
        {statusSteps.map(statusKey => (
          <Step key={statusKey} name={statusKey} tip={statuses[statusKey].displayName} />
        ))}
      </Stepper>
      <ItemRoutingDecisionViewer
        accessToken={auth.getAccessToken()}
        environment={process.env.REACT_APP_ENV === 'loc' ? 'int' : process.env.REACT_APP_ENV}
        item={item}
        isModal={true}
        showModal={showSuspendedOrdersItemModal}
        closeModal={onCloseSuspendedOrdersItemModal}
      />
      {errors.length || missedSlas.length ? (
        <div style={{ marginTop: '-25px' }}>
          <a className="clickable" onClick={onMoreDetailsClick}>
            <FormattedMessage id="Global.SeeStatusDetails" />
          </a>
        </div>
      ) : null}
    </div>
  );
};

ItemStatusStepper.propTypes = {
  currentStatus: PropTypes.shape({ type: PropTypes.string }),
  errors: PropTypes.array,
  missedSlas: PropTypes.array,
  itemStatuses: PropTypes.object,
  onMoreDetailsClick: PropTypes.func,
  intl: PropTypes.object,
  item: PropTypes.object.isRequired,
};

export default injectIntl(ItemStatusStepper);
