import { callFetch, buildOptions } from './serviceHelpers';

export const queryNotifications = (fulfillerId, orderId, itemId) =>
  callFetch(
    process.env.REACT_APP_FULFILLMENT_ORDER_MANAGER_URL,
    'GET',
    `v1/notifications?fulfillerId=${fulfillerId}&itemId=${itemId}&orderId=${orderId}&status=All`
  );

export const postShipment = json => {
  const options = buildOptions('POST', JSON.stringify(json));
  return fetch(`${process.env.REACT_APP_FULFILLMENT_ORDER_MANAGER_URL}/v1/shipments`, options);
};

export const postNotification = json => {
  const options = buildOptions('POST', JSON.stringify(json));
  return fetch(`${process.env.REACT_APP_FULFILLMENT_ORDER_MANAGER_URL}/v1/notifications`, options);
};

export const respondNotification = (json, notificationId, itemId, response) => {
  const options = buildOptions('POST', JSON.stringify(json));
  return fetch(
    `${process.env.REACT_APP_FULFILLMENT_ORDER_MANAGER_URL}/v1/notifications/${notificationId}/items/${itemId}/${response}`,
    options
  );
};
