import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import MiniItemDetails from '../MiniItemDetails';

const FulfillmentGroupSelector = ({ fulfillmentGroups, selectedFulfillmentGroupIds, toggleFulfillmentGroup }) => {
  if (!fulfillmentGroups || !fulfillmentGroups.length) {
    return null;
  }

  return (
    <div>
      {fulfillmentGroups.map(items => {
        if (!items.length) {
          return null;
        }
        const firstItem = items[0];
        const fulfillmentGroupId = firstItem.fulfillmentGroupId;
        const fulfillerName = firstItem.fulfiller ? firstItem.fulfiller.name : firstItem.fulfillerId;
        const isSelected = selectedFulfillmentGroupIds.includes(fulfillmentGroupId);
        return (
          <div key={fulfillmentGroupId}>
            <Checkbox
              label={fulfillerName}
              checked={isSelected}
              onChange={() => toggleFulfillmentGroup(fulfillmentGroupId)}
            />
            <table className="table">
              <tbody>
                {items.map(item => (
                  <MiniItemDetails key={item.itemId} item={item} selectable={false} isSelected={isSelected} />
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

FulfillmentGroupSelector.propTypes = {
  fulfillmentGroups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  selectedFulfillmentGroupIds: PropTypes.array,
  toggleFulfillmentGroup: PropTypes.func,
};

export default FulfillmentGroupSelector;
