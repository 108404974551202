export function initSearchStatuses(intl) {
  return [
    {
      value: 'standardizingOrder',
      label: intl.formatMessage({ id: 'Statuses.StandardizingOrder' }),
    },
    {
      value: 'docReview',
      label: intl.formatMessage({ id: 'Statuses.DocumentReview' }),
    },
    {
      value: 'routing',
      label: intl.formatMessage({ id: 'Statuses.Routing' }),
    },
    {
      value: 'shippingAggregation',
      label: intl.formatMessage({ id: 'Statuses.ShippingAggregation' }),
    },
    {
      value: 'processing',
      label: intl.formatMessage({ id: 'Statuses.Processing' }),
    },
    {
      value: 'awaitingFulfillerResponse',
      label: intl.formatMessage({ id: 'Statuses.AwaitingFulfillerResponse' }),
    },
    {
      value: 'inProduction',
      label: intl.formatMessage({ id: 'Statuses.InProduction' }),
    },
    {
      value: 'production.production',
      label: intl.formatMessage({ id: 'Statuses.Production' }),
    },
    {
      value: 'production.productionAccepted',
      label: intl.formatMessage({ id: 'Statuses.ProductionAccepted' }),
    },
    {
      value: 'shipped',
      label: intl.formatMessage({ id: 'Statuses.Shipped' }),
    },
    {
      value: 'cancelled',
      label: intl.formatMessage({ id: 'Statuses.Cancelled' }),
    },
    {
      value: 'rejected',
      label: intl.formatMessage({ id: 'Statuses.Rejected' }),
    },
    {
      value: 'suspended',
      label: intl.formatMessage({ id: 'Statuses.Suspended' }),
    },
  ];
}
