import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export default class MessageWithSingleLink extends React.Component {
  render() {
    return (
      <span>
        {this.props.message}{' '}
        {this.props.url && (
          <a href={this.props.url} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="Global.Here" /> <i className="fa fa-sm fa-external-link" />
          </a>
        )}
      </span>
    );
  }
}

MessageWithSingleLink.propTypes = {
  message: PropTypes.string,
  url: PropTypes.string,
};
