import { callFetch } from './serviceHelpers';
import auth from '../utils/auth';

const endpointUrl = process.env.REACT_APP_SUBSCRIPTION_HUB_URL;

export const putUser = (authorizationCode, redirectUri) => {
  return callFetch(endpointUrl, 'PUT', `v1/users/${auth.getProfile().id}`, { authorizationCode, redirectUri });
};

export const getSubscriptions = params => {
  const searchParams = new URLSearchParams(params);
  return callFetch(endpointUrl, 'GET', `v1/subscriptions?${searchParams.toString()}`).then(rs => rs.subscriptions);
};

export const postSubscription = subscription => {
  return callFetch(endpointUrl, 'POST', `v1/subscriptions`, JSON.stringify(subscription));
};

export const deleteSubscription = subscriptionId => {
  return callFetch(endpointUrl, 'DELETE', `v1/subscriptions/${subscriptionId}`);
};
