/***********************************************
 * sumoSearch.js
 * Moved and modified from
 * https://mcpstash.cimpress.net/projects/FFR/repos/administration/browse/SumoLogicSearchEngine/SumoSearch.js
 * This utility function generates a url that does a full text search using keywords passed in for the last 24 hours
 ***********************************************/
export function generateSumoSearchUrlForOrder(orderId, orderCreateTime) {
  var searchParams = [orderId, '_index=MCP_Commerce_Application'];
  var searchStartTime = new Date(orderCreateTime).getTime();
  var searchEndTime = new Date().getTime();
  var search = encodeURI(searchParams.join(' AND '));
  var jsonAuto = encodeURI(
    '| json auto keys "additionaldata.httpbody","additionaldata.httpdirection","application","orioncorrelationdata.rootid"'
  );
  var whereClause = encodeURI('| where !(application in ("MEX Orders","Order Console"))');
  var url =
    'https://service.us2.sumologic.com/ui/#section/search/@' +
    searchStartTime +
    ',' +
    searchEndTime +
    '@' +
    search +
    jsonAuto +
    whereClause;

  return url;
}
