import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { availableSearchColumns } from '../../constants/searchColumns';

const alignStyle = { verticalAlign: 'middle' };
const SearchResultsHeader = ({
  searchColumns,
  reduxState,
  showCheckbox,
  isCheckboxEnabled,
  disabledMessage,
  isSelected,
  toggleSelected,
}) => {
  return (
    <tr>
      {showCheckbox ? (
        <th style={alignStyle}>
          {isCheckboxEnabled ? (
            <Checkbox style={{ margin: 0 }} checked={isSelected} onChange={toggleSelected} />
          ) : (
            <Tooltip direction="right" contents={disabledMessage}>
              <Checkbox style={{ margin: 0 }} disabled />
            </Tooltip>
          )}
        </th>
      ) : null}
      {availableSearchColumns
        .filter(col => searchColumns.includes(col.key))
        .map(searchColumn => {
          const { headerKey, tooltipKey } = searchColumn.headingFormatter(reduxState);
          const header = <FormattedMessage id={headerKey} />;
          if (tooltipKey) {
            return (
              <th key={headerKey} style={alignStyle}>
                <Tooltip contents={<FormattedMessage id={tooltipKey} />}>
                  {header}
                  <IconInformationCircle style={{ marginLeft: '5px' }} className="text-info" weight="fill" />
                </Tooltip>
              </th>
            );
          } else {
            return (
              <th key={headerKey} style={alignStyle}>
                {header}
              </th>
            );
          }
        })}
    </tr>
  );
};

SearchResultsHeader.propTypes = {
  searchColumns: PropTypes.arrayOf(PropTypes.string),
  reduxState: PropTypes.object,
  showCheckbox: PropTypes.bool,
  isCheckboxEnabled: PropTypes.bool,
  disabledMessage: PropTypes.node,
  isSelected: PropTypes.bool,
  toggleSelected: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    searchColumns: { searchColumns },
  } = state;

  return { searchColumns, reduxState: state };
};

export default connect(mapStateToProps)(injectIntl(SearchResultsHeader));
