import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FlexBox } from '@cimpress/react-components';
import IconTimeClockCircle from '@cimpress-technology/react-streamline-icons/lib/IconTimeClockCircle';
import IconCheckBadge from '@cimpress-technology/react-streamline-icons/lib/IconCheckBadge';
import { info, warning, danger, platinum } from '@cimpress/react-components/lib/colors';
import styled from 'styled-components';
import { debounce } from 'lodash';

const sizes = {
  s: '2px',
  m: '4px',
  l: '6px',
  xl: '8px',
};

const RESIZE_TIMEOUT = 100;
const ICON_WIDTH = 14;

const ProgressBar = styled.div`
  width: ${props => props.width};
  background: ${props => props.color || info.base};
  height: 100%;
`;

const ProgressBarContainer = styled.div`
  height: ${props => props.height};
  background: ${props => props.color || platinum};
`;

const DashedLine = styled.div`
  border-top: 2px dashed ${props => props.color || info.base};
  width: 100%;
  margin: 0px 1px;
`;

const VerticalLine = styled.div`
  border-${props => props.side}: medium solid ${props => props.color || info.base};
  height: 250%;
`;

const Clock = styled(IconTimeClockCircle)`
  left: 50%;
  top: -15px;
  transform: translateX(-50%);
`;

const AbbreviatedContainer = styled(FlexBox)`
  min-width: 50px;
`;

const HoverContainer = styled.div`
  :hover {
    .growable {
      transition: transform 0.2s;
      transform: scaleY(1.5);
    }
  }
`;

const StyledProgressBar = ({ size = 's', color, overflowColor, value, className, abbreviated }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState();
  const barHeight = sizes[size];
  const isDelayed = value > 100; // When progress bar is larger than 100%, we assume this status has taken too long
  const normalizedPercentage = isDelayed ? 10000 / value : value;

  const onResize = debounce(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, RESIZE_TIMEOUT);

  useEffect(() => {
    if (!abbreviated) {
      window.addEventListener('resize', onResize);
      onResize();
    }

    return () => !abbreviated && window.removeEventListener('resize', onResize);
  }, [abbreviated, onResize]);

  return (
    <div className={className} ref={ref}>
      {!width || width > ICON_WIDTH ? (
        <ProgressBarContainer
          className="growable"
          height={barHeight}
          {...(isDelayed ? { color: overflowColor || danger.base } : {})}>
          <ProgressBar width={`${normalizedPercentage}%`} color={isDelayed ? color || warning.base : info.base} />
        </ProgressBarContainer>
      ) : (
        <IconCheckBadge color={info.base} />
      )}
    </div>
  );
};

const AbbreviatedProgressBar = props => {
  const { value, color } = props;
  const barColor = value > 100 ? warning.base : color || info.base;
  return (
    <AbbreviatedContainer>
      <FlexBox grow={1} middle>
        <StyledProgressBar {...props} color={barColor} overflowColor={barColor} />
        <VerticalLine side="left" color={barColor} />
      </FlexBox>
      <FlexBox grow={2} middle center style={{ position: 'relative' }}>
        <Clock size="1x" color={barColor} style={{ position: 'absolute' }} />
        <DashedLine className="growable" color={barColor} />
      </FlexBox>
      <FlexBox grow={1} middle>
        <VerticalLine side="right" color={barColor} />
        <StyledProgressBar {...props} color={barColor} overflowColor={barColor} />
      </FlexBox>
    </AbbreviatedContainer>
  );
};

const MultiColorProgressBar = props => {
  return (
    <HoverContainer>
      {props.abbreviated ? <AbbreviatedProgressBar {...props} /> : <StyledProgressBar {...props} />}
    </HoverContainer>
  );
};

MultiColorProgressBar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
  color: PropTypes.string,
  overflowColor: PropTypes.string,
  value: PropTypes.number,
  className: PropTypes.string,
  abbreviated: PropTypes.bool,
};

export default MultiColorProgressBar;
