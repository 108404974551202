import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@cimpress/react-components';
import { countBy, some } from 'lodash';
import { injectIntl, createIntl, createIntlCache } from 'react-intl';

// You can pass your messages to the IntlProvider. Optional: remove if unneeded.
const messages = require('../../i18n').default['en']; // en.json

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en',
    messages,
  },
  cache
);

const productionStatus = {
  value: 'inProduction',
  label: intl.formatMessage({ id: 'Statuses.InProduction' }),
};
const productionSubStatuses = [
  {
    value: 'production.production',
    label: intl.formatMessage({ id: 'Statuses.Production' }),
  },
  {
    value: 'production.productionAccepted',
    label: intl.formatMessage({ id: 'Statuses.ProductionAccepted' }),
  },
];

// ECOM-3216 Item Sub-filters
export function selectionChange(item, selectedItemsKey, props) {
  const { selectedItems, setProductionIndeterminate } = props;

  /** @type {Array} */
  let selectedState = [...selectedItems];

  /**
   * Is this item Production?
   *  - Yes
   *    Is Production already in the List?
   *      + Yes
   *        Remove Production
   *        Remove Substatuses
   *        Set productionIndeterminate to false
   *      + No
   *        Add Production
   *        Add Substatuses
   *        Set productionIndeterminate to false
   *  - No, Is this Item production.* ?
   *    + Yes
   *      Is this Item already in the List?
   *      - Yes
   *        Remove Item
   *      - No
   *        Add Item
   *      How many substatuses are left?
   *      + 0
   *        Set productionIndeterminate to false
   *        break;
   *      + possibleSubStatuses.length
   *        Add Production
   *        Set productionIndeterminate to false
   *        break;
   *      + default:
   *        Remove Production
   *        Set productionIndeterminate to true
   *    + No
   *        Add Status
   *        Add Production (deduplicated later)
   *  - No, It's some other status
   *    call props.selectionChange as usual
   */
  function indexOfItemInSelectedState(item) {
    return selectedState.map(s => s.value).indexOf(item.value);
  }
  function isItemProduction() {
    return item.value === productionStatus.value;
  }
  function isItemSubStatus() {
    return String(item.value).startsWith('production.');
  }
  function isProductionSelected() {
    return selectedState.some(s => s.value === productionStatus.value);
  }
  function isItemAlreadySelected() {
    return selectedState.map(s => s.value).indexOf(item.value) !== -1;
  }
  function removeItemFromSelectedState() {
    selectedState.splice(indexOfItemInSelectedState(item), 1);
  }
  function removeAllSubStatusesFromSelectedState() {
    let selectedStateValues = selectedState.map(s => s.value);
    for (let status of productionSubStatuses) {
      if (selectedStateValues.includes(status.value)) {
        let index = selectedState.map(s => s.value).indexOf(status.value);
        selectedState.splice(index, 1);
      }
      // if it's not in the array, skip removal
    }
  }
  function removeProductionFromSelectedItems() {
    if (indexOfItemInSelectedState(productionStatus) !== -1) {
      selectedState.splice(indexOfItemInSelectedState(productionStatus), 1);
    }
  }

  if (isItemProduction()) {
    if (isProductionSelected()) {
      selectedState.splice(indexOfItemInSelectedState(productionStatus), 1); // remove production
      removeAllSubStatusesFromSelectedState(); // remove substatuses
    } else {
      selectedState.push(productionStatus); // add production
      selectedState = selectedState.concat(productionSubStatuses); // add substatus
    }
    setProductionIndeterminate(false); // both cases
  } else if (isItemSubStatus()) {
    // Add or Remove Item Accordingly
    if (isItemAlreadySelected()) {
      removeItemFromSelectedState();
    } else {
      selectedState.push(item);
    }
    // With Item added or Removed, get count of substatuses for determining indeterminacy
    switch (countBy(selectedState, item => String(item.value).startsWith('production.')).true) {
      case 0:
      case undefined: {
        // countBy returns an object, the `true` property may be undefined
        setProductionIndeterminate(false);
        break;
      }
      case productionSubStatuses.length: {
        selectedState.push(productionStatus);
        setProductionIndeterminate(false);
        break;
      }
      default: {
        setProductionIndeterminate(true);
        removeProductionFromSelectedItems();
        break;
      }
    }
  } else {
    // This item isn't the production status or a substatus, prodocede as usual
    props.selectionChange(item, selectedItemsKey);
    return;
  }

  // Deduplicate List to avoid issues
  selectedState = Array.from(new Set(selectedState.map(x => x.value))).map(v => selectedState.find(i => i.value === v));

  props.selectionChange(selectedState, selectedItemsKey);
}

const ItemCheckbox = props => {
  const { item, selectedItems, selectedItemsKey } = props;

  // add production to selected, if for some reason all substatuses are selected and it isn't
  // prevents page-load bug where first click on Production (3) (when already selected) will Add the selection
  // behind the scenes. This causes UX Bug, where first click is "eaten" and appears to have no action
  if (
    countBy(selectedItems, item => String(item.value).startsWith('production.')).true === productionSubStatuses.length
  ) {
    if (!selectedItems.some(si => si.value === productionStatus.value)) {
      selectedItems.push(productionStatus);
    }
  }

  // ECOM-3216 Item Sub-statuses
  const isProductionChecked = () => {
    if (item.value === 'inProduction') {
      if (
        countBy(selectedItems, item => String(item.value).startsWith('production.')).true ===
        productionSubStatuses.length
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Checkbox
      style={{ padding: '6px 16px' }}
      label={item.label}
      checked={isProductionChecked() || some(selectedItems, selectedItem => selectedItem.value === item.value)}
      onChange={() => selectionChange(item, selectedItemsKey, props)}
      indeterminate={
        item.value === 'inProduction' &&
        countBy(selectedItems, item => String(item.value).startsWith('production.')).true > 0 &&
        countBy(selectedItems, item => String(item.value).startsWith('production.')).true < 3
      }
    />
  );
};

ItemCheckbox.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  selectedItemsKey: PropTypes.string,
  selectionChange: PropTypes.func,
};

export default injectIntl(ItemCheckbox);
