import React from 'react';
import { FormattedMessage } from 'react-intl';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import styled from 'styled-components';

const Line = styled.div`
  margin-left: 1rem;
`;

const RawJsonView = ({ json, deprecatedFields = [], messagePrefix }) => {
  const getValueType = value => {
    let valueType = typeof value;
    const isSimpleValue = ['string', 'number', 'boolean'].includes(valueType) || !value;

    if (valueType === 'boolean' || !value) {
      valueType = 'literal';
    }

    if (value === '') {
      valueType = 'string';
    }
    return { valueType, isSimpleValue };
  };

  const displayJson = (jsonObj, isValueInsideArray) => {
    // remove undefined fields from the json
    const sanitizedObject = omitBy(jsonObj, isUndefined);

    let index = 0;
    const length = Object.keys(sanitizedObject).length;
    return Object.keys(sanitizedObject).map(key => {
      const value = sanitizedObject[key];
      const { valueType, isSimpleValue } = getValueType(value);

      const comma = index++ === length - 1 ? '' : ',';
      const formattedValue = (valueType === 'string' ? `"${value}"` : value) + comma;
      const isValueAnArray = Array.isArray(value);

      return (
        <Line key={key}>
          {deprecatedFields.includes(key) && (
            <Tooltip style={{ marginLeft: '-14px' }} contents={<FormattedMessage id={`${messagePrefix}.${key}`} />}>
              <IconAlertTriangle className="text-warning" weight="fill" />
            </Tooltip>
          )}
          {!isValueInsideArray && (
            <>
              <span className="hljs-attr">"{key}"</span>
              {': '}
            </>
          )}

          {isSimpleValue ? (
            <>
              <span className={`hljs-${valueType}`}>{`${formattedValue}`}</span>
              {'\n'}
            </>
          ) : (
            <>
              {`${isValueAnArray ? '[' : '{'}\n`}
              {displayJson(value, isValueAnArray)}
              {`${isValueAnArray ? ']' : '}'}${comma}`}
            </>
          )}
        </Line>
      );
    });
  };

  return (
    <pre className="highlight-block">
      <code className="json hljs">
        {'{\n'}
        {displayJson(json)}
        {'}\n'}
      </code>
    </pre>
  );
};

export default RawJsonView;
