import React from 'react';
import { Alert as RawAlert } from '@cimpress/react-components';
import { FormattedMessage } from 'react-intl';

export const Alert = ({ errorMessage, formattedErrorMessageId }) => (
  <RawAlert
    message={
      <div>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            <FormattedMessage id={formattedErrorMessageId} />
          </span>
        </div>
        <div>{errorMessage}</div>
      </div>
    }
  />
);
