import React, { useReducer, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import some from 'lodash/some';
import Modal from '@cimpress/react-components/lib/Modal';

import { acknowledgeDiscrepancies } from '../../../actions/watchdogactions';
import { reloadOrder } from '../../../actions/orderv2actions';
import ActionLink from '../../shared/ActionLink';
import MissedSlaRow from './MissedSlaRow';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';
import { initialState, reducer } from './ClearOrderAlertsModal';

const ClearItemAlertsModal = ({ merchantId, itemId, clearableStatuses = [], linkTitle }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    if (clearableStatuses.length === 1) {
      toggleSelected(clearableStatuses[0].name);
    }
  }, [clearableStatuses]);

  const open = () => {
    dispatch({ type: 'showModal' });

    trackEvent(TrackingEventTitles.CLEAR_ITEM_ALERTS_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.ITEM_ID]: itemId,
    });
  };

  const close = () => {
    dispatch({ type: 'closeModal' });
  };

  const closeAndReload = () => {
    dispatch({ type: 'closeModal' });
    reduxDispatch(reloadOrder());
  };

  const updateComment = (key, text) => {
    dispatch({ type: 'updateComment', key, text });
  };

  const toggleSelected = key => {
    dispatch({ type: 'toggleSelected', key });
  };

  const onSubmit = async () => {
    dispatch({ type: 'submitting' });

    const clearedAlerts = [];
    clearableStatuses.forEach(status => {
      if (state[status.name]) {
        clearedAlerts.push({
          itemId: itemId,
          status: status.name,
          comment: state[status.name].comment || '',
        });
      }
    });

    try {
      await reduxDispatch(acknowledgeDiscrepancies(merchantId, clearedAlerts));
      closeAndReload();

      trackEvent(TrackingEventTitles.CLEAR_ITEM_ALERTS_SUBMITTED, {
        [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.ITEM_ID]: itemId,
        [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.CLEARED_STATUSES]: clearedAlerts.map(
          alert => alert.status
        ),
        [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_ACTIONS.HAS_CLEARED_STATUS_COMMENT]: clearedAlerts.some(
          alert => alert.comment
        ),
      });
    } catch (error) {
      close();
    }
  };

  const { submitting, showModal } = state;
  const numMissedSlas = clearableStatuses.length;

  // TODO: use react-intl pluralization instead of double translation keys
  const modalTitle =
    numMissedSlas > 1 ? (
      <FormattedMessage id="WatchDog.ClearAlerts.ItemLevel" />
    ) : (
      <FormattedMessage id="WatchDog.ClearAlert.ItemLevel" />
    );

  return (
    <div>
      <ActionLink
        text={linkTitle || modalTitle}
        action={open}
        disabled={!numMissedSlas}
        disabledMessage={<FormattedMessage id="WatchDog.NoAlertsToClear" />}
      />
      <Modal
        size="lg"
        show={showModal}
        onRequestHide={close}
        title={modalTitle}
        closeButton
        footer={
          <div>
            <button className="btn btn-default" onClick={close} disabled={submitting}>
              {' '}
              <FormattedMessage id="Global.Cancel" />{' '}
            </button>
            <button
              className="btn btn-primary"
              onClick={onSubmit}
              disabled={!some(clearableStatuses, s => state[s.name]) || submitting}>
              {modalTitle}
            </button>
          </div>
        }>
        <p>
          <FormattedMessage id="WatchDog.ClearAlertsInformation" />
        </p>
        {clearableStatuses.map(({ name }) => (
          <MissedSlaRow
            key={name}
            name={name}
            selected={Boolean(state[name])}
            data={state[name]}
            updateComment={updateComment}
            toggleSelected={toggleSelected}
          />
        ))}
      </Modal>
    </div>
  );
};

ClearItemAlertsModal.propTypes = {
  itemId: PropTypes.string.isRequired,
  merchantId: PropTypes.string,
  clearableStatuses: PropTypes.array,
  linkTitle: PropTypes.node,
};

export default ClearItemAlertsModal;
