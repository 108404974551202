import stringify from 'json-stringify-safe';
import * as types from '../constants/actiontypes.js';
import { formatSearchParams } from '../utils/searchModels';
import { callFetch } from '../services/serviceHelpers';
import { getOrder } from '../services/orderManager';

export const setSearchTerm = searchTerm => {
  return {
    type: types.SET_SEARCH_TERM,
    searchTerm,
  };
};

export const setStartDate = (startDate, isClick = false) => (dispatch, getState) => {
  const {
    userPreferences: { displayTimezone },
  } = getState();
  dispatch({
    type: types.SET_START_DATE,
    startDate,
    displayTimezone,
    isClick,
  });
};

export const setEndDate = (endDate, isClick = false) => (dispatch, getState) => {
  const {
    userPreferences: { displayTimezone },
  } = getState();
  dispatch({
    type: types.SET_END_DATE,
    endDate,
    displayTimezone,
    isClick,
  });
};

export const setMerchants = merchants => {
  return {
    type: types.SET_MERCHANTS,
    merchants,
  };
};

export const setFulfillers = fulfillers => {
  return {
    type: types.SET_FULFILLERS,
    fulfillers,
  };
};

export const setDelayedStatuses = delayedStatuses => {
  return {
    type: types.SET_DELAYED_STATUSES,
    delayedStatuses,
  };
};

export const setErrorStatuses = errorStatuses => {
  return {
    type: types.SET_ERROR_STATUSES,
    errorStatuses,
  };
};

export const setOrderTypes = orderTypes => {
  return {
    type: types.SET_ORDER_TYPES,
    orderTypes,
  };
};

export const setSalesChannels = salesChannels => {
  return {
    type: types.SET_SALES_CHANNELS,
    salesChannels,
  };
};

export const setProductCategories = productCategories => {
  return {
    type: types.SET_PRODUCT_CATEGORIES,
    productCategories,
  };
};

export const setClaimTypes = claimTypes => {
  return {
    type: types.SET_CLAIM_TYPES,
    claimTypes,
  };
};

export const setComplaintTypes = complaintTypes => {
  return {
    type: types.SET_COMPLAINT_TYPES,
    complaintTypes,
  };
};

export const setChangeRequestTypes = changeRequestTypes => {
  return {
    type: types.SET_CHANGE_REQUEST_TYPES,
    changeRequestTypes,
  };
};

export const setStatuses = statuses => {
  return {
    type: types.SET_STATUSES,
    statuses,
  };
};

export const setSort = (sortField, sortOrder) => {
  return {
    type: types.SET_SEARCH_SORT,
    sortField,
    sortOrder,
  };
};

export const clearSearch = () => {
  return {
    type: types.CLEAR_SEARCH,
  };
};

export const search = (params, goToOrderDetails) => async dispatch => {
  dispatch({ type: types.SEARCH_REQUEST });
  try {
    const response = await callFetch(
      process.env.REACT_APP_ORDER_MANAGER_URL,
      'POST',
      'v2/ordersearch',
      stringify(formatSearchParams(params))
    );

    // The following is a simple check to see if the search was looking for an order older than 6 months.
    // If we get no results, we take a look at the searchTerm and see if we get a response from order manager
    // for an actual order. This would mean the search term was a platform order id.
    // As a result, we can push the user to the order page.
    // This will obviously fail for any search term that isn't an order id. That's fine, and the error we get back
    // should not impact the overall search.
    if (response.totalResults === 0) {
      try {
        const orderResponse = await getOrder(params.searchTerm);
        goToOrderDetails(orderResponse.orderId);
      } catch (e) {
        // The following error is not important and we don't want it to impact the overall search results.
        console.error(e);
      }
    }

    return dispatch({ type: types.SEARCH_SUCCESS, response });
  } catch (error) {
    dispatch({
      type: types.SEARCH_FAILURE,
      payload: error.response
        ? `${error.response.status} ${error.response.statusText}`
        : `An unknown exception occurred`,
      errorMessage: error.message,
    });
  }
};

export const changePage = page => {
  return {
    type: types.CHANGE_PAGE,
    page,
  };
};

export const setLastOrder = orderId => {
  return {
    type: types.SELECT_ORDER,
    orderId,
  };
};
