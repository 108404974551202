export const status = () => ({
  headerKey: 'OrderLevelFields.Status',
  tooltipKey: 'OrderLevelFieldTooltips.Status',
});
export const testingConfiguration = () => ({
  headerKey: 'OrderLevelFields.TestingConfiguration',
});
export const merchant = () => ({
  headerKey: 'OrderLevelFields.Merchant',
});
export const fulfiller = () => ({
  headerKey: 'OrderLevelFields.Fulfillers',
});
export const recipient = () => ({
  headerKey: 'OrderLevelFields.Recipient',
  tooltipKey: 'OrderLevelFieldTooltips.Recipient',
});
export const createdDate = () => ({
  headerKey: 'OrderLevelFields.CreatedDate',
  tooltipKey: 'OrderLevelFieldTooltips.CreatedDate',
});
export const promiseDate = () => ({
  headerKey: 'OrderLevelFields.PromiseDate',
  tooltipKey: 'OrderLevelFieldTooltips.PromiseDate',
});
export const customerTotal = () => ({
  headerKey: 'OrderLevelFields.Total',
  tooltipKey: 'OrderLevelFieldTooltips.Total',
});
export const itemShippedDate = () => ({
  headerKey: 'ItemLevelFields.ShippedDates',
  tooltipKey: 'ItemLevelFields.ShippedDatesTooltip',
});
export const salesChannel = () => ({
  headerKey: 'OrderLevelFields.SalesChannels',
});
export const merchantOrderId = () => ({
  headerKey: 'OrderLevelFields.MerchantOrderIDNoParam',
  tooltipKey: 'OrderLevelFieldTooltips.MerchantOrderID',
});
export const fulfillerOrderId = () => ({
  headerKey: 'OrderLevelFields.FulfillerOrderIDNoParam',
  tooltipKey: 'OrderLevelFieldTooltips.FulfillerOrderID',
});
export const platformOrderId = () => ({
  headerKey: 'OrderLevelFields.PlatformID',
  tooltipKey: 'OrderLevelFieldTooltips.PlatformID',
});
export const fulfillmentGroupId = () => ({
  headerKey: 'OrderLevelFields.FulfillmentGroupId',
  tooltipKey: 'OrderLevelFieldTooltips.FulfillmentGroupId',
});
export const shortFulfillmentGroupId = () => ({
  headerKey: 'OrderLevelFields.ShortFulfillmentGroupId',
  tooltipKey: 'OrderLevelFieldTooltips.ShortFulfillmentGroupId',
});
export const orderedSkus = () => ({
  headerKey: 'ItemLevelFields.OrderedSku',
});
export const fulfilledSkus = () => ({
  headerKey: 'ItemLevelFields.FulfilledSku',
});
export const platformItemId = () => ({
  headerKey: 'ItemLevelFields.PlatformItemID',
  tooltipKey: 'ItemLevelFields.PlatformItemIDTooltip',
});
export const merchantItemId = () => ({
  headerKey: 'ItemLevelFields.MerchantItemIDNoParam',
  tooltipKey: 'ItemLevelFields.MerchantItemIDTooltip',
});
export const fulfillerItemId = () => ({
  headerKey: 'ItemLevelFields.FulfillerItemIDNoParam',
  tooltipKey: 'ItemLevelFields.FulfillerItemIDTooltip',
});
export const shortItemId = () => ({
  headerKey: 'ItemLevelFields.ShortItemID',
  tooltipKey: 'ItemLevelFields.ShortItemIDTooltip',
});
export const orderType = () => ({
  headerKey: 'OrderLevelFields.OrderType',
});
export const preview = () => ({
  headerKey: 'Global.Preview',
});
export const missedSlaTime = () => ({
  headerKey: 'ItemLevelFields.MissedSlaHours',
});
export const printJob = () => ({
  headerKey: 'ItemLevelFields.OnPrintJob',
});
export const expectedShipDate = () => ({
  headerKey: 'ItemLevelFields.ExpectedShipDate',
  tooltipKey: 'ItemLevelFields.ExpectedShipDateTooltip',
});
export const carrierServices = () => ({
  headerKey: 'ItemLevelFields.CarrierServices',
  tooltipKey: 'ItemLevelFields.CarrierServicesTooltip',
});
