import { callFetch } from './serviceHelpers';
import get from 'lodash/get';

const carrierServiceCache = {};

export const getCarrierServiceById = recsId => {
  if (carrierServiceCache[recsId]) {
    return Promise.resolve(carrierServiceCache[recsId]);
  }
  return callFetch(process.env.REACT_APP_CARRIERS_API_URL, 'get', `api/v1/carrierServices/${recsId}`).then(
    recsResponse => {
      carrierServiceCache[recsId] = recsResponse.name;
      return recsResponse.name;
    }
  );
};

export const getAllCarrierServices = async nextFetchLink => {
  const fetchUrl = nextFetchLink
    ? nextFetchLink
    : `${process.env.REACT_APP_CARRIERS_API_URL}/api/v1/carrierServices?size=200`;
  const fetchResponse = await callFetch(fetchUrl);
  const carrierServices = fetchResponse.carrierServices;
  carrierServices.forEach(carrierService => (carrierServiceCache[carrierService.key] = carrierService.name));
  if (get(fetchResponse, '_links.next.href')) {
    return carrierServices.concat(await getAllCarrierServices(fetchResponse._links.next.href));
  } else {
    return carrierServices;
  }
};
