import eventTypes from '../../../constants/eventtypes';
import itemServiceStatuses from '../../../constants/itemservicestatuses';
import { isFromPrepress } from '../../../utils/events';

// Date that FI deployed the feature that sets the rejection source to "CimpressTech"
const DETAILED_FULFILLER_REJECTED_DEPLOY_DATE = '2018-10-20';

export const eventDisplayMessages = {
  [eventTypes.PLATFORM_ACCEPTED]: () => 'EventTypes.PLATFORM_ACCEPTED',
  [eventTypes.PLATFORM_REJECTED]: () => 'EventTypes.PLATFORM_REJECTED',
  [eventTypes.PRODUCTION_ACCEPTED]: () => 'EventTypes.PRODUCTION_ACCEPTED',
  [eventTypes.PRODUCTION_STARTED]: () => 'EventTypes.PRODUCTION_STARTED',
  [eventTypes.FULFILLMENT]: () => 'EventTypes.FULFILLMENT',
  [eventTypes.CANCELLATION_REQUEST_RECEIVED]: () => 'EventTypes.CANCELLATION_REQUEST_RECEIVED',
  [eventTypes.CANCELLATION_RESPONSE]: () => 'EventTypes.CANCELLATION_RESPONSE',
  [eventTypes.NOTHING_CANCELLED]: () => 'EventTypes.NOTHING_CANCELLED',
  [eventTypes.FULFILLER_REJECTED]: ({ additionalData: { rejectionSource, additionalInfo } = {}, eventDate }) => {
    if (isFromPrepress(additionalInfo)) {
      return 'EventTypes.FULFILLER_REJECTED_BY_PREPRESS';
    }
    if (rejectionSource === 'CimpressTech') {
      return 'EventTypes.FULFILLER_REJECTED_BY_MCP';
    } else if (eventDate < DETAILED_FULFILLER_REJECTED_DEPLOY_DATE) {
      return 'EventTypes.FULFILLER_REJECTED_BY_UNKNOWN';
    }
    return 'EventTypes.FULFILLER_REJECTED';
  },
  [eventTypes.FULFILLER_ADAPTER_ERROR]: () => 'EventTypes.FULFILLER_ADAPTER_ERROR',
  [eventTypes.FULFILLER_TRANSMISSION_ERROR]: () => 'EventTypes.FULFILLER_TRANSMISSION_ERROR',
  [eventTypes.FULFILLER_ID_MAPPINGS_CREATED]: () => 'EventTypes.FULFILLER_ID_MAPPINGS_CREATED',
  [eventTypes.UNSPECIFIED]: () => 'EventTypes.UNSPECIFIED',
  [eventTypes.SHIPPING_PLANNED]: () => 'EventTypes.SHIPPING_PLANNED',
  [eventTypes.DRAWING_REQUESTED]: () => 'EventTypes.DRAWING_REQUESTED',
  [eventTypes.DRAWING_COMPLETED]: () => 'EventTypes.DRAWING_COMPLETED',
  [eventTypes.DRAWING_FAILED]: () => 'EventTypes.DRAWING_FAILED',
  [eventTypes.DOCUMENT_REVIEW_STARTED]: () => 'EventTypes.DOCUMENT_REVIEW_STARTED',
  [eventTypes.DOCUMENT_REVIEW_APPROVED]: e => {
    const { additionalData } = e;
    return additionalData && additionalData.reviewedByAgent
      ? 'EventTypes.DOCUMENT_REVIEW_APPROVED_MANUAL'
      : 'EventTypes.DOCUMENT_REVIEW_APPROVED_AUTO';
  },
  [eventTypes.DOCUMENT_REVIEW_EXCEPTION]: () => 'EventTypes.DOCUMENT_REVIEW_EXCEPTION',
  [eventTypes.DOCUMENT_REVIEW_REJECTED]: () => 'EventTypes.DOCUMENT_REVIEW_REJECTED',
  [eventTypes.DOCUMENT_REVIEW_FEEDBACK_REQUESTED]: () => 'EventTypes.DOCUMENT_REVIEW_FEEDBACK_REQUESTED',
  [eventTypes.DOCUMENT_REVIEW_FEEDBACK_RECEIVED]: () => 'EventTypes.DOCUMENT_REVIEW_FEEDBACK_RECEIVED',
  [eventTypes.INVENTORY_CHECK_REQUESTED]: () => 'EventTypes.INVENTORY_CHECK_REQUESTED',
  [eventTypes.AWAITING_SUFFICIENT_INVENTORY]: () => 'EventTypes.AWAITING_SUFFICIENT_INVENTORY',
  [eventTypes.SUFFICIENT_INVENTORY]: () => 'EventTypes.SUFFICIENT_INVENTORY',
  [eventTypes.INSUFFICIENT_INVENTORY]: () => 'EventTypes.INSUFFICIENT_INVENTORY',
  [eventTypes.CHANGE_REQUEST_RECEIVED]: () => 'EventTypes.CHANGE_REQUEST_RECEIVED',
  [eventTypes.CHANGE_REQUEST_TRANSFERRED_TO_FULFILLER]: () => 'EventTypes.CHANGE_REQUEST_TRANSFERRED_TO_FULFILLER',
  [eventTypes.CHANGE_RESPONSE]: () => 'EventTypes.CHANGE_RESPONSE',
  [eventTypes.PROMISED_ARRIVAL_DATE_CHANGED]: () => 'EventTypes.PROMISED_ARRIVAL_DATE_CHANGED',
  [eventTypes.ORDER_ROUTED]: () => 'EventTypes.ORDER_ROUTED',
  [eventTypes.READY_TO_ROUTE]: () => 'EventTypes.READY_TO_ROUTE',
  [eventTypes.READY_FOR_FULFILLER]: () => 'EventTypes.READY_FOR_FULFILLER',
  [eventTypes.TRANSMITTED_TO_FULFILLER]: () => 'EventTypes.TRANSMITTED_TO_FULFILLER',
  [eventTypes.CLAIM_CREATED]: () => 'EventTypes.CLAIM_CREATED',
  [eventTypes.REORDER_CREATED]: () => 'EventTypes.REORDER_CREATED',
  [eventTypes.FULFILLER_DELAYED]: () => 'EventTypes.FULFILLER_DELAYED',
  [eventTypes.SHIPMENT_UPDATED]: () => 'EventTypes.SHIPMENT_UPDATED',
};

export const statusDisplayMessages = {
  [itemServiceStatuses.PENDING_CANCELLATION]: 'Statuses.PendingCancellation',
  [itemServiceStatuses.FULFILLMENT]: 'Statuses.AwaitingFulfillment',
  [itemServiceStatuses.AWAITING_FULFILLER_RESPONSE]: 'Statuses.AwaitingFulfillerResponse',
  [itemServiceStatuses.PREPARING]: 'Statuses.AwaitingPreparation',
  [itemServiceStatuses.DOCUMENT_REVIEW]: 'Statuses.AwaitingDocumentReviewCompletion',
  [itemServiceStatuses.DOCUMENT_REVIEW_FEEDBACK]: 'Statuses.AwaitingDocumentReviewFeedback',
  [itemServiceStatuses.REJECTED]: 'Statuses.Rejected',
  [itemServiceStatuses.DOCUMENT_REVIEW_REJECTED]: 'Statuses.DocumentReviewRejected',
  [itemServiceStatuses.INVENTORY_CHECK_REJECTED]: 'Statuses.InventoryCheckRejected',
  [itemServiceStatuses.PREPRESS_FAILED]: 'Statuses.PrepressFailed',
  [itemServiceStatuses.SUSPENDED]: 'Statuses.Suspended',
  [itemServiceStatuses.FULFILLMENT_DELAYED]: 'Statuses.FulfillmentDelayed',
  [itemServiceStatuses.ON_TIME_TO_CUSTOMER]: 'Statuses.LateToCustomer',
};
