import * as types from '../constants/actiontypes.js';

export const initialState = {
  loading: false,
  merchantsMap: {},
  error: null,
};

const chooseDisplayName = merchant => {
  if (!merchant.displayName || merchant.displayName.trim() === '') {
    return merchant.merchantId;
  }

  return merchant.displayName;
};

export default function merchants(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_MERCHANTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case types.GET_MERCHANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        merchantsMap: action.response.reduce((acc, merchant) => {
          acc[merchant.merchantId] = merchant;
          return acc;
        }, {}),
        error: null,
      };
    case types.GET_MERCHANTS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    case types.GET_SINGLE_MERCHANT: {
      if (!state.merchantsMap[action.merchant.merchantId]) {
        return {
          ...state,
          merchantsMap: {
            ...state.merchantsMap,
            [action.merchant.merchantId]: {
              ...action.merchant,
              displayName: chooseDisplayName(action.merchant),
            },
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}
