import {
  GET_FULFILLERS_REQUEST,
  GET_FULFILLERS_SUCCESS,
  GET_FULFILLERS_FAILURE,
  FOMA_ORDERS_FETCH_SUCCESS,
  FOMA_ORDERS_FETCH_FAILURE,
  FOMA_ORDERS_FETCH_REQUEST,
} from '../constants/actiontypes';
import { callFetch } from '../services/serviceHelpers';
const FOMA = process.env.REACT_APP_FULFILLMENT_ORDER_MANAGER_URL;

export const loadFulfillers = () => async dispatch => {
  dispatch({ type: GET_FULFILLERS_REQUEST });

  try {
    const fulfillers = await callFetch(`${process.env.REACT_APP_FULFILLER_IDENTITY_URL}/v1/fulfillers`);
    dispatch({ type: GET_FULFILLERS_SUCCESS, response: fulfillers });
  } catch (error) {
    dispatch({ type: GET_FULFILLERS_FAILURE, error });
  }
};

export const filterOrdersExistingInFoma = shortOrderIds => async dispatch => {
  dispatch({ type: FOMA_ORDERS_FETCH_REQUEST });

  try {
    const fomaGetOrderCalls = [];
    shortOrderIds.forEach(shortOrderId => fomaGetOrderCalls.push(callFetch(FOMA, `GET`, `v1/orders/${shortOrderId}`)));
    const callResponses = await Promise.allSettled(fomaGetOrderCalls);
    const orderIdsInFoma = callResponses
      .filter(callResponse => callResponse.status === 'fulfilled')
      .map(callResponse => callResponse.value.orderId);
    dispatch({ type: FOMA_ORDERS_FETCH_SUCCESS, shortOrderIdsInFoma: orderIdsInFoma });
  } catch (error) {
    dispatch({ type: FOMA_ORDERS_FETCH_FAILURE, error: error });
  }
};
