import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import ProgressBar from '@cimpress/react-components/lib/ProgressBar';
import { granite } from '@cimpress/react-components/lib/colors';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import { decoratedOrderShape } from '../propTypes';
import { initStatuses } from '../../constants/statuses';
import { CANCELLED, UNKNOWN } from '../../constants/statustypes';

const OrderSnapshot = ({ order, style, intl }) => {
  const itemList = Object.values(order.items);
  const itemsByStatus = groupBy(itemList, item => get(item, 'computedStatus.type'));
  const statuses = initStatuses(intl);
  const orderedStatusKeys = Object.keys(statuses).sort((a, b) => statuses[a].step - statuses[b].step);
  const totalItems = itemList.length;
  const progressByStatus = orderedStatusKeys.map(statusKey => {
    const itemsInStatus = itemsByStatus[statusKey];
    const numInStatus = itemsInStatus ? itemsInStatus.length : 0;
    // for the special statuses (rejected, cancelled, unknown), only show it if there are items in that status
    if (!numInStatus && !statuses[statusKey].step) {
      return null;
    }

    let colorProps = { status: 'info' };
    if (numInStatus) {
      if (statusKey === CANCELLED) {
        colorProps = { color: granite.base };
      } else if (statusKey === UNKNOWN || itemsInStatus.some(item => get(item, 'computedErrors.length'))) {
        colorProps = { status: 'danger' };
      } else if (itemsInStatus.some(item => get(item, 'computedMissedSlas.length'))) {
        colorProps = { status: 'warning' };
      }
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={statusKey}>
        <div style={{ flex: 0.5, paddingRight: '5px' }}>{statuses[statusKey].displayName}</div>
        <ProgressBar
          style={{ height: '5px', flex: 1, marginBottom: 0 }}
          value={(numInStatus / totalItems) * 100}
          {...colorProps}
        />
        <div style={{ flex: 0.1, textAlign: 'right', paddingLeft: '5px' }}>({numInStatus})</div>
      </div>
    );
  });

  return (
    <div style={style}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <span style={{ flex: 1 }} className="h6">
          <FormattedMessage id="OrderSnapshot.OrderSnapshot" />
        </span>
        <span>
          <FormattedMessage id="OrderSnapshot.TotalItems" values={{ total: totalItems }} />
        </span>
      </div>
      <div>{progressByStatus}</div>
    </div>
  );
};

OrderSnapshot.propTypes = {
  order: decoratedOrderShape,
  style: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(OrderSnapshot);
