import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { availableSearchColumns } from '../../constants/searchColumns';
import * as statusDecorator from '../../decorators/status';
import filter from 'lodash/filter';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

class OrderRow extends React.Component {
  onClick = e => {
    this.props.onClick(this.props.result.orderId, e);
  };

  onContextMenu = e => {
    this.props.onContextMenu(this.props.result.orderId, e);
  };

  onAuxClick = e => {
    this.props.onAuxClick(this.props.result.orderId, e);
  };

  setRef = elem => (this.row = elem);

  componentDidMount() {
    if (this.row) {
      this.row.addEventListener('auxclick', this.onAuxClick);
    }
  }

  componentWillUnmount() {
    if (this.row) {
      this.row.removeEventListener('auxclick', this.onAuxClick);
    }
  }

  stopClick = e => {
    e.stopPropagation();
  };

  render() {
    const {
      searchColumns,
      reduxState,
      result: order,
      showCheckbox,
      isCheckboxEnabled,
      disabledMessage,
      isSelected,
      toggleSelected,
    } = this.props;
    let statusOrder = statusDecorator.attachStatus(order);
    return (
      <tr
        onClick={this.onClick}
        onContextMenu={this.onContextMenu}
        className={`${this.props.className} clickable`}
        ref={this.setRef}
        key={order.orderId}>
        {showCheckbox ? (
          <td onClick={this.stopClick}>
            {isCheckboxEnabled ? (
              <Checkbox style={{ margin: 0 }} checked={isSelected} onChange={toggleSelected} />
            ) : (
              <Tooltip direction="right" contents={disabledMessage}>
                <Checkbox style={{ margin: 0 }} disabled />
              </Tooltip>
            )}
          </td>
        ) : null}
        {filter(availableSearchColumns, col => searchColumns.includes(col.key)).map((searchColumn, idx) => (
          <td style={{ maxWidth: '300px', wordWrap: 'break-word' }} key={`${searchColumn.key}-${idx}`}>
            {searchColumn.valueFormatter(statusOrder, reduxState)}
          </td>
        ))}
      </tr>
    );
  }
}

OrderRow.propTypes = {
  result: PropTypes.shape({
    merchantOrderId: PropTypes.string,
    orderId: PropTypes.string,
    fulfillerOrderIds: PropTypes.arrayOf(PropTypes.string),
    shortFulfillmentGroupIds: PropTypes.arrayOf(PropTypes.string),
    createdDate: PropTypes.string,
    orderStatus: PropTypes.object,
    fulfillmentGroups: PropTypes.arrayOf(
      PropTypes.shape({
        destinationAddress: PropTypes.object,
      })
    ),
  }),
  onClick: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  onAuxClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  searchColumns: PropTypes.arrayOf(PropTypes.string),
  reduxState: PropTypes.object,
  showCheckbox: PropTypes.bool,
  isCheckboxEnabled: PropTypes.bool,
  disabledMessage: PropTypes.node,
  isSelected: PropTypes.bool,
  toggleSelected: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    searchColumns: { searchColumns },
  } = state;

  return {
    reduxState: state,
    searchColumns,
  };
}

export default connect(mapStateToProps, null)(OrderRow);
