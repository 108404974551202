import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ColumnCheckbox from './columnCheckbox';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import filter from 'lodash/filter';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';

import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

export class ColumnDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPopover: false, selectedColumns: [] };
  }

  onClickOutside = () => {
    this.setState({ showPopover: false });
  };

  onClickShow = () => {
    this.setState({
      showPopover: !this.state.showPopover,
      selectedColumns: !this.state.showPopover ? this.props.selectedColumns : this.state.selectedColumns,
    });
  };

  onSaveDefault = () => {
    this.props.saveColumns(this.state.selectedColumns);
    this.setState({ showPopover: false });

    trackEvent(TrackingEventTitles.EDIT_COLUMNS, {
      [TrackingEventPropertiesNames.EDIT_COLUMNS.SELECTED_COLUMNS]: this.state.selectedColumns,
    });
  };

  onResetDefault = () => {
    this.props.resetColumns();
    this.setState({ showPopover: false });
  };

  onCheckChange = column => {
    const { selectedColumns } = this.state;
    const duplicatedColumns = [...selectedColumns];
    let index = duplicatedColumns.indexOf(column);
    if (index < 0) {
      duplicatedColumns.push(column);
    } else {
      duplicatedColumns.splice(index, 1);
    }
    this.setState({ selectedColumns: duplicatedColumns });
  };

  render() {
    const { selectedColumns } = this.state;
    const { availableColumns } = this.props;
    return (
      <Tooltip
        direction="bottom"
        variant="popover"
        constraints={[{ to: 'scrollParent', pin: ['top', 'right'] }]}
        contents={
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '550px',
                width: '600px',
                flexWrap: 'wrap',
              }}>
              {availableColumns.map((column, idx) => (
                <ColumnCheckbox
                  key={`${column}-${idx}`}
                  column={column.key}
                  selectedColumns={selectedColumns || []}
                  onCheckChange={this.onCheckChange}
                  availableColumns={availableColumns}
                />
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '15px' }}>
              <button onClick={this.onSaveDefault} className="btn btn-primary">
                <FormattedMessage id="ResultsSection.ApplyColumns" />
              </button>
              <button onClick={this.onResetDefault} className="btn btn-link" style={{ marginRight: '10px' }}>
                <FormattedMessage id="ResultsSection.ResetColumns" />
              </button>
            </div>
          </div>
        }
        show={this.state.showPopover}
        onClickOutside={this.onClickOutside}
        tooltipStyle={{ maxWidth: '650px' }}
        style={{ marginRight: '15px' }}>
        <a onClick={this.onClickShow} className="clickable">
          <IconPencilAlt weight="fill" /> <FormattedMessage id="ResultsSection.Columns" />
        </a>
      </Tooltip>
    );
  }
}

ColumnDropdown.propTypes = {
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  saveColumns: PropTypes.func,
  resetColumns: PropTypes.func,
  availableColumns: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, headingFormatter: PropTypes.func, valueFormatter: PropTypes.func })
  ),
  gaCategory: PropTypes.string,
};

export default ColumnDropdown;
