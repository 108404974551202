export function initSearchChangeRequestTypes(intl) {
  return [
    {
      value: 'artwork',
      label: intl.formatMessage({ id: 'ChangeRequest.ArtworkType' }),
    },
    {
      value: 'delivery',
      label: intl.formatMessage({ id: 'ChangeRequest.DeliveryType' }),
    },
    {
      value: 'shipmentRequirement',
      label: intl.formatMessage({ id: 'ChangeRequest.ShipmentRequirementType' }),
    },
  ];
}
