import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import TextField from '@cimpress/react-components/lib/TextField';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import styled from 'styled-components';

import { MDY_DATE_FORMAT, TIME_FORMAT } from '../../constants/dates.js';
import moment from 'moment-timezone';

const StyledDatePicker = styled(DatePicker)`
  .rdt {
    ${({ sidebar }) =>
      sidebar &&
      `
      width: 100%;
  `}
  }

  .rdtPicker {
    ${({ rightAlignedDatePicker }) =>
      rightAlignedDatePicker &&
      `
      right: 0;
    `}
  }

  &.datetime {
    width: 100%;
    max-width: 300px;
    margin-bottom: 0px;
  }
`;

class DatePickerInternal extends React.PureComponent {
  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.object,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    now: PropTypes.object,
    validate: PropTypes.func,
    startDate: PropTypes.object,
    rightAlignedDatePicker: PropTypes.bool,
    sidebar: PropTypes.bool,
  };

  validate = selectedDate => this.props.validate(selectedDate, this.props.now);

  render() {
    return (
      <StyledDatePicker
        dateFormat={MDY_DATE_FORMAT}
        timeFormat={TIME_FORMAT}
        onBlur={this.props.onBlur}
        onChange={this.props.onChange}
        value={this.props.value && this.props.value.isValid() ? this.props.value : ''}
        className={this.props.className}
        readOnly={true}
        placeholder={this.props.placeholder}
        isValidDate={this.validate}
        sidebar={this.props.sidebar}
        rightAlignedDatePicker={this.props.rightAlignedDatePicker}
      />
    );
  }
}

class SearchBar extends React.Component {
  state = {
    startDateError: '',
    endDateError: '',
  };

  submitSearch = e => {
    e.preventDefault();
    const { intl } = this.props;
    // clear old errors
    this.setState({ startDateError: '', endDateError: '' });
    // then verify search input
    if (this.props.startDate > this.props.endDate) {
      //check for valid date range
      this.setState({
        startDateError: intl.formatMessage({ id: 'SearchBar.StartDateError' }),
        endDateError: intl.formatMessage({ id: 'SearchBar.EndDateError' }),
      });
      return;
    } else if (this.props.canSubmit) {
      // check for ability to submit by default
      this.props.onSubmit(); // submit if we are in the advanced search pane
      return;
    } else if (
      !this.props.startDate ||
      !this.props.startDate.isValid() ||
      !this.props.endDate ||
      !this.props.endDate.isValid()
    ) {
      //check for missing dates
      if (!this.props.startDate || !this.props.startDate.isValid()) {
        this.setState({ startDateError: intl.formatMessage({ id: 'SearchBar.EnterStartDate' }) });
      }
      if (!this.props.endDate || !this.props.endDate.isValid()) {
        this.setState({ endDateError: intl.formatMessage({ id: 'SearchBar.EnterEndDate' }) });
      }
      return;
    } else {
      // submit if no problems are found
      this.props.onSubmit();
    }
  };

  onStartDateCleared = e => {
    this.clearDateField(e, 'start');
  };

  onEndDateCleared = e => {
    this.clearDateField(e, 'end');
  };

  clearDateField = (e, fieldName) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (fieldName === 'start') {
        this.props.onStartDateChanged(null);
      } else if (fieldName === 'end') {
        this.props.onEndDateChanged(null);
      }
    }
  };

  clearStartDateError = () => this.setState({ startDateError: '' });

  clearEndDateError = () => this.setState({ endDateError: '' });

  validateStartDate = (selectedDate, now) => {
    // Use the Date Api to set the max startDate to the day before the 1st of the last month
    var priorDate = new Date();
    priorDate.setDate(0);
    priorDate.setDate(1);
    priorDate.setDate(0);
    const dateLimit = process.env.REACT_APP_ENV === 'prd' ? moment(now).subtract(180, 'days') : priorDate;
    return selectedDate > dateLimit && selectedDate < now;
  };

  validateEndDate = (selectedDate, now) => {
    if (selectedDate.isAfter(now, 'day')) {
      //if calendar day is after today
      return false;
    } else if (this.props.startDate && selectedDate.isBefore(this.props.startDate, 'day')) {
      // if calendar day is before selected start date
      return false;
    }
    return true;
  };

  render() {
    const { sidebar, intl } = this.props;

    const now = moment().tz(this.props.displayTimezone);
    const startDatePicker = (
      <div
        style={sidebar ? { paddingRight: '5px', flex: 1 } : { marginLeft: '15px', flex: 0.5 }}
        onKeyDown={this.onStartDateCleared}>
        <Tooltip
          direction="top"
          style={{ display: 'block ' }}
          contents={this.state.startDateError}
          show={Boolean(this.state.startDateError)}>
          <DatePickerInternal
            onBlur={this.clearStartDateError}
            onChange={this.props.onStartDateChanged}
            value={this.props.startDate}
            className={`${sidebar && 'narrow'} ${this.state.startDateError && 'has-error'}`}
            placeholder={intl.formatMessage({ id: 'SearchBar.StartDate' })}
            now={now}
            validate={this.validateStartDate}
            sidebar={sidebar}
          />
        </Tooltip>
      </div>
    );

    const endDatePicker = (
      <div
        style={sidebar ? { paddingLeft: '5px', flex: 1 } : { marginLeft: '15px', flex: 0.5 }}
        onKeyDown={this.onEndDateCleared}>
        <Tooltip
          style={{ display: 'block' }}
          placement="top"
          contents={this.state.endDateError}
          show={Boolean(this.state.endDateError)}>
          <DatePickerInternal
            onBlur={this.clearEndDateError}
            onChange={this.props.onEndDateChanged}
            value={this.props.endDate}
            className={`${sidebar && 'right-open narrow'} ${this.state.endDateError && 'has-error'}`}
            placeholder={intl.formatMessage({ id: 'SearchBar.EndDate' })}
            now={now}
            validate={this.validateEndDate}
            startDate={this.props.startDate}
            sidebar={sidebar}
            rightAlignedDatePicker
          />
        </Tooltip>
      </div>
    );

    const popover = (
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'SearchBar.SearchFieldTooltip' }),
        }}
      />
    );

    const searchTermField = (
      <div style={{ position: 'relative', flex: '1 0 auto' }}>
        <TextField
          autoFocus
          style={{ marginBottom: sidebar ? '15px' : 0 }}
          inputStyle={{ paddingRight: '35px' }}
          type="text"
          value={this.props.searchTerm || ''}
          placeholder={intl.formatMessage({ id: 'SearchBar.SearchPlaceHolder' })}
          onChange={this.props.onSearchTermChanged}
        />
        {!sidebar && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '20px',
            }}>
            <Tooltip direction="bottom" contents={popover} variant="popover">
              <i className="fa fa-info-circle text-info" aria-hidden="true" />
            </Tooltip>
          </div>
        )}
      </div>
    );

    const searchButton = !sidebar ? (
      <div style={{ marginLeft: '15px', flex: 0.5 }}>
        <div className="input-group-button">
          <button
            disabled={!this.props.canSubmit}
            className="btn btn-block btn-primary"
            style={{ height: '50px' }}
            type="submit"
            onClick={this.submitSearch}>
            <i className="fa fa-search" /> <FormattedMessage id="SearchBar.Search" />
          </button>
        </div>
      </div>
    ) : null;

    const searchbar = (
      <form>
        {!sidebar ? (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {searchTermField}
            {startDatePicker}
            {endDatePicker}
            {searchButton}
          </div>
        ) : (
          <div>
            {searchTermField}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {startDatePicker}
              {endDatePicker}
            </div>
            {searchButton}
          </div>
        )}
      </form>
    );

    return searchbar;
  }
}

SearchBar.propTypes = {
  searchTerm: PropTypes.string,
  onSearchTermChanged: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  displayTimezone: PropTypes.string,
  onStartDateChanged: PropTypes.func,
  onEndDateChanged: PropTypes.func,
  canSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
  sidebar: PropTypes.bool,
  intl: PropTypes.object,
};

SearchBar.defaultProps = {
  searchTerm: '',
  canSubmit: true,
  sidebar: false,
};

export default injectIntl(SearchBar);
