import React from 'react';
import PropTypes from 'prop-types';
import Header from './components/shared/header.jsx';
import MessageBar from './components/detailsv2/MessageBar';
import TagWrapper from './components/tags/TagWrapper';
import { withRouter } from 'react-router';
import Package from '../../../package.json';

class AppShell extends React.Component {
  render() {
    const { children, location } = this.props;
    let version = process.env.PACKAGE_VERSION || Package.version;
    let envString = `v.${version}`;
    if (process.env.REACT_APP_ENVIRONMENT_NAME) {
      envString += ` - ${process.env.REACT_APP_ENVIRONMENT_NAME}`;
    }

    if (process.env.REACT_APP_GIT_COMMIT) {
      envString += ` (${process.env.REACT_APP_GIT_COMMIT})`;
    }

    return (
      <div>
        <TagWrapper />
        <Header location={location} />
        <MessageBar />
        <div className="app-container">
          {/* render the main page component */}
          {children}
        </div>
        <span style={{ display: 'none' }}>version: {envString}</span>
      </div>
    );
  }
}

AppShell.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  route: PropTypes.object,
};

AppShell.contextTypes = {
  router: PropTypes.object,
};

export default withRouter(AppShell);
