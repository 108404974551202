import { loadPermissions } from '../actions/permissionsActions';
import auth from '../utils/auth';

const ensurePermissions = (dispatch, getState, subscribe) => {
  if (!auth.isLoggedIn()) {
    throw new Error('Permissions cannot be loaded without authenticated user');
  }
  let permissions = getState().permissions;
  if (permissions) {
    if (permissions.permissions || permissions.error) {
      // permissions is in final state.  resolve
      return Promise.resolve(permissions);
    } else {
      // permissions hasn't been called.  dispatch
      if (!permissions.loading) {
        dispatch(loadPermissions());
      }

      // return a promise that resolves when permissions call is complete
      return new Promise(function(resolve) {
        let unsubscribe = subscribe(() => {
          let permissions = getState().permissions;
          if (permissions.permissions || permissions.error) {
            callUnsubscribe();
            resolve(permissions);
          }
        });
        let callUnsubscribe = () => {
          unsubscribe();
        };
      });
    }
  }

  throw new Error('Store is in an invalid state.  Cannot get permissions.');
};

export default ensurePermissions;
