import flatten from 'lodash/flatten';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';

const getLinks = (items, linkName) => uniq(items.map(item => get(item, `_links.${linkName}.href`)).filter(x => x));

export const getOrderIds = items => {
  // get the various order ids from each item
  const orderIds = items.map(item => ({
    platform: item.orderId,
    merchant: item.merchantOrderId,
    short: item.shortFulfillmentGroupId,
    fulfiller: item.fulfillerOrderId,
  }));
  // group them by platform order id
  return orderIds.reduce((consolidated, orderIds) => {
    const existingIds = consolidated.find(ids => ids.platform === orderIds.platform);
    if (existingIds) {
      existingIds.fulfiller = uniq(flatten([existingIds.fulfiller, orderIds.fulfiller]));
      existingIds.short = uniq(flatten([existingIds.short, orderIds.short]));
    } else {
      consolidated.push(orderIds);
    }
    return consolidated;
  }, []);
};

export const attachActionsToItems = (actions, items) => {
  actions.fulfillmentGroups.forEach(fg => {
    fg.items.forEach(item => {
      items[item.itemId].allowedActions = item.actions;
    });
  });
  return items;
};

// copy item-level fields to the order
export const aggregateItemLevelDetails = order => {
  if (!order.items) {
    return order;
  }

  const items = Object.values(order.items).filter(i => i);
  if (!items.length) {
    return order;
  }

  const item = items[0];
  const { merchantId, merchantSalesChannel, merchantOrderId, createdDate, fakeOrder, testingConfiguration } = item;

  // IDs
  const fulfillerOrderIds = uniq(items.map(item => item.fulfillerOrderId).filter(x => x));
  const shortOrderIds = uniq(items.map(item => item.shortFulfillmentGroupId).filter(x => x));

  // prices
  const customerPrices = items
    .map(item => {
      //Remove fallback to customsInformation after 1/9/2021 - a year after CO2-3276 and every item has a pricing link
      //At that time, any permissions check for price visibility should be unnecessary, since the pricing link will fully check for it.
      return {
        basePrice: get(item, 'itemPricing.basePrice') || get(item, 'customsInformation.pricePaid.basePrice'),
        shippingPrice: get(item, 'itemPricing.shipping') || get(item, 'customsInformation.pricePaid.shippingPrice'),
        currencyCode: get(item, 'itemPricing.currencyCode') || get(item, 'customsInformation.pricePaid.currencyCode'),
      };
    })
    .reduce((acc, curr) => {
      acc[curr.currencyCode] = (acc[curr.currencyCode] || 0) + (curr.basePrice || 0) + (curr.shippingPrice || 0);
      return acc;
    }, {});

  const merchantPrices = items
    .map(item => item.transferPrice)
    .filter(price => price)
    .reduce((acc, curr) => {
      if (curr.shippingCurrencyCode) {
        acc[curr.currencyCode] = (acc[curr.currencyCode] || 0) + (curr.basePrice || 0);
        acc[curr.shippingCurrencyCode] = (acc[curr.shippingCurrencyCode] || 0) + (curr.shippingPrice || 0);
      } else {
        acc[curr.currencyCode] = (acc[curr.currencyCode] || 0) + (curr.basePrice || 0) + (curr.shippingPrice || 0);
      }
      return acc;
    }, {});

  merge(order, {
    merchantId,
    merchantSalesChannel,
    merchantOrderId,
    createdDate,
    fulfillerOrderIds,
    shortOrderIds,
    customerPrices,
    merchantPrices,
    fakeOrder,
    testingConfiguration,
    claimLinks: getLinks(items, 'claims'),
    reorderClaimLinks: getLinks(items, 'reorderClaim'),
    complaintLinks: getLinks(items, 'complaints'),
    reorderComplaintLinks: getLinks(items, 'reorderComplaint'),
    fulfillerQuoteLinks: getLinks(items, 'fulfillerQuoteItem'),
  });

  return order;
};
