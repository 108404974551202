import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { FlexBox, Tooltip } from '@cimpress/react-components';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import styled from 'styled-components';
import DateDisplay from '../shared/dateDisplay';
import FieldDisplay from '../detailsv2/FieldDisplay';

const ColumnDiv = styled.div`
  flex: 1;
  max-width: 700px;
  margin-bottom: 25px;
`;

const RequestResponseContainer = styled.div`
  min-height: 150px;
`;

const CancellationRequests = ({ events }) => {
  const intl = useIntl();

  const cancellationRequestReceivedEvents = events.filter(event => event.eventType === 'cancellationRequestReceived');
  const responseEvents = events.filter(
    event => event.eventType === 'cancellationResponse' || event.eventType === 'nothingCancelled'
  );

  return (
    <FlexBox>
      <ColumnDiv>
        <FlexBox isVertical={false}>
          <h3>
            <FormattedMessage id="CancellationRequest.Requests" />
          </h3>
          <Tooltip contents={<FormattedMessage id="CancellationRequest.Tooltip" />}>
            <IconInformationCircle
              style={{ marginLeft: '5px', marginTop: '20px' }}
              className="text-info"
              weight="fill"
            />
          </Tooltip>
        </FlexBox>
        {cancellationRequestReceivedEvents.map((cancellationRequestEvent, index) => (
          <>
            <RequestResponseContainer>
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.RequestDate' })}
                value={<DateDisplay date={cancellationRequestEvent.eventDate} />}
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.RequestedBy' })}
                value={cancellationRequestEvent.additionalData.requestedBy}
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.CancelledBy' })}
                value={cancellationRequestEvent.additionalData.cancelledBy}
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.RequestDetails' })}
                value={cancellationRequestEvent.detail}
              />
            </RequestResponseContainer>
            {cancellationRequestReceivedEvents.length > 1 && index < cancellationRequestReceivedEvents.length - 1 && (
              <hr />
            )}
          </>
        ))}
      </ColumnDiv>
      <ColumnDiv style={{ marginLeft: '60px' }}>
        <h3>
          <FormattedMessage id="CancellationRequest.Responses" />
        </h3>
        {responseEvents.map((responseEvent, index) => (
          <>
            <RequestResponseContainer>
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.ResponseDate' })}
                value={<DateDisplay date={responseEvent.eventDate} />}
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.CostsIncurred' })}
                value={
                  responseEvent.additionalData.costsIncurred !== undefined
                    ? responseEvent.additionalData.costsIncurred.toString()
                    : ''
                }
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.Status' })}
                value={
                  responseEvent.eventType === 'cancellationResponse'
                    ? intl.formatMessage({ id: 'CancellationRequest.Status.Accepted' })
                    : intl.formatMessage({ id: 'CancellationRequest.Status.Rejected' })
                }
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.Quantity' })}
                value={responseEvent.additionalData.quantity}
              />
              <FieldDisplay
                name={intl.formatMessage({ id: 'CancellationRequest.ResponseDetails' })}
                value={responseEvent.detail}
              />
            </RequestResponseContainer>
            {responseEvents.length > 1 && index < responseEvents.length - 1 && <hr />}
          </>
        ))}
      </ColumnDiv>
    </FlexBox>
  );
};

CancellationRequests.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CancellationRequests;
