import { callFetch } from './serviceHelpers';

export const postOrder = order =>
  callFetch(
    process.env.REACT_APP_AGGREGATION_URL,
    'POST',
    'v1/orders?disallowDuplicateOrderItems=false',
    JSON.stringify(order)
  );

export const requestItemCancellation = item =>
  callFetch(
    process.env.REACT_APP_AGGREGATION_URL,
    'POST',
    `v1/orders/${item.orderId}/items/${item.itemId}/cancelrequest`
  );

export const requestRedirect = (orderId, redirectRequest) =>
  callFetch(
    process.env.REACT_APP_AGGREGATION_URL,
    'POST',
    `v1/orders/${orderId}/redirectRequest`,
    JSON.stringify(redirectRequest)
  );
