import React from 'react';
import { FlexBox, Radio, RadioGroup } from '@cimpress/react-components';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import intersectionWith from 'lodash/intersectionWith';

import ItemSelector from './ItemSelector';
import useRedirectContext from './RedirectContext';

const ConfirmSelections = ({ items }) => {
  const { state, dispatch } = useRedirectContext();

  const itemsToRedirect = intersectionWith(
    items,
    Object.values(state.itemsAvailableToRedirect),
    (item, itemToRedirect) => {
      return !isEmpty(itemToRedirect.newFulfillmentConfigurationUrl) && itemToRedirect.itemId === item.itemId;
    }
  );

  // const onChange = () => dispatch({ type: 'TOGGLE_WAIT_FOR_FULFILLER' });

  return (
    <FlexBox>
      <FlexBox isVertical shrink={1} basis={0}>
        <h4>
          <FormattedMessage id="RequestRedirect.Summary" />
        </h4>

        <ItemSelector
          items={itemsToRedirect}
          // isSelected={() => false}
          checkable={false}
          displayNewFulfillmentConfiguration
        />

        {/* <RadioGroup onChange={onChange} defaultSelected={state.waitForFulfiller}>
          <Radio label={<FormattedMessage id="RequestRedirect.WaitForFulfiller" />} value={true} />
          <Radio label={<FormattedMessage id="RequestRedirect.ApplyImmediately" />} value={false} />
        </RadioGroup> */}
      </FlexBox>
    </FlexBox>
  );
};

export default ConfirmSelections;
