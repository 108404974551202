import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchDropdownPanel from './SearchDropdownPanel';
import {
  initRecommendedSearches,
  SAVED_SEARCH,
  RECOMMENDED_SEARCH,
  TIME_PERIOD_SEARCH,
} from '../../constants/recommendedSearches';

class GettingStarted extends PureComponent {
  static propTypes = {
    onFiltersUpdated: PropTypes.func.isRequired,
    savedSearches: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string.isRequired })),
    intl: PropTypes.object,
  };

  render() {
    const { intl } = this.props;
    const { recommendedSearches, timePeriodSearches } = initRecommendedSearches(intl);
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around', marginLeft: '100px', marginRight: '100px' }}>
        <SearchDropdownPanel
          title={intl.formatMessage({ id: 'ResultsSection.RecentOrders' })}
          searchType={TIME_PERIOD_SEARCH}
          searchList={timePeriodSearches}
          onFiltersUpdated={this.props.onFiltersUpdated}
        />
        <SearchDropdownPanel
          title={intl.formatMessage({ id: 'SaveSearch.SavedSearches' })}
          searchType={SAVED_SEARCH}
          searchList={this.props.savedSearches}
          emptyMessage={intl.formatMessage({ id: 'SaveSearch.NoSavedSearches' })}
          onFiltersUpdated={this.props.onFiltersUpdated}
        />
        <SearchDropdownPanel
          title={intl.formatMessage({ id: 'RecommendedSearches.RecommendedSearches' })}
          searchType={RECOMMENDED_SEARCH}
          searchList={recommendedSearches}
          onFiltersUpdated={this.props.onFiltersUpdated}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    savedSearches: { savedSearches },
  } = state;
  return { savedSearches };
};

export default connect(mapStateToProps)(injectIntl(GettingStarted));
