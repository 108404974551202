import * as types from '../constants/actiontypes.js';

export const initialState = {
  loading: false,
  fulfillersMap: {},
  fulfillerDetailsMap: {},
  error: null,
};

export default function fulfillers(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_FULFILLERS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case types.GET_FULFILLERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        fulfillersMap: action.response.reduce((acc, fulfiller) => {
          acc[fulfiller.fulfillerId] = fulfiller;
          return acc;
        }, {}),
        error: null,
      });
    case types.LOAD_FULFILLER_SUCCESS: {
      const fulfiller = action.response;

      if (!state.fulfillersMap[fulfiller.fulfillerId]) {
        return {
          ...state,
          fulfillersMap: {
            ...state.fulfillersMap,
            [fulfiller.fulfillerId]: fulfiller,
          },
        };
      }

      return state;
    }
    case types.LOAD_FULFILLER_DETAILS_SUCCESS: {
      const { expectations, fulfillmentConfiguration } = action.response;
      if (!state.fulfillerDetailsMap[fulfillmentConfiguration]) {
        return {
          ...state,
          fulfillerDetailsMap: {
            ...state.fulfillerDetailsMap,
            [fulfillmentConfiguration]: expectations,
          },
        };
      }

      return state;
    }
    case types.GET_FULFILLERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
