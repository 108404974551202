import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { granite } from '@cimpress/react-components/lib/colors';
import { Close } from '@cimpress/react-components/lib/shapes/hamburger';

class FilterTag extends PureComponent {
  static propTypes = {
    reference: PropTypes.string,
    value: PropTypes.string,
    removeFilter: PropTypes.func,
  };

  remove = () => this.props.removeFilter(this.props.reference, this.props.value);

  render() {
    const { removeFilter, reference, value } = this.props;
    return (
      <span style={{ marginLeft: '5px', marginBottom: '5px' }} className="tag">
        {removeFilter && reference ? (
          <button style={{ marginLeft: 0 }} className="close" onClick={this.remove}>
            <Close width={'8px'} color={granite.base} cropped />
          </button>
        ) : null}
        {'  '}
        {value}
      </span>
    );
  }
}

class FilterDisplay extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.string),
    reference: PropTypes.string,
    removeFilter: PropTypes.func,
    inline: PropTypes.bool,
  };

  render() {
    const { name, values, reference, removeFilter, inline } = this.props;
    if (!values || !values.length) {
      return null;
    }
    const body = (
      <span>
        <b>{name} </b>
        {values.map(value => {
          // ECOM-3216 Item Sub-statuses
          let newValue = String(value).includes('.') ? value.split('.')[1] : value;
          return <FilterTag key={newValue} removeFilter={removeFilter} reference={reference} value={newValue} />;
        })}
      </span>
    );
    return inline ? (
      <span style={{ paddingRight: '12px' }} key={name}>
        {body}
      </span>
    ) : (
      <div key={name}>{body}</div>
    );
  }
}

export default class SelectedFilters extends PureComponent {
  static propTypes = {
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.string),
        reference: PropTypes.string,
      })
    ),
    removeFilter: PropTypes.func,
    inline: PropTypes.bool,
  };

  render() {
    const { filters, removeFilter, inline } = this.props;
    return (
      <div>
        {filters.map(filter => (
          <FilterDisplay
            key={filter.name}
            name={filter.name}
            values={filter.values}
            reference={filter.reference}
            removeFilter={removeFilter}
            inline={inline}
          />
        ))}
      </div>
    );
  }
}
