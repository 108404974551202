import {
  FOMA_ORDERS_FETCH_REQUEST,
  FOMA_ORDERS_FETCH_SUCCESS,
  FOMA_ORDERS_FETCH_FAILURE,
} from '../constants/actiontypes';

export const initialState = {
  loading: false,
  shortOrderIdsInFoma: [],
  error: null,
};

export default function foma(state = initialState, action = {}) {
  switch (action.type) {
    case FOMA_ORDERS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FOMA_ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        shortOrderIdsInFoma: action.shortOrderIdsInFoma,
      };

    case FOMA_ORDERS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
