export const initialFilterState = {
  selectedMerchants: [],
  selectedFulfillers: [],
  selectedStatuses: [],
  selectedDelayedStatuses: [],
  selectedErrorStatuses: [],
  selectedOrderTypes: [],
  selectedSalesChannels: [],
  selectedProductCategories: [],
  selectedClaimTypes: [],
  selectedComplaintTypes: [],
  selectedChangeRequestTypes: [],
  sortField: undefined,
  sortOrder: undefined,
  searchTerm: '',
};
