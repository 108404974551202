export function initSearchDelayedStatuses(intl) {
  return [
    {
      value: 'pendingCancellation',
      label: intl.formatMessage({ id: 'Statuses.PendingCancellation' }),
    },
    {
      value: 'fulfillment',
      label: intl.formatMessage({ id: 'Statuses.AwaitingFulfillment' }),
    },
    {
      value: 'awaitingFulfillerResponse',
      label: intl.formatMessage({ id: 'Statuses.AwaitingFulfillerResponse' }),
    },
    {
      value: 'preparing',
      label: intl.formatMessage({ id: 'Statuses.AwaitingPreparation' }),
    },
    {
      value: 'documentReview',
      label: intl.formatMessage({ id: 'Statuses.AwaitingDocumentReviewCompletion' }),
    },
    {
      value: 'documentReviewFeedback',
      label: intl.formatMessage({ id: 'Statuses.AwaitingDocumentReviewFeedback' }),
    },
    {
      value: 'fulfillmentDelayed',
      label: intl.formatMessage({ id: 'Statuses.FulfillmentDelayedReportFilter' }),
    },
    {
      value: 'inventoryCheck',
      label: intl.formatMessage({ id: 'Statuses.AwaitingInventoryCheck' }),
    },
    {
      value: 'routing',
      label: intl.formatMessage({ id: 'Statuses.RoutingDelayed' }),
    },
    {
      value: 'onTimeToCustomer',
      label: intl.formatMessage({ id: 'Statuses.LateToCustomer' }),
    },
  ];
}
