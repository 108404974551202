import { colors, Tooltip } from '@cimpress/react-components';
import IconTruckCargo from '@cimpress-technology/react-streamline-icons/lib/IconTruckCargo';
import IconShipmentUploadAlt from '@cimpress-technology/react-streamline-icons/lib/IconShipmentUploadAlt';
import IconHouse from '@cimpress-technology/react-streamline-icons/lib/IconHouse';
import IconShipmentPlane from '@cimpress-technology/react-streamline-icons/lib/IconShipmentPlane';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import React from 'react';
import styled from 'styled-components';
import DateRangePopover from './DateRangePopover';
import { StyledText } from './StyledText';

import MultiColorProgressBar from './MultiColorProgressBar';
import { DELIVERED, DISPATCHED, FULFILLED, OUT_FOR_DELIVERY } from '../../../constants/itemservicestatuses';

const StyledProgressBar = styled(MultiColorProgressBar)`
  width: 100%;
`;

// if StatusRow is a child row then give the title less room to accomodate for the indenting
// that the accordion component does (20px difference) so the grid lines up correctly
const StatusRow = styled.div`
  display: grid;
  grid-template-columns: ${({ numColumns, isChild }) => `${isChild ? '236px' : '256px'} repeat(${numColumns}, 1fr)`};
  width: 100%;
  align-items: center;
`;

const StatusRowCell = styled.div`
  grid-column: ${({ column }) => column};
  display: flex;
  align-items: center;
`;

export default ({ rowData, isChild }) => {
  const {
    name,
    state,
    startDate,
    endDate,
    startColumn,
    endColumn,
    elapsedTime,
    isError,
    isAlertOpen,
    displayName,
    isAbbreviated,
  } = rowData;
  const renderItemStatus = (name, isError) => {
    console.log('name', name);
    switch (name) {
      case FULFILLED: {
        return <IconShipmentUploadAlt size="lg" style={{ transform: 'scale(2)' }} color={colors.granite.base} />;
      }
      case DISPATCHED: {
        return <IconShipmentPlane size="lg" style={{ transform: 'scale(2)' }} color={colors.granite.base} />;
      }
      case OUT_FOR_DELIVERY: {
        return <IconTruckCargo size="lg" style={{ transform: 'scale(2)' }} color={colors.granite.base} />;
      }
      case DELIVERED: {
        return <IconHouse size="lg" style={{ transform: 'scale(2)' }} color={colors.granite.base} />;
      }
      case isError: {
        return (
          <IconAlertTriangle
            size="lg"
            weight={isAlertOpen ? 'fill' : 'regular'}
            color={isAlertOpen ? colors.danger.base : colors.granite.base}
          />
        );
      }
      default: {
        return <StyledProgressBar size="l" abbreviated={isAbbreviated} />;
      }
    }
  };
  return (
    <StatusRow numColumns={500} key={name} isChild={isChild}>
      <StyledText>{displayName}</StyledText>
      <StatusRowCell column={`${startColumn} / ${endColumn}`}>
        <Tooltip
          direction="left"
          style={{ width: '100%' }}
          tooltipInnerStyle={{ maxWidth: 'none' }}
          contents={
            <DateRangePopover
              startDate={startDate}
              endDate={endDate}
              duration={elapsedTime}
              state={state}
              status={displayName}
            />
          }>
          {renderItemStatus(name, isError)}
        </Tooltip>
      </StatusRowCell>
    </StatusRow>
  );
};
