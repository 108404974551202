import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import uniqWith from 'lodash/uniqWith';
import { navigate } from '@cimpress-technology/unified-cimpress-navigator';
import Button from '@cimpress/react-components/lib/Button';

import { decoratedOrderShape, orderIdsShape } from '../propTypes';
import FieldDisplay from './FieldDisplay';
import OrderLink from '../shared/OrderLink';
import RelatedResourceLabels from '../shared/RelatedResourceLabels';
import { loadClaims, loadComplaints, loadOriginalOrders } from '../../actions/orderv2actions';
import useLinkedItems from './useLinkedItems';
import auth from '../../utils/auth';

const OOPS_UI_URL = process.env.REACT_APP_OOPS_UI_URL;

const RelatedLinks = ({
  order,
  style,
  claimsSummary,
  claimReordersSummary,
  complaintsSummary,
  complaintReordersSummary,
  claimOriginalOrdersSummary,
  complaintOriginalOrdersSummary,
  loadClaims,
  loadComplaints,
  loadOriginalOrders,
  intl,
}) => {
  useEffect(() => {
    const { claimLinks, reorderClaimLinks, complaintLinks, reorderComplaintLinks } = order;
    loadClaims(claimLinks);
    loadComplaints(complaintLinks);
    loadOriginalOrders(reorderClaimLinks, reorderComplaintLinks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasClaim = !isEmpty(claimsSummary);
  const hasComplaint = !isEmpty(complaintsSummary);
  const isClaimReorder = !isEmpty(claimOriginalOrdersSummary);
  const isComplaintReorder = !isEmpty(complaintOriginalOrdersSummary);
  const hasClaimReorders = !isEmpty(claimReordersSummary);
  const hasComplaintReorders = !isEmpty(complaintReordersSummary);
  const isCustomQuote = !isEmpty(order.fulfillerQuoteLinks);

  const getChangeRequestLinks = rel => uniq(Object.values(order.items).map(item => get(item, rel))).filter(val => val);
  const getSummary = orders => uniqBy(orders || [], 'orderId').map(order => ({ platform: order.orderId }));
  const changeRequestOriginalOrdersSummary = getSummary(
    useLinkedItems(getChangeRequestLinks('_links.originalItem.href'))
  );

  const itemsHaveChangeRequestReorderLinks = Object.values(order.items).some(
    item => item._links?.reorderCreatedByChangeRequest
  );
  const isChangeRequestReorder = !isEmpty(changeRequestOriginalOrdersSummary) && itemsHaveChangeRequestReorderLinks;
  const isPreferredOrder = !isEmpty(changeRequestOriginalOrdersSummary) && !itemsHaveChangeRequestReorderLinks;

  const changeRequestReorderSummary = getSummary(
    useLinkedItems(getChangeRequestLinks('_links.reorderChangeRequestItem.href'))
  );
  const hasChangeRequestReorder = !isEmpty(changeRequestReorderSummary);

  const getItemLinks = rel => {
    const [privateNetworkItems, nonPrivateNetworkItems] = partition(
      Object.values(order.items),
      item => get(item, '_links.routedItem.href') || get(item, '_links.orderedItem.href')
    );

    const firstRedirectedNonPrivateNetworkItem =
      nonPrivateNetworkItems.find(item => get(item, `_links.${rel}.href`) != null) || {};

    return [firstRedirectedNonPrivateNetworkItem, ...privateNetworkItems]
      .map(item => get(item, `_links.${rel}.href`))
      .filter(link => !!link);
  };

  const redirectedItems = useLinkedItems(getItemLinks('redirectedItem'));
  const wasRedirected = !isEmpty(redirectedItems);

  const redirectedFromItems = useLinkedItems(getItemLinks('redirectedFromItem'));
  const isRedirectedOrder = !isEmpty(redirectedFromItems);

  if (
    !hasClaim &&
    !hasComplaint &&
    !isClaimReorder &&
    !isComplaintReorder &&
    !hasClaimReorders &&
    !hasComplaintReorders &&
    !isCustomQuote &&
    !wasRedirected &&
    !isRedirectedOrder &&
    !isChangeRequestReorder &&
    !isPreferredOrder &&
    !hasChangeRequestReorder
  ) {
    return null;
  }

  // Helper function that handles any needed UCN actions before navigation
  const triggerUcnNavigation = async ({ target, payload }) => {
    if (payload) {
      // Start/update a UCN context to share information OOps wants the next UI to have
      const { destination, error } = await navigate({
        userData: payload,
        destination: target,
        accessToken: auth.getAccessToken(),
      });

      if (error) {
        // Navigating shouldn't be blocked by a UCN failure
        window.location.assign(target);
      } else {
        // Use the returned destination link to navigate
        window.location.assign(destination);
      }
    } else {
      window.location.assign(target);
    }
  };

  const claimLinks = hasClaim
    ? claimsSummary.map(({ id, url }) => (
        <div key={id}>
          <Button
            variant="anchor"
            onClick={() =>
              triggerUcnNavigation({
                target: url,
                payload: {
                  navigation: {
                    appName: 'Order Operations',
                    returnUrl: `${OOPS_UI_URL}/orders/${order.orderId}`,
                  },
                },
              })
            }>
            {id}
          </Button>
        </div>
      ))
    : null;

  const complaintLinks = hasComplaint
    ? complaintsSummary.map(({ id, url }) => (
        <div key={id}>
          <a href={url} rel="noreferrer noopener" target="_blank">
            {id}
          </a>
        </div>
      ))
    : null;

  const reorders =
    hasClaimReorders && hasComplaintReorders
      ? uniqWith(claimReordersSummary.concat(complaintReordersSummary), isEqual)
      : hasClaimReorders
      ? claimReordersSummary
      : hasComplaintReorders
      ? complaintReordersSummary
      : changeRequestReorderSummary;

  const originalOrders =
    isClaimReorder && isComplaintReorder
      ? uniqWith(claimOriginalOrdersSummary.concat(complaintOriginalOrdersSummary), isEqual)
      : isClaimReorder
      ? claimOriginalOrdersSummary
      : isComplaintReorder
      ? complaintOriginalOrdersSummary
      : changeRequestOriginalOrdersSummary;

  return (
    <div style={style}>
      <FieldDisplay
        name={intl.formatMessage({ id: 'RelatedResources.OrderType' })}
        value={
          <RelatedResourceLabels
            hasClaim={hasClaim}
            hasComplaint={hasComplaint}
            isClaimReorder={isClaimReorder}
            isComplaintReorder={isComplaintReorder}
            isCustomQuote={isCustomQuote}
            wasRedirected={wasRedirected}
            isRedirectedOrder={isRedirectedOrder}
            isChangeRequestReorder={isChangeRequestReorder}
            isPreferredOrder={isPreferredOrder}
            hasChangeRequestReorder={hasChangeRequestReorder}
          />
        }
      />
      {claimLinks ? <FieldDisplay name={intl.formatMessage({ id: 'Claims.Claims' })} value={claimLinks} /> : null}
      {complaintLinks ? (
        <FieldDisplay name={intl.formatMessage({ id: 'Claims.Complaints' })} value={complaintLinks} />
      ) : null}
      {!isEmpty(reorders) ? (
        <FieldDisplay
          name={intl.formatMessage({ id: 'Claims.Reorders' })}
          value={reorders.map(ids => (
            <OrderLink orderIds={ids} key={ids.platform} />
          ))}
        />
      ) : null}
      {!isEmpty(originalOrders) ? (
        <FieldDisplay
          name={intl.formatMessage({ id: 'Claims.OriginalOrders' })}
          value={originalOrders.map(ids => (
            <OrderLink orderIds={ids} key={ids.platform} />
          ))}
        />
      ) : null}
      {!isEmpty(redirectedItems) ? (
        <FieldDisplay
          name={intl.formatMessage({ id: 'RequestRedirect.RedirectedOrders' })}
          value={uniqBy(redirectedItems, 'orderId').map(item => (
            <OrderLink orderIds={{ platform: item.orderId }} key={item.orderId} />
          ))}
        />
      ) : null}
      {!isEmpty(redirectedFromItems) ? (
        <FieldDisplay
          name={intl.formatMessage({ id: 'RequestRedirect.RedirectedFromOrders' })}
          value={uniqBy(redirectedFromItems, 'orderId').map(item => (
            <OrderLink orderIds={{ platform: item.orderId }} key={item.orderId} />
          ))}
        />
      ) : null}
    </div>
  );
};

RelatedLinks.propTypes = {
  order: decoratedOrderShape.isRequired,
  style: PropTypes.object,
  claimsSummary: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, url: PropTypes.string })),
  claimReordersSummary: PropTypes.arrayOf(orderIdsShape),
  complaintsSummary: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, url: PropTypes.string })),
  complaintReordersSummary: PropTypes.arrayOf(orderIdsShape),
  claimOriginalOrdersSummary: PropTypes.arrayOf(orderIdsShape),
  complaintOriginalOrdersSummary: PropTypes.arrayOf(orderIdsShape),
  loadClaims: PropTypes.func.isRequired,
  loadComplaints: PropTypes.func.isRequired,
  loadOriginalOrders: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

const mapStateToProps = ({
  order: {
    claimsSummary,
    claimReordersSummary,
    complaintsSummary,
    complaintReordersSummary,
    claimOriginalOrdersSummary,
    complaintOriginalOrdersSummary,
  },
}) => ({
  claimsSummary,
  claimReordersSummary,
  complaintsSummary,
  complaintReordersSummary,
  claimOriginalOrdersSummary,
  complaintOriginalOrdersSummary,
});

export default connect(mapStateToProps, { loadClaims, loadComplaints, loadOriginalOrders })(injectIntl(RelatedLinks));
