import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { constructQuery, getQueryString } from '../../utils/utilityfunctions';
import { search } from '../../actions/ordersearchactions.js';

const QuickSearch = props => {
  const intl = useIntl();
  const history = useHistory();
  const { style } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const searchOrders = async e => {
    e.preventDefault();
    setLoading(true);
    const params = constructQuery({ searchTerm });
    const res = await search(params);
    setLoading(false);
    const orderIds =
      res.response && res.response.results ? res.response.results.map(result => result.order.orderId) : [];
    if (orderIds && orderIds.length === 1) {
      // for a single result, just load that result
      const orderId = orderIds[0];
      const nextRoute = `/orders/${orderId}`;
      history.push(nextRoute);
    } else {
      // for multiple results or no results, go back to the search page
      const queryString = getQueryString(params);
      const nextRoute = `/?${queryString}`;
      history.push(nextRoute);
    }
  };

  const onInputChange = e => setSearchTerm(e.target.value);

  return (
    <div style={{ ...style, zIndex: '0' }}>
      <form className="form-group" onSubmit={searchOrders}>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({ id: 'SearchBar.QuickSearch' })}
            onChange={onInputChange}
          />
          <span className="input-group-btn">
            <button className="btn btn-primary" style={{ height: '50px' }}>
              <i className="fa fa-search" />
            </button>
          </span>
        </div>
      </form>
      {loading ? <Spinner className="overlay" /> : null}
    </div>
  );
};

QuickSearch.propTypes = {
  search: PropTypes.func,
  style: PropTypes.object,
  intl: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ search }, dispatch);
}

export default connect(null, mapDispatchToProps)(QuickSearch);
