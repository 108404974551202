import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class AddressSection extends PureComponent {
  render() {
    const { address, includeName } = this.props;
    return address ? (
      <div>
        {includeName ? (
          <div>
            {address.firstName} {address.lastName}
          </div>
        ) : null}
        <div>{address.company}</div>
        <div>{address.street1}</div>
        <div>{address.street2}</div>
        <div>
          {address.city} {address.stateOrProvince} {address.postalCode}
        </div>
        <div>{address.country}</div>
        <div>
          {address.phone ? address.phone : ''} {address.phoneExt ? address.phoneExt : ''}
        </div>
      </div>
    ) : null;
  }
}

AddressSection.propTypes = {
  address: PropTypes.object,
  includeName: PropTypes.bool,
};
