import moment from 'moment';
import { map, filter, minBy, maxBy } from 'lodash';

// start at 2 since 0 is invalid and the status name takes up the first column
const FIRST_COLUMN = 2;
const LAST_COLUMN = 500;
const OVERLAP_THRESHOLD = 0.2;
const EXTRA_COLUMNS = 50;
const MAX_ITERATIONS = 5;
const OUTLIER_CONSTANT = 1.9;

// get the duration between two dates
export const getDuration = (startDate, endDate) => moment(endDate).diff(moment(startDate));

// scale a duration to grid columns, using the max duration as the max value to scale against
const convertDurationToColumn = (duration, maximumDuration) => {
  const normalized = duration / maximumDuration;
  const numColumns = LAST_COLUMN - FIRST_COLUMN;
  const column = normalized * numColumns;
  return FIRST_COLUMN + column;
};

// compute an array of objects, each includes the starting, ending columns (in float), if abbreviation is applied to this status, and if the length is an outlier
const populateInitialColumnSpans = (displayedStatuses, earliestStartDate, latestClosedDate) =>
  displayedStatuses.map(itemHistory => {
    // calculate the max width of the Item History timeline by finding the difference between the first start date and the last end date
    const maximumStatusDuration = getDuration(earliestStartDate, latestClosedDate);
    const startDate = itemHistory.startDate || latestClosedDate;
    const endDate = itemHistory.endDate || latestClosedDate;

    const startColumn = convertDurationToColumn(getDuration(earliestStartDate, startDate), maximumStatusDuration);
    const endColumn = convertDurationToColumn(getDuration(earliestStartDate, endDate), maximumStatusDuration);

    return {
      ...itemHistory,
      startColumn,
      endColumn,
      isAbbreviated: false,
      isOutlier: null,
    };
  });

// given a column span, compute the length of the progress bar
const calculateColumnLength = columnSpan => columnSpan.endColumn - columnSpan.startColumn;

// update the isOutlier attribute of column span from a column span array
const updateOutliers = columnSpans => {
  const visibleColumns = filter(columnSpans, span => span.isVisible);
  const columnLengths = map(visibleColumns, calculateColumnLength);
  const mean = columnLengths.reduce((a, b) => a + b) / columnLengths.length;
  const std = Math.sqrt(columnLengths.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / columnLengths.length);
  columnLengths.forEach(
    (columnLength, index) => (visibleColumns[index].isOutlier = columnLength > mean + OUTLIER_CONSTANT * std)
  );
};

// given a column span array with the max end column less than LAST_COLUMN, rescale the array such that the two values are equal
const rescaleColumnSpans = columnSpans => {
  const endColumn = Math.max(...columnSpans.map(columnSpan => columnSpan.endColumn));
  const scale = (LAST_COLUMN - FIRST_COLUMN) / (endColumn - FIRST_COLUMN);
  const scaledColumnSpans = columnSpans.map(columnSpan => ({
    ...columnSpan,
    startColumn: (columnSpan.startColumn - FIRST_COLUMN) * scale + FIRST_COLUMN,
    endColumn: (columnSpan.endColumn - FIRST_COLUMN) * scale + FIRST_COLUMN,
  }));
  return scaledColumnSpans;
};

// given an array of (unrounded) column span, compute a new array of (rounded) column span with outliers abbreviated

// Algorithm description
// Step 0: update outliers info
// Step 1: loop through the outliers
// Step 2: find those outliers with less than OVERLAP_THRESHOLD overlap with non-outliers and extract the non-overlapping column length
// Step 3: compute new column span based on the non-overlapping column length
// Step 4: rescale back and go back to step 0
const populateAbbreviatedColumnSpans = columnSpans => {
  updateOutliers(columnSpans);
  let iteration = 0;
  while (columnSpans.filter(columnSpan => columnSpan.isOutlier).length !== 0 && iteration < MAX_ITERATIONS) {
    for (let i = 0; i < columnSpans.length; i++) {
      if (columnSpans[i].isOutlier) {
        let curStart = columnSpans[i].startColumn;
        let curEnd = columnSpans[i].endColumn;
        const outlierColumnLength = curEnd - curStart;
        for (let j = 0; j < columnSpans.length; j++) {
          if (!columnSpans[j].isOutlier) {
            const start = columnSpans[j].startColumn;
            const end = columnSpans[j].endColumn;
            if (start <= curStart && end > curStart) {
              curStart = end;
            } else if (start < curEnd && end >= curEnd) {
              curEnd = start;
            } else if (start >= curStart && end <= curEnd) {
              curStart = start - curStart > curEnd - end ? curStart : end;
              curEnd = start - curStart > curEnd - end ? start : curEnd;
            }
          }
        }
        if (outlierColumnLength * (1 - OVERLAP_THRESHOLD) < curEnd - curStart) {
          const abbreviateColumnLength = curEnd - curStart - EXTRA_COLUMNS;
          for (let k = 0; k < columnSpans.length; k++) {
            if (!columnSpans[k].isOutlier && columnSpans[k].startColumn >= curEnd) {
              columnSpans[k].startColumn -= abbreviateColumnLength;
              columnSpans[k].endColumn -= abbreviateColumnLength;
            } else if (
              columnSpans[k].isOutlier &&
              columnSpans[k].startColumn <= curStart &&
              columnSpans[k].endColumn >= curEnd
            ) {
              columnSpans[k].endColumn -= abbreviateColumnLength;
              columnSpans[k].isAbbreviated = true;
            } else if (
              columnSpans[k].isOutlier &&
              columnSpans[k].startColumn < curStart + EXTRA_COLUMNS &&
              columnSpans[k].endColumn > curStart + EXTRA_COLUMNS
            ) {
              columnSpans[k].endColumn = curStart + EXTRA_COLUMNS;
              columnSpans[k].isAbbreviated = true;
            }
          }
        }
      }
    }
    iteration += 1;
    columnSpans = rescaleColumnSpans(columnSpans);
    updateOutliers(columnSpans);
  }
  return columnSpans;
};

export default timelineData => {
  const { startDate } = minBy(timelineData, 'startDate');
  const { endDate } = maxBy(timelineData, 'endDate');

  const timelineDataWithInitialColumns = populateInitialColumnSpans(timelineData, startDate, endDate);
  const timelineDataWithAbbreviatedColumns = populateAbbreviatedColumnSpans(timelineDataWithInitialColumns);

  // round the column spans in the column span array
  return timelineDataWithAbbreviatedColumns.map(timelineData => ({
    ...timelineData,
    startColumn: Math.round(timelineData.startColumn),
    endColumn: Math.round(timelineData.endColumn),
  }));
};
