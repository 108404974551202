import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Toggle } from '@cimpress/react-components';
import FlexBox from '@cimpress/react-components/lib/FlexBox';
import { FeedbackSticker } from '@cimpress-technology/react-feedback';

import VerticalRule from '../../shared/VerticalRule';
import itemservicestatuses from '../../../constants/itemservicestatuses';
import { initStatuses } from '../../../constants/statuses';
import JSONModal from '../actions/JSONModal';
import ItemHistory from './ItemHistory';
import { useUnmetExpectations } from './useUnmetExpectations';
import { StyledText } from './StyledText';
import RemediationSteps from './RemediationSteps';
import auth from '../../../utils/auth';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const ColumnDiv = styled.div`
  min-width: 250px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
`;
const RowDiv = styled.div`
  margin-bottom: 25px;
`;

const BottomAlignedDiv = styled(RowDiv)`
  margin-top: auto;
`;

const statusIntlMap = {
  'Suspended In Standardizing Order': 'Statuses.SuspendedInStandardizingOrder',
  'Suspended In Document Review': 'Statuses.SuspendedInDocumentReview',
  'Suspended In Routing': 'Statuses.SuspendedInRouting',
  'Suspended In Processing': 'Statuses.SuspendedInProcessing',
  'Standardizing Order': 'Statuses.StandardizingOrder',
  'Document Review': 'Statuses.DocumentReview',
  Routing: 'Statuses.Routing',
  Processing: 'Statuses.Processing',
  AwaitingFulfillerResponse: 'Statuses.AwaitingFulfillerResponse',
  Production: 'Statuses.Production',
  Shipped: 'Statuses.Shipped',
  Cancelled: 'Statuses.Cancelled',
  Rejected: 'Statuses.Rejected',
};

const StatusOverview = ({ item, itemHistoryData, allStatuses }) => {
  const intl = useIntl();
  const parentStatuses = initStatuses(intl);
  const [showAdvancedDeveloperView, setShowAdvancedDeveloperView] = useState(false);
  const unmetExpectationData = useUnmetExpectations(item.unmetExpectations);

  let rejectedErrorObject = item.computedErrors.find(ce => ce.name === itemservicestatuses.REJECTED);
  let suspendedErrorObject = item.computedErrors.find(ce => ce.name === itemservicestatuses.SUSPENDED);
  let rejectedErrorDetail = rejectedErrorObject && rejectedErrorObject.detail;
  let suspendedErrorDetail = suspendedErrorObject && suspendedErrorObject.detail;
  let currentParentStatus = parentStatuses[item.computedStatus.type].displayName;

  const statusClass = item.isRejected || item.isSuspended ? 'text-danger' : '';

  const itemState = item.isSuspended && !item.isRejected ? 'Suspended In ' + currentParentStatus : currentParentStatus;

  const toggleAdvancedDeveloperView = () => {
    setShowAdvancedDeveloperView(prevShowAdvancedDeveloperView => {
      trackEvent(TrackingEventTitles.ADVANCED_DEVELOPER_VIEW_CHANGED, {
        [TrackingEventPropertiesNames.STATUS_OVERVIEW.ADVANCED_DEVELOPER_VIEW]: prevShowAdvancedDeveloperView
          ? 'Off'
          : 'On',
      });
      return !prevShowAdvancedDeveloperView;
    });
  };

  const trackClickDocumentationLink = link => {
    trackEvent(TrackingEventTitles.CLICK_UNMET_EXPECTATION_DOCUMENTATION_LINK, {
      [TrackingEventPropertiesNames.STATUS_OVERVIEW.UNMET_EXPECTATION_DOCUMENTATION_LINK]: link,
    });
  };

  return (
    <>
      <FlexBox>
        <ColumnDiv>
          <RowDiv>
            <StyledText boldText>{intl.formatMessage({ id: 'StatusOverview.CurrentItemState' })}</StyledText>
            <StyledText className={statusClass}>
              {statusIntlMap[itemState] ? intl.formatMessage({ id: statusIntlMap[itemState] }) : itemState}
            </StyledText>
          </RowDiv>

          {(item.isRejected || item.isSuspended) && (
            <>
              <RowDiv>
                <StyledText boldText>{intl.formatMessage({ id: 'StatusOverview.Details' })}</StyledText>
                <StyledText errorText={item.isRejected || item.isSuspended}>
                  {rejectedErrorDetail ||
                    suspendedErrorDetail ||
                    // if neither rejectErrorDetail nor suspendedErrorDetail exist try adding first unmetExpectation's failedReason
                    (!unmetExpectationData?.expectations?.length
                      ? intl.formatMessage({ id: 'StatusOverview.NotAvailable' })
                      : unmetExpectationData.expectations[0].failedReason)}
                </StyledText>
              </RowDiv>

              <RowDiv>
                <StyledText boldText>{intl.formatMessage({ id: 'StatusOverview.RemediationSteps' })}</StyledText>
                <RemediationSteps item={item} {...unmetExpectationData} />
              </RowDiv>

              <RowDiv>
                <StyledText boldText>{intl.formatMessage({ id: 'StatusOverview.AdditionalDocumentation' })}</StyledText>
                <StyledText>
                  {!unmetExpectationData.expectations || !unmetExpectationData.expectations.length ? (
                    <span>{intl.formatMessage({ id: 'StatusOverview.NotAvailable' })}</span>
                  ) : (
                    unmetExpectationData.expectations.map(expectation => (
                      <div>
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href={expectation._links.documentation.href}
                          onClick={() => trackClickDocumentationLink(expectation._links.documentation.href)}>
                          {expectation.name}
                        </a>
                      </div>
                    ))
                  )}
                </StyledText>
              </RowDiv>
            </>
          )}
          <BottomAlignedDiv>
            <Toggle
              size="sm"
              on={showAdvancedDeveloperView}
              onClick={() => toggleAdvancedDeveloperView()}
              style={{ marginRight: '10px' }}
            />
            <FormattedMessage id="StatusOverview.AdvancedDeveloperView" />
          </BottomAlignedDiv>
        </ColumnDiv>

        <VerticalRule />

        <ItemHistory showAdvancedDeveloperView={showAdvancedDeveloperView} item={item} {...itemHistoryData} />
      </FlexBox>
      <FlexBox spaceBetween>
        <JSONModal
          dataType={intl.formatMessage({ id: 'OrderLevelFields.Status' })}
          json={allStatuses}
          variant="default"
        />
        <FeedbackSticker
          title={intl.formatMessage({ id: 'Global.Feedback' })}
          accessToken={auth.getAccessToken()}
          campaignId="46da5740-fad7-11eb-a848-fd98b97f1d71"
        />
      </FlexBox>
    </>
  );
};

StatusOverview.propTypes = {
  item: PropTypes.object.isRequired,
  itemHistoryData: PropTypes.object.isRequired,
};

export default StatusOverview;
