import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import ItemList from './detailsv2/ItemList';
import FourOhFour from './fourOhFour';
import GeneralErrorPage from './GeneralErrorPage';
import OrderSummary from './detailsv2/orderSummary';
import QuickSearch from './detailsv2/QuickSearch';
import Breadcrumbs from './detailsv2/navigation/Breadcrumbs';
import SearchResultsNavigator from './detailsv2/navigation/SearchResultsNavigator';
import WatchButtonModal from './subscriptions/WatchButtonModal';
import OrderActions from './detailsv2/actions/OrderActions';
import auth from '../utils/auth';
import { OPA_ORDER } from '../constants/notifications';
import {
  loadOrder,
  loadEventsForOrder,
  loadOrderActions,
  loadFulfillerAlternateDetails,
  resetOrder,
} from '../actions/orderv2actions';
import { loadMerchantById } from '../actions/merchantactions';
import { filterOrdersExistingInFoma } from '../actions/fulfilleractions';
import { getSubscriptions, addSubscription, removeSubscription } from '../actions/subscriptions';
import LastUpdated from './shared/LastUpdated';
import moment from 'moment';

const OrderDetails = props => {
  const {
    loadMerchantById,
    resetOrder,
    loadOrder,
    order,
    loadEventsForOrder,
    loadOrderActions,
    filterOrdersExistingInFoma,
    location,
    history,
    match,
    merchantsMap,
    permissions,
    subscriptions,
    intl,
    ...rest
  } = props;

  const queryParams = new URLSearchParams(location.search);
  const orderId = match.params.orderId || location.search.orderId;

  const [filterCriteria, setFilterCriteria] = useState({});

  // Refresh the order any time the order Id changes
  useEffect(() => {
    if (orderId) {
      loadOrder(orderId);
      window.scrollTo(0, 0);
    }

    return () => resetOrder();
  }, [orderId, resetOrder, loadOrder]);

  // Retrieve other necessary data once an order has finished loading
  useEffect(() => {
    if (!order.loadingOrder && order.totalRows) {
      const items = Object.values(order.items);

      loadEventsForOrder(orderId);
      loadOrderActions(orderId, order.merchantId);

      // check if the merchant has already been loaded
      if (!merchantsMap[order.merchantId]) {
        // if not, then load it now
        loadMerchantById(order.merchantId);
      }

      if (order.totalRows === 1) {
        queryParams.set('itemId', items[0].itemId);
        history.replace({ search: queryParams.toString() });
      }

      if (order.shortOrderIds && order.shortOrderIds.length) {
        filterOrdersExistingInFoma(order.shortOrderIds);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.loadingOrder]);

  const refreshOrder = () => {
    resetOrder();
    loadOrder(orderId);
  };

  if (!Object.keys(order).length) {
    // render before loading has started
    return null;
  }

  const merchant = merchantsMap[order.merchantId];
  const merchantName = merchant ? merchant.displayName : order.merchantId;

  if (order.loadingOrder || order.loadingMerchantOrder) {
    return <Spinner />;
  }

  if (!order.totalRows && !order.merchantOrder) {
    return <FourOhFour />;
  }

  if (!order.totalRows && order.merchantOrder) {
    // Incomplete order, probably being indexed.

    const now = moment();
    const orderPlacedDate = moment(order.merchantOrder.createdDate);

    // If it's been waiting for a long time, something is probably wrong
    return now.diff(orderPlacedDate, 'minutes') > 5 ? (
      <GeneralErrorPage
        title={intl.formatMessage({ id: 'Global.WeHaveYourOrder' })}
        message={intl.formatMessage({ id: 'Global.OrderIsStuck' }, { timeAgo: orderPlacedDate.fromNow() })}
        status="warning"
        orderId={orderId}
      />
    ) : (
      <GeneralErrorPage
        title={intl.formatMessage({ id: 'Global.WeHaveYourOrder' })}
        message={intl.formatMessage({ id: 'Global.WorkingOnIt' })}
        status="info"
        alternate
        orderId={orderId}
      />
    );
  }

  if (!order.totalRows) {
    return (
      <GeneralErrorPage
        title={intl.formatMessage({ id: 'Global.ProblemRetrievingOrder' })}
        message=""
        status="danger"
        orderId={orderId}
      />
    );
  }

  const relevantSubscriptions = (subscriptions[OPA_ORDER] || []).filter(
    subscription => subscription && subscription.resourceId === orderId
  );

  return (
    <div className="container-fluid">
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flex: '1', alignItems: 'left', flexWrap: 'wrap', minWidth: '300px' }}>
          <Breadcrumbs
            style={{ flex: '1', minWidth: '300px', paddingRight: '20px', marginBottom: '10px' }}
            order={order}
          />
          <SearchResultsNavigator
            style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }}
            orderId={order.orderId}
          />
        </div>
        <QuickSearch style={{ flex: '1', minWidth: '300px', marginBottom: '10px' }} />
      </div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <h5>{intl.formatMessage({ id: 'OrderDetails.OrderDetails' }).toUpperCase()}</h5>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LastUpdated onRefresh={refreshOrder} />
            <WatchButtonModal
              subscriptionResourceType={OPA_ORDER}
              relevantSubscriptions={relevantSubscriptions}
              subscriptions={subscriptions}
              orderId={orderId}
              accessToken={auth.getAccessToken()}
              environment={process.env.REACT_APP_SUBSCRIPTION_HUB_ENV}
              location={location}
              {...rest}
            />
            <OrderActions order={order} />
          </div>
        </div>
        <OrderSummary
          order={order}
          permissions={permissions}
          merchant={merchant}
          merchantName={merchantName}
          onFilter={setFilterCriteria}
        />
        <ItemList
          order={order}
          permissions={permissions}
          history={history}
          location={location}
          filterCriteria={filterCriteria}
          setFilterCriteria={setFilterCriteria}
        />
      </div>
    </div>
  );
};

OrderDetails.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object.isRequired,
  routeParams: PropTypes.object,
  merchantsMap: PropTypes.object,
  permissions: PropTypes.object,
  order: PropTypes.object,
  subscriptions: PropTypes.object.isRequired,
  loadOrder: PropTypes.func,
  loadEventsForOrder: PropTypes.func,
  loadBuyerProductPricingForItem: PropTypes.func,
  loadBuyerShippingPricingForItem: PropTypes.func,
  loadMerchantById: PropTypes.func,
  loadOrderActions: PropTypes.func,
  loadFulfiller: PropTypes.func,
  loadFulfillerDetails: PropTypes.func,
  loadFulfillerAlternateDetails: PropTypes.func,
  loadProductManufacturing: PropTypes.func,
  loadPrintJob: PropTypes.func,
  resetOrder: PropTypes.func,
  intl: PropTypes.object,
};

const mapStateToProps = state => ({
  permissions: state.permissions.permissions,
  merchantsMap: state.merchants.merchantsMap,
  order: state.order,
  subscriptions: state.subscriptions.subscriptions,
});

export default connect(mapStateToProps, {
  loadOrder,
  loadEventsForOrder,
  getSubscriptions,
  addSubscription,
  removeSubscription,
  loadMerchantById,
  loadOrderActions,
  resetOrder,
  loadFulfillerAlternateDetails,
  filterOrdersExistingInFoma,
})(injectIntl(OrderDetails));
