import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import { ocean } from '@cimpress/react-components/lib/colors';
import IconCommonFileText from '@cimpress-technology/react-streamline-icons/lib/IconCommonFileText';
import IconDownloadBottom from '@cimpress-technology/react-streamline-icons/lib/IconDownloadBottom';

import { MAX_DOWNLOAD_RESULTS } from '../../utils/csvdownload';
import { trackEvent, TrackingEventTitles } from '../../analytics';

const DownloadButton = ({ disabled, download, downloading }) => {
  const downloadClicked = () => {
    trackEvent(TrackingEventTitles.DOWNLOAD, {});

    download();
  };

  const createReportClicked = () => {
    trackEvent(TrackingEventTitles.CREATE_REPORT, {});
  };

  const tooltipText = disabled ? (
    <FormattedMessage id="DownloadButton.DownloadDisabledTooltip" values={{ max: MAX_DOWNLOAD_RESULTS }} />
  ) : (
    <FormattedMessage id="DownloadButton.DownloadTooltip" values={{ max: MAX_DOWNLOAD_RESULTS }} />
  );

  return (
    <Fragment>
      {downloading ? (
        <span className="text-muted">
          <IconDownloadBottom weight="fill" />
          &nbsp;
          <FormattedMessage id="DownloadButton.Downloading" />
        </span>
      ) : (
        <Tooltip contents={tooltipText} direction="right">
          <a
            onClick={!disabled ? downloadClicked : null}
            className={disabled ? 'btn text-muted disabled' : 'clickable'}
            style={{ padding: 0, pointerEvents: 'auto' }}>
            <IconDownloadBottom weight="fill" />
            &nbsp;
            <FormattedMessage id="DownloadButton.Download" />
          </a>
        </Tooltip>
      )}
      <span style={{ marginRight: '20px' }} />
      <Tooltip contents={<FormattedMessage id="Reports.CreateReportFromSearchTooltip" />} direction="right">
        <IconCommonFileText weight="fill" color={ocean.base} />
        &nbsp;
        <Link to="reports/create?useLastSearch=true" onClick={createReportClicked}>
          <FormattedMessage id="Reports.CreateReport" />
        </Link>
      </Tooltip>
    </Fragment>
  );
};

DownloadButton.propTypes = {
  download: PropTypes.func,
  downloading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DownloadButton;
