import React from 'react';
import auth from '../../utils/auth';
import ReactFullstory, { identify } from 'react-fullstory';

const ORG = '97CT5';

const Fullstory = () => {
  if (auth.isLoggedIn()) {
    const email = auth.getProfile()['https://claims.cimpress.io/canonical_id'];
    identify(email);
    return <ReactFullstory org={ORG} />;
  }
  return null;
};

export default Fullstory;
