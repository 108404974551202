import isString from 'lodash/isString';
import moment from 'moment';

import { event } from 'react-fullstory';

export const TrackingApplicationName = 'Order Operations';

export const trackEvent = (eventName, eventProps) => event(`${TrackingApplicationName} - ${eventName}`, eventProps);

export const TrackingEventTitles = {
  ADDITIONAL_ID_COPIED: 'Additional ID Copied',
  ADDITIONAL_IDS_MODAL_OPENED: 'Additional IDs Modal Opened',
  ADDITIONAL_LINK_COPIED: 'Additional Link Copied',
  ADDITIONAL_LINKS_MODAL_OPENED: 'Additional Links Modal Opened',
  ADDRESS_POPOVER_OPENED: 'Address Popover Opened',
  ADVANCED_DEVELOPER_VIEW_CHANGED: 'Advanced Developer View Changed',
  ATTRIBUTES_MODAL_OPENED: 'Attributes Modal Opened',
  APPLY_ADVANCED_FILTER: 'Apply Advanced Filter',
  BULK_REORDER_RESULTS_DOWNLOADED: 'Bulk Reorder results downloaded',
  CALLBACK_URL_MODAL_OPENED: 'Callback Url Modal Opened',
  CALLBACK_URL_MODAL_SUBMITTED: 'Callback Url Modal Submitted',
  CANCEL_REQUEST_MODAL: 'Cancel Request Modal',
  CLEAR_ALERTS_MODAL_OPENED: 'Clear Order Alerts Modal Opened',
  CLEAR_ALERTS_SUBMITTED: 'Clear Order Alerts Submitted',
  CLEAR_ITEM_ALERTS_MODAL_OPENED: 'Clear Item Alerts Modal Opened',
  CLEAR_ITEM_ALERTS_SUBMITTED: 'Clear Item Alerts Submitted',
  CLEAR_SEARCH: 'Clear Search',
  CLICK_UNMET_EXPECTATION_DOCUMENTATION_LINK: 'Click Unmet Expectation Documentation Link',
  CONTACT_SUPPORT: 'Contact Support',
  CREATE_CLAIM: 'Create Claim',
  CREATE_REPORT: 'Create Report',
  DOWNLOAD: 'Download',
  EDIT_COLUMNS: 'Edit Columns',
  EVENT_VIEW_FORMAT_CHANGED: 'Event View Format Changed',
  EVENTS_RESENT: 'Events Resent',
  FULFILLER_POPOVER_OPENED: 'Fulfiller Popover Opened',
  HOVER_OVER_ITEM_HISTORY_STATUS_PROGRESS_BAR: 'Hover Over Item History Progress Bar',
  ITEM_TAB: 'Item Tab',
  JSON_MODAL_COPIED: 'JSON Modal Copied',
  JSON_MODAL_OPENED: 'JSON Modal Opened',
  PAGE_OPEN: 'Page Open',
  PAGE_THROUGH_ORDERS: 'Navigate Search Orders Clicked',
  PLACE_REORDER_MODAL_OPENED: 'Place Reorder Modal Opened',
  PLACE_REORDER_SUBMITTED: 'Place Reorder Submitted',
  RECENT_ORDERS_LAST_HOUR: 'Recent Orders - Last Hour',
  RECENT_ORDERS_LAST_FOUR_HOURS: 'Recent Orders - Last 4 Hours',
  RECENT_ORDERS_LAST_TWELVE_HOURS: 'Recent Orders - Last 12 Hours',
  RECENT_ORDERS_LAST_TWENTY_FOUR_HOURS: 'Recent Orders - Last 24 Hours',
  RECOMMENDED_SEARCH_ALL_BLOCKED_ORDERS: 'Recommended Searches - All Blocked Orders',
  RECOMMENDED_SEARCH_AWAITING_CUSTOMER_FEEDBACK: 'Recommended Searches - Awaiting Customer Feedback',
  RECOMMENDED_SEARCH_AWAITING_FULFILLER_RESPONSE: 'Recommended Searches - Awaiting Fulfiller Response',
  RECOMMENDED_SEARCH_AWAITING_FULFILLMENT: 'Recommended Searches - Awaiting Fulfillment',
  RECOMMENDED_SEARCH_MISSED_SLA: 'Recommended Searches - Missed SLA',
  RECOMMENDED_SEARCH_PENDING_CANCELLATION: 'Recommended Searches - Pending Cancellation',
  REFRESH_ORDER: 'Order Details Refreshed',
  REJECT_ITEM_MODAL: 'Reject Item Modal',
  REJECTED_ITEM: 'Rejected Item',
  REQUESTED_ITEM_CANCEL: 'Requested Item Cancel',
  REQUESTED_ORDER_CANCEL: 'Requested Order Cancel',
  RETRY_ROUTING_MODAL: 'Retry Routing Modal',
  SAVED_ORDER_SUBSCRIPTION: 'Saved Order Subscription',
  SAVED_SEARCH: 'Saved Search',
  SHIPMENT_PLAN_MODAL_OPENED: 'Shipment Plan Modal Opened',
  UNFOLD_ITEM_HISTORY_PARENT_STATUS: 'Unfold Item History Parent Status',
  UPDATE_CUSTOMER_ADDRESS_MODAL_OPENED: 'Update Customer Address Modal Opened',
  UPDATE_CUSTOMER_ADDRESS_SUBMITTED: 'Update Customer Address Submitted',
  UPDATE_STATUS_MODAL_OPENED: 'Update Status Modal Opened',
  UPDATE_STATUS_MODAL_SUBMITTED: 'Update Status Modal Submitted',
  VIEWED_IN_ECOMMERCE: 'Viewed in ECommerce',
  VIEWED_IN_FULFILLMENT_SYSTEM: 'Viewed in Fulfillment System',
  VIEWED_IN_POM: 'Viewed in POM',
  VIEW_FULFILLER_IN_FULFILLER_CONFIGURATION: 'View Fulfiller in Fulfiller Configuration',
  VIEW_ORDER_LOGS: 'View Order Logs',
  VIEW_ORDER_SUBSCRIPTION_OPTIONS: 'View Order Subscription Options',
  VIEW_SKU_IN_PRODUCT_CATALOG: 'View SKU in Product Catalog',
};

export const TrackingEventPropertiesNames = {
  APPLY_ADVANCED_FILTER: {
    START_DATE: 'Start Date',
    END_DATE: 'End date',
    SORT_FIELD: 'Sort Field',
    MERCHANTS: 'Merchants',
    FULFILLERS: 'Fulfillers',
    SALES_CHANNELS: 'Sales Channels',
    PRODUCT_CATEGORIES: 'Product Categories',
    ITEM_STATUS: 'Item Status',
    BLOCKED_ORDERS: 'Blocked Orders',
    ORDERS_WITH_MISSED_SLAS: 'Orders with Missed SLAs',
    ORDER_TYPE: 'Order Type',
    CLAIMS: 'Claims',
    COMPLAINTS: 'Complaints',
    CHANGE_REQUESTS: 'Change Requests',
    CHANGE_REQUEST_TYPES: 'Change Request Types',
  },
  EDIT_COLUMNS: {
    SELECTED_COLUMNS: 'Selected Columns',
  },
  ORDER_DETAILS: {
    CANCEL_REQUEST: {
      TYPE: 'Type',
      ORDER_ID: 'Order ID',
      ITEM_ID: 'Item ID',
    },
    CONTACT_SUPPORT: {
      LOCATION: 'Location',
    },
    EVENTS: {
      ORDER_ID: 'Order ID',
      VIEW_FORMAT: 'View Format',
    },
    ITEM_ACTIONS: {
      ITEM_ID: 'Item ID',
      CLEARED_STATUSES: 'Cleared Statuses',
      HAS_CLEARED_STATUS_COMMENT: 'Has Cleared Status Comment',
    },
    ITEM_DETAILS: {
      SKU: 'SKU',
      ORDER_ID: 'Order ID',
      ITEM_ID: 'Item ID',
      ATTRIBUTE_MODAL_TYPE: 'Attribute Modal Type',
    },
    ITEM_TAB_LABEL: 'Item Tab Label',
    ORDER_ACTIONS: {
      ORDER_ID: 'Order ID',
      MERCHANT_ID: 'Merchant ID',
      CLEARED_STATUSES: 'Cleared Statuses',
      HAS_CLEARED_STATUS_COMMENT: 'Has Cleared Status Comment',
      JSON_MODAL_DATA_TYPE: 'JSON Modal Data Type',
      REORDER_KEEP_PROMISE_DATE: 'Keep Original Promise Date',
      REORDER_TYPE: 'Reorder Type',
      FULFILLMENT_GROUPS_AVAILABLE_FOR_ADDRESS_CHANGE: 'Changeable Fulfillment Groups',
      FULFILLMENT_GROUP_SELECTED_FOR_ADDRESS_CHANGE: 'Selected Fulfillment Group',
    },
    ORDER_SUBSCRIPTION: {
      ORDER_ID: 'Order ID',
    },
    PAGE_THROUGH_ORDERS: {
      DIRECTION: 'Direction',
    },
    REJECT_ITEM: {
      ORDER_ID: 'Order ID',
      ITEM_ID: 'Item ID',
    },
    RETRY_ROUTING: {
      ORDER_ID: 'Order ID',
      ITEM_ID: 'Item ID',
    },
  },
  STATUS_OVERVIEW: {
    ADVANCED_DEVELOPER_VIEW: 'Advanced Developer View',
    UNFOLD_PARENT_STATUS: 'Unfold Parent Status',
    HOVER_STATUS_NAME: 'Hover Status Name',
    HOVER_STATUS_DURATION: 'Hover Status Duration',
    HOVER_STATUS_STATE: 'Hover Status Duration',
    UNMET_EXPECTATION_DOCUMENTATION_LINK: 'Unmet Expectation Documentation Link',
  },
};

export const TrackingPageNames = {
  CLAIMS: 'Claims',
};

export const PageOpen = 'Page Open';

export const trackOrderSearch = searchParams => {
  const pageProps = {};
  const addTracking = (value, property) => {
    if (isString(value)) {
      pageProps[property] = value;
    }
    if (value instanceof moment) {
      pageProps[property] = value.format();
    }
    if (Array.isArray(value)) {
      pageProps[property] = value.map(f => f.label);
    }
  };

  const valuesToTrack = [
    { value: searchParams.startDate, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.START_DATE },
    { value: searchParams.endDate, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.END_DATE },
    // TODO this is different from the implementation spec
    { value: searchParams.sortField, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.SORT_FIELD },
    { value: searchParams.merchants, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.MERCHANTS },
    { value: searchParams.fulfillers, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.FULFILLERS },
    {
      value: searchParams.salesChannels,
      property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.SALES_CHANNELS,
    },
    {
      value: searchParams.productCategories,
      property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.PRODUCT_CATEGORIES,
    },
    { value: searchParams.statuses, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.ITEM_STATUS },
    {
      value: searchParams.errorStatuses,
      property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.BLOCKED_ORDERS,
    },
    {
      value: searchParams.delayedStatuses,
      property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.ORDERS_WITH_MISSED_SLAS,
    },
    { value: searchParams.orderTypes, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.ORDER_TYPE },
    { value: searchParams.claimTypes, property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.CLAIMS },
    {
      value: searchParams.complaintTypes,
      property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.COMPLAINTS,
    },
    {
      value: searchParams.changeRequestTypes,
      property: TrackingEventPropertiesNames.APPLY_ADVANCED_FILTER.CHANGE_REQUEST_TYPES,
    },
  ];

  valuesToTrack.forEach(({ value, property }) => addTracking(value, property));

  trackEvent(TrackingEventTitles.APPLY_ADVANCED_FILTER, pageProps);
};
