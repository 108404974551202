import React, { useState, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Snackbar } from '@cimpress/react-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import get from 'lodash/get';
import map from 'lodash/map';

import { callFetch } from '../../../services/serviceHelpers';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const MODAL_ID = 'additionalIds1';
const valueLengthLimit = 100;

const AdditionalLinks = ({ item, location: { search }, history, children }) => {
  const queryParams = new URLSearchParams(search);
  const [showModal, setShowModal] = useState(
    queryParams.get('modal') === MODAL_ID && queryParams.get('itemId') === item.itemId
  );
  const [copied, setCopied] = useState(false);
  const [orderedItemOopsLink, setOrderedItemOopsLink] = useState();
  const [routedItemOopsLink, setRoutedItemOopsLink] = useState();
  const [redirectedItemLink, setRedirectedItemLink] = useState();
  const [redirectedFromItemLink, setRedirectedFromItemLink] = useState();
  const intl = useIntl();

  useEffect(() => {
    const routedItemLink = get(item, '_links.routedItem.href');
    const orderedItemLink = get(item, '_links.orderedItem.href');
    const link = routedItemLink || orderedItemLink;

    const getLinkedItem = async () => {
      try {
        const {
          _links: {
            orderOperations: { href },
          },
        } = await callFetch(link, 'GET');

        if (routedItemLink) {
          setRoutedItemOopsLink(href);
        } else {
          setOrderedItemOopsLink(href);
        }
      } catch (error) {
        // do nothing, the user will get a 404 if they don't have access to the item
      }
    };

    if (link) {
      getLinkedItem();
    }
  }, []);

  useEffect(() => {
    const redirectedFromItem = get(item, '_links.redirectedFromItem.href');
    const redirectedItem = get(item, '_links.redirectedItem.href');
    const link = redirectedItem || redirectedFromItem;

    const getLinkedItem = async () => {
      try {
        const {
          _links: {
            orderOperations: { href },
          },
        } = await callFetch(link, 'GET');

        if (redirectedItem) {
          setRedirectedItemLink(href);
        } else {
          setRedirectedFromItemLink(href);
        }
      } catch (error) {
        // do nothing, the user will get a 404 if they don't have access to the item
      }
    };

    if (link) {
      getLinkedItem();
    }
  }, []);

  const close = () => {
    queryParams.delete('modal');
    history.replace({
      search: queryParams.toString(),
    });

    setShowModal(false);
    setCopied(false);
  };

  const open = itemId => e => {
    e.stopPropagation();

    queryParams.set('itemId', itemId);
    queryParams.set('modal', MODAL_ID);
    history.replace({
      search: queryParams.toString(),
    });

    trackEvent(TrackingEventTitles.ADDITIONAL_LINKS_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ORDER_ID]: item.orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ITEM_ID]: item.itemId,
    });

    setShowModal(true);
  };

  const onCopy = () => {
    setCopied(true);

    trackEvent(TrackingEventTitles.ADDITIONAL_LINK_COPIED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ORDER_ID]: item.orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ITEM_ID]: item.itemId,
    });
  };

  const buildLinkRow = (title, value, showIfEmpty) => {
    if (!value && !showIfEmpty) {
      return null;
    }

    const rowValue = value ? (
      <Fragment>
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value.length > valueLengthLimit ? `${value.substring(0, valueLengthLimit - 1)}...` : value}
        </a>{' '}
        <CopyToClipboard text={value} onCopy={onCopy}>
          <a className="clickable pull-right">
            <i className="fa fa-clipboard" aria-hidden="true" />
          </a>
        </CopyToClipboard>
      </Fragment>
    ) : (
      <FormattedMessage id="ItemLevelFields.NotAvailable" />
    );

    return (
      <tr key={title}>
        <td className="text-right">
          <strong>{title}</strong>
        </td>
        <td style={{ wordBreak: 'break-word', width: '75%' }}>{rowValue}</td>
      </tr>
    );
  };

  const buildLinkTable = (heading, rowData, showIfEmpty) => {
    const tableRows = map(rowData, ({ value, showIfEmpty }, key) => buildLinkRow(key, value, showIfEmpty));

    if (!tableRows.some(e => e !== null) && !showIfEmpty) {
      return;
    }

    return (
      <table className="table">
        <thead>
          <tr>
            <th className="text-center" colSpan="2">
              <strong>{heading}</strong>
            </th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };

  const documentLinks = buildLinkTable(intl.formatMessage({ id: 'AdditionalLinksSections.Document' }), {
    [intl.formatMessage({ id: 'ItemLevelFields.DocRefUrl' })]: { value: item.documentReferenceUrl },
    [intl.formatMessage({ id: 'ItemLevelFields.MrdUrl' })]: {
      value: item.manufacturingReadyDataUrl,
      showIfEmpty: true,
    },
  });

  const itemLinks = buildLinkTable(
    intl.formatMessage({ id: 'AdditionalLinksSections.Transactions' }),
    {
      [intl.formatMessage({ id: 'ItemLevelFields.RoutedItem' })]: { value: routedItemOopsLink },
      [intl.formatMessage({ id: 'ItemLevelFields.OrderedItem' })]: { value: orderedItemOopsLink },
    },
    false
  );

  const redirectionLinks = buildLinkTable(
    intl.formatMessage({ id: 'AdditionalLinksSections.Redirection' }),
    {
      [intl.formatMessage({ id: 'ItemLevelFields.RedirectedItem' })]: { value: redirectedItemLink },
      [intl.formatMessage({ id: 'ItemLevelFields.RedirectedFromItem' })]: { value: redirectedFromItemLink },
    },
    false
  );

  const deliverabilityLinks = buildLinkTable(
    intl.formatMessage({ id: 'AdditionalLinksSections.DeliveryPlanning' }),
    {
      [intl.formatMessage({ id: 'ItemLevelFields.CartEvaluation' })]: {
        value: get(item, '_links.cartEvaluation.href'),
      },
      [intl.formatMessage({ id: 'ItemLevelFields.DeliveryDateCollection' })]: {
        value: get(item, '_links.deliveryDateCollection.href'),
      },
    },
    false
  );

  const fulfillmentLinks = buildLinkTable(
    intl.formatMessage({ id: 'AdditionalLinksSections.Fulfillment' }),
    {
      [intl.formatMessage({ id: 'ItemLevelFields.FulfillmentConfiguration' })]: {
        value: get(item, '_links.fulfillmentConfiguration.href'),
      },
      [intl.formatMessage({ id: 'ItemLevelFields.ProductConfiguration' })]: {
        value: get(item, '_links.productConfiguration.href'),
      },
      [intl.formatMessage({ id: 'ItemLevelFields.RoutingRequest' })]: {
        value: get(item, '_links.routingRequest.href'),
      },
      [intl.formatMessage({ id: 'ItemLevelFields.RoutingDecision' })]: {
        value: get(item, '_links.routingDecision.href'),
      },
    },
    false
  );

  const additionalLinksDialog = (
    <Modal
      size="lg"
      show={showModal}
      onRequestHide={close}
      closeOnOutsideClick
      closeButton
      footer={
        <button className="btn btn-default" onClick={close}>
          <FormattedMessage id="Global.Close" />
        </button>
      }>
      <div>{documentLinks}</div>
      <div>{itemLinks}</div>
      <div>{redirectionLinks}</div>
      <div>{deliverabilityLinks}</div>
      <div>{fulfillmentLinks}</div>
    </Modal>
  );

  return (
    <Fragment>
      <div>
        <a className="clickable" onClick={open(item.itemId)}>
          {children}
        </a>
      </div>
      {additionalLinksDialog}
      <Snackbar show={copied} delay={3000}>
        <FormattedMessage id="Global.CopiedToClipboard" />
      </Snackbar>
    </Fragment>
  );
};

AdditionalLinks.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default AdditionalLinks;
