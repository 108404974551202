export function initSearchErrorStatuses(intl) {
  return [
    {
      value: 'rejected',
      label: intl.formatMessage({ id: 'Statuses.Rejected' }),
    },
    {
      value: 'documentReviewRejected',
      label: intl.formatMessage({ id: 'Statuses.DocumentReviewRejected' }),
    },
    {
      value: 'inventoryCheckRejected',
      label: intl.formatMessage({ id: 'Statuses.InventoryCheckRejected' }),
    },
    {
      value: 'prepressFailed',
      label: intl.formatMessage({ id: 'Statuses.PrepressFailed' }),
    },
    {
      value: 'suspended',
      label: intl.formatMessage({ id: 'Statuses.Suspended' }),
    },
    {
      value: 'routing+suspended',
      label: intl.formatMessage({ id: 'Statuses.SuspendedInRouting' }),
    },
    {
      value: 'onHold',
      label: intl.formatMessage({ id: 'Statuses.OnHold' }),
    },
  ];
}
