import React from 'react';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import { TemplatePreview } from '@cimpress-technology/react-templates';
import { FormattedMessage } from 'react-intl';

import { Alert } from '../../shared/Alert';
import auth from '../../../utils/auth';
import { StyledText } from './StyledText';

const DefaultRemediationSteps = ({ item }) => {
  let defaultSteps;
  if (item.isRejected) {
    defaultSteps = [
      'StatusOverview.DefaultRemediationRejectedReview',
      'StatusOverview.DefaultRemediationContactFulfiller',
      'StatusOverview.DefaultRemediationReorder',
    ];
  }
  if (item.isSuspended) {
    defaultSteps = [
      'StatusOverview.DefaultRemediationSuspendedReview',
      'StatusOverview.DefaultRemediationSoftware',
      'StatusOverview.DefaultRemediationLargeOrder',
      'StatusOverview.DefaultRemediationRouting',
    ];
  }
  return (
    <StyledText>
      <ul>
        {defaultSteps.map(step => (
          <li>
            <FormattedMessage id={step} />
          </li>
        ))}
      </ul>
    </StyledText>
  );
};

const RemediationSteps = ({ item, remediationSteps, errorMessage, isLoading }) => {
  if (isLoading) {
    return <Spinner />;
  } else if (errorMessage) {
    return <Alert errorMessage={errorMessage} formattedErrorMessageId={'StatusOverview.RemediationStepsFailure'} />;
  } else if (remediationSteps && Object.entries(remediationSteps).length > 0) {
    return Object.values(remediationSteps).map(remediationStep => (
      <>
        <TemplatePreview
          accessToken={auth.getAccessToken()}
          templateUrl={remediationStep._links.template.href}
          renderContainer={'htmlDiv'}
          enforceHtmlProcessor={false}
          payload={{ ...item }}
          crawlingOptions={{ maxDepth: 0 }}
        />
      </>
    ));
  } else {
    return <DefaultRemediationSteps item={item} />;
  }
};

export default RemediationSteps;
