import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Snackbar from '@cimpress/react-components/lib/Snackbar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeSnackBar } from '../../actions/messageBarActions';

const MessageBar = ({
  showSnackbar,
  closeSnackBar,
  messageKey,
  detailsKey,
  detailsValues,
  additionalData,
  style,
  delay,
}) => {
  let details = null;

  if (detailsKey) {
    if (additionalData) {
      details = (
        <span>
          <b>
            <FormattedMessage id={detailsKey} values={detailsValues} />
          </b>{' '}
          {Array.isArray(additionalData) ? (
            <div style={{ marginTop: '15px' }}>
              {additionalData.map(({ key, value }, idx) => (
                <div key={idx} style={{ marginBottom: '5px' }}>
                  <strong>{key}</strong>: {value}
                </div>
              ))}
            </div>
          ) : (
            additionalData
          )}
        </span>
      );
    } else {
      details = (
        <span>
          <FormattedMessage id={detailsKey} values={detailsValues} />
        </span>
      );
    }
  }

  return (
    <Snackbar show={showSnackbar} delay={delay} status={style} onHideSnackbar={closeSnackBar}>
      <b className={style ? `text-${style}` : ''}>
        <FormattedMessage id={messageKey} />.{' '}
      </b>
      {details}
    </Snackbar>
  );
};

MessageBar.propTypes = {
  showSnackbar: PropTypes.bool,
  messageKey: PropTypes.string,
  detailsKey: PropTypes.string,
  detailsValues: PropTypes.object,
  additionalData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
  ]),
  style: PropTypes.string,
  delay: PropTypes.number,
  closeSnackBar: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    messages: { showSnackbar, messageKey, detailsKey, detailsValues, additionalData, style, delay },
  } = state;

  return {
    showSnackbar,
    messageKey,
    detailsKey,
    detailsValues,
    additionalData,
    style,
    delay,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeSnackBar }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageBar);
