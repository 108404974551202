import {
  interpretItemServiceStatus,
  getItemMissedSlaStatuses,
  getItemErrorStatuses,
  getOrderStatus,
  getItemCancellationStatus,
} from '../utils/statusModels';
import merge from 'lodash/merge';
import some from 'lodash/some';
import get from 'lodash/get';
import itemservicestatusstates from '../constants/itemservicestatusstates';

export const attachStatusToItem = item => {
  item.computedStatus = interpretItemServiceStatus(item);
  item.computedMissedSlas = getItemMissedSlaStatuses(item.statuses);
  item.computedErrors = getItemErrorStatuses(item.statuses);
  item.pendingCancellation = getItemCancellationStatus(item.statuses);
  item.isRejected = !!(item.statuses.rejected && item.statuses.rejected.state === itemservicestatusstates.CURRENT);
  item.isSuspended = !!(item.statuses.suspended && item.statuses.suspended.state === itemservicestatusstates.CURRENT);
};

export const attachStatus = order => {
  if (!order.items) {
    return order;
  }

  const items = Object.values(order.items);
  if (!items.length) {
    return order;
  }

  items.forEach(attachStatusToItem);

  const orderStatus = getOrderStatus(items.map(item => item.computedStatus));
  const hasMissedSlas = some(items, item => get(item, 'computedMissedSlas.length'));
  const hasErrors = some(items, item => get(item, 'computedErrors.length'));
  const hasPendingCancellation = some(items, item => item.pendingCancellation);
  order.computedStatus = merge(orderStatus, { hasMissedSlas, hasErrors });
  order.computedStatus = merge(orderStatus, { hasMissedSlas, hasErrors, hasPendingCancellation });

  return order;
};
