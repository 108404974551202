import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ItemStatusStepper from './ItemStatusStepper';
import PreviewCarousel from '@cimpress-technology/mex-preview-carousel';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import auth from '../../utils/auth';
import { statusDisplayMessages } from './events/eventDisplayMessages';
import TrackingDetailsModal from './events/TrackingDetailsModal';
import IdDisplay from '../shared/idDisplay';
import FieldDisplay from './FieldDisplay';
import eventTypes from '../../constants/eventtypes';
import itemStatuses from '../../constants/itemservicestatuses';
import { getSkuDetails, getSurfaces } from '../../services/introduction';
import SpinnerComp from '@cimpress/react-components/module/shapes/Spinner';
import { getVariablesFromItem } from '../../utils/utilityfunctions';

// eslint-disable-next-line no-undef
var Spinner = SpinnerComp;
class ItemHeader extends Component {
  state = { skuDescription: '', surfaces: {}, isReady: false };

  handleClick(e) {
    // this prevents the accordion from opening and closing when the preview is clicked
    e.stopPropagation();
  }

  componentDidMount() {
    const { skuCode } = this.props.item;
    const variables = getVariablesFromItem(this.props.item);
    getSkuDetails(skuCode).then(({ description }) => this.setState({ skuDescription: description }));
    const surfaces = {};
    getSurfaces(skuCode, variables)
      .then(({ surfaceGroups }) => {
        surfaceGroups.forEach(group => group.surfaces.forEach(surface => (surfaces[surface.id] = surface.name)));

        this.setState({ surfaces, isReady: true });
      })
      .catch(er => {
        console.error(er);
        this.setState({ isReady: true });
      });
  }

  // Function to sort the Preview Items
  handleSort = items => {
    const { surfaces } = this.state;
    const sortFunction = function(itemA, itemB) {
      const surfaceA = surfaces[itemA.subReferenceIds] || '';
      const surfaceB = surfaces[itemB.subReferenceIds] || '';
      const hasNumbers = surfaceA.split(' ').some(e => parseInt(e));
      // If the surface has a number, order alphabetically
      if (hasNumbers) {
        return surfaceA.localeCompare(surfaceB, 'en', { numeric: true });
      }

      // If It doesn't have a number, look for Front and Back
      if (surfaceA && surfaceB) {
        // Case Front (A) is greather than Back (B)
        if (surfaceA.indexOf('Front') >= 0 && surfaceB.indexOf('Front') < 0) {
          return -1;
        }
        // Case Back (A) is lower than Front (B)
        if (surfaceA.indexOf('Front') < 0 && surfaceB.indexOf('Front') >= 0) {
          return 1;
        }
        // Case Anything (A) is greather than Back (B)
        if (surfaceA.indexOf('Back') < 0 && surfaceB.indexOf('Back') >= 0) {
          return -1;
        }
      }
      // a must be equal to b
      return 0;
    };

    return items.sort(sortFunction);
  };

  render() {
    const { item, onMoreDetailsClick, intl, showStepper = true, shipmentData } = this.props;
    const {
      computedStatus = {},
      computedErrors = [],
      computedMissedSlas = [],
      pendingCancellation,
      events = [],
      statuses = [],
    } = item;
    const fulfillmentEvents = events.filter(({ eventType }) => eventType === eventTypes.FULFILLMENT);
    const { skuDescription, isReady } = this.state;
    const previews = isReady ? (
      <PreviewCarousel
        spinner={Spinner}
        pxSize={100}
        minimal
        hideIndicators
        auth={auth}
        mcpSku={item.skuCode}
        docRefUrl={item.documentReferenceUrl}
        variableAttributes={item.variableAttributes || []}
        fallbackPreview={intl.formatMessage({ id: 'ItemActions.NoPreview' })}
        embiggenButton
        includePreviewInfo
        includeMerchandising={!item.documentReferenceUrl && !item.manufacturingReadyDataUrl}
        onSortPreviews={this.handleSort}
      />
    ) : (
      <Spinner size="medium" />
    );
    const errorsToDisplay = computedErrors.filter(
      error => ![itemStatuses.REJECTED, itemStatuses.SUSPENDED].includes(error.name)
    );
    return (
      <div
        style={{ display: 'flex', flex: '1', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', margin: '5px 0', minWidth: '110px' }}>
          <div onClick={this.handleClick}>{previews}</div>
        </div>
        <div style={{ flex: '1 0', minWidth: '275px', margin: 'auto auto auto 15px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="h5">{item.merchantProductName}</span>
            {skuDescription ? (
              <Tooltip contents={skuDescription}>
                <IconInformationCircle style={{ marginLeft: '5px' }} className="text-info" weight="fill" />
              </Tooltip>
            ) : null}
          </div>
          <FieldDisplay
            squished
            compact
            name={intl.formatMessage({ id: 'ItemLevelFields.ItemID' })}
            value={
              <IdDisplay
                platform={item.itemId}
                merchant={item.merchantItemId}
                fulfiller={item.fulfillerItemId}
                short={item.shortItemId}
              />
            }
          />
          {item.fulfiller ? (
            <FieldDisplay
              squished
              compact
              name={intl.formatMessage({ id: 'FulfillmentGroup.Fulfiller' })}
              value={item.fulfiller.name}
            />
          ) : null}
          <FieldDisplay
            squished
            compact
            name={intl.formatMessage({ id: 'ItemLevelFields.Quantity' })}
            value={item.quantity}
          />
          <div style={errorsToDisplay.length || computedMissedSlas.length ? { marginTop: '8px' } : {}}>
            {errorsToDisplay.length ? (
              <div>
                <i className="fa fa-exclamation-triangle text-danger" />{' '}
                {errorsToDisplay.map(error => intl.formatMessage({ id: statusDisplayMessages[error.name] })).join(', ')}
              </div>
            ) : null}
            {computedMissedSlas.length ? (
              <div>
                <i className="fa fa-exclamation-triangle text-warning" />{' '}
                <FormattedMessage id="ItemLevelFields.MissedSla" />{' '}
                {computedMissedSlas
                  .map(missedSla => intl.formatMessage({ id: statusDisplayMessages[missedSla.name] }))
                  .join(', ')}
              </div>
            ) : null}
            {pendingCancellation ? (
              <div>
                <i className="fa fa-info-circle text-info" />{' '}
                <FormattedMessage id={statusDisplayMessages[itemStatuses.PENDING_CANCELLATION]} />
              </div>
            ) : null}
          </div>
        </div>
        {showStepper && (
          <div
            style={{
              minWidth: '200px',
              margin: '5px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <ItemStatusStepper
              currentStatus={computedStatus}
              itemStatuses={statuses}
              errors={computedErrors}
              missedSlas={computedMissedSlas}
              onMoreDetailsClick={onMoreDetailsClick}
              item={item}
            />
            <TrackingDetailsModal itemId={item.itemId} itemStatuses={statuses} shipmentData={shipmentData} />
          </div>
        )}
      </div>
    );
  }
}

ItemHeader.propTypes = {
  onMoreDetailsClick: PropTypes.func,
  item: PropTypes.shape({
    computedStatus: PropTypes.object,
    computedErrors: PropTypes.array,
    computedMissedSlas: PropTypes.array,
    merchantProductName: PropTypes.string,
    skuCode: PropTypes.string,
    variableAttributes: PropTypes.array,
    documentReferenceUrl: PropTypes.string,
  }),
  shipmentData: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentId: PropTypes.string.isRequired,
      carrierServiceKey: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      shipmentCreationDateTime: PropTypes.string,
      packedDateTime: PropTypes.string,
      destinationAddress: PropTypes.any.isRequired,
      packages: PropTypes.arrayOf(PropTypes.any),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          mcpItemId: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  intl: PropTypes.object,
};

export default injectIntl(ItemHeader);
