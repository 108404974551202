import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShipmentPlanAnalysisViewer } from '@cimpress-technology/shipment-planner-component';
import { Modal, Button } from '@cimpress/react-components';

import ActionLink from '../shared/ActionLink';
import { TrackingEventPropertiesNames, TrackingEventTitles, trackEvent } from '../../analytics';

const ShipmentPlanModal = ({ isDelayed, item, accessToken }) => {
  const [showShipmentPlan, setShowShipmentPlan] = useState(false);
  const intl = useIntl();

  const toggleModal = () => {
    setShowShipmentPlan(showPlan => !showPlan);

    // Yay for closures! "showShipmentPlan" is false when the modal has been toggled to opened.
    if (!showShipmentPlan) {
      trackEvent(TrackingEventTitles.SHIPMENT_PLAN_MODAL_OPENED, {
        [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ORDER_ID]: item.orderId,
        [TrackingEventPropertiesNames.ORDER_DETAILS.ITEM_DETAILS.ITEM_ID]: item.itemId,
      });
    }
  };

  return (
    <span>
      <ActionLink
        text={intl.formatMessage({
          id: `${isDelayed ? 'ShippingMessage.ViewOriginalShipmentPlan' : 'ShippingMessage.ViewShipmentPlan'}`,
        })}
        action={toggleModal}
        style={{ padding: '0' }}
      />
      <Modal
        show={showShipmentPlan}
        title={intl.formatMessage({ id: 'Fulfiller.ShipmentPlan' })}
        closeButton
        closeOnOutsideClick
        onRequestHide={toggleModal}
        size="lg"
        footer={
          <Button className="btn btn-default" onClick={toggleModal}>
            <FormattedMessage id="Global.Close" />
          </Button>
        }>
        <ShipmentPlanAnalysisViewer item={item} accessToken={accessToken} style={{ maxHeight: '450px' }} />
      </Modal>
    </span>
  );
};

export default ShipmentPlanModal;
