import api from '../middleware/api';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';

export default function configureStore(initialState) {
  const subscribe = listener => {
    return store.subscribe(listener);
  };

  var store = createStore(rootReducer, initialState, applyMiddleware(thunk.withExtraArgument(subscribe), api));

  return store;
}
