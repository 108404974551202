import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Alert from '@cimpress/react-components/lib/Alert';
import Checkbox from '@cimpress/react-components/lib/Checkbox';
import { searchResultsOrderShape } from '../propTypes';
import OrderLink from './OrderLink';
import { shouldSkipItem, isItemInProgress } from '../../utils/reorders';

const BulkReorderReview = ({ orders, onKeepOriginalPromiseDateChange, shouldKeepOriginalPromiseDate }) => {
  const hasCancelledOrShippedItems = orders.some(order => order.items.some(item => shouldSkipItem(item)));
  const hasInProgressItems = orders.some(order => order.items.some(item => isItemInProgress(item)));
  const hasMultiItemOrders = orders.some(order => order.items.filter(item => !shouldSkipItem(item)).length > 1);

  return (
    <div>
      {hasCancelledOrShippedItems ? (
        <Alert
          status="warning"
          message={<FormattedMessage id="BulkReorder.CancelledOrShippedWarning" />}
          dismissible={false}
        />
      ) : null}
      {hasInProgressItems ? (
        <Alert status="warning" message={<FormattedMessage id="BulkReorder.InProgressWarning" />} dismissible={false} />
      ) : null}
      {hasMultiItemOrders ? (
        <Alert status="warning" message={<FormattedMessage id="BulkReorder.MultiItemWarning" />} dismissible={false} />
      ) : null}

      <div>
        <strong>
          <FormattedMessage id="BulkReorder.RoutingNote" />
        </strong>
      </div>
      <div style={{ marginTop: '10px' }}>
        <FormattedMessage id="BulkReorder.Description" values={{ num: orders.length }} />
      </div>
      <div>
        <Checkbox
          label={<FormattedMessage id="BulkReorder.KeepOriginalDateCheckboxLabel" />}
          checked={shouldKeepOriginalPromiseDate}
          payload="keepOriginalPromisedDate"
          onChange={onKeepOriginalPromiseDateChange}
        />
        {shouldKeepOriginalPromiseDate ? (
          <span style={{ color: 'red' }}>
            <FormattedMessage id="BulkReorder.KeepOriginalDateNote" />
          </span>
        ) : null}
      </div>
      <div style={{ marginTop: '10px' }}>
        {orders.map(order => (
          <div key={order.orderId}>
            <OrderLink order={order} />
          </div>
        ))}
      </div>
    </div>
  );
};

BulkReorderReview.propTypes = {
  orders: PropTypes.arrayOf(searchResultsOrderShape),
};

export default injectIntl(BulkReorderReview);
