import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '@cimpress/react-components/lib/Modal';
import Button from '@cimpress/react-components/lib/Button';

export const MISSING_AUTH = 'missingAuth';
export const CANCEL = 'cancel';
export const INVALID_TOKEN = 'invalidToken';
export const BACKEND = 'backend';

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

const config = {
  int: {
    centralUrl: 'https://int-central.mex.cimpress.io',
    subscriptionsUrl: 'https://int-subscriptions.commerce.cimpress.io',
  },
  prd: {
    centralUrl: 'https://central.mex.cimpress.io',
    subscriptionsUrl: 'https://subscriptions.commerce.cimpress.io',
  },
};

export const AUTHORIZATION_RETURN_KEY = 'authorizationReturn';

class AuthorizationModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
    environment: PropTypes.oneOf(['int', 'prd']).isRequired,
  };

  onRedirect = () => {
    const { environment } = this.props;
    const { centralUrl } = config[environment];
    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set(AUTHORIZATION_RETURN_KEY, true);
    window.location.href = `${centralUrl}/subscriptions/authorize?returnUri=${encodeURIComponent(returnUrl.href)}`;
  };

  render() {
    const { onClose } = this.props;
    return (
      <Modal
        show
        onRequestHide={onClose}
        closeButton
        closeOnOutsideClick
        title={<FormattedMessage id="AuthFlow.AuthModalTitle" />}
        footer={
          <Fragment>
            <Button className="btn btn-default" onClick={onClose}>
              <FormattedMessage id="Global.Cancel" />
            </Button>
            <Button className="btn btn-primary" onClick={this.onRedirect}>
              <FormattedMessage id="Global.Next" />
            </Button>
          </Fragment>
        }>
        <p>
          <FormattedMessage id="AuthFlow.AuthModalParagraph1" />
        </p>
        <p>
          <FormattedMessage id="AuthFlow.AuthModalParagraph2" />
        </p>
      </Modal>
    );
  }
}

const withNotificationsHubAuthFlow = WrappedComponent => {
  class NotificationsHubAuthFlowHOC extends Component {
    static propTypes = {
      environment: PropTypes.oneOf(['int', 'prd']),
      accessToken: PropTypes.string,
      location: PropTypes.shape({
        search: PropTypes.string,
      }),
      history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
      }),
    };

    static defaultProps = {
      environment: 'int',
      location: {},
    };

    constructor(props) {
      super(props);

      const {
        location: { search = '' },
        history,
      } = this.props;
      const queryParams = new URLSearchParams(search);
      const returningFromAuth = Boolean(queryParams.get(AUTHORIZATION_RETURN_KEY));

      if (returningFromAuth && history) {
        queryParams.delete(AUTHORIZATION_RETURN_KEY);
        history.replace({
          search: queryParams.toString(),
        });
      }

      this.state = {
        showAuthModal: false,
        returningFromAuth,
      };
    }

    componentDidMount() {
      const { accessToken } = this.props;

      if (accessToken) {
        this.loadUser();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.accessToken !== prevProps.accessToken) {
        this.loadUser();
      }
    }

    loadUser = () => {
      this.getUserPromise = this.getUser().then(user => {
        const { isAuthorized } = user;
        this.setState({ isAuthorized });
        return user;
      });
    };

    getUser = () => {
      const { accessToken, environment } = this.props;
      if (!accessToken) {
        return Promise.reject({ reason: MISSING_AUTH });
      }

      let decoded;
      try {
        decoded = parseJwt(accessToken);
      } catch (error) {
        return Promise.reject({ reason: INVALID_TOKEN, error });
      }

      const { sub } = decoded;
      if (!sub) {
        return Promise.reject({ reason: INVALID_TOKEN });
      }

      const headers = new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });

      const options = {
        method: 'GET',
        mode: 'cors',
        headers,
      };

      const { subscriptionsUrl } = config[environment];
      const fullUrl = `${subscriptionsUrl}/v1/users/${sub}`;

      return fetch(fullUrl, options).then(res => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 404) {
          return { isAuthorized: false };
        }

        return Promise.reject({ reason: BACKEND, error: res });
      });
    };

    ensureAuthorized = () =>
      new Promise((resolve, reject) => {
        this.setState({ reject });

        if (this.getUserPromise) {
          this.getUserPromise
            .then(user => {
              if (!user.isAuthorized) {
                this.setState({ showAuthModal: true });
              } else {
                resolve(user);
              }
            })
            .catch(reject);
        } else {
          reject({ reason: MISSING_AUTH });
        }
      });

    showModal = () => this.setState({ showAuthModal: true });
    closeModal = () => {
      this.state.reject({ reason: CANCEL });
      this.setState({ showAuthModal: false, returningFromAuth: false });
    };
    finishAuthFlow = () => {
      this.setState({ showAuthModal: false, returningFromAuth: false });
    };

    render() {
      const { showAuthModal, isAuthorized, returningFromAuth } = this.state;
      const { environment } = this.props;
      return (
        <Fragment>
          {showAuthModal ? <AuthorizationModal environment={environment} onClose={this.closeModal} /> : null}
          <WrappedComponent
            {...this.props}
            finishAuthFlow={this.finishAuthFlow}
            returningFromAuth={returningFromAuth}
            isAuthorized={isAuthorized}
            ensureAuthorized={this.ensureAuthorized}
          />
        </Fragment>
      );
    }
  }

  return NotificationsHubAuthFlowHOC;
};

export default withNotificationsHubAuthFlow;
