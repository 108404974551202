import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Drawer from '@cimpress/react-components/lib/Drawer';
import TextField from '@cimpress/react-components/lib/TextField';
import Modal from '@cimpress/react-components/lib/Modal';
import Button from '@cimpress/react-components/lib/Button';
import { slate } from '@cimpress/react-components/lib/colors';
import AccordionTreeFilter from './AccordionTreeFilter';
import AccordionCheckboxFilter from './AccordionCheckboxFilter';
import SelectedFilters from './selectedfilters';
import SavedSearchSelect from './savedSearchSelect';
import sumBy from 'lodash/sumBy';
import SearchBar from './searchbar';
import SortDropdown from './sortdropdown';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class FilterDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      filterQuery: '',
      showSave: false,
    };
    this.filterSearchArea = null;
  }

  closeDrawer = () => {
    this.setState({ open: false });
  };

  openDrawer = () => {
    this.setState({ open: true });
  };

  updateFilterQuery = e => {
    this.setState({ filterQuery: e.target.value });
  };

  scrollToTop = () => {
    this.filterSearchArea.scrollIntoView({ behavior: 'smooth' });
  };

  onSaveClick = () => {
    this.setState({ showSave: true });
  };

  onSaveFilters = () => {
    this.props.onSaveClick(this.state.savedFilterName).then(this.onCloseSave);
  };

  onCloseSave = () => {
    this.setState({ showSave: false });
  };

  onSavedFilterNameChange = e => {
    this.setState({ savedFilterName: e.target.value });
  };

  setRef = el => (this.filterSearchArea = el);

  componentDidMount() {
    if (this.props.location) {
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location) {
    }
  }

  render() {
    // not getting initial selected filters based on queryString
    const selectedFilters = this.props.filters.map(filterCategory => ({
      name: filterCategory.name,
      reference: filterCategory.selectedValuesKey,
      values: filterCategory.selectedValues
        .filter(selectedValue => {
          // ECOM 3216 - Item Sub-statuses
          if (filterCategory.selectedValuesKey === 'selectedStatuses') {
            if (selectedValue.value === 'inProduction') {
              return false;
            } else {
              return filterCategory.availableValues.map(ai => ai.value).includes(selectedValue.value);
            }
          }
          return true;
        })
        .map(x => x.label),
    }));

    const { selectedSavedSearch, intl } = this.props;
    const { showSave, savedFilterName } = this.state;

    const filtersSelected = sumBy(selectedFilters, filterGroup => {
      return filterGroup.values.length;
    });
    return (
      <div>
        <a className="clickable" onClick={this.openDrawer}>
          <i className="fa fa-filter" aria-hidden="true" /> <FormattedMessage id="SearchBar.AdvancedSearch" />{' '}
          {filtersSelected > 0 ? `(${filtersSelected})` : null}
        </a>
        <Drawer
          show={this.state.open}
          onRequestHide={this.closeDrawer}
          closeOnClickOutside={true}
          header={
            <h2>
              <FormattedMessage id="SearchBar.AdvancedSearch" />
            </h2>
          }
          footerStyle={{ paddingRight: 0 }}
          footer={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {filtersSelected > 0 ? (
                <div
                  style={{
                    textAlign: 'left',
                    marginBottom: '16px',
                    maxHeight: '33%',
                    overflowY: 'scroll',
                  }}>
                  <h4>
                    <FormattedMessage id="Global.Selected" />
                  </h4>
                  <SelectedFilters filters={selectedFilters} removeFilter={this.props.onFilterRemove} />
                </div>
              ) : null}
              <div style={{ display: 'flex', paddingRight: '20px' }}>
                <div style={{ flexGrow: '1', textAlign: 'left', padding: '15px 0 0 5px' }}>
                  <a className="clickable" onClick={this.props.onClearAll}>
                    <FormattedMessage id="Global.ClearAll" />
                  </a>
                </div>
                {this.props.onSaveClick ? (
                  <div style={{ padding: '5px' }}>
                    <button className="btn btn-default" onClick={this.onSaveClick}>
                      <FormattedMessage id="Global.Save" />
                    </button>
                  </div>
                ) : null}
                <div style={{ padding: '5px' }}>
                  <button className="btn btn-primary" onClick={this.props.onUpdateFilters}>
                    <FormattedMessage id="Global.Apply" />
                  </button>
                </div>
              </div>
            </div>
          }>
          <div>
            <SavedSearchSelect
              selectedSavedSearch={selectedSavedSearch}
              selectedFilters={selectedFilters}
              onSavedSearchChange={this.props.onSavedSearchChange}
            />
            <hr />
            <SearchBar
              searchTerm={this.props.searchTerm}
              onSearchTermChanged={this.props.onSearchTermChanged}
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              displayTimezone={this.props.displayTimezone}
              onStartDateChanged={this.props.onStartDateChanged}
              onEndDateChanged={this.props.onEndDateChanged}
              sidebar
            />
            <SortDropdown
              setSort={this.props.setSort}
              sortField={this.props.sortField}
              sortOrder={this.props.sortOrder}
            />
            <h3 ref={this.setRef}>
              <FormattedMessage id="Reports.Filters" />
            </h3>
            <div style={{ position: 'relative' }}>
              <i
                style={{
                  position: 'absolute',
                  padding: '18px',
                  pointerEvents: 'none',
                  right: '0',
                  zIndex: '1',
                  color: slate,
                }}
                className="fa fa-search"
                aria-hidden="true"
              />
              <TextField
                placeholder="Search filters"
                onChange={this.updateFilterQuery}
                value={this.state.filterQuery}
              />
            </div>
            {this.props.filters.map(filterGroup =>
              !filterGroup.hide ? (
                !filterGroup.selectTree ? (
                  <AccordionCheckboxFilter
                    key={filterGroup.name}
                    searchQuery={this.state.filterQuery}
                    filterTitle={filterGroup.name}
                    availableItems={filterGroup.availableValues}
                    selectedItems={filterGroup.selectedValues}
                    selectedItemsKey={filterGroup.selectedValuesKey}
                    selectionChange={this.props.onFilterChange}
                    isLoading={filterGroup.isLoading}
                    hasError={filterGroup.hasError}
                    scrollToTop={this.scrollToTop}
                  />
                ) : (
                  <AccordionTreeFilter
                    key={filterGroup.name}
                    filterTitle={filterGroup.name}
                    searchQuery={this.state.filterQuery}
                    availableItems={filterGroup.availableValues}
                    selectedItems={filterGroup.selectedValues}
                    selectedItemsKey={filterGroup.selectedValuesKey}
                    removeFilter={this.props.onFilterRemove}
                    selectTree={filterGroup.selectTree}
                    selectionChange={this.props.onFilterChange}
                  />
                )
              ) : null
            )}
          </div>
        </Drawer>
        <Modal
          show={showSave}
          onRequestHide={this.onCloseSave}
          closeButton={true}
          title={<FormattedMessage id="SaveSearch.SavedFiltersTitle" />}
          footer={
            <Fragment>
              <Button className="btn btn-default" onClick={this.onCloseSave}>
                <FormattedMessage id="Global.Cancel" />
              </Button>
              <Button className="btn btn-primary" onClick={this.onSaveFilters} disabled={!savedFilterName}>
                <FormattedMessage id="Global.Save" />
              </Button>
            </Fragment>
          }>
          <TextField
            name="savedFilters"
            label={intl.formatMessage({ id: 'SaveSearch.SavedFiltersPlaceholder' })}
            value={savedFilterName}
            onChange={this.onSavedFilterNameChange}
          />
        </Modal>
      </div>
    );
  }
}

FilterDrawer.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      availableValues: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
      selectedValues: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
      selectedValuesKey: PropTypes.string.isRequired,
      selectTree: PropTypes.object,
    })
  ),
  onFilterChange: PropTypes.func.isRequired,
  onFilterRemove: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onUpdateFilters: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onSearchTermChanged: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  displayTimezone: PropTypes.string,
  onStartDateChanged: PropTypes.func.isRequired,
  onEndDateChanged: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func,
  onSavedSearchChange: PropTypes.func,
  selectedSavedSearch: PropTypes.object,
  setSort: PropTypes.func,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  intl: PropTypes.object,
  location: PropTypes.object,
};

export default connect()(injectIntl(withRouter(FilterDrawer)));
