import React, { useContext, useReducer } from 'react';
import get from 'lodash/get';
import produce from 'immer';

const RedirectContext = React.createContext();

export const areAllItemsSelectedForRedirection = state =>
  Object.values(state.itemsAvailableToRedirect).every(item => item.isSelectedForRedirection);
export const isSelectedForRedirection = (state, itemId) =>
  get(state, `itemsAvailableToRedirect.${itemId}.isSelectedForRedirection`, false);

const reducer = (state, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case 'TOGGLE_ALL_ITEMS_FOR_REDIRECTION': {
        const allSelected = areAllItemsSelectedForRedirection(state);
        Object.values(draft.itemsAvailableToRedirect).forEach(item => {
          item.isSelectedForRedirection = !allSelected;
        });
        return;
      }
      case 'TOGGLE_ITEM_FOR_REDIRECTION': {
        const item = draft.itemsAvailableToRedirect[action.payload.itemId];
        item.isSelectedForRedirection = !item.isSelectedForRedirection;
        return;
      }
      case 'SELECT_FULFILLMENT_CONFIGURATION': {
        draft.itemsAvailableToRedirect[action.payload.itemId].newFulfillmentConfigurationUrl =
          action.payload.fulfillmentConfigurationUrl;
        return;
      }
      case 'TOGGLE_WAIT_FOR_FULFILLER': {
        draft.waitForFulfiller = !state.waitForFulfiller;
        return;
      }
      default:
        return state;
    }
  });
};

export default () => {
  const { state, dispatch } = useContext(RedirectContext);
  return { state, dispatch };
};

export const RedirectProvider = ({ items, children }) => {
  const initialState = {
    itemsAvailableToRedirect: items.reduce((itemsAvailableToRedirect, item) => {
      itemsAvailableToRedirect[item.itemId] = {
        itemId: item.itemId,
        isSelectedForRedirection: false,
      };
      return itemsAvailableToRedirect;
    }, {}),
    waitForFulfiller: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <RedirectContext.Provider value={{ state, dispatch }}>{children}</RedirectContext.Provider>;
};
