import * as types from '../constants/actiontypes.js';
import { getSubscriptions as loadSubscriptions, postSubscription, deleteSubscription } from '../services/subscriptions';

export const getSubscriptions = params => dispatch =>
  loadSubscriptions(params).then(subscriptions => {
    dispatch({
      type: types.ADD_SUBSCRIPTION_RESOURCE_SUBSCRIPTIONS,
      subscriptions,
      ...params,
    });
  });

export const removeSubscription = subscriptionId => dispatch =>
  deleteSubscription(subscriptionId).then(() => {
    dispatch({
      type: types.REMOVE_SUBSCRIPTION,
      subscriptionId,
    });
  });

export const addSubscription = subscription => dispatch =>
  postSubscription(subscription).then(newSubscription => {
    dispatch({
      type: types.ADD_SUBSCRIPTION,
      subscription: newSubscription,
    });
  });
