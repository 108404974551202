import React from 'react';
import { FlexBox } from '@cimpress/react-components';
import { alloy } from '@cimpress/react-components/lib/colors';
import styled from 'styled-components';
import { map, get, groupBy, find, findIndex } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const TimestampRow = styled.div`
  display: grid;
  grid-template-columns: ${({ numColumns }) => `256px repeat(${numColumns}, 1fr)`};
  width: 100%;
  align-items: center;
  margin-top: 15px;
`;

const TimestampRowCell = styled.div`
  grid-column: ${({ column }) => column};
  display: flex;
  align-items: center;
`;

const TimelineRow = ({ timelineData, numColumns, displayTimezone }) => {
  // How frequently we will have time stamps
  // number of time stamps = numColumns / (timestampFrequency  + spacingFactor) + 1
  // the first timestamp is always hardcoded due to the larger width of columns 1 and 2
  const timestampFrequency = 50;
  // allows for spacing between each other and the first time stamp or how wide of space the timestamp takes up
  // tweak for whatever factor looks evenly spaced
  const spacingFactor = 25;

  const formattedDate = dateString => {
    return moment(dateString)
      .tz(displayTimezone)
      .format('MMM D');
  };

  const findTimeStampForColumn = column => {
    // find what status/ progress bar the timestamp vertically lines up with
    const terminalStatuses = new Set(['fulfilled', 'rejected', 'cancelled']);
    let nonTerminalMatchingStatus = find(
      timelineData,
      timelineRow =>
        column >= timelineRow.startColumn && column < timelineRow.endColumn && !terminalStatuses.has(timelineRow.name)
    );
    const terminalMatchingStatus = find(
      timelineData,
      timelineRow =>
        column >= timelineRow.startColumn && column < timelineRow.endColumn && terminalStatuses.has(timelineRow.name)
    );

    // If we can't find a status that contains our column, there is a gap in the item's statuses
    // use the two statuses around our column to get the approximate startDate, endDate, startColumn, and endColumn
    if (!nonTerminalMatchingStatus && !terminalMatchingStatus) {
      const indexOfClosestEndTimeStatus = findIndex(timelineData, timelineRow => column < timelineRow.startColumn);
      const indexOfClosestStartTimeStatus = indexOfClosestEndTimeStatus - 1;
      // if we can't find any status the timeline data may not be populated yet, return to avoid null errors
      if (indexOfClosestEndTimeStatus < 0) {
        return;
      }
      nonTerminalMatchingStatus = {
        startDate: timelineData[indexOfClosestStartTimeStatus].endDate,
        endDate: timelineData[indexOfClosestEndTimeStatus].startDate,
        startColumn: timelineData[indexOfClosestStartTimeStatus].endColumn,
        endColumn: timelineData[indexOfClosestEndTimeStatus].startColumn,
      };
    }

    // terminal statuses visually will not vertically line up with the timestamps, prefer other statuses when choosing timestamp
    const preferredMatchingStatus = nonTerminalMatchingStatus || terminalMatchingStatus;
    const { endColumn, startColumn, startDate, endDate } = preferredMatchingStatus;

    // convert everything to milliseconds from unix epoch timestamps since that is the easiest arithmetically
    const startDateMoment = moment(startDate).valueOf();
    const endDateMoment = endDate ? moment(endDate).valueOf() : startDateMoment;

    // compare the percentage the column is from the start of the chosen status to total time of the status
    // to find the matching timestamp in between the start and end times of the status
    const matchingColumnTime =
      ((column - startColumn) / (endColumn - startColumn)) * (endDateMoment - startDateMoment) + startDateMoment;
    return matchingColumnTime;
  };

  const generateColumnArray = () => {
    const columnArray = [];
    for (let i = spacingFactor + timestampFrequency; i < numColumns; i += timestampFrequency + spacingFactor) {
      columnArray.push(i);
    }

    const timestampData = map(columnArray, timestampColumn => ({
      endTimeStampColumn: timestampColumn + timestampFrequency,
      startTimeStampColumn: timestampColumn,
      timeStamp: findTimeStampForColumn(timestampColumn),
      shouldShowDate: false,
    }));

    const sameDayTimeStamps = groupBy(timestampData, data => formattedDate(data.timeStamp));
    Object.values(sameDayTimeStamps).forEach(sameDayTimeStampGroup => {
      if (formattedDate(sameDayTimeStampGroup[0].timeStamp) !== formattedDate(get(timelineData[0], 'startDate'))) {
        sameDayTimeStampGroup[0].shouldShowDate = true;
      }
    });
    return timestampData;
  };

  const timestampColumnDataArray = generateColumnArray();
  return (
    <>
      <div style={{ borderBottom: `1px solid ${alloy}`, marginTop: '15px' }} />
      <TimestampRow numColumns={numColumns}>
        <TimestampRowCell column={`1 / 2`} />
        <TimestampRowCell column={`3 / ${timestampFrequency}`}>
          {moment(get(timelineData[0], 'startDate'))
            .tz(displayTimezone)
            .format('H:mm:ss')}
        </TimestampRowCell>
        {map(timestampColumnDataArray, (columnData, index) => (
          <TimestampRowCell
            column={`${columnData.startTimeStampColumn} / ${columnData.endTimeStampColumn}`}
            key={`timeStampColumn-${columnData.startTimeStampColumn}-${columnData.endTimeStampColumn}`}>
            {moment(columnData.timeStamp)
              .tz(displayTimezone)
              .format('H:mm:ss')}
          </TimestampRowCell>
        ))}
      </TimestampRow>
      <TimestampRow numColumns={numColumns}>
        <TimestampRowCell column={`1 / 2`} />
        <TimestampRowCell column={`3 / ${timestampFrequency}`}>
          <b>{formattedDate(get(timelineData[0], 'startDate'))}</b>
        </TimestampRowCell>
        {map(timestampColumnDataArray, (columnData, index) => (
          <TimestampRowCell
            column={`${columnData.startTimeStampColumn} / ${columnData.endTimeStampColumn}`}
            key={`dateColumn-${columnData.startTimeStampColumn}-${columnData.endTimeStampColumn}`}>
            <b>{columnData.shouldShowDate && formattedDate(columnData.timeStamp)}</b>
          </TimestampRowCell>
        ))}
      </TimestampRow>
      <FlexBox center>
        <FormattedMessage tagName="em" id="StatusOverview.DateLabel" />
      </FlexBox>
    </>
  );
};

TimelineRow.propTypes = {
  timelineData: PropTypes.array.isRequired,
  numColumns: PropTypes.number.isRequired,
  displayTimezone: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {
    userPreferences: { displayTimezone },
  } = state;

  return {
    displayTimezone,
  };
}

export default connect(mapStateToProps, null)(TimelineRow);
