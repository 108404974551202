// search input
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_MERCHANTS = 'SET_MERCHANTS';
export const SET_FULFILLERS = 'SET_FULFILLERS';
export const SET_STATUSES = 'SET_STATUSES';
export const SET_DELAYED_STATUSES = 'SET_DELAYED_STATUSES';
export const SET_ERROR_STATUSES = 'SET_ERROR_STATUSES';
export const SET_ORDER_TYPES = 'SET_ORDER_TYPES';
export const SET_SALES_CHANNELS = 'SET_SALES_CHANNELS';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_CLAIM_TYPES = 'SET_CLAIM_TYPES';
export const SET_COMPLAINT_TYPES = 'SET_COMPLAINT_TYPES';
export const SET_CHANGE_REQUEST_TYPES = 'SET_CHANGE_REQUEST_TYPES';
export const SET_SEARCH_SORT = 'SET_SEARCH_SORT';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

// generic search actions
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';

// page navigation
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const SELECT_ORDER = 'SELECT_ORDER';

// updating callback url
export const GET_CALLBACK_URL_REQUEST = 'GET_CALLBACK_URL_REQUEST';
export const GET_CALLBACK_URL_SUCCESS = 'GET_CALLBACK_URL_SUCCESS';
export const GET_CALLBACK_URL_FAILURE = 'GET_CALLBACK_URL_FAILURE';

export const POST_CALLBACK_URL_REQUEST = 'POST_CALLBACK_URL_REQUEST';
export const POST_CALLBACK_URL_SUCCESS = 'POST_CALLBACK_URL_SUCCESS';
export const POST_CALLBACK_URL_FAILURE = 'POST_CALLBACK_URL_FAILURE';

// cancel items
export const CANCEL_ITEMS_REQUEST = 'CANCEL_ITEMS_REQUEST';
export const CANCEL_ITEMS_SUCCESS = 'CANCEL_ITEMS_SUCCESS';
export const CANCEL_ITEMS_FAILURE = 'CANCEL_ITEMS_FAILURE';

// cancel order
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

// reject items
export const REJECT_ITEM_REQUEST = 'REJECT_ITEM_REQUEST';
export const REJECT_ITEM_SUCCESS = 'REJECT_ITEM_SUCCESS';
export const REJECT_ITEM_FAILURE = 'REJECT_ITEM_FAILURE';

export const GET_MERCHANTS_REQUEST = 'GET_MERCHANTS_REQUEST';
export const GET_MERCHANTS_SUCCESS = 'GET_MERCHANTS_SUCCESS';
export const GET_MERCHANTS_FAILURE = 'GET_MERCHANTS_FAILURE';

export const GET_SINGLE_MERCHANT = 'GET_SINGLE_MERCHANT';

export const GET_FULFILLERS_REQUEST = 'GET_FULFILLERS_REQUEST';
export const GET_FULFILLERS_SUCCESS = 'GET_FULFILLERS_SUCCESS';
export const GET_FULFILLERS_FAILURE = 'GET_FULFILLERS_FAILURE';

export const GET_AVAILABLE_FILTERS_REQUEST = 'GET_AVAILABLE_FILTERS_REQUEST';
export const GET_AVAILABLE_FILTERS_SUCCESS = 'GET_AVAILABLE_FILTERS_SUCCESS';
export const GET_AVAILABLE_FILTERS_FAILURE = 'GET_AVAILABLE_FILTERS_FAILURE';

// resend events
export const POST_EVENT_ATTEMPT_REQUEST = 'POST_EVENT_ATTEMPT_REQUEST';
export const POST_EVENT_ATTEMPT_SUCCESS = 'POST_EVENT_ATTEMPT_SUCCESS';
export const POST_EVENT_ATTEMPT_FAILURE = 'POST_EVENT_ATTEMPT_FAILURE';

// update order/item status
export const UPDATE_GROUP_STATUS_SUCCESS = 'UPDATE_GROUP_STATUS_SUCCESS';
export const UPDATE_GROUP_STATUS_FAILURE = 'UPDATE_GROUP_STATUS_FAILURE';
export const UPDATE_ITEM_STATUS_SUCCESS = 'UPDATE_ITEM_STATUS_SUCCESS';
export const UPDATE_ITEM_STATUS_FAILURE = 'UPDATE_ITEM_STATUS_FAILURE';

// acknowledge (clear) a watchdog discrepancy
export const ACKNOWLEDGE_DISCREPANCY_REQUEST = 'ACKNOWLEDGE_DISCREPANCY_REQUEST';
export const ACKNOWLEDGE_DISCREPANCY_SUCCESS = 'ACKNOWLEDGE_DISCREPANCY_SUCCESS';
export const ACKNOWLEDGE_DISCREPANCY_FAILURE = 'ACKNOWLEDGE_DISCREPANCY_FAILURE';

// Settings stuff
export const SET_DISPLAY_TIMEZONE = 'SET_DISPLAY_TIMEZONE';
export const LOAD_GLOBAL_SETTINGS = 'LOAD_GLOBAL_SETTINGS';
export const LOAD_APP_SETTINGS = 'LOAD_APP_SETTINGS';
export const SAVE_APP_SETTINGS = 'SAVE_APP_SETTINGS';

// Error snackbar
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const LOGOUT_SESSION_REQUEST = 'LOGOUT_SESSION_REQUEST';
export const LOGOUT_SESSION_SUCCESS = 'LOGOUT_SESSION_SUCCESS';
export const LOGOUT_SESSION_FAILURE = 'LOGOUT_SESSION_FAILURE';

// Get available order actions
export const GET_ORDER_ACTIONS_REQUEST = 'GET_ORDER_ACTIONS_REQUEST';
export const GET_ORDER_ACTIONS_SUCCESS = 'GET_ORDER_ACTIONS_SUCCESS';
export const GET_ORDER_ACTIONS_FAILURE = 'GET_ORDER_ACTIONS_FAILURE';

// Change order delivery
export const CHANGE_DELIVERY_REQUEST = 'CHANGE_DELIVERY_REQUEST';
export const CHANGE_DELIVERY_SUCCESS = 'CHANGE_DELIVERY_SUCCESS';
export const CHANGE_DELIVERY_FAILURE = 'CHANGE_DELIVERY_FAILURE';

export const GET_SALES_CHANNELS_REQUEST = 'GET_SALES_CHANNELS_REQUEST';
export const GET_SALES_CHANNELS_SUCCESS = 'GET_SALES_CHANNELS_SUCCESS';
export const GET_SALES_CHANNELS_FAILURE = 'GET_SALES_CHANNELS_FAILURE';

export const GET_ALL_PERMISSIONS_REQUEST = 'GET_ALL_PERMISSIONS_REQUEST';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAILURE = 'GET_ALL_PERMISSIONS_FAILURE';

export const GET_ALL_GROUPS_REQUEST = 'GET_ALL_GROUPS_REQUEST';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const GET_ALL_GROUPS_FAILURE = 'GET_ALL_GROUPS_FAILURE';

// search searchColumns
export const SET_SEARCH_COLUMNS = 'SET_SEARCH_COLUMNS';
export const RESET_SEARCH_COLUMNS = 'RESET_SEARCH_COLUMNS';

// new order details page
export const LOAD_ORDER_ITEMS_REQUEST = 'LOAD_ORDER_ITEMS_REQUEST';
export const LOAD_ORDER_ITEMS_SUCCESS = 'LOAD_ORDER_ITEMS_SUCCESS';
export const LOAD_ORDER_ITEMS_FAILURE = 'LOAD_ORDER_ITEMS_FAILURE';

export const LOAD_MERCHANT_ORDER_REQUEST = 'LOAD_MERCHANT_ORDER_REQUEST';
export const LOAD_MERCHANT_ORDER_SUCCESS = 'LOAD_MERCHANT_ORDER_SUCCESS';
export const LOAD_MERCHANT_ORDER_FAILURE = 'LOAD_MERCHANT_ORDER_FAILURE';

export const LOAD_EVENTS_REQUEST = 'LOAD_EVENTS_REQUEST';
export const LOAD_EVENTS_SUCCESS = 'LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_FAILURE = 'LOAD_EVENTS_FAILURE';

export const LOAD_CLAIMS_SUCCESS = 'LOAD_CLAIMS_SUCCESS';
export const LOAD_COMPLAINTS_SUCCESS = 'LOAD_COMPLAINTS_SUCCESS';
export const LOAD_ORIGINAL_ORDERS_SUCCESS = 'LOAD_ORIGINAL_ORDERS_SUCCESS';

export const LOAD_ORDER_SHIPPING_PRICING_REQUEST = 'LOAD_ORDER_SHIPPING_PRICING_REQUEST';
export const LOAD_ORDER_SHIPPING_PRICING_SUCCESS = 'LOAD_ORDER_SHIPPING_PRICING_SUCCESS';
export const LOAD_ORDER_SHIPPING_PRICING_FAILURE = 'LOAD_ORDER_SHIPPING_PRICING_FAILURE';

export const LOAD_ORDER_PRODUCT_PRICING_REQUEST = 'LOAD_ORDER_PRODUCT_PRICING_REQUEST';
export const LOAD_ORDER_PRODUCT_PRICING_SUCCESS = 'LOAD_ORDER_PRODUCT_PRICING_SUCCESS';
export const LOAD_ORDER_PRODUCT_PRICING_FAILURE = 'LOAD_ORDER_PRODUCT_PRICING_FAILURE';

export const LOAD_ACTIONS_REQUEST = 'LOAD_ACTIONS_REQUEST';
export const LOAD_ACTIONS_SUCCESS = 'LOAD_ACTIONS_SUCCESS';
export const LOAD_ACTIONS_FAILURE = 'LOAD_ACTIONS_FAILURE';

export const LOAD_FULFILLER_REQUEST = 'LOAD_FULFILLER_REQUEST';
export const LOAD_FULFILLER_SUCCESS = 'LOAD_FULFILLER_SUCCESS';
export const LOAD_FULFILLER_FAILURE = 'LOAD_FULFILLER_FAILURE';

export const LOAD_FULFILLER_DETAILS_REQUEST = 'LOAD_FULFILLER_DETAILS_REQUEST';
export const LOAD_FULFILLER_DETAILS_SUCCESS = 'LOAD_FULFILLER_DETAILS_SUCCESS';
export const LOAD_FULFILLER_DETAILS_FAILURE = 'LOAD_FULFILLER_DETAILS_FAILURE';

export const LOAD_FULFILLER_NO_PERMISSION = 'LOAD_FULFILLER_NO_PERMISSION';

export const LOAD_ITEM_ALERT_COMMENTS_SUCCESS = 'LOAD_ITEM_ALERT_COMMENTS_SUCCESS';
export const LOAD_ITEM_ALERT_COMMENTS_FAILURE = 'LOAD_ITEM_ALERT_COMMENTS_FAILURE';

export const RESET_ORDER = 'RESET_ORDER';

// Order Product Manufacturing Data

export const LOAD_ITEM_PRODUCT_MANUFACTURING_REQUEST = 'LOAD_ITEM_PRODUCT_MANUFACTURING_REQUEST';
export const LOAD_ITEM_PRODUCT_MANUFACTURING_SUCCESS = 'LOAD_ITEM_PRODUCT_MANUFACTURING_SUCCESS';
export const LOAD_ITEM_PRODUCT_MANUFACTURING_FAILURE = 'LOAD_ITEM_PRODUCT_MANUFACTURING_FAILURE';

export const LOAD_PRINT_JOB_SUCCESS = 'LOAD_PRINT_JOB_SUCCESS';
export const LOAD_PRINT_JOB_FAILURE = 'LOAD_PRINT_JOB_FAILURE';

export const SET_SAVED_SEARCHES = 'SET_SAVED_SEARCHES';
export const SET_SELECTED_SAVED_SEARCH = 'SET_SELECTED_SAVED_SEARCH';

export const ADD_SUBSCRIPTION_RESOURCE_SUBSCRIPTIONS = 'ADD_SUBSCRIPTION_RESOURCE_SUBSCRIPTIONS';
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';

export const BULK_REORDER_STARTED = 'BULK_REORDER_STARTED';
export const BULK_REORDER_COMPLETED = 'BULK_REORDER_COMPLETED';
export const CLEAR_BULK_REORDER = 'CLEAR_BULK_REORDER';
export const BULK_REORDER_SEARCH_REQUEST = 'BULK_REORDER_SEARCH_REQUEST';
export const BULK_REORDER_SEARCH_SUCCESS = 'BULK_REORDER_SEARCH_SUCCESS';
export const BULK_REORDER_SEARCH_FAILURE = 'BULK_REORDER_SEARCH_FAILURE';

// Carrier Services Fetch Actions
export const GET_CARRIER_SERVICES_REQUEST = 'GET_CARRIER_SERVICES_REQUEST';
export const GET_CARRIER_SERVICES_SUCCESS = 'GET_CARRIER_SERVICES_SUCCESS';
export const GET_CARRIER_SERVICES_FAILURE = 'GET_CARRIER_SERVICES_FAILURE';

// FOMA
export const FOMA_ORDERS_FETCH_REQUEST = 'FOMA_ORDERS_FETCH_REQUEST';
export const FOMA_ORDERS_FETCH_SUCCESS = 'FOMA_ORDERS_FETCH_SUCCESS';
export const FOMA_ORDERS_FETCH_FAILURE = 'FOMA_ORDERS_FETCH_FAILURE';
