import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from '@cimpress/react-components/lib/Modal';
import { connect } from 'react-redux';
import { sendRejection } from '../../../actions/devtoolsactions';
import ActionLink from '../../shared/ActionLink';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

export class RejectItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      reason: '',
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    const { orderId, item } = this.props;

    this.setState({ showModal: true });

    trackEvent(TrackingEventTitles.REJECT_ITEM_MODAL, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.REJECT_ITEM.ORDER_ID]: orderId,
      [TrackingEventPropertiesNames.ORDER_DETAILS.REJECT_ITEM.ITEM_ID]: item.itemId,
    });
  };

  onInputChange = e => {
    this.setState({ reason: e.target.value });
  };

  onSubmit = () => {
    const { orderId, item } = this.props;

    const itemId = item.shortItemId;
    const { reason } = this.state;

    const rejection = {
      notificationType: 'ItemsRejected',
      items: [{ itemId }],
      rejectionDetails: { reason },
    };

    this.setState({ loading: true });
    this.props.sendRejection(rejection).then(() => {
      trackEvent(TrackingEventTitles.REJECTED_ITEM, {
        [TrackingEventPropertiesNames.ORDER_DETAILS.REJECT_ITEM.ORDER_ID]: orderId,
        [TrackingEventPropertiesNames.ORDER_DETAILS.REJECT_ITEM.ITEM_ID]: itemId,
      });

      this.setState({ reason: '' });
      this.close();
    });
  };

  render() {
    const {
      intl,
      item: {
        shortItemId: itemId,
        merchantId,
        merchantProductName,
        fulfillerOrderId,
        shortFulfillmentGroupId,
        fulfillerId,
        fulfillerItemId,
      } = {},
    } = this.props;

    return (
      <div>
        <ActionLink
          text={<FormattedMessage id="DeveloperTools.RejectItem" />}
          action={this.open}
          disabled={!(fulfillerId && (fulfillerItemId || itemId))}
          disabledMessage={<FormattedMessage id="DeveloperTools.RejectItemUnavailable" />}
        />
        <Modal
          size="lg"
          show={this.state.showModal}
          onRequestHide={this.close}
          closeOnOutsideClick
          closeButton
          title={<FormattedMessage id="DeveloperTools.RejectItem" />}
          footer={
            <div>
              <button className="btn btn-primary" onClick={this.onSubmit}>
                <FormattedMessage id="DeveloperTools.YesReject" />
              </button>
              <button className="btn btn-default" onClick={this.close}>
                <FormattedMessage id="DeveloperTools.DoNotReject" />
              </button>
            </div>
          }>
          <div>
            <p>
              <FormattedMessage
                id="DeveloperTools.FullfillerRejectInstructions"
                values={{ merchantName: merchantId, productName: merchantProductName }}
              />
            </p>
            <form>
              <div style={{ marginBottom: '10px' }}>
                <b>FulfillerOrderId:</b> {fulfillerOrderId || shortFulfillmentGroupId}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <b>FulfillerOrderItemId:</b> {itemId}
              </div>
              <div className="form-group form-group-active">
                <label className="control-label" htmlFor="textarea">
                  <FormattedMessage id="DeveloperTools.RejectReason" />
                </label>
                <textarea
                  onChange={this.onInputChange}
                  value={this.state.reason}
                  name="reason"
                  className="form-control"
                  rows="10"
                  placeholder={intl.formatMessage({ id: 'DeveloperTools.RejectItemHelpText' })}
                />
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

RejectItemModal.propTypes = {
  onClose: PropTypes.func,
  showModal: PropTypes.bool,
  item: PropTypes.object,
  orderId: PropTypes.string,
  sendRejection: PropTypes.func,
  intl: PropTypes.object,
};

export default connect(null, { sendRejection })(injectIntl(RejectItemModal));
