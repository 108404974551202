import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { initStatuses } from '../../constants/statuses';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';

const OrderStatus = ({ computedStatus, intl }) => {
  const statusTypes = initStatuses(intl);
  const { type, hasMissedSlas, hasErrors, hasPendingCancellation } = computedStatus;
  const displayName = statusTypes[type]
    ? statusTypes[type].getDisplayName
      ? statusTypes[type].getDisplayName(computedStatus)
      : statusTypes[type].displayName
    : intl.formatMessage({ id: 'Global.Unknown' });
  const errorIcon = hasErrors ? <i className="fa fa-exclamation-triangle text-danger" /> : null;
  const warningIcon = hasMissedSlas ? <i className="fa fa-exclamation-triangle text-warning" /> : null;
  const infoIcon = hasPendingCancellation ? <i className="fa fa-info-circle text-info" /> : null;
  return (
    <div>
      <span>
        {errorIcon} {warningIcon} {infoIcon} {displayName}
      </span>
      <Tooltip contents={intl.formatMessage({ id: 'OrderLevelFieldTooltips.Status' })}>
        <IconInformationCircle style={{ marginLeft: '5px' }} className="text-info" weight="fill" />
      </Tooltip>
    </div>
  );
};

OrderStatus.propTypes = {
  computedStatus: PropTypes.shape({
    type: PropTypes.string.isRequired,
    hasMissedSlas: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
  }).isRequired,
  intl: PropTypes.object,
};

export default injectIntl(OrderStatus);
