import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from '@cimpress/react-components';
import get from 'lodash/get';

import ActionLink from '../shared/ActionLink';
import { getMerchantById } from '../../services/merchants';

const InfoRow = ({ title, info }) => (
  <div>
    {title}: {info}
  </div>
);

const MerchantPopover = ({ merchantId, merchantName }) => {
  const [show, setShow] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();

  const toggleShow = () => setShow(prevShow => !prevShow);

  useEffect(() => {
    const fetchMerchant = async () => {
      try {
        setIsLoading(true);
        const response = await getMerchantById(merchantId);
        setMerchant(response);
        setIsLoading(false);
      } catch (error) {
        // don't try to show the merchant if we can't fetch it
      }
    };
    fetchMerchant();
  }, [merchantId]);

  if (isLoading) {
    return <div>{merchantName}</div>;
  }

  return (
    <Tooltip
      tooltipStyle={{ maxWidth: '550px' }}
      direction="bottom"
      variant="popover"
      show={show}
      onClickOutside={show ? toggleShow : null}
      contents={
        <div>
          <h5>{merchant.displayName}</h5>
          <div>
            <h6>
              <FormattedMessage id="Merchant.General" />
            </h6>
            <InfoRow
              title={intl.formatMessage({ id: 'Merchant.OrderSupportPhone' })}
              info={get(merchant, 'defaultOrderSupportContact.phone')}
            />
            <InfoRow
              title={intl.formatMessage({ id: 'Merchant.OrderSupportEmail' })}
              info={get(merchant, 'defaultOrderSupportContact.email')}
            />
            <InfoRow
              title={intl.formatMessage({ id: 'Merchant.TechnicalSupportPhone' })}
              info={get(merchant, 'defaultTechSupportContact.phone')}
            />
            <InfoRow
              title={intl.formatMessage({ id: 'Merchant.TechnicalSupportEmail' })}
              info={get(merchant, 'defaultTechSupportContact.email')}
            />
          </div>
        </div>
      }>
      <ActionLink text={merchant.displayName} action={toggleShow} style={{ padding: '0' }} />
    </Tooltip>
  );
};

export default MerchantPopover;
