import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsModal from '@cimpress-technology/react-platform-settings';
import { saveAppSettings, loadGlobalSettings } from '../../actions/userPreferencesActions';
import auth from '../../utils/auth';
import DisplayPreferences from './displayPreferences';
import { LANGUAGES } from '../../constants/languages';

export class Settings extends Component {
  static propTypes = {
    idFormat: PropTypes.string,
    userLanguage: PropTypes.string,
    beta: PropTypes.bool,
    saveAppSettings: PropTypes.func.isRequired,
    loadGlobalSettings: PropTypes.func.isRequired,
    intl: PropTypes.object,
  };

  getInitialState = () => {
    const { idFormat } = this.props;
    return { idFormat };
  };

  state = { ...this.getInitialState() };

  onUpdateIdFormat = idFormat => {
    this.setState({ idFormat: idFormat });
  };

  canSave = () => {
    return Boolean(this.state.idFormat);
  };

  onOpen = () => {
    this.setState({ ...this.getInitialState() });
  };

  onSave = () => {
    const { beta } = this.props;
    const { idFormat } = this.state;
    this.props.saveAppSettings({ idFormat, beta });
    this.props.loadGlobalSettings();
  };

  render() {
    const { userLanguage, intl } = this.props;
    const { idFormat } = this.state;
    return (
      <SettingsModal
        lang={userLanguage}
        authToken={auth.getAccessToken()}
        appSettingsTitle={intl.formatMessage({ id: 'Preferences.OrderOperationsSettings' })}
        appSettingsContent={<DisplayPreferences idFormat={idFormat} onUpdateIdFormat={this.onUpdateIdFormat} />}
        canSave={this.canSave()}
        onOpen={this.onOpen}
        onSave={this.onSave}
        supportedLanguages={LANGUAGES}
      />
    );
  }
}

const mapStateToProps = ({ userPreferences: { userLanguage, idFormat, beta } }) => ({
  userLanguage,
  idFormat,
  beta,
});

export default connect(mapStateToProps, { saveAppSettings, loadGlobalSettings })(injectIntl(Settings));
