export function initSearchSortOptions(intl) {
  return [
    {
      value: 'createdDate', // used by dropdown
      label: intl.formatMessage({ id: 'SortBy.ByCreatedDate' }), // used by dropdown
      description: intl.formatMessage({ id: 'SortBy.CreatedDate' }), // used for search results display
      sortField: 'createdDate',
      sortOrder: 'desc',
    },
    {
      value: 'promiseDate',
      label: intl.formatMessage({ id: 'SortBy.ByPromiseDate' }),
      description: intl.formatMessage({ id: 'SortBy.PromiseDate' }),
      sortField: 'promiseDate',
      sortOrder: 'asc',
    },
    {
      value: 'relevance',
      label: intl.formatMessage({ id: 'SortBy.ByRelevance' }),
      description: intl.formatMessage({ id: 'SortBy.Relevance' }),
      sortField: 'relevance',
      sortOrder: 'desc',
    },
  ];
}
