import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, IntlProvider } from 'react-intl';
import moment from 'moment';
import { createBrowserHistory } from 'history';

import { loadAppSettings, loadGlobalSettings } from './actions/userPreferencesActions';
import { loadPermissions } from './actions/permissionsActions';
import { loadGroups } from './actions/groupsActions';
import { loadAvailableFilters } from './actions/availableFiltersActions';
import { loadMerchants } from './actions/merchantactions';
import { loadFulfillers } from './actions/fulfilleractions';
import { getSearchColumnSettings } from './actions/searchColumnActions';
import { loadCarrierServices } from './actions/carrierServicesActions';

import AppShell from './appShell.jsx';
import OrderSearch from './components/ordersearch.jsx';
import OrderDetails from './components/orderDetailsV2';
import FourOhFour from './components/fourOhFour.jsx';
import ContactPage from './components/contactPage.jsx';
import Loading from './components/shared/Loading';
import LoudspeakerWrapper from './components/shared/LoudspeakerWrapper';
import Snackbar from '@cimpress/react-components/lib/Snackbar';
import isNil from 'lodash/isNil';

import translations from './i18n';

import auth from './utils/auth.js';
import { DEFAULT_LANGUAGE } from './constants/languages';

auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));
const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
const history = createBrowserHistory({ basename });

class AppRouter extends Component {
  state = { authenticating: false, authenticationError: null };

  async componentDidMount() {
    // make sure the user is logged in
    this.setState({ authenticating: true });
    try {
      await auth.ensureAuthentication({ nextUri: window.location.pathname + window.location.search });
      this.setState({ authenticating: false });
      this.props.loadGlobalSettings();
      this.props.loadAppSettings();
      this.props.loadPermissions();
      this.props.loadGroups();
      this.props.loadAvailableFilters();
      this.props.loadMerchants();
      this.props.loadFulfillers();
      this.props.getSearchColumnSettings();
      this.props.loadCarrierServices();
    } catch (error) {
      console.error(error);
      this.setState({ authenticationError: error });
    }
  }

  componentDidUpdate(prevProps) {
    const { userLanguage } = this.props;

    if (prevProps.userLanguage !== userLanguage) {
      moment.locale(userLanguage);
    }
  }

  reload = () => window.location.reload();

  render() {
    const { areSettingsLoaded, userLanguage } = this.props;
    const { authenticating, authenticationError } = this.state;
    const messages = translations[userLanguage];

    if (authenticationError) {
      return (
        <Snackbar show={!isNil(authenticationError)} bsSytle="danger">
          <div>
            <FormattedMessage id="Login.AuthenticationFailed" />: "
            {(authenticationError && authenticationError.message) || 'unkown error'}"
          </div>
          <div>
            <button style={{ marginTop: '15px' }} className="btn btn-primary" onClick={this.reload}>
              <FormattedMessage id="Login.RefreshPage" />
            </button>
          </div>
        </Snackbar>
      );
    }

    if (authenticating || !areSettingsLoaded) {
      return <Loading />;
    }

    return (
      <Fragment>
        <IntlProvider locale={userLanguage} key={userLanguage} messages={messages} defaultLocale="en">
          <LoudspeakerWrapper accessToken={auth.getAccessToken()} language={userLanguage} />
          <Router basename={basename} history={history}>
            <AppShell>
              <Switch>
                <Route exact path="/(index.html)?" component={OrderSearch} />
                <Route path="/orders/:orderId" component={OrderDetails} />
                <Route path="/support" component={ContactPage} />
                <Route
                  path="/reports"
                  component={() => {
                    window.location.replace(`${process.env.REACT_APP_REPORTER_URL}`);
                    return null;
                  }}
                />
                <Route component={FourOhFour} />
              </Switch>
              <Switch>
                <Route
                  path="/reports/create"
                  component={() => {
                    window.location.replace(`${process.env.REACT_APP_REPORTER_URL}/createreport`);
                    return null;
                  }}
                />
              </Switch>
            </AppShell>
          </Router>
        </IntlProvider>
      </Fragment>
    );
  }
}

AppRouter.propTypes = {
  path: PropTypes.string,
  areSettingsLoaded: PropTypes.bool,
  userLanguage: PropTypes.string,
  loadGlobalSettings: PropTypes.func.isRequired,
  loadAppSettings: PropTypes.func.isRequired,
  loadPermissions: PropTypes.func.isRequired,
  loadGroups: PropTypes.func.isRequired,
  loadAvailableFilters: PropTypes.func.isRequired,
  loadMerchants: PropTypes.func.isRequired,
  loadFulfillers: PropTypes.func.isRequired,
  getSearchColumnSettings: PropTypes.func.isRequired,
  loadCarrierServices: PropTypes.func.isRequired,
};

AppRouter.defaultProps = {
  userLanguage: DEFAULT_LANGUAGE,
};

function mapStateToProps(state) {
  const {
    userPreferences: { userLanguage, areSettingsLoaded },
  } = state;

  return {
    userLanguage,
    areSettingsLoaded,
  };
}

export default connect(mapStateToProps, {
  loadGlobalSettings,
  loadAppSettings,
  loadPermissions,
  loadGroups,
  loadAvailableFilters,
  loadMerchants,
  loadFulfillers,
  getSearchColumnSettings,
  loadCarrierServices,
})(AppRouter);
