import React, { PureComponent, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

export default class FulfillerDeniedPopover extends PureComponent {
  state = {
    show: false,
  };

  toggleShow = () => this.setState({ show: !this.state.show });
  render() {
    const { fulfillerId } = this.props;
    const contact = 'platformsupport@cimpress.com';

    return (
      <Tooltip
        tooltipStyle={{ maxWidth: '550px' }}
        direction="bottom"
        variant="popover"
        show={this.state.show}
        onClickOutside={this.state.show ? this.toggleShow : null}
        contents={
          <div style={{ marginTop: '9px' }}>
            <h5>
              {
                <Fragment>
                  <FormattedMessage id="Fulfiller.Fulfiller" /> <FormattedMessage id="Fulfiller.ID" />
                </Fragment>
              }{' '}
              : {fulfillerId}
            </h5>
            <div>
              <p>
                <FormattedMessage id="Fulfiller.AccessDeniedMessage" />
                <a href={'mailto:' + contact}>{contact}</a>
              </p>
            </div>
          </div>
        }>
        <a className="clickable" onClick={this.toggleShow}>
          {fulfillerId}
        </a>
      </Tooltip>
    );
  }
}

FulfillerDeniedPopover.propTypes = {
  fulfillerId: PropTypes.string,
};
