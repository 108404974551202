import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { HISTORICAL_SHIPMENT_UPDATE } from '../../../constants/carriers';

const TrackingLink = ({ trackingId: unformattedTrackingId, trackingUrl }) => {
  const intl = useIntl();
  const trackingId = unformattedTrackingId || intl.formatMessage({ id: 'Events.UnknownTrackingId' });
  const title = <FormattedMessage id="Events.TrackingId" />;

  let value;
  if (trackingId === HISTORICAL_SHIPMENT_UPDATE) {
    value = (
      <span>
        <FormattedMessage id="Global.NotApplicable" />
      </span>
    );
  } else if (trackingUrl && trackingUrl.substring(0, 4) === 'http') {
    value = (
      <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
        {trackingId} <i className="fa fa-sm fa-external-link" />
      </a>
    );
  } else {
    value = (
      <span>
        {trackingId}
        {trackingUrl ? ` - ${trackingUrl}` : ''}
      </span>
    );
  }

  return (
    <div>
      {title}: {value}
    </div>
  );
};

TrackingLink.propTypes = {
  trackingId: PropTypes.string,
  trackingUrl: PropTypes.string,
};

const FulfillmentEventDetails = ({ additionalData: { trackingId, trackingUrl, quantity } }) => {
  return (
    <span>
      <FormattedMessage id="Events.ShippingDetails" values={{ quantity }} />
      <TrackingLink trackingId={trackingId} trackingUrl={trackingUrl} />
    </span>
  );
};

FulfillmentEventDetails.propTypes = {
  additionalData: PropTypes.object.isRequired,
};

export default FulfillmentEventDetails;
