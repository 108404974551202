import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Pagination from 'react-paginate';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Alert from '@cimpress/react-components/lib/Alert';
import Snackbar from '@cimpress/react-components/lib/Snackbar';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

import SearchResultsTable from './searchresultstable.jsx';
import DownloadButton from './downloadbutton.jsx';
import ClearButton from './clearbutton.jsx';
import FilterDrawerContainer from './filterdrawercontainer';
import SearchColumnDropdown from './searchColumnDropdown';
import { downloadSearchResults, MAX_DOWNLOAD_RESULTS } from '../../utils/csvdownload';
import { PAGE_SIZE } from '../../constants/pagination.js';
import { initSearchSortOptions } from '../../constants/searchSortOptions.js';
import { setSort } from '../../actions/ordersearchactions.js';
import { initialState } from '../../reducers/search';
import GettingStarted from './GettingStarted';
import usePrevious from '../../hooks/usePrevious';

const idFieldNames = [
  'fulfillmentGroupId',
  'itemId',
  'merchantItemId',
  'merchantOrderId',
  'orderId',
  'shortFulfillmentGroupId',
  'shortItemId',
];

const ResultsSection = props => {
  const intl = useIntl();
  const history = useHistory();
  const searchSortOptions = initSearchSortOptions(intl);
  const [downloading, setDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState('');
  const previouslyLoading = usePrevious(props.loading);
  let resultsSection, searchMessage, totalRows, firstRow, lastRow;
  let { loading, error, results, noSearchPerformed, query, searchState, onFiltersUpdated } = props;

  const changePage = ({ selected }) => {
    // react-paginate gives you a callback of ({ selected: index })
    props.handlePageChange(selected + 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!loading && previouslyLoading && results.totalResults === 1) {
      const { searchTerm } = query;
      const [singleOrder] = results.orders;

      const orderIdentifiers = singleOrder.items.reduce((agg, item) => {
        idFieldNames.forEach(idFieldName => {
          const id = item[idFieldName];
          if (!agg.includes(id)) {
            agg.push(id);
          }
        });
        return agg;
      }, []);

      const singleResultMatchesSearchTerm = orderIdentifiers.some(identifier => searchTerm.includes(identifier));

      // if it's likely that the user is searching for a single order, save them the extra click
      if (singleResultMatchesSearchTerm) {
        history.push(`/orders/${singleOrder.orderId}`);
      }
    }
  }, [history, loading, previouslyLoading, query, results.orders, results.totalResults]);

  const downloadResults = async () => {
    try {
      setDownloading(true);
      await downloadSearchResults(props.queryString, props.results.totalResults, props.permissions, intl);
      setDownloading(false);
    } catch (e) {
      setDownloading(false);
      if (e.message === '401') {
        // redirect to same search page to trigger logout and get a redirect back to the same page
        const nextRoute = window.location.search;
        history.push(nextRoute);
      } else {
        setDownloadError(e.message);
      }
    }
  };

  if (loading) {
    // if the page is loading, return right now with a spinner
    return (
      <div>
        <div className="clearfix" />
        <div className="card" style={{ margin: '20px 0' }}>
          <Spinner className="spinner" />
        </div>
      </div>
    );
  }

  // Messaging displayed in cases where there are no search results
  if (error) {
    // Search error
    searchMessage = (
      <div className="card-block text-center" style={{ marginBottom: '55px' }}>
        <div className="h5 text-danger">
          <i className="fa fa-exclamation-triangle" /> {error.payload}
        </div>
        <div className="h6">
          <FormattedMessage id="Global.ErrorSource" values={{ source: error.source }} />
        </div>
        <p>&nbsp;</p>
        <h3>
          <FormattedMessage id="ResultsSection.Error" />
        </h3>
      </div>
    );
  } else if (noSearchPerformed) {
    // No search performed message
    searchMessage = (
      <div className="card-block text-center" style={{ marginBottom: '55px' }}>
        <h2>
          <FormattedMessage id="ResultsSection.PerformSearch" />
        </h2>
        <h4 style={{ marginBottom: '50px' }}>
          <FormattedMessage id="Global.Or" />
        </h4>
        <GettingStarted onFiltersUpdated={onFiltersUpdated} />
      </div>
    );
  } else if (results && !results.totalResults) {
    // No orders found
    searchMessage = (
      <div className="card-block text-center" style={{ marginBottom: '55px' }}>
        <h4 style={{ width: '50%', margin: '1em 25% 0 25%' }}>
          <i className="fa fa-info-circle text-info" style={{ marginRight: '10px' }} aria-hidden="true" />
          {query && query.searchTerm ? (
            <FormattedMessage
              id="ResultsSection.NoOrdersQuery"
              values={{
                searchQuery: query.searchTerm,
                a: chunks => <a href="https://cimpress.slack.com/messages/platform-support">{chunks}</a>,
              }}
            />
          ) : (
            <FormattedMessage id="ResultsSection.NoOrders" />
          )}
        </h4>
        {props.includeDateHint ? (
          <p className="text-info">
            <i className="fa fa-lg fa-info-circle" /> <FormattedMessage id="ResultsSection.DateHint" />
          </p>
        ) : null}
      </div>
    );
  }

  if (results && results.totalResults) {
    totalRows = results.totalResults;
    firstRow = (props.page - 1) * PAGE_SIZE + 1;
    lastRow = firstRow + PAGE_SIZE - 1;
    if (lastRow > totalRows) {
      lastRow = totalRows;
    }

    // don't need to show pagination if <1 page of results
    let pagination =
      totalRows <= PAGE_SIZE ? null : (
        <div style={{ textAlign: 'center' }}>
          <Pagination
            previousLabel={<i className="fa fa-angle-left" />}
            nextLabel={<i className="fa fa-angle-right" />}
            breakLabel={<a>...</a>}
            breakClassName={'disabled'}
            pageCount={Math.min(Math.ceil(totalRows / PAGE_SIZE), 100)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            forcePage={props.page - 1}
            onPageChange={changePage}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
      );

    resultsSection = (
      <div>
        <SearchResultsTable
          results={props.results.orders}
          lastOrder={props.lastOrder}
          goToOrderDetails={props.goToOrderDetails}
          setLastOrder={props.setLastOrder}
          totalResults={props.results.totalResults}
          queryString={props.queryString}
        />
        {pagination}
      </div>
    );
  }

  let tooManyResults =
    results && results.totalResults > 10000 ? (
      <div style={{ margin: '10px 0' }}>
        <Alert
          status="info"
          message={
            <Fragment>
              <b>
                <FormattedMessage id="ResultsSection.ToManyResults" />
              </b>{' '}
              <FormattedMessage
                id="ResultsSection.DisplayFirstXOrders"
                values={{ orderNumber: intl.formatNumber(10000) }}
              />
            </Fragment>
          }
        />
      </div>
    ) : null;

  let downloadButton =
    results && results.totalResults ? (
      <span style={{ padding: '0 20px' }}>
        <DownloadButton
          download={downloadResults}
          downloading={downloading}
          disabled={results.totalResults > MAX_DOWNLOAD_RESULTS}
        />
      </span>
    ) : null;

  let sortOption = searchSortOptions.find(
    opt => opt.sortField === query.sortField && opt.sortOrder === query.sortOrder
  );
  let sortOrder = (sortOption && sortOption.description) || intl.formatMessage({ id: 'SortBy.Relevance' });

  const msg =
    query && (query.startDate || query.endDate) ? 'ResultsSection.ShowOrders' : 'ResultsSection.ShowOrdersDefaultDate';
  const resultsCounter =
    results && results.totalResults
      ? intl.formatMessage(
          { id: msg },
          {
            firstRow: intl.formatNumber(firstRow),
            lastRow: intl.formatNumber(lastRow),
            totalRows: intl.formatNumber(totalRows),
            sortOrder: sortOrder,
          }
        )
      : '';

  const searchResultsHeader = (
    <div className="clearfix" style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          {resultsCounter}
          {downloadButton}
          {!isEqual(searchState, initialState) ? <ClearButton /> : null}
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <SearchColumnDropdown />
          <FilterDrawerContainer onFiltersUpdated={props.onFiltersUpdated} />
        </div>
      </div>
      {tooManyResults}
    </div>
  );

  return (
    <div>
      {searchResultsHeader}
      {searchMessage ? (
        <div className="card" style={{ margin: '20px 0' }}>
          {searchMessage}
        </div>
      ) : null}
      {resultsSection ? <div style={{ margin: '10px 0' }}>{resultsSection}</div> : null}
      <Snackbar show={Boolean(downloadError)} delay={10000} status="danger" onHideSnackbar={() => {}}>
        <b className="text-danger">
          <FormattedMessage id="Global.Error" />: {downloadError}
        </b>
      </Snackbar>
    </div>
  );
};

ResultsSection.propTypes = {
  page: PropTypes.number,
  loading: PropTypes.bool,
  results: PropTypes.object,
  error: PropTypes.object,
  goToOrderDetails: PropTypes.func,
  handlePageChange: PropTypes.func,
  noSearchPerformed: PropTypes.bool,
  query: PropTypes.object,
  queryString: PropTypes.string,
  lastOrder: PropTypes.string,
  includeDateHint: PropTypes.bool,
  setLastOrder: PropTypes.func,
  onFiltersUpdated: PropTypes.func,
  statuses: PropTypes.arrayOf(PropTypes.object),
  changeRequestTypes: PropTypes.arrayOf(PropTypes.object),
  errorStatuses: PropTypes.arrayOf(PropTypes.object),
  delayedStatuses: PropTypes.arrayOf(PropTypes.object),
  setSort: PropTypes.func,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  searchState: PropTypes.object,
  permissions: PropTypes.object,
};

const mapStateToProps = state => {
  const {
    search: { sortField, sortOrder },
    permissions: { permissions },
  } = state;

  return {
    sortField,
    sortOrder,
    searchState: state.search,
    permissions,
  };
};

export default connect(mapStateToProps, { setSort })(ResultsSection);
