import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuidV4 from 'uuid/v4';
import { RIEInput } from 'riek';
import get from 'lodash/get';
import { Modal, Select, shapes } from '@cimpress/react-components';

import MiniItemDetails from '../MiniItemDetails';
import ActionLink from '../../shared/ActionLink';
import * as validators from '../../../utils/statusDetailsValidators';
import * as fieldRules from '../../../utils/statusDetailsRules';
import { statusTypeLabelMap } from '../../../constants/statusTypeLabels';
import { updateStatus } from '../../../actions/updateStatusActions';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const { Spinner } = shapes;

const initialState = {
  showModal: false,
  loading: false,
  guid: '',
  selectedItems: [], // all the items the user has currently selected
  fulfillmentGroups: [], // all possible fulfillment groups the selected items belong to
  statusTypeOption: undefined,
  validator: {}, // a non-customized event template, exists to validate types
};

const splitKey = 'splitstringhereplz';

export class UpdateStatusModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  close = () => {
    this.setState({ ...initialState });
  };

  open = () => {
    const { order } = this.props;

    this.setState({ showModal: true, guid: uuidV4() });

    trackEvent(TrackingEventTitles.UPDATE_STATUS_MODAL_OPENED, {
      [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: order.orderId,
    });
  };

  toggleItem = item => {
    const selectedItems = [...this.state.selectedItems];
    const idx = selectedItems.findIndex(itm => itm.itemId === item.itemId);
    if (idx > -1) {
      selectedItems.splice(idx, 1);
    } else {
      selectedItems.push(item);
    }

    const fulfillmentGroups = [];
    selectedItems.forEach(itm => {
      const fulfillmentGroupIdx = fulfillmentGroups.findIndex(
        fulfillmentGroup => fulfillmentGroup.fulfillmentGroupId === itm.fulfillmentGroupId
      );
      if (fulfillmentGroupIdx > -1) {
        fulfillmentGroups[fulfillmentGroupIdx].items.push(itm);
      } else {
        const additionalData = {};
        Object.keys(this.state.validator).forEach(data => {
          additionalData[data] = '';
        });
        fulfillmentGroups.push({
          fulfillmentGroupId: itm.fulfillmentGroupId,
          fulfillerId: itm.fulfillerId,
          fulfillerName: get(itm, 'fulfiller.name'),
          items: [itm],
          additionalData,
        });
      }
    });
    this.setState({ fulfillmentGroups, selectedItems });
  };

  onStatusTypeSelected = statusTypeOption => {
    const validator = validators[statusTypeOption && statusTypeOption.value];
    const fulfillmentGroups = this.state.fulfillmentGroups;
    fulfillmentGroups.forEach(fulfillmentGroup => {
      const additionalData = {};
      Object.keys(validator).forEach(data => {
        if (typeof validator[data] === 'boolean') {
          additionalData[data] = false;
        } else {
          additionalData[data] = '';
        }
      });
      fulfillmentGroup.additionalData = additionalData;
    });
    this.setState({ statusTypeOption, validator, fulfillmentGroups });
  };

  onQuantityChange = quantity => {
    const fulfillmentGroups = [...this.state.fulfillmentGroups];
    const selectedItems = [...this.state.selectedItems];
    const [key] = Object.keys(quantity);
    const [fulfillmentGroupId, itemId] = key.split(splitKey);

    const idx = selectedItems.findIndex(itm => itm.itemId === itemId);
    selectedItems[idx].quantity = quantity;

    const fulfillmentGroupIdx = fulfillmentGroups.findIndex(group => group.fulfillmentGroupId === fulfillmentGroupId);
    fulfillmentGroups[fulfillmentGroupIdx].items.forEach(itm => {
      if (itm.itemId === itemId) {
        itm.quantity = Number(quantity[key].length ? quantity[key] : ' ');
      }
    });
    this.setState({ selectedItems, fulfillmentGroups });
  };

  onInputChange = parameter => {
    const fulfillmentGroups = [...this.state.fulfillmentGroups];
    const [key] = Object.keys(parameter);
    const text = parameter[key].length ? parameter[key] : ' ';
    const [fulfillmentGroupId, field] = key.split(splitKey);

    // convert value to the appropriate type
    const expectedType = typeof this.state.validator[field];
    let value;
    if (expectedType === 'string') {
      value = text;
    } else if (expectedType === 'number') {
      value = Number(text);
    } else {
      try {
        value = JSON.parse(text);
      } catch (e) {
        value = text;
      }
    }

    fulfillmentGroups.forEach(fGroup => {
      if (fGroup.fulfillmentGroupId === fulfillmentGroupId) {
        fGroup.additionalData[field] = value;
      }
    });
    this.setState({ fulfillmentGroups });
  };

  onSubmit = () => {
    const { statusTypeOption } = this.state;
    const { order } = this.props;
    const statusValue = statusTypeOption && statusTypeOption.value;

    this.setState({ loading: true });
    this.props.updateStatus(statusValue, this.state.fulfillmentGroups, this.state.selectedItems).then(() => {
      trackEvent(TrackingEventTitles.UPDATE_STATUS_MODAL_SUBMITTED, {
        [TrackingEventPropertiesNames.ORDER_DETAILS.ORDER_ACTIONS.ORDER_ID]: order.orderId,
      });

      this.close();
    });
  };

  render() {
    const { order, accessibleItems, intl } = this.props;
    const { statusTypeOption, validator } = this.state;
    const statusValue = statusTypeOption && statusTypeOption.value;

    // always an array, even with length of Zero
    if (!(accessibleItems.length > 0)) {
      // If there are no items to update, this means the user does not have permissions to perform this action for any
      // item in the order.
      return null;
    }

    const itemsDisplay = accessibleItems.map(item => (
      <MiniItemDetails
        key={item.itemId}
        item={item}
        selectable={true}
        isSelected={Boolean(this.state.selectedItems.find(i => i.itemId === item.itemId))}
        toggle={this.toggleItem}
      />
    ));

    const statusForm = statusValue ? (
      <pre>
        {'{'}
        <br />
        {'  '}"statusType": "{statusValue}",
        <br />
        {'  '}"orderId": "{order.orderId}",
        <br />
        {'  '}"merchantOrderId": "{order.merchantOrderId}",
        <br />
        {'  '}"fulfillmentGroup": [<br />
        {this.state.fulfillmentGroups.map((d, i) => (
          <div key={i}>
            {'    {'}
            <br />
            {'      '}"fulfillmentGroupId": "{d.fulfillmentGroupId}",
            <br />
            {'      '}"fulfillerId": "{d.fulfillerId}",
            <br />
            {'      '}"fulfillerName": "{d.fulfillerName}",
            <br />
            {'      '}"items": [<br />
            {d.items.map((itm, j) => (
              <div key={j}>
                {'        {'}
                <br />
                {'            '}"itemId": "{itm.itemId}",
                <br />
                {'            '}"merchantItemId": "{itm.merchantItemId}",
                <br />
                {'            '}"quantity":{' '}
                <RIEInput
                  tabIndex={i + j}
                  value={itm.quantity}
                  validate={input => input.length > 0 && !isNaN(input) && Math.floor(input) === input && input >= 0}
                  className="clickable code-edit"
                  classInvalid="has-error"
                  propName={`${d.fulfillmentGroupId}${splitKey}${itm.itemId}`}
                  change={this.onQuantityChange}
                />
                <br />
                {'        }'}
                <br />
              </div>
            ))}
            {'      ]'}
            <br />
            {'      '}"additionalData": {'{'}
            <br />
            {Object.keys(validator).map((key, j) => {
              const currentValue = d.additionalData[key] ? d.additionalData[key] : validator[key];
              return (
                <div key={uuidV4()}>
                  {'        '}"{key}": {typeof validator[key] !== 'string' ? '' : '"'}
                  <RIEInput
                    tabIndex={i + j}
                    value={typeof validator[key] === 'boolean' ? JSON.stringify(currentValue) : currentValue}
                    validate={input => {
                      const expectedType = typeof validator[key];
                      if (expectedType === 'string') {
                        if (input.trim().length > 0) {
                          if (fieldRules[statusValue] && fieldRules[statusValue][key]) {
                            return fieldRules[statusValue][key](input);
                          }
                          return true;
                        }
                        return false;
                      } else if (expectedType === 'boolean') {
                        return input === 'true' || input === 'false' || input === true || input === false;
                      } else {
                        try {
                          return typeof JSON.parse(input) === expectedType;
                        } catch (e) {
                          return false;
                        }
                      }
                    }}
                    className="clickable code-edit"
                    classInvalid="has-error"
                    propName={`${d.fulfillmentGroupId}${splitKey}${key}`}
                    change={this.onInputChange}
                  />
                  {typeof validator[key] !== 'string' ? '' : '"'}
                  {j === Object.keys(validator).length - 1 ? '' : ','}
                </div>
              );
            })}
            {'      }'}
            <br />
            {/* ternary to omit the comma from the last item in the list */}
            {`    }${i === this.state.fulfillmentGroups.length - 1 ? '' : ','}`}
            <br />
          </div>
        ))}
        {'  ]'}
        <br />
        {'}'}
      </pre>
    ) : null;

    const instructions = statusValue ? (
      <div className="row">
        <div className="col-md-12">
          <h4>
            <FormattedMessage id="DeveloperTools.StatusInstructions" />
          </h4>
        </div>
      </div>
    ) : null;

    const statusTypeOptions = Object.keys(validators).map(e => ({
      label: intl.formatMessage({ id: statusTypeLabelMap[e] }),
      value: e,
    }));

    return (
      <div>
        <ActionLink
          text={<FormattedMessage id="DeveloperTools.UpdateStatus" />}
          action={this.open}
          helpText={<FormattedMessage id="DeveloperTools.UpdateStatusForFulfiller" />}
        />
        <Modal
          size="lg"
          show={this.state.showModal}
          onRequestHide={this.close}
          closeOnOutsideClick
          closeButton
          title={<FormattedMessage id="DeveloperTools.UpdateStatusForFulfiller" />}
          footer={
            <div>
              <button className="btn btn-default" onClick={this.close}>
                <FormattedMessage id="Global.Close" />
              </button>
              <button
                className="btn btn-primary"
                disabled={this.state.loading || !this.state.selectedItems.length || !statusValue}
                onClick={this.onSubmit}>
                <FormattedMessage id="Global.Submit" />
              </button>
            </div>
          }>
          {this.state.loading ? (
            <Spinner />
          ) : (
            <div>
              <h4>
                <FormattedMessage id="DeveloperTools.UpdateItemStatus" />
              </h4>
              <table className="table table-hover">
                <tbody>{itemsDisplay}</tbody>
              </table>
              <p>&nbsp;</p>
              <p>
                <FormattedMessage
                  id="DeveloperTools.NumberOfItemsSelected"
                  values={{ n: this.state.selectedItems.length }}
                />
              </p>
              <h4>
                <FormattedMessage id="DeveloperTools.UpdateStatus" />
              </h4>
              <Select
                label={intl.formatMessage({ id: 'DeveloperTools.StatusType' })}
                value={statusTypeOption}
                options={statusTypeOptions}
                onChange={this.onStatusTypeSelected}
              />
              <div style={{ minHeight: '250px' }}>
                {/** prevent modal from cutting off select and prevent bouncing **/}
                {instructions}
                {statusForm}
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

UpdateStatusModal.propTypes = {
  order: PropTypes.object,
  accessibleItems: PropTypes.array,
  updateStatus: PropTypes.func,
  intl: PropTypes.object,
};

export default connect(null, { updateStatus })(injectIntl(UpdateStatusModal));
