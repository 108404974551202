// Some enumerated data for managing languages in the application.
export class Language {
  constructor(name, tag, url, pathPrefix) {
    this.name = name;
    this.tag = tag;
    this.url = url;
    this.pathPrefix = pathPrefix;
  }
}

export const ENGLISH = new Language('English', 'en', '/ui', '');
export const FRENCH = new Language('Français', 'fr', '/ui/fr', '/fr');
export const GERMAN = new Language('Deutsch', 'de', '/ui/de', '/de');
export const DUTCH = new Language('Nederlands', 'nl', '/ui/nl', '/nl');
export const ITALIAN = new Language('Italiano', 'it', '/ui/it', '/it');

export const SUPPORTED_LANGUAGES = [ENGLISH, FRENCH, GERMAN, DUTCH, ITALIAN];

export const LANGUAGES = ['de', 'en', 'fr', 'it', 'nl'];
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE = 'en';
