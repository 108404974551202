import get from 'lodash/get';
import { SHIPPED, CANCELLED, REJECTED } from '../constants/statustypes';

export const shouldSkipItem = decoratedSearchResultItem => {
  const itemStatus = get(decoratedSearchResultItem, 'computedStatus.type');
  return itemStatus === CANCELLED || decoratedSearchResultItem.pendingCancellation || itemStatus === SHIPPED;
};

export const isItemInProgress = decoratedSearchResultItem => {
  const itemStatus = get(decoratedSearchResultItem, 'computedStatus.type');
  return !shouldSkipItem(decoratedSearchResultItem) && itemStatus !== REJECTED;
};
