import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { Tooltip } from '@cimpress/react-components';

import AttributesModal from './AttributesModal';

const Attributes = ({ attributes: { variableAttributes, productManufacturingData } }) => {
  const intl = useIntl();

  return (
    <Fragment>
      <span>
        {variableAttributes ? (
          <AttributesModal
            attributes={variableAttributes}
            title={intl.formatMessage({ id: 'ItemLevelFields.VariableAttributes' })}
            showAttributeClass>
            <FormattedMessage id="ItemLevelFields.Variable" />
          </AttributesModal>
        ) : null}
        {variableAttributes && productManufacturingData ? <div style={{ height: '10px' }} /> : null}
        {productManufacturingData ? (
          <div style={{ display: 'flex' }}>
            <AttributesModal
              attributes={productManufacturingData}
              title={intl.formatMessage({ id: 'ItemLevelFields.ManufacturingAttributes' })}
              showAttributeSource>
              <FormattedMessage id="ItemLevelFields.Manufacturing" />
            </AttributesModal>
            <div style={{ paddingRight: '5px' }} />
            <Tooltip contents={intl.formatMessage({ id: 'Helpers.ProductDocumentation' })}>
              <a href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/176161917/Add+Products+to+the+Product+Catalog">
                <IconInformationCircle />
              </a>
            </Tooltip>
          </div>
        ) : null}
      </span>
    </Fragment>
  );
};

Attributes.propTypes = {
  attributes: PropTypes.object,
};

export default Attributes;
