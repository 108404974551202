export class IdFormat {
  constructor(format, titleKey) {
    this.format = format;
    this.titleKey = titleKey;
  }
}

export const MERCHANT = new IdFormat('merchant', 'IdFormats.Merchant');
export const PLATFORM = new IdFormat('platform', 'IdFormats.Platform');
export const FULFILLER = new IdFormat('fulfiller', 'IdFormats.Fulfiller');
export const SHORT = new IdFormat('short', 'IdFormats.Short');

export const ID_FORMATS = [MERCHANT, PLATFORM, FULFILLER, SHORT];

export const DEFAULT_ID_FORMAT = PLATFORM.format;
