import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';
import { callFetch } from '../services/serviceHelpers';
const FOMA = process.env.REACT_APP_FULFILLMENT_ORDER_MANAGER_URL;

export function updateCallbackUrl(newUrl, callbackUrlObject) {
  var payload = Object.assign({}, callbackUrlObject, { url: newUrl });
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.POST_CALLBACK_URL_REQUEST, types.POST_CALLBACK_URL_SUCCESS, types.POST_CALLBACK_URL_FAILURE],
        endpoint: `${process.env.REACT_APP_OOPS_URL}/api/v1/callbacks`,
        method: 'PUT',
        body: JSON.stringify(payload),
      },
    });
  };
}

export function getCallbackUrl(orderId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.GET_CALLBACK_URL_REQUEST, types.GET_CALLBACK_URL_SUCCESS, types.GET_CALLBACK_URL_FAILURE],
        endpoint: `${process.env.REACT_APP_OOPS_URL}/api/v1/callbacks?orderId=${orderId}`,
      },
    });
  };
}

export function sendRejection(rejection) {
  return dispatch => {
    dispatch({ type: types.REJECT_ITEM_REQUEST });
    return callFetch(FOMA, 'POST', 'v1/notifications', JSON.stringify(rejection))
      .then(() => dispatch({ type: types.REJECT_ITEM_SUCCESS }))
      .catch(error => dispatch({ type: types.REJECT_ITEM_FAILURE, error }));
  };
}
