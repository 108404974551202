import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Select from '@cimpress/react-components/lib/Select';
import { ID_FORMATS } from '../../constants/idFormats';

class DisplayPreferences extends Component {
  static propTypes = {
    idFormat: PropTypes.string,
    onUpdateIdFormat: PropTypes.func.isRequired,
    intl: PropTypes.object,
  };

  onIdFormatOptionChange = (idFormatOption = {}) => {
    const { onUpdateIdFormat } = this.props;

    onUpdateIdFormat(idFormatOption.value);
  };

  render() {
    const { idFormat, intl } = this.props;
    const allIdFormats = ID_FORMATS.map(f => ({ value: f.format, label: intl.formatMessage({ id: f.titleKey }) }));
    const menuPortalTarget = document.getElementById('settings-modal');

    return (
      <Select
        name="idFormat"
        placeholder={<FormattedMessage id="Preferences.IdFormat" />}
        helpText={<FormattedMessage id="Preferences.IdFormatInfo" />}
        options={allIdFormats}
        value={allIdFormats.find(f => f.value === idFormat)}
        onChange={this.onIdFormatOptionChange}
        menuPortalTarget={menuPortalTarget}
      />
    );
  }
}

export default injectIntl(DisplayPreferences);
