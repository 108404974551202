import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Select from '@cimpress/react-components/lib/Select';
import { initSearchSortOptions } from '../../constants/searchSortOptions.js';

class SortDropdown extends React.Component {
  setSort = opt => {
    const sortField = opt ? opt.sortField : null;
    const sortOrder = opt ? opt.sortOrder : null;

    this.props.setSort(sortField, sortOrder);
  };

  render() {
    const { intl } = this.props;
    const searchSortOptions = initSearchSortOptions(intl);
    const selectedOption = searchSortOptions.find(
      opt => opt.sortField === this.props.sortField && opt.sortOrder === this.props.sortOrder
    );

    return (
      <Select
        style={this.props.style}
        placeholder={intl.formatMessage({ id: 'SortBy.SortBy' })}
        options={searchSortOptions}
        value={selectedOption}
        onChange={this.setSort}
      />
    );
  }
}

SortDropdown.propTypes = {
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  setSort: PropTypes.func,
  style: PropTypes.object,
  intl: PropTypes.object,
};

SortDropdown.defaultProps = {
  style: {},
};

export default injectIntl(SortDropdown);
