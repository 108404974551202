import {
  GET_CARRIER_SERVICES_REQUEST,
  GET_CARRIER_SERVICES_SUCCESS,
  GET_CARRIER_SERVICES_FAILURE,
} from '../constants/actiontypes';
import { getAllCarrierServices } from '../services/registryCarrierServices';

export const loadCarrierServices = () => async dispatch => {
  dispatch({ type: GET_CARRIER_SERVICES_REQUEST });

  try {
    const carrierServices = await getAllCarrierServices();
    dispatch({ type: GET_CARRIER_SERVICES_SUCCESS, response: carrierServices });
  } catch (error) {
    dispatch({ type: GET_CARRIER_SERVICES_FAILURE, error });
  }
};
