import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CurrencyDisplay from '../components/shared/currencyDisplay';
import DateDisplay from '../components/shared/dateDisplay';
import OrderStatus from '../components/status/OrderStatus';
import RelatedResourceLabels from '../components/shared/RelatedResourceLabels';
import TestingConfigurationLabel from '../components/shared/TestingConfigurationLabel';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import min from 'lodash/min';
import max from 'lodash/max';
import compact from 'lodash/compact';
import LazyLoad from 'react-lazy-load';
import ItemPreview from '@cimpress-technology/mex-preview-carousel';
import auth from './auth';
import { hasMerchantReadPermission } from './permissions-v2';
import moment from 'moment';
import { statusDisplayMessages } from '../components/detailsv2/events/eventDisplayMessages';
import { printJobRel } from '../constants/printJob';
import { MDY_DATE_FORMAT } from '../constants/dates';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { warning } from '@cimpress/react-components/lib/colors';
import { getVariablesFromItem } from './utilityfunctions';
import { getSurfaces } from '../services/introduction';

const nowrap = { whiteSpace: 'nowrap' };

const killClick = evt => {
  evt.stopPropagation();
};

const multiItemValue = (order, key, formatter) => {
  const values = uniq(order.items.map(item => (formatter ? formatter(item[key]) : item[key]))).filter(x => x);
  if (!values.length) {
    return <FormattedMessage id="ItemLevelFields.NotAvailable" />;
  }
  return values.join(', ');
};

/**
 * Gets expected ship date related data for item by checking shipment plans and 'fulfillmentDelayed' status data
 * @param {*} item
 * @returns {{ hasFulfillerMarkedAsDelayed: bool; expectedShipDate: string | undefined }}
 */
const getExpectedShipDateDataForItem = item => {
  const expectedShipDateData = {
    hasFulfillerMarkedAsDelayed: false,
    expectedShipDate: undefined,
  };
  // Find expected ship date for items marked as delayed by fulfiller
  expectedShipDateData.hasFulfillerMarkedAsDelayed = get(item.statuses, 'fulfillmentDelayed.state') === 'current';

  // Find furthest expected ship date from shipment plans in delivery configurations
  const expectedShipDateFromShipmentPlan = max(
    item.deliveryConfigurations.map(deliveryConfig => get(deliveryConfig, 'shipmentPlan.expectedShipDate'))
  );

  expectedShipDateData.expectedShipDate = expectedShipDateData.hasFulfillerMarkedAsDelayed
    ? get(item.statuses, 'fulfillment.expectedCloseDate', expectedShipDateFromShipmentPlan)
    : expectedShipDateFromShipmentPlan;

  return expectedShipDateData;
};

export const status = order => <OrderStatus order={order} />;

export const testingConfiguration = order => (
  <TestingConfigurationLabel testingConfiguration={order.testingConfiguration} />
);

export const merchant = (order, state) =>
  multiItemValue(order, 'merchantId', merchantId => {
    if (!state.merchants || state.merchants.loading) {
      return merchantId;
    }
    return get(state, `merchants.merchantsMap.${merchantId}.displayName`, merchantId);
  });
export const fulfiller = (order, state) =>
  multiItemValue(order, 'globalFulfillerId', fulfillerId => {
    if (!state.fulfillers || state.fulfillers.loading) {
      return fulfillerId;
    }
    return get(state, `fulfillers.fulfillersMap.${fulfillerId}.name`, fulfillerId);
  });
export const recipient = order => {
  // Remove fallback to an empty string after 9/1/2020
  return order.customerName && !order.customerName.includes('null') ? order.customerName : '';
};
export const createdDate = order => (
  <div style={nowrap}>{order.createdDate ? <DateDisplay date={order.createdDate} /> : 'N/A'}</div>
);
export const promiseDate = order => (
  <div style={nowrap}>{order.localPromisedArrivalDate || min(order.items.map(i => i.localPromisedArrivalDate))}</div>
);
export const customerTotal = (order, state) => {
  const canReadCustomerTotal = hasMerchantReadPermission(state.permissions.permissions, order.merchantId);
  if (!canReadCustomerTotal) {
    return null;
  }

  const customerPrices = order.items
    ? order.items
        .map(item => {
          //Remove fallback to customsInformation after 1/9/2021 - a year after CO2-3276 and every item has a pricing link
          //At that time, any permissions check for price visibility should be unnecessary, since the pricing link will fully check for it."
          return {
            basePrice: get(item, 'itemPricing.basePrice') || get(item, 'customsInformation.pricePaid.basePrice'),
            shippingPrice: get(item, 'itemPricing.shipping') || get(item, 'customsInformation.pricePaid.shippingPrice'),
            currencyCode:
              get(item, 'itemPricing.currencyCode') || get(item, 'customsInformation.pricePaid.currencyCode'),
          };
        })
        .reduce((acc, curr) => {
          acc[curr.currencyCode] = (acc[curr.currencyCode] || 0) + (curr.basePrice || 0) + (curr.shippingPrice || 0);
          return acc;
        }, {})
    : {};

  return Object.keys(customerPrices).length ? (
    Object.keys(customerPrices).map(currency => (
      <div key={currency}>
        <CurrencyDisplay position="top" currency={currency} value={customerPrices[currency]} />
      </div>
    ))
  ) : (
    <CurrencyDisplay position="top" value={0} />
  );
};

export const salesChannel = order => multiItemValue(order, 'merchantSalesChannel');
export const merchantOrderId = order => multiItemValue(order, 'merchantOrderId');
export const fulfillerOrderId = order => multiItemValue(order, 'fulfillerOrderId');
export const platformOrderId = order => multiItemValue(order, 'orderId');
export const fulfillmentGroupId = order => multiItemValue(order, 'fulfillmentGroupId');
export const shortFulfillmentGroupId = order => multiItemValue(order, 'shortFulfillmentGroupId');
export const fulfilledSkus = order => multiItemValue(order, 'skuCode');
export const orderedSkus = order => multiItemValue(order, 'orderedSkuCode');
export const platformItemId = order => multiItemValue(order, 'itemId');
export const merchantItemId = order => multiItemValue(order, 'merchantItemId');
export const fulfillerItemId = order => multiItemValue(order, 'fulfillerItemId');
export const shortItemId = order => multiItemValue(order, 'shortItemId');

export const orderType = order => {
  const hasClaim = order.items && order.items.some(item => get(item, 'linkedData.claims.results[0]', false));
  const hasComplaint = order.items && order.items.some(item => get(item, 'linkedData.complaints.results[0]', false));
  const isClaimReorder = order.items && order.items.some(item => get(item, 'linkedData.reorderClaim', false));
  const isComplaintReorder = order.items && order.items.some(item => get(item, 'linkedData.reorderComplaint', false));
  const isCustomQuote = order.items && order.items.some(item => get(item, '_links.fulfillerQuoteItem', false));
  return (
    <RelatedResourceLabels
      hasClaim={hasClaim}
      hasComplaint={hasComplaint}
      isClaimReorder={isClaimReorder}
      isComplaintReorder={isComplaintReorder}
      isCustomQuote={isCustomQuote}
    />
  );
};

const PreviewSurface = ({ order }) => {
  const [surfaces, setSurfaces] = useState({});
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const loadSurfaces = async () => {
      const tempSurfaces = {};
      const promises = [];
      order.items.forEach(item => {
        const { skuCode } = item;
        const variables = getVariablesFromItem(item);
        promises.push(getSurfaces(skuCode, variables));
      });
      const data = await Promise.all(promises);
      data.forEach(surfaceData =>
        surfaceData.surfaceGroups.forEach(group =>
          group.surfaces.forEach(surface => (tempSurfaces[surface.id] = surface.name))
        )
      );
      setSurfaces(tempSurfaces);
      setIsReady(true);
    };
    loadSurfaces();
  }, [order]);

  const handleSort = useCallback(
    items => {
      const sortFunction = function(itemA, itemB) {
        const surfaceA = surfaces[itemA.subReferenceIds] || '';
        const surfaceB = surfaces[itemB.subReferenceIds] || '';
        const hasNumbers = surfaceA.split(' ').some(e => parseInt(e));
        // If the surface has a number, order alphabetically
        if (hasNumbers) {
          return surfaceA.localeCompare(surfaceB, 'en', { numeric: true });
        }

        // If It doesn't have a number, look for Front and Back
        if (surfaceA && surfaceB) {
          // Case Front (A) is greather than Back (B)
          if (surfaceA.indexOf('Front') >= 0 && surfaceB.indexOf('Front') < 0) {
            return -1;
          }
          // Case Back (A) is lower than Front (B)
          if (surfaceA.indexOf('Front') < 0 && surfaceB.indexOf('Front') >= 0) {
            return 1;
          }
          // Case Anything (A) is greather than Back (B)
          if (surfaceA.indexOf('Back') < 0 && surfaceB.indexOf('Back') >= 0) {
            return -1;
          }
        }
        // a must be equal to b
        return 0;
      };

      return Object.keys(surfaces).length > 0 ? items.sort(sortFunction) : items;
    },
    [surfaces]
  );

  // this will avoid the preview to render without order the first time
  if (!isReady) return <></>;

  return order.items.map(item => (
    <div key={item.itemId} onClick={killClick}>
      <LazyLoad offset={300} height={150} width={150}>
        <ItemPreview
          pxSize={150}
          minimal
          hideIndicators
          docRefUrl={item.documentReferenceUrl}
          variableAttributes={item.variableAttributes}
          auth={auth}
          mcpSku={item.skuCode}
          includeMerchandising={!item.documentReferenceUrl && !item.manufacturingReadyDataUrl}
          onSortPreviews={handleSort}
        />
      </LazyLoad>
    </div>
  ));
};
export const preview = order => {
  return (
    <>
      <PreviewSurface order={order} />
    </>
  );
};

export const missedSlaTime = order => {
  const statusHourDiff = {};
  const currentTime = moment();
  order.items.forEach(item => {
    if (item.computedMissedSlas) {
      item.computedMissedSlas.forEach(missedSla => {
        const hourDiff = Math.round(moment.duration(currentTime.diff(moment(missedSla.expectedCloseDate))).asHours());
        if (!statusHourDiff[missedSla.name] || statusHourDiff[missedSla.name] < hourDiff) {
          statusHourDiff[missedSla.name] = hourDiff;
        }
      });
    }
  });
  return Object.keys(statusHourDiff).map(key => {
    const diff = statusHourDiff[key];
    return (
      <div key={`${order.orderId}-${key}`} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flexGrow: 1 }}>
          <FormattedMessage id={statusDisplayMessages[key]} />
        </div>
        <div style={{ textAlign: 'right' }}>{diff > 0 ? diff : `<1`}</div>
      </div>
    );
  });
};

export const printJob = order => {
  const isItemOnPrintJob = item => get(item, `_links["${printJobRel}"].href`);
  const someItemsOnPrintJobs = order.items && order.items.some(isItemOnPrintJob);
  const allItemsOnPrintJobs = order.items && order.items.every(isItemOnPrintJob);

  return someItemsOnPrintJobs ? (
    <FormattedMessage id="PrintJob.AllItems" />
  ) : allItemsOnPrintJobs ? (
    <FormattedMessage id="PrintJob.SomeItems" />
  ) : null;
};

export const expectedShipDate = order => {
  const expectedShipDatesData = order.items.map(item => getExpectedShipDateDataForItem(item));
  const hasFulfillerDelayedOrder = expectedShipDatesData.some(data => data.hasFulfillerMarkedAsDelayed);
  const itemsWithoutShipDateExists = expectedShipDatesData.some(data => data.expectedShipDate === undefined);
  let expectedShipDate = max(compact(expectedShipDatesData.map(data => data.expectedShipDate)));
  if (expectedShipDate) {
    expectedShipDate = moment.utc(expectedShipDate).format(MDY_DATE_FORMAT);
  }

  if (!expectedShipDate) {
    return <FormattedMessage id="ItemLevelFields.NotAvailable" />;
  }

  if (!itemsWithoutShipDateExists && !hasFulfillerDelayedOrder) {
    return expectedShipDate;
  }

  const warningIcon = <IconAlertTriangle className="text-warning" color={warning.base} weight="fill" />;
  const onlyFulfillmentDelayMessage = (
    <div>
      {warningIcon} <FormattedMessage id="FulfillmentGroup.ExpectedShipDateUpdated" />
    </div>
  );
  const onlyMissingExpectedShipDateMessage = (
    <div>
      {warningIcon} <FormattedMessage id="ItemLevelFields.ItemsWithMissingExpectedShipDates" />
    </div>
  );
  const fulfillmentDelayAndMissingExpectedDateMessage = (
    <div>
      <p>{onlyMissingExpectedShipDateMessage}</p>
      <p>{onlyFulfillmentDelayMessage}</p>
    </div>
  );
  const expectedShipDateWithWarning = (
    <div>
      {warningIcon} {expectedShipDate}
    </div>
  );

  let tooltipContents = '';

  if (itemsWithoutShipDateExists && hasFulfillerDelayedOrder) {
    tooltipContents = fulfillmentDelayAndMissingExpectedDateMessage;
  } else if (itemsWithoutShipDateExists) {
    tooltipContents = onlyMissingExpectedShipDateMessage;
  } else if (hasFulfillerDelayedOrder) {
    tooltipContents = onlyFulfillmentDelayMessage;
  }

  return <Tooltip contents={tooltipContents}>{expectedShipDateWithWarning}</Tooltip>;
};

export const itemShippedDate = order => {
  const shipmentDates = new Set();

  order.items.forEach(item => {
    const shipments = get(item, 'linkedData.shipments', []);
    shipments.forEach(shipment => {
      if (!isNaN(Date.parse(shipment.shipmentCreationDateTime))) {
        shipmentDates.add(moment.utc(shipment.shipmentCreationDateTime).format(MDY_DATE_FORMAT));
      }
    });
  });

  if (shipmentDates.size === 0) {
    return <FormattedMessage id="ItemLevelFields.NotAvailable" />;
  }

  return Array.from(shipmentDates).join(', ');
};

export const carrierServices = (order, state) => {
  let containsUnknownCarrier = false;
  const carrierServices = new Set();

  order.items.forEach(item => {
    const shipments = get(item, 'linkedData.shipments', []);
    shipments.forEach(shipment => {
      if (shipment.carrierServiceKey) {
        const carrierServicesName =
          state.carrierServices.error || state.carrierServices.loading
            ? shipment.carrierServiceKey
            : get(state, `carrierServices.carrierServicesMap.${shipment.carrierServiceKey}`);
        if (!carrierServicesName && !containsUnknownCarrier) {
          containsUnknownCarrier = true;
        } else {
          carrierServices.add(carrierServicesName);
        }
      }
    });
  });

  const warningIcon = <IconAlertTriangle className="text-warning" color={warning.base} weight="fill" />;
  const unknownCarriersTooltipContent = (
    <div>
      {warningIcon} <FormattedMessage id="ItemLevelFields.CarrierServiceNotProvided" />
    </div>
  );

  if (carrierServices.size === 0) {
    return containsUnknownCarrier ? (
      <Tooltip contents={unknownCarriersTooltipContent}>{warningIcon}</Tooltip>
    ) : (
      <FormattedMessage id="ItemLevelFields.NotAvailable" />
    );
  }

  const carrierServicesList = Array.from(carrierServices).join(', ');

  return containsUnknownCarrier ? (
    <Tooltip contents={unknownCarriersTooltipContent}>
      {warningIcon} {carrierServicesList}
    </Tooltip>
  ) : (
    carrierServicesList
  );
};
