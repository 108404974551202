/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import P from 'bluebird';
import { Alert, FlexBox } from '@cimpress/react-components/lib';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import IconShipmentPackages from '@cimpress-technology/react-streamline-icons/lib/IconShipmentPackages';
import IconShipmentBox from '@cimpress-technology/react-streamline-icons/lib/IconShipmentBox';
import { useIntl } from 'react-intl';

import { callFetch } from '../../services/serviceHelpers';
import TrackingLink from '../shared/TrackingLink';
import IdDisplay from '../shared/idDisplay';
import JSONModal from './actions/JSONModal';
import DateDisplay from '../shared/dateDisplay';
import FieldDisplay from '../detailsv2/FieldDisplay';
import AddressSection from './AddressSection';

const initialShipmentDataState = { isLoading: false, shipmentData: [], errorMessage: null };

export const useShipmentData = (shipments, itemList) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'REQUEST':
        return { isLoading: true, shipmentData: [], errorMessage: null };
      case 'SUCCESS':
        return { isLoading: false, shipmentData: action.shipmentData, errorMessage: null };
      case 'FAILURE':
        return { isLoading: false, shipmentData: [], errorMessage: action.errorMessage };
      default:
        return state;
    }
  }, initialShipmentDataState);

  const updateShipmentData = (shipmentData, itemList) => {
    //traversing through the shipment data to add the corresponding fulfillerItemId to each item in the shipmentData
    return shipmentData.map(shipmentInfo => {
      shipmentInfo.packages.map(packages => {
        return packages.items?.map(shipmentItem => {
          const item = itemList[shipmentItem.mcpItemId];
          shipmentItem.fulfillerItemId = item?.fulfillerItemId;
          shipmentItem.shortItemId = item?.shortItemId;
          shipmentItem.merchantItemId = item?.merchantItemId;
          return shipmentItem;
        });
      });
      return shipmentInfo;
    });
  };

  useEffect(() => {
    const fetchShipmentData = async () => {
      dispatch({ type: 'REQUEST' });
      try {
        const shipmentData = await P.map(shipments, ({ href }) => callFetch(href, 'GET'));
        dispatch({ type: 'SUCCESS', shipmentData: updateShipmentData(shipmentData, itemList) });
      } catch (error) {
        console.error(error.message);
        dispatch({ type: 'FAILURE', errorMessage: intl.formatMessage({ id: 'Shipments.ErrorRetrievingData' }) });
      }
    };
    if (!_.isEmpty(shipments)) {
      fetchShipmentData();
    }
  }, []);

  return state;
};

const formatPhysicalProperty = property => {
  const [value, qualifier] = _.split(property, ' ');
  return _.join([_.round(value, 2), qualifier], ' ');
};

const Package = ({
  intl,
  package: { trackingId, trackingUrl, dimensions, weight, items },
  topLevelItems,
  style = {},
}) => (
  <FlexBox style={style}>
    <IconShipmentBox size="lg" style={{ margin: '6px 20px 0px 20px' }} />
    <div>
      <FieldDisplay
        compact
        name={intl.formatMessage({ id: 'Shipments.TrackingId' })}
        value={<TrackingLink trackingId={trackingId} trackingUrl={trackingUrl} />}
      />
      <FieldDisplay
        compact
        name={intl.formatMessage({ id: 'Shipments.Dimensions' })}
        value={_.join(_.map(dimensions, formatPhysicalProperty), ' x ')}
      />
      <FieldDisplay
        compact
        name={intl.formatMessage({ id: 'Shipments.Weight' })}
        value={formatPhysicalProperty(weight)}
      />
      <FieldDisplay
        squished
        name={intl.formatMessage({ id: 'Shipments.Items' })}
        value={_.map(topLevelItems || items, item => (
          <IdDisplay
            platform={item.mcpItemId}
            merchant={item.merchantItemId}
            fulfiller={item.fulfillerItemId}
            short={item.shortItemId}
          />
        ))}
      />
      <FieldDisplay
        compact
        name={intl.formatMessage({ id: 'ItemLevelFields.FulfillerItemIDNoParam' })}
        value={_.map(topLevelItems || items, item => (
          <div key={item.fulfillerItemId}>
            {item.fulfillerItemId ? item.fulfillerItemId : intl.formatMessage({ id: 'Events.UnknownFulfillerItemID' })}
          </div>
        ))}
      />
    </div>
  </FlexBox>
);

const Shipment = ({ intl, shipment }) => (
  <FlexBox>
    <IconShipmentPackages size="lg" style={{ margin: '6px 20px 0px 20px' }} />
    <div style={{ minWidth: '400px' }}>
      <FieldDisplay compact name={intl.formatMessage({ id: 'Shipments.ShipmentId' })} value={shipment.shipmentId} />
      <FieldDisplay compact name={intl.formatMessage({ id: 'Shipments.Status' })} value={shipment.status} />
      <FieldDisplay
        compact
        name={intl.formatMessage({ id: 'Shipments.CreatedDate' })}
        value={<DateDisplay date={shipment.shipmentCreationDateTime} />}
      />
      <FieldDisplay
        name={intl.formatMessage({ id: 'Shipments.CarrierServiceKey' })}
        value={shipment.carrierServiceKey}
      />
      {shipment.packedDateTime && (
        <FieldDisplay
          name={intl.formatMessage({ id: 'Shipments.PackedDate' })}
          value={<DateDisplay date={shipment.packedDateTime} />}
        />
      )}
      <FieldDisplay
        name={intl.formatMessage({ id: 'Shipments.ShippedTo' })}
        value={
          <AddressSection
            address={{
              ...shipment.destinationAddress,
              firstName: shipment.destinationAddress.name,
            }}
            includeName
          />
        }
      />
    </div>
    <FlexBox left shouldWrap style={{ flex: '1' }}>
      {_.map(shipment.packages, p => (
        <Package
          key={p.trackingId}
          intl={intl}
          package={p}
          topLevelItems={shipment.items}
          style={{ marginBottom: '18px', maxWidth: '375px' }}
        />
      ))}
    </FlexBox>
  </FlexBox>
);

const Shipments = ({ isLoading, shipmentData, errorMessage }) => {
  const intl = useIntl();
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : errorMessage ? (
        <Alert message={errorMessage} />
      ) : (
        <>
          {_.map(shipmentData, (data, index) => (
            <div key={data.shipmentId}>
              <Shipment intl={intl} shipment={data} />
              {index < shipmentData.length - 1 ? <hr /> : null}
            </div>
          ))}
          <JSONModal
            style={{ marginTop: '10px' }}
            dataType={intl.formatMessage({ id: 'Shipments.Shipments' })}
            json={shipmentData}
            variant={'default'}
          />
        </>
      )}
    </>
  );
};

Shipments.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  shipmentData: PropTypes.arrayOf(
    PropTypes.shape({
      shipmentId: PropTypes.string.isRequired,
      carrierServiceKey: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      shipmentCreationDateTime: PropTypes.string,
      packedDateTime: PropTypes.string,
      destinationAddress: PropTypes.any.isRequired,
      packages: PropTypes.arrayOf(PropTypes.any),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          mcpItemId: PropTypes.string.isRequired,
          fulfillerItemId: PropTypes.string,
        })
      ),
    })
  ),
  errorMessage: PropTypes.string,
};

export default Shipments;
