export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const DELAYED = 'DELAYED';

export function hasError(status) {
  return Boolean(status.issues && status.issues.find(issue => issue.severity === ERROR));
}

export function hasWarning(status) {
  return Boolean(status.issues && status.issues.find(issue => issue.severity === WARNING));
}

export function hasInfo(status) {
  return Boolean(status.issues && status.issues.find(issue => issue.severity === INFO));
}

export function wasDelayed(status) {
  return Boolean(status.issues && status.issues.find(issue => issue.causeType === DELAYED));
}
