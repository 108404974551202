import * as types from '../constants/actiontypes.js';

export const initialState = {
  callbackInfo: null,
};

export default function devtools(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_CALLBACK_URL_REQUEST:
      return state;
    // TODO: loading/error handling
    // return Object.assign({}, state, {
    //   loading: true,
    //   error: null
    // });
    case types.GET_CALLBACK_URL_SUCCESS:
      return Object.assign({}, state, {
        // loading: false,
        callbackInfo: action.response,
        // error: null
      });
    case types.GET_CALLBACK_URL_FAILURE:
      return state;
    // return Object.assign({}, state, {
    //   loading: false,
    //   error: action.error
    // });
    case types.POST_CALLBACK_URL_REQUEST:
      return state;
    // return Object.assign({}, state, {
    //   loading: true,
    //   error: null
    // });
    case types.POST_CALLBACK_URL_SUCCESS:
      return Object.assign({}, state, {
        // loading: false,
        callbackInfo: action.response,
        // error: null
      });
    case types.POST_CALLBACK_URL_FAILURE:
      return state;
    // return Object.assign({}, state, {
    //   loading: false,
    //   error: action.error
    // });
    default:
      return state;
  }
}
