import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ReactSmoothCollapse from 'react-smooth-collapse';
import AttemptFailureJson from './AttemptFailureJson';
import DateDisplay from '../../shared/dateDisplay';
import { isJsonString } from '../../../utils/utilityfunctions';

export default class AttemptFailureDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };
  }

  toggleDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  render() {
    const { attempt } = this.props;

    const errorDescription =
      attempt.errorDescription && isJsonString(attempt.errorDescription) ? JSON.parse(attempt.errorDescription) : null;
    const errorMessage =
      errorDescription && errorDescription.body && (errorDescription.body.message || errorDescription.body.Message);
    const date = attempt.createdDate || attempt.attemptedDate;
    // many fields are inconsistently named across event attempts

    return (
      <div className="faux-table-row">
        <h5>
          <b>
            <FormattedMessage id="EventAttempts.AttemptId" />:
          </b>{' '}
          {attempt.attemptId}
        </h5>
        <div>
          <b>
            <FormattedMessage id="EventAttempts.EventId" />:
          </b>{' '}
          {attempt.eventId}
        </div>
        <div>
          <b>
            <FormattedMessage id="EventAttempts.AttemptDate" />:
          </b>{' '}
          <DateDisplay date={date} />
        </div>
        {attempt.merchantId ? (
          <div>
            <b>
              <FormattedMessage id="EventAttempts.MerchantId" />:
            </b>{' '}
            {attempt.merchantId}
          </div>
        ) : null}
        <div>
          <b>
            <FormattedMessage id="Global.Success" />:
          </b>{' '}
          {/* TODO: add translations */}
          {attempt.success ? 'Yes' : 'No'}
        </div>
        {attempt.attempt.source ? (
          <div>
            <b>
              <FormattedMessage id="EventAttempts.ErrorSource" />:
            </b>{' '}
            {attempt.attempt.source}
          </div>
        ) : null}
        {attempt.attempt.errorId ? (
          <div>
            <b>
              <FormattedMessage id="EventAttempts.ErrorId" />:
            </b>{' '}
            {attempt.attempt.errorId}
          </div>
        ) : null}
        {attempt.attempt.errorResponseCode ? (
          <div>
            <b>
              <FormattedMessage id="EventAttempts.ErrorStatusCode" />:
            </b>{' '}
            {attempt.attempt.errorResponseCode}
          </div>
        ) : null}
        <div>
          <b>
            <FormattedMessage id="EventAttempts.ErrorMessage" />:
          </b>{' '}
          {errorMessage || 'no message available'}&nbsp;
          <a className="clickable" onClick={this.toggleDetails}>
            <FormattedMessage id="EventAttempts.SeeDetails" />
          </a>
          <ReactSmoothCollapse expanded={this.state.showDetails}>
            <AttemptFailureJson attempt={attempt.attempt} />
          </ReactSmoothCollapse>
        </div>
      </div>
    );
  }
}

AttemptFailureDetails.propTypes = {
  attempt: PropTypes.object,
};
