/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { trackEvent, TrackingEventTitles } from '../../analytics';
import Alert from '@cimpress/react-components/lib/Alert';
import IconDownloadBottom from '@cimpress-technology/react-streamline-icons/lib/IconDownloadBottom';
import OrderLink from './OrderLink';
import { downloadToCsvBulkReorderResults } from '../../utils/csvdownload';
import { searchResultsOrderShape, merchantOrderShape } from '../propTypes';
import { useState } from 'react';
import Tooltip from '@cimpress/react-components/lib/Tooltip';

const emailLink = <a href="mailto:PlatformSupport@cimpress.com">PlatformSupport@cimpress.com</a>;

const messageStyle = { marginTop: '10px' };

const BulkReorderResults = ({ reorderSucceeded, reorderFailed, cancellationFailed, clearAlertsFailed, skipped }) => {
  const [downloading, setDownloading] = useState(false);

  const downloadResults = async () => {
    trackEvent(TrackingEventTitles.BULK_REORDER_RESULTS_DOWNLOADED, {});

    setDownloading(true);
    await downloadToCsvBulkReorderResults(
      reorderSucceeded,
      reorderFailed,
      cancellationFailed,
      clearAlertsFailed,
      skipped
    );
    setDownloading(false);
  };

  return (
    <div>
      <div style={{ paddingBottom: '20px' }}>
        {downloading ? (
          <span className="text-muted">
            <IconDownloadBottom weight="fill" />
            &nbsp;
            <FormattedMessage id="DownloadButton.Downloading" />
          </span>
        ) : (
          <Tooltip contents={<FormattedMessage id="BulkReorder.DownloadTooltip" />} direction="right">
            <a onClick={downloadResults} className="clickable" style={{ padding: 0, pointerEvents: 'auto' }}>
              <IconDownloadBottom weight="fill" />
              &nbsp;
              <FormattedMessage id="DownloadButton.Download" />
            </a>
          </Tooltip>
        )}
      </div>

      {reorderFailed.length ? (
        <Alert
          status="danger"
          title={<FormattedMessage id="BulkReorder.ReorderError" values={{ emailLink }} />}
          message={
            <div style={messageStyle}>
              {reorderFailed.map(({ order, errorMessage }) => (
                <>
                  <div key={order.orderId}>
                    <OrderLink order={order} />
                  </div>
                  <div>{errorMessage}</div>
                </>
              ))}
            </div>
          }
          dismissible={false}
        />
      ) : null}

      {cancellationFailed.length ? (
        <Alert
          status="warning"
          title={<FormattedMessage id="BulkReorder.CancellationError" values={{ emailLink }} />}
          message={
            <div style={messageStyle}>
              {cancellationFailed.map(order => (
                <div key={order.orderId}>
                  <OrderLink order={order} />
                </div>
              ))}
            </div>
          }
          dismissible={false}
        />
      ) : null}

      {clearAlertsFailed.length ? (
        <Alert
          status="warning"
          title={<FormattedMessage id="BulkReorder.ClearAlertsError" values={{ emailLink }} />}
          message={
            <div style={messageStyle}>
              {clearAlertsFailed.map(order => (
                <div key={order.orderId}>
                  <OrderLink order={order} />
                </div>
              ))}
            </div>
          }
          dismissible={false}
        />
      ) : null}

      {skipped.length ? (
        <Alert
          status="info"
          title={<FormattedMessage id="BulkReorder.SkippedMessage" />}
          message={
            <div style={messageStyle}>
              {skipped.map(order => (
                <div key={order.orderId}>
                  <OrderLink order={order} />
                </div>
              ))}
            </div>
          }
          dismissible={false}
        />
      ) : null}

      {reorderSucceeded.length ? (
        <Alert
          status="success"
          title={<FormattedMessage id="BulkReorder.Success" />}
          message={
            <table style={messageStyle} className="table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="BulkReorder.OriginalOrder" />
                  </th>
                  <th>
                    <FormattedMessage id="BulkReorder.ReorderPlatformOrderId" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {reorderSucceeded.map(({ originalOrder, reorder }) => (
                  <tr key={originalOrder.orderId}>
                    <td>
                      <OrderLink order={originalOrder} />
                    </td>
                    <td>
                      <a
                        href={`${process.env.REACT_APP_OOPS_UI_URL}/orders/${reorder.orderId}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        {reorder.orderId}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
          dismissible={false}
        />
      ) : null}
    </div>
  );
};

BulkReorderResults.propTypes = {
  reorderSucceeded: PropTypes.arrayOf(
    PropTypes.shape({ originalOrder: searchResultsOrderShape, reorder: merchantOrderShape })
  ),
  reorderFailed: PropTypes.arrayOf(searchResultsOrderShape),
  cancellationFailed: PropTypes.arrayOf(searchResultsOrderShape),
  clearAlertsFailed: PropTypes.arrayOf(searchResultsOrderShape),
  skipped: PropTypes.arrayOf(searchResultsOrderShape),
};

const mapStateToProps = ({
  bulkReorder: { reorderSucceeded, reorderFailed, cancellationFailed, clearAlertsFailed, skipped },
}) => ({ reorderSucceeded, reorderFailed, cancellationFailed, clearAlertsFailed, skipped });

export default connect(mapStateToProps)(injectIntl(BulkReorderResults));
