import React, { useEffect } from 'react';
import DateDisplay from '../../shared/dateDisplay';
import { FlexBox } from '@cimpress/react-components';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { capitalize } from 'lodash';
import { trackEvent, TrackingEventTitles, TrackingEventPropertiesNames } from '../../../analytics';

const NoWrapText = styled.div`
  white-space: nowrap;
  text-align: left;
`;

const DateRangePopover = ({ startDate, endDate, duration, status, state }) => {
  const hasEndDate = !!endDate && state !== 'current';
  const hasDuration = !!endDate;

  useEffect(() => {
    trackEvent(TrackingEventTitles.HOVER_OVER_ITEM_HISTORY_STATUS_PROGRESS_BAR, {
      [TrackingEventPropertiesNames.STATUS_OVERVIEW.HOVER_STATUS_STATE]: state,
      [TrackingEventPropertiesNames.STATUS_OVERVIEW.HOVER_STATUS_NAME]: status,
      [TrackingEventPropertiesNames.STATUS_OVERVIEW.HOVER_STATUS_DURATION]: hasDuration ? duration : 'N/A',
    });
  }, []);

  return (
    <FlexBox>
      <div style={{ marginRight: '5px', textAlign: 'left' }}>
        <NoWrapText>
          <FormattedMessage id="StatusOverview.ItemHistoryTooltipStartDate" />
        </NoWrapText>
        {hasEndDate && (
          <NoWrapText>
            <FormattedMessage id="StatusOverview.ItemHistoryTooltipEndDate" />
          </NoWrapText>
        )}
        {hasDuration && (
          <NoWrapText>
            <FormattedMessage id="StatusOverview.ItemHistoryTooltipDuration" />
          </NoWrapText>
        )}
        <NoWrapText>
          <FormattedMessage id="StatusOverview.ItemHistoryTooltipStatus" />
        </NoWrapText>
      </div>
      <div>
        <NoWrapText>
          <DateDisplay date={startDate} />
        </NoWrapText>
        {hasEndDate && (
          <NoWrapText>
            <DateDisplay date={endDate} />
          </NoWrapText>
        )}
        {hasDuration && (
          <NoWrapText>
            <FormattedMessage
              id="StatusOverview.ItemHistoryTooltipDurationInfo"
              values={{ duration: capitalize(duration) }}
            />
          </NoWrapText>
        )}
        <NoWrapText>
          <FormattedMessage id="StatusOverview.ItemHistoryTooltipStatusInfo" values={{ status: capitalize(state) }} />
        </NoWrapText>
      </div>
    </FlexBox>
  );
};

export default DateRangePopover;
