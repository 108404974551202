import * as types from '../constants/actiontypes.js';
import { getAvailableFilters } from '../services/dashboard-service';
import { getTree } from '../services/catalogSchema';

export const loadAvailableFilters = () => async dispatch => {
  dispatch({ type: types.GET_AVAILABLE_FILTERS_REQUEST });
  try {
    const [response, productCategories] = await Promise.all([getAvailableFilters(), getTree()]);
    return dispatch({
      type: types.GET_AVAILABLE_FILTERS_SUCCESS,
      response: { ...response, productCategories },
    });
  } catch (error) {
    return dispatch({ type: types.GET_AVAILABLE_FILTERS_FAILURE, error });
  }
};
