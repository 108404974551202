import React from 'react';

import ItemSelector from './ItemSelector';
import useRedirectContext, { isSelectedForRedirection, areAllItemsSelectedForRedirection } from './RedirectContext';

const SelectItems = ({ items }) => {
  const { state, dispatch } = useRedirectContext();

  return (
    <ItemSelector
      items={items}
      selectAll={() => dispatch({ type: 'TOGGLE_ALL_ITEMS_FOR_REDIRECTION' })}
      areAllSelected={areAllItemsSelectedForRedirection(state)}
      selectItem={itemId => dispatch({ type: 'TOGGLE_ITEM_FOR_REDIRECTION', payload: { itemId } })}
      isSelected={itemId => isSelectedForRedirection(state, itemId)}
    />
  );
};

export default SelectItems;
