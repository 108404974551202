import { CALL_API } from '../middleware/api';
import * as types from '../constants/actiontypes.js';
import pickBy from 'lodash/pickBy';

const BASE_URL = process.env.REACT_APP_AGGREGATION_URL;

export function getOrderActions(orderId) {
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.GET_ORDER_ACTIONS_REQUEST, types.GET_ORDER_ACTIONS_SUCCESS, types.GET_ORDER_ACTIONS_FAILURE],
        endpoint: `${BASE_URL}/v1/orders/${orderId}/actions`,
        method: 'GET',
      },
    });
  };
}

export function changeDelivery(orderId, fulfillmentGroupId, address) {
  const destinationAddress = pickBy(address, val => val); // only use truthy values
  const json = {
    fulfillmentGroupChangeRequests: [
      {
        fulfillmentGroupId,
        destinationAddress,
      },
    ],
  };
  return dispatch => {
    return dispatch({
      [CALL_API]: {
        types: [types.CHANGE_DELIVERY_REQUEST, types.CHANGE_DELIVERY_SUCCESS, types.CHANGE_DELIVERY_FAILURE],
        endpoint: `${BASE_URL}/v1/orders/${orderId}/deliverychangerequests`,
        method: 'POST',
        body: JSON.stringify(json),
      },
    });
  };
}
